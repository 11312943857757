import { FormInput } from 'Components';

export const InputField = ({ custom_field, onChange, validationMessages }) => {
  const { id, required, label } = custom_field;
  return (
    <FormInput
      key={id}
      label={label}
      required={required}
      onChange={(e) => {
        onChange(e, id);
      }}
      validationMessages={validationMessages}
    />
  );
};
