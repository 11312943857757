import { FormInput } from 'Components';
import { IMaskInput } from 'react-imask';
import { v1 as uuidv1 } from 'uuid';
import { setObjectValue } from 'Helpers';

function TextMaskCustom(props) {
  const { inputRef, onChange, ...other } = props;
  function handleChange(e) {
    const trimedValue = e.target.value.replace(/[\s|\(|\)|-]*/g, '');
    onChange(setObjectValue(e, 'target.value', trimedValue));
  }
  return (
    <IMaskInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.element : null);
      }}
      mask={'+{38}(000)000-00-00'}
      onChange={handleChange}
      variant="outlined"
      placeholderChar={'\u2000'}
    />
  );
}

export const PhoneInput = React.memo(({ value, name, onChange, ...props }) => (
  <>
    <input type="hidden" name={name} value={value} />
    <FormInput
      InputProps={{
        inputComponent: TextMaskCustom,
        onChange,
        value: value || undefined,
      }}
      {...props}
    />
  </>
));

PhoneInput.defaultProps = {
  id: uuidv1(),
  margin: 'dense',
};
