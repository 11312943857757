import { Fragment } from 'react';
import { deleteItem } from 'Helpers';
import { useTranslation } from 'react-i18next';
import { ListWrapper, Table, ListActions, Button } from 'Components';
import { useList } from 'Hooks';
import notificationsStore from 'Helpers/notifications';

const DEFAULT_SORT_PARAMS = {
  sort_column: 'order_position',
  sort_direction: 'asc',
};

export default ({ url, new_item_url, fetch_url, services_url }) => {
  const { t } = useTranslation();
  const {
    pending,
    data,
    records_total,
    paginationParams,
    handleChangePagination,
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  function onDeleteItem(res) {
    if (res.status === 'error') {
      return notificationsStore.add({ type: 'error', text: res.errors });
    }

    location.reload();
  }

  const tableConfig = [
    {
      title: t('Position'),
      accessor: 'order_position',
      sortable: true,
    },
    {
      title: t('Name'),
      accessor: 'name',
      sortable: true,
      cellComponent: (item) => {
        return <div dangerouslySetInnerHTML={{ __html: item.name }}></div>;
      },
    },
    {
      title: t('Services'),
      accessor: 'services',
      cellComponent: (item) => {
        return item.services.map((service, index) => {
          return (
            <Fragment key={service.id}>
              <a
                target="_blank"
                href={`${services_url}/${service.id}/edit`}
              >
                {service.name}
              </a>
              {index !== item.services.length - 1 && ', '}
            </Fragment>
          );
        });
      },
    },
    {
      title: t('Locations'),
      accessor: 'locations',
      cellComponent: (item) => {
        return item.locations.map(({ id, name }) => (
          <div
            key={id}
            dangerouslySetInnerHTML={{ __html: name }}
          ></div>
        ));
      },
    },
    {
      title: t('Active'),
      accessor: 'active',
      cellComponent: (item) => (item.active ? '+' : '-'),
    },
    {
      title: t('Subscription'),
      accessor: 'subscription',
      cellComponent: (item) => (item.subscription ? '+' : '-'),
    },
    {
      title: t('Price'),
      accessor: 'price',
    },
    {
      title: t('Online Payment Key'),
      accessor: 'online_payment_key',
      sortable: true,
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }) => (
        <ListActions
          editUrl={`${url}/${id}/edit`}
          onDelete={deleteItem(fetch_url, { id }, onDeleteItem)}
        />
      ),
    },
  ];

  return (
    <ListWrapper
      title={`${t('Online Services')} (${records_total})`}
      headerAddon={
        <div>
          <Button
            href={new_item_url}
            variant="contained"
            color="primary"
          >
            {t('Create Online Service')}
          </Button>
        </div>
      }
    >
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
};
