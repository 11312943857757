import {
  Grid,
  makeStyles,
  Chip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  ListWrapper,
  FormControl,
  DatePicker,
  Chart,
  ServicesPickerDialog,
  Button
} from 'Components';
import { removeFromArray } from 'Helpers';

const useStyles = makeStyles(() => ({
  servicesFormGroup: {
    width: '100%',
    cursor: 'pointer'
  },
  chip: {
    height: 'auto',
    justifyContent: 'space-between',
    marginBottom: '2px'
  },
  submitRow: {
    marginBottom: '20px',
    marginTop: '20px',

    '& button': {
      display: 'block',
      marginLeft: 'auto'
    }
  },
}))

export const PurchasedServicesChart = ({ title, pending, data, validators, servicesToDisplay, fetchData }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [filter, setFilter] = React.useState({
    services: [],
    from: moment().format(),
    till: moment().add(1, 'month').format(),
  });

  const servicesPicker = React.useRef(null);

  function onServicesDialogOpen() {
    servicesPicker.current.open(filter.services, onServicesChange)
  }

  function onServicesChange(services) {
    setFilter({
      ...filter,
      services
    })
  }

  function onStartPeriodChange({ target }) {
    setFilter({
      ...filter,
      from: target.value
    })
  }

  function onEndPeriodChange({ target }) {
    setFilter({
      ...filter,
      till: target.value
    })
  }

  function onServiceDelete(id) {
    setFilter({ ...filter, services: removeFromArray(filter.services, id) });
  }

  function onFetch() {
    fetchData(filter)
  }

  const isChartEmpty = React.useMemo(() => {
    return Object.keys(data).length === 0
  }, [data])

  return (
    <ListWrapper title={t(title)}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={4}
        >
          <FormControl
            className={classes.servicesFormGroup}
            onClick={onServicesDialogOpen}
            label={t('Services')}
            name='services'
            isRequired
          >
            {filter.services.map(({ id, name }) => (
              <Chip
                key={id}
                className={classes.chip}
                label={(<p className={classes.chipLabel}>{name}</p>)}
                variant="outlined"
                onDelete={() => onServiceDelete(id)}
              />
            ))}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <DatePicker
            label={t('From')}
            value={filter.from}
            name='from'
            onChange={onStartPeriodChange}
            required
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <DatePicker
            label={t('Till')}
            value={filter.till}
            onChange={onEndPeriodChange}
            required
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.submitRow}
      >
        <Button
          type="button"
          onClick={onFetch}
        >
          {t('Filter')}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
      >
        {!isChartEmpty && (
          <Chart
            type="column"
            tooltipHeaderText={t('Date')}
            yText={t('Count')}
            tooltip={{
              headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
              footerFormat: '</table>',
              useHTML: true
            }}
            yAxis={{
              stackLabels: {
                enabled: true,

              }
            }}
            plotOptions={{
              column: {
                stacking: 'normal',
                dataLabels: {
                  enabled: false
                }
              }
            }}
            pending={pending}
            {...data}
          />
        )}
      </Grid>
      <ServicesPickerDialog
        ref={servicesPicker}
        services={servicesToDisplay}
      />
    </ListWrapper>
  )
}
