import { useRef, useState, useEffect, ChangeEvent } from 'react';
import { FormControl as MFormControl, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useForm } from 'Hooks';
import {
  FormWrapper,
  Form,
  FormInput,
  TextEditor,
  FormControl,
  Select,
  Checkbox,
} from 'Components';

import notificationsStore from 'Helpers/notifications';

import { BookingEmailTemplateFormProps } from './type';

const useStyles = makeStyles(() => ({
  textToCopy: {
    position: 'absolute',
    opacity: 0,
  },
  editorParametrs: {
    width: '40%',
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
}));

export const BookingEmailTemplateForm = ({
  booking_email_template,
  url,
  api_url,
}: BookingEmailTemplateFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    item,
    action,
    method,
    isItemInvalid,
    changeValue,
    submit,
    setServerErrors,
  } = useForm(booking_email_template, {}, api_url);

  const [buffer, setBuffer] = useState('');

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const onRequestStart = () => setIsFormSubmitting(true);
  const onRequestEnd = () => setIsFormSubmitting(false);

  const copyToBufferInputRef = useRef<HTMLInputElement>(null!);

  const onSubjectValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeValue('subject', e.target.value);
  };

  const onActiveChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeValue('active', e.target.checked);
  };

  const SUBJECT_PARAMETRS = [
    { id: '{{booking_location}}', name: t('Booking location') },
    { id: '{{booking_service_name}}', name: t('Booking service name') },
    { id: '{{booking_date}}', name: t('Booking date') },
    { id: '{{booking_provider}}', name: t('Booking provider') },
  ];

  const BODY_PARAMETRS = [
    { id: '{{client_name}}', name: t('Client first name') },
    ...SUBJECT_PARAMETRS,
    {
      id: '{{booking_service_description}}',
      name: t('Booking service description'),
    },
  ];

  useEffect(() => {
    if (!buffer) return;
    copyToBufferInputRef.current.select();
    document.execCommand('copy');
    notificationsStore.add({ type: 'success', text: t('Successfully copied') });
  }, [buffer]);

  const onCopyToBuffer = (e: ChangeEvent<HTMLInputElement>) => {
    setBuffer(e.target.value);
  };

  return (
    <>
      <Form
        item={item}
        name="booking_email_template"
        action={action}
        method={method}
        disabled={isItemInvalid}
        onSubmit={submit}
        onRequestStart={onRequestStart}
        onRequestEnd={onRequestEnd}
        onError={setServerErrors}
        encType="multipart/form-data"
      >
        <FormWrapper
          backUrl={url}
          title="Booking Email Template"
          item={item}
          disabled={isFormSubmitting}
        >
          <FormControl
            fullWidth
            label={t('Subject')}
          >
            <div className={classes.editorParametrs}>
              <Select
                value={''}
                options={SUBJECT_PARAMETRS}
                label={t('Subject parametrs')}
                onChange={onCopyToBuffer}
              />
            </div>
            <FormInput
              value={item.subject}
              label={''}
              name="booking_email_template[subject]"
              onChange={onSubjectValueChange}
            />
          </FormControl>
          <FormControl
            fullWidth
            label={t('Body')}
          >
            <div className={classes.editorParametrs}>
              <Select
                value={''}
                label={t('Body parametrs')}
                options={BODY_PARAMETRS}
                onChange={onCopyToBuffer}
              />
            </div>
            <TextEditor
              value={item.body}
              name="booking_email_template[body]"
              multipartFormData
            />
          </FormControl>
          <MFormControl fullWidth>
            <Checkbox
              checked={item.active}
              onChange={onActiveChange}
              value={item.active}
              label={t('Active')}
            />
            <input
              value={item.active ? 'true' : 'false'}
              hidden
              readOnly
              name="booking_email_template[active]"
            />
          </MFormControl>
        </FormWrapper>
      </Form>
      <input
        className={classes.textToCopy}
        value={buffer}
        readOnly
        ref={copyToBufferInputRef}
      />
    </>
  );
};
