import {
  Button,
  Link,
} from '@material-ui/core';
import { getParamFromQueryString } from 'Helpers';
import { FormInput } from 'Components';
import { passwordValidator } from 'Helpers/validation';
import { useForm } from 'Hooks';

const validators = {
  password: passwordValidator(),
};

export default ({ useCommonStyles, authenticity_token, login_url, new_confirmation_url, change_password_url }) => {
  const classes = useCommonStyles();

  const resetPasswordToken = React.useMemo(() => {
    return getParamFromQueryString('reset_password_token')
  }, []);

  const {
    item,
    changeValue,
    submit,
    isItemInvalid,
    validationMessagesToDisplay,
  } = useForm({ password: '', password_confirmation: '' }, validators);

  function handleChangePassword({ target }) {
    changeValue('password', target.value);
  }

  function handleChangeConfirmation({ target }) {
    changeValue('password_confirmation', target.value);
  }

  const formRef = React.useRef(null);

  function handleSubmit(e) {
    e.preventDefault();
    submit()
    if (!!isItemInvalid) {
      return
    }
    formRef.current.submit()
  }

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit} className={classes.form} action={change_password_url} method="post">
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value="put" />
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticity_token}
        />
        <input
          type="hidden"
          name="client[reset_password_token]"
          value={resetPasswordToken}
        />
        <FormInput
          name="client[password]"
          label="Пароль"
          type="password"
          value={item.password}
          onChange={handleChangePassword}
          validationMessages={validationMessagesToDisplay.password}
          required
          autoComplete="current-password"
        />
        <FormInput
          name="client[password_confirmation]"
          label="Пiдтвердження пароля"
          type="password"
          validationMessages={validationMessagesToDisplay.password}
          value={item.password_confirmation}
          onChange={handleChangeConfirmation}
          required
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Змiнити Пароль
        </Button>
        <Link href={login_url}>Вхiд</Link>
        <br />
        <Link href={new_confirmation_url}>Не отримали інструкцій щодо пiдтвердження?</Link>
      </form>
    </>
  )
}