import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ListWrapper, Table } from 'Components';
import { useList } from 'Hooks';

export const ServiceUsageHistoryExports = ({ fetch_url }) => {
  const { t } = useTranslation();

  const tableConfig = [
    {
      title: t('Filename'),
      accessor: 'filename',
    },
    {
      title: t('Status'),
      accessor: 'status',
    },
    {
      title: t('Error'),
      accessor: 'error',
      cellComponent: ({ error }) => {
        return error || '-'
      }
    },
    {
      title: t('Actions'),
      cellComponent: ({ download_path }) => {
        if (!!download_path) {
          return (
            <Link href={download_path}>{t('Download')}</Link>
          );
        }
      },
    },
  ];

  const { pending, data } = useList({ fetchUrl: fetch_url, pagination: {} });

  return (
    <ListWrapper title={t('Service Usage History Exports')}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
      />
    </ListWrapper>
  );
};
