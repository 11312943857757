import { TextField, InputAdornment, } from '@material-ui/core';
let timeoutInstance = null;

export const SearchInput = ({ value = '', name = 'search', onChange, timeout = 500, className, ...props }) => {
  const [inputValue, setInputValue] = React.useState(value);

  function handleChange({ target }) {
    setInputValue(target.value);
    if (timeoutInstance) {
      clearTimeout(timeoutInstance);
    }
    timeoutInstance = setTimeout(function () {
      onChange({ [name]: target.value });
    }, timeout);
  }

  return (
    <TextField className={className}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <i className="icon-search"></i>
          </InputAdornment>
        ),
      }} value={inputValue} onChange={handleChange}
      {...props}
    />
  )
};
