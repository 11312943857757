import { makeStyles, FormControl as MFormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  FormWrapper, Form, FormInput, FormControl, ImageUpload, Checkbox, TextEditor
} from 'Components';
import { checkRequired } from 'Helpers/validation';
import { useForm } from 'Hooks';

const validators = {
  name: checkRequired('name'),
  address_link: checkRequired('address_link')
};

const useStyles = makeStyles(() => ({
  imagePreview: {
    maxHeight: '350px',
    objectFit: 'contain'
  },
}));

export const LocationForm = ({ url, location, api_url, }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    item, changeValue, action, method, isItemInvalid, validationMessagesToDisplay,
    submit, setServerErrors
  } = useForm(location, validators, api_url);

  const onNameChange = ({ target }) => {
    changeValue('name', target.value);
  }

  const onWebsiteChange = ({ target }) => {
    changeValue('website_link', target.value);
  }

  const onAddressChange = ({ target }) => {
    changeValue('address_link', target.value);
  }

  function onPhotoChange(photo) {
    changeValue('photo', photo);
  }

  function onActiveForBookingChange({ target }) {
    changeValue('active_for_booking', target.checked);
  }

  function onVatEnabledChange({ target }) {
    changeValue('vat_enabled', target.checked);
  }

  return (
    <Form name="location" action={action} method={method} validators={validators}
      disabled={isItemInvalid} item={item} onSubmit={submit}
      onError={setServerErrors} encType="multipart/form-data">
      <FormWrapper title="Location" item={item} backUrl={url}>
        <FormInput name="location[name]" validationMessages={validationMessagesToDisplay.name}
          value={item.name} label={t('Name')} onChange={onNameChange}
          autoFocus required />
          <FormInput name="location[website_link]" validationMessages={validationMessagesToDisplay.website_link}
          value={item.website_link || ''} label={t('Website')} onChange={onWebsiteChange}
          autoFocus />
          <FormInput name="location[address_link]" validationMessages={validationMessagesToDisplay.address_link}
          value={item.address_link || ''} label={t('Location on Google Maps')} onChange={onAddressChange}
          autoFocus required/>
        <FormControl validationMessages={validationMessagesToDisplay.photo}
          fullWidth>
          <ImageUpload value={item.photo} url={item.photo_url}
            label={t('Choose Image File')} onChange={onPhotoChange}
            name="location[photo]" previewClassName={classes.imagePreview}
            hasSizeLimit />
        </FormControl>
        <MFormControl fullWidth>
          <Checkbox
            checked={item.active_for_booking || false}
            onChange={onActiveForBookingChange}
            label={t('Active For Booking')}
          />
          <input name="location[active_for_booking]" value={item.active_for_booking ? 'true' : 'false'}
            hidden readOnly />
        </MFormControl>
        <MFormControl fullWidth>
          <Checkbox
            checked={item.vat_enabled || false}
            onChange={onVatEnabledChange}
            label={t('VAT enabled')}
          />
          <input name="location[vat_enabled]" value={item.vat_enabled ? 'true' : 'false'}
            hidden readOnly />
        </MFormControl>
        <FormControl fullWidth label={t('Booking description')}
          validationMessages={validationMessagesToDisplay.booking_description}>
          <TextEditor value={item.booking_description}
            name="location[booking_description]" multipartFormData />
        </FormControl>
      </FormWrapper>
    </Form>
  );
};

LocationForm.defaultProps = {
  location: {
    name: '',
    photo: '',
    website_link: '',
    address_link: '',
    active_for_booking: false,
    booking_description: '',
    vat_enabled: false
  }
};
