export const BookingsByPaymentDates = {
  en: {
    'Paid Bookings By Payment Dates': 'Paid Bookings By Payment Dates',
    'Date': 'Date',
    'Count': 'Count',
  },
  uk: {
    'Paid Bookings By Payment Dates': 'Сплачені бронювання по датах платежів',
    'Date': 'Дата',
    'Count': 'Кількість',
  },
};
