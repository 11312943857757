export const Trainers = {
  en: {
    'Full Name': 'Full Name',
    'Create Trainer': 'Create Trainer',
    'locations': 'Locations',
    'Active': 'Active'
  },
  ru: {
    'Full Name': 'Имя',
    'Create Trainer': 'Создать Тренера',
    'locations': 'Локации',
    'Active': 'Активный'
  },
  uk: {
    'Full Name': "Ім'я",
    'Create Trainer': 'Створити Тренера',
    'locations': 'Локації',
    'Active': 'Активний'
  }
}

export const TrainerForm = {
  en: {
    ...Trainers.en,
    'New Trainer': 'New Trainer',
    'Edit Trainer': 'Edit Trainer',
    'Update Trainer': 'Update Trainer',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
  },
  ru: {
    ...Trainers.ru,
    'New Trainer': 'Новый Тренер',
    'Edit Trainer': 'Редактирование Инструктора',
    'Update Trainer': 'Обновить Инструктора',
    'First Name': 'Имя',
    'Last Name': 'Фамилия',
  },
  uk: {
    ...Trainers.uk,
    'New Trainer': 'Новий Тренер',
    'Edit Trainer': 'Редагування Тренера',
    'Update Trainer': 'Оновити Тренера',
    'First Name': "Ім'я",
    'Last Name': 'Прізвище',
  },
}
