import { memo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  makeStyles,
} from '@material-ui/core';

import { findInArrayBy } from 'Helpers';

import Service from './Service';

import { ServicesListProps } from './types';
import { Service as ServiceType } from '../../types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  servicesList: {
    width: '100%',
  },
}));

const ServicesList = memo((props: ServicesListProps) => {
  const {
    services,
    cart,
    expandedCategory,
    onCategoryExpandToggle,
    onServiceCheckedToogle,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {Object.keys(services).map((categoryName: string) => (
        <Accordion
          key={categoryName}
          expanded={expandedCategory === categoryName}
          onChange={() => onCategoryExpandToggle(categoryName)}
        >
          <AccordionSummary expandIcon={<i className=" icon-down-open"></i>}>
            <Typography className={classes.heading}>{categoryName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List className={classes.servicesList}>
              {services[categoryName].map((service: ServiceType) => (
                <Service
                  key={service.id}
                  service={service}
                  isChecked={!!findInArrayBy(cart, service.id)}
                  onServiceCheckedToogle={onServiceCheckedToogle}
                />
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
});

export default ServicesList;