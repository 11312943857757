import {
  Grid, makeStyles, Table as MaterialTable, TableContainer,
  TableBody, TableCell, TableRow, Box, IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  FormWrapper, FormControl, FormInput, Button, ServicesPickerDialog,
  Counter
} from 'Components';
import { updateItemInArray, findInArrayBy, removeFromArray, AJAX, toMoneyFormat, getSerializedPaymentTypes } from 'Helpers';
import { checkRequiredArray } from 'Helpers/validation';
import { useForm, usePaymentTypesForm } from 'Hooks';
import notificationsStore from 'Helpers/notifications';

import PaymentTypes from 'pages/clients/show/BuyServiceDialog/PaymentTypes';

const validators = {
  services: checkRequiredArray('services'),
};

const useStyles = makeStyles((theme) => ({
  servicesContainer: {
    border: `1px solid  ${theme.palette.text.disabled}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
  },
  servicesContainerInvalid: {
    borderColor: theme.palette.error.main,
  },
  servicesHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchContainer: {
    textAlign: 'right',
  },
  selectServicesBtn: {
    width: '50%'
  },
  settingsCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  counterWrapper: {
    marginRight: '12px'
  },
  totalWrapper: {
    textAlign: 'right',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',

  }
}));

export const CertificateForm = ({ api_url, url, payment_types, services, certificate }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const {
    item,
    changeValue,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    setServerErrors,
    submit,
    submitted
  } = useForm(certificate, validators, api_url);

  const paymentTypesForm = usePaymentTypesForm(payment_types,item.services);
  const {paymentTypesValidationMessages} = paymentTypesForm;

  const onCommentChange = ({ target }) => {
    changeValue('comment', target.value);
  }

  const servicesPicker = React.useRef(null);

  const onServicesChange = (selectedServices) =>  {
    changeValue('services', selectedServices);
  }

  const onServicesDialogShow = () =>  {
    servicesPicker.current.open(item.services, onServicesChange)
  }

  const onServiceDelete = (id) =>  {
    changeValue('services', removeFromArray(item.services, id));
  }

  const onServiceInitialCountChange = (value, id) =>  {
    const currentService = findInArrayBy(item.services, id);
    const updatedItem = {
      ...currentService,
      initial_count: value
    }
    const updatedServices = updateItemInArray(item.services, updatedItem, id)
    changeValue('services', updatedServices);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    submit();
    if (!!isItemInvalid || !!paymentTypesValidationMessages.payment_types) {
      return
    }

    const { services, payment_type, ...values } = item;
    const body = {
      certificate: {
        ...values,
        certificate_options_attributes: services.map(({ initial_count, id }) => {
          return {
            service_id: id,
            initial_count
          }
        })
      },
      payment_types: getSerializedPaymentTypes(paymentTypesForm.paymentTypes),
    }

    const request = await AJAX[method](action, {
      body
    })

    if (!!request.errors) {
      setServerErrors(request.errors);
      notificationsStore.add({ type: 'error', text: request.errors });
    }
  }

  return (
    <>
      <form onSubmit={onSubmit} noValidate>
        <FormWrapper backUrl={url} title="Certificate" item={item}>
          <Grid container spacing={2} className={classes.servicesConainer}>
            <Grid item xs={12}>
              <FormInput
                label={t('Comment')}
                name="certificate[comment]"
                value={item.comment}
                onChange={onCommentChange}
              />
            </Grid>
            <Grid item xs={12} >
              <FormControl
                fullWidth
                label={t('Services')}
                validationMessages={validationMessagesToDisplay.services}
              >
                <Button color="primary" className={classes.selectServicesBtn} onClick={onServicesDialogShow}>
                  {t('Select services')}
                </Button>
                <TableContainer>
                  <MaterialTable>
                    <TableBody>
                      {item.services.map((service) => (
                        <TableRow key={service.id}>
                          <TableCell >
                            <IconButton size="small" onClick={() => onServiceDelete(service.id)}>
                              <i className="icon-trash-empty"></i>
                            </IconButton>
                            {service.name}
                          </TableCell>
                          <TableCell>
                            <Box className={classes.settingsCell}>
                              <Box className={classes.counterWrapper}>
                                <Counter count={service.initial_count} id={service.id} onChange={onServiceInitialCountChange} />
                              </Box>
                              {toMoneyFormat(service.price * service.initial_count)}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </MaterialTable>
                </TableContainer>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                label={t('Payment Types')}
                validationMessages={submitted && paymentTypesValidationMessages.payment_types}
              >
               <PaymentTypes payment_types={paymentTypesForm} />
              </FormControl>
            </Grid>
          </Grid>
        </FormWrapper>
      </form>
      <ServicesPickerDialog ref={servicesPicker} services={services} />
    </>
  );
};

CertificateForm.defaultProps = {
  certificate: {
    comment: '',
    services: [],
    payment_type: '',
  },
}
