import { DatePicker } from '@material-ui/pickers';
import { SEND_DATES_FORMAT, DISPLAY_DATES_FORMAT } from 'Constants';
import { useDatePicker } from 'Hooks';

export const BirthdayDatePicker = ({ value, validationMessages, onChange, name, dateFormat = SEND_DATES_FORMAT, displayDateFormat = DISPLAY_DATES_FORMAT, ...props }) => {
  const {inputValue, handleChange} = useDatePicker(value, onChange, dateFormat, displayDateFormat);
  return (
    <>
      <DatePicker
        disableFuture
        openTo="year"
        format="dd/MM/yyyy"
        label="Date of birth"
        views={["year", "month", "date"]}
        value={inputValue}
        onChange={handleChange}
        format={displayDateFormat}
        inputVariant="outlined"
        variant="dialog"
        margin="dense"
        error={!!validationMessages}
        helperText={validationMessages}
        fullWidth
        {...props}
      />
    </>
  );
};