import { useTranslation, } from 'react-i18next';
import { ListWrapper, Table, ListActions, Button, } from 'Components';
import { useList } from 'Hooks';

const DEFAULT_SORT_PARAMS = {
  sort_column: 'first_name',
  sort_direction: 'asc'
}

export const Trainers = ({ url, new_item_url, fetch_url }) => {
  const { t } = useTranslation();
  const {
    pending, data, records_total, paginationParams, handleChangePagination,
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  const tableConfig = [
    {
      title: t('Full Name'),
      accessor: 'name',
    },
    {
      title: t('locations'),
      accessor: 'locations',
      cellComponent: ({ locations }) => locations.map(({ name }) => name).join(', ')
    },
    {
      title: t('Active'),
      accessor: 'active',
      cellComponent: ({ active }) => !!active ? "+" : "-"
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }) => (
        <ListActions editUrl={`${url}/${id}/edit`} />
      )
    }
  ];

  return (
    <ListWrapper title={`${t('Trainers')} (${data.length})`} headerAddon={(
      <div>
        <Button href={new_item_url} variant="contained" color="primary">
          {t('Create Trainer')}
        </Button>
      </div>
    )}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
}
