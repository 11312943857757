import Highcharts from 'highcharts';
import { makeStyles } from '@material-ui/core';
import { Preloader } from 'Components';

const useStyles = makeStyles(() => ({
  container: {},
  preloader: {
    height: '120px',
  },
}));

export const Chart = React.memo(
  ({ pending, label, categories, data, type, yText, tooltipHeaderText, series, crosshair, tooltip = {}, yAxis = {}, plotOptions = {} }) => {
    const classes = useStyles();
    const container = React.useRef(null);

    function formatTooltipSeparate() {
      return this.points.reduce(function (s, point) {
        return s + '<br/>' + point.series.name + ': ' +
          point.y;
      }, `<b>${this.x}</b>`);
    }

    function formatTooltipTotal() {
      return `${tooltipHeaderText} : <b>${this.x}</b><br/>${yText}: <b>${this.y}</b>`;
    }

    function drawChart() {
      Highcharts.chart(container.current, {
        chart: { type },
        title: { text: label },
        xAxis: { categories, crosshair: !!crosshair },
        yAxis: {
          title: { text: yText },
          ...yAxis
        },
        tooltip: {
          formatter: !!series ? formatTooltipSeparate : formatTooltipTotal,
          shared: !!series,
          ...tooltip
        },
        legend: { enabled: !!series },
        exploring: { enabled: false },
        series: series || [{ data }],
        plotOptions: plotOptions
      });
    }

    React.useEffect(function () {
      (function (Highcharts) {
        Highcharts.wrap(Highcharts.Legend.prototype, 'renderItem', function (
          proceed,
          item
        ) {
          proceed.call(this, item);

          var isPoint = !!item.series,
            collection = isPoint ? item.series.points : this.chart.series,
            element = item.legendGroup.element;

          element.onmouseover = function () {
            if (isPoint) {
              Highcharts.each(collection, function (seriesItem) {
                if (seriesItem === item) {
                  Highcharts.each(item.series.data, function (data) {
                    if (data.drilldown == item.drilldown) {
                      item.series.chart.tooltip.refresh(data);
                    }
                  });
                }
              });
            }
          };
          element.onmouseout = function () {
            if (isPoint) {
              item.series.chart.tooltip.hide();
            }
          };
        });
      })(Highcharts);
    }, []);

    React.useEffect(
      function () {
        if (!pending) {
          drawChart();
        }
      },
      [data, pending]
    );

    if (!!pending) {
      return <Preloader className={classes.preloader} />;
    }
    return <div className={classes.container} ref={container}></div>;
  }
);
