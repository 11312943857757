import { useRef, useMemo } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ListWrapper, Table } from 'Components';
import { CLIENT_PAGE_TABLES_PAGE_SIZES } from 'Constants';

import FreezeServiceDialog from '../FreezeServiceDialog';
import FreezingListDialog from '../FreezingListDialog';
import ExpirationDateDialog from '../ExpirationDateDialog';
import { useClientContext } from '../providers/ClientShowProvider';

import { DiscountsProps } from './types';
import { ServiceResponseData as Service } from 'schemas';

const useStyles = makeStyles((theme) => ({
  frozenService: {
    backgroundColor: '#4c7baf',
    '& td, & i': {
      color: theme.palette.common.white,
    },
  },
}));

const DiscountTable = (props: DiscountsProps) => {
  const {
    title,
    recordsTotal,
    services,
    paginationParams,
    pending,
    onChangePaginationParams,
  } = props;
  const { api_client_url } = useClientContext();

  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const freezeServiceDialog = useRef<HTMLFormElement>(null);
  const freezingListDialog = useRef<HTMLFormElement>(null);
  const expirationDateDialog = useRef<HTMLFormElement>(null);

  const rowClassFn = (data: { freeze: string }) => {
    const { freeze } = data;
    if (!!freeze) {
      return classes.frozenService;
    }
  };

  const tableConfig = useMemo(
    () => [
      {
        title: t('Service'),
        accessor: 'service_name',
      },
      {
        title: t('Service Type'),
        accessor: 'service_type',
      },
      {
        title: t('Discount Type'),
        accessor: 'discount_type',
      },
      {
        title: t('Buy Time'),
        accessor: 'buy_time',
      },
      {
        title: t('Expiration'),
        accessor: 'expiration_date',
      },
      {
        title: t('Actions'),
        cellComponent: (service: Service) =>
          !!service.expiration_date && (
            <>
              <IconButton
                size="small"
                onClick={() => freezeServiceDialog.current?.onOpen(service)}
              >
                <i className="icon-freeze"></i>
              </IconButton>
              <IconButton
                size="small"
                onClick={() =>
                  freezingListDialog.current?.onOpen(
                    service.freeze_periods,
                    service.id
                  )
                }
              >
                <i className="icon-list"></i>
              </IconButton>
              <IconButton
                size="small"
                onClick={() => expirationDateDialog.current?.onOpen(service)}
              >
                <i className="icon-clock"></i>
              </IconButton>
            </>
          ),
      },
    ],
    [i18n.language]
  );

  return (
    <ListWrapper title={title}>
      <Table
        config={tableConfig}
        pending={pending}
        data={services}
        rowClassFn={rowClassFn}
        rowsPerPageOptions={CLIENT_PAGE_TABLES_PAGE_SIZES}
        pagination={{
          records_total: recordsTotal,
          paginationParams,
          handleChangePagination: onChangePaginationParams,
        }}
      />
      <FreezeServiceDialog
        ref={freezeServiceDialog}
        url={api_client_url}
      />
      <FreezingListDialog
        ref={freezingListDialog}
        url={api_client_url}
      />
      <ExpirationDateDialog
        ref={expirationDateDialog}
        url={api_client_url}
      />
    </ListWrapper>
  );
};

export default DiscountTable;