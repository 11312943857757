import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  btnWrapper: {
    padding: '60px 0 30px',
    display: 'flex',
    justifyContent: 'center'
  },
  info: {
    margin: '0 auto 24px',
    maxWidth: '500px',
    color: theme.palette.error.main,
  }
}));

export const ClientBookingPaymentConfirmation = ({ button }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.btnWrapper}>
        <div dangerouslySetInnerHTML={{ __html: button }}></div>
      </Box>
      <p className={classes.info}>
        ВАЖЛИВО! У випадку, якщо Користувач оплатив послугу, але не з'явився до
        заброньованого ним часу і не скористався послугою, кошти не повертаються!
        Повернення коштів можливе лише за умови попередження про відміну або
        перенесення тренування за одну добу
    </p>
    </>
  )
}
