import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, } from "react-i18next";
import { LOCALES_LIST, } from 'Constants';

export function initI18n(resources = {}) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      interpolation: {
        escapeValue: false
      },
    });
}

export function transformLocales(...locales) {
  return LOCALES_LIST.reduce(function (res, lang) {
    const translations = locales.reduce(function (translation, locale = {}) {
      return { ...translation, ...locale[lang] };
    }, {});
    return { ...res, [lang]: { translation: translations } };
  }, {});
}
