export const AverageServiceVisits = {
  en: {
    'Average Service Visits': 'Average Service Visits',
    'Client': 'Client',
    'Average Visits Count': 'Average Visits Count',
    'Filtering by dates means filtering by service activated at date': 'Filtering by dates means filtering by service activated at date',
    'Service': 'Service',
    'Service name': 'Service name',
    'Service type': 'Service type',
    'Duration in days': 'Duration in days',
    'Visits count': 'Visits count',
    'Count': 'Count'
  },
  uk: {
    'Average Service Visits': 'Середня кількість відвідувань по сервісу',
    'Client': 'Клієнт',
    'Average Visits Count': 'Середня кількість візитів',
    'Filtering by dates means filtering by service activated at date': 'Фільтрація відбувається по датах активації сервісу',
    'Service': 'Сервіс',
    'Service name': 'Назва сервісу',
    'Service type': 'Тип сервісу',
    'Duration in days': 'Тривалість у днях',
    'Visits count': 'Кількість візитів',
    'Count': 'Кількість'
  },
}
