import { Fragment } from 'react';
import { makeStyles, Link } from '@material-ui/core';

import { EVENT_TYPES } from 'Constants';

const useStyles = makeStyles((theme) => ({
  service: {
    color: theme.palette.error.main,
  },
  [EVENT_TYPES.UNASSIGNED]: {
    color: '#ff0000',
  },
  [EVENT_TYPES.ASSIGNED_AND_USED]: {
    color: '#007A00',
  },
  [EVENT_TYPES.ASSIGNED_AND_NOT_USED]: {
    color: '#000000',
  },
}));

export const ServicesCell = ({ services_to_assign, clients_url }) => {
  const classes = useStyles();
  return (
    <div>
      {services_to_assign.map(
        (
          {
            service_name,
            assigned_client_id,
            assigned_client_full_name,
            status,
          },
          index
        ) => (
          <Fragment key={index}>
            <span
              className={classnames(classes.service, classes[status] || '')}
            >
              {service_name}
              {!!assigned_client_full_name && (
                <>
                  &nbsp;-&nbsp;
                  <Link
                    href={`${clients_url}/${assigned_client_id}`}
                    target="_blank"
                  >
                    {assigned_client_full_name}
                  </Link>
                </>
              )}
              ;
            </span>
            <br />
          </Fragment>
        )
      )}
    </div>
  );
};
