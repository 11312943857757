import { makeStyles } from '@material-ui/core';

export const PERIOD_HEIGHT = 20;
export const MIN_COLUMN_WIDTH = 215;
export const useStyles = makeStyles((theme) => ({
  dayHeaderWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  dayHeaderTitle: {
    textAlign: 'center',
    textTransform: 'capitalize',
    width: '100%'
  },
  preloader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px'
  },
  iconLeft: {
    width: '50px',
    height: '50px',
    flexShrink: 0
  },
  iconRight: {
    width: '50px',
    height: '50px',
    flexShrink: 0,
    transformOrigin: 'center',
    transform: 'rotate(180deg)'
  },
  headActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start'
  },
  headAction: {
    marginLeft: '8px'
  },
  calendarHeader: {
    marginBottom: '24px'
  },
  emptyHeadCol: {
    width: '50px',
    minWidth: '50px',
    flexShrink: 0,
    borderRight: '1px solid #dfd8d8',
    borderBottom: '1px solid #dfd8d8',
    position: 'sticky',
    left: 0,
    zIndex: 10,
    background: '#fff'
  },
  dayWrapper: {
    position: 'relative',
    width: 'calc(100vw - 139px)',
    border: '1px solid #dfd8d8',
    borderBottom: '1px solid #dfd8d8',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 80px)',
    scrollBehavior: 'smooth',

    '& *': {
      boxSizing: 'border-box',
    }
  },
  dayHead: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    zIndex: 7
  },
  dayHeadColumns: {
    display: 'flex',
    width: '100%'
  },
  columnTitle: {
    padding: 0,

    '& p, & h1, & h2, & h3, & h4, & h5, & h6': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  dayBody: {
    display: 'flex',
    userSelect: 'none'
  },
  dayHeadColumn: {
    width: '100%',
    minWidth: `${MIN_COLUMN_WIDTH}px`,
    borderLeft: '1px solid #dfd8d8',
    borderBottom: '1px solid #dfd8d8',
    padding: '8px',
    background: '#fff',

    '&:first-child': {
      borderLeft: 'none',
    },
  },
  hours: {
    width: '50px',
    flexShrink: 0,
    position: 'sticky',
    left: 0,
    background: '#fff',
    zIndex: 6,
    borderRight: '1px solid #dfd8d8',
  },
  hour: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `${PERIOD_HEIGHT * 2}px`,
    fontSize: '0.8125rem',
    borderBottom: '1px solid #dfd8d8',

    '&:last-child': {
      borderBottom: 'none'
    }
  },
  dayBodyColumns: {
    display: 'flex',
    width: '100%'
  },
  dayBodyColumn: {
    position: 'relative',
    width: '100%',
    minWidth: `${MIN_COLUMN_WIDTH}px`,
    borderLeft: '1px solid #dfd8d8',

    '&:first-child': {
      borderLeft: 'none',
    }
  },
  period: {
    borderBottom: '1px solid #f1ebeb',
    height: `${PERIOD_HEIGHT}px`,
    position: 'relative',
    zIndex: 2,
  },
  disabledPeriod: {
    background: 'rgba(109, 140, 183, 0.3)'
  },
  selection: {
    position: 'absolute',
    background: 'rgba(43, 191, 110, 0.34)',
    padding: '0 10px',
    width: '100%',
    zIndex: 1,
    borderRadius: '4px',

    '& .event-note': {
      display: 'block'
    },
  },
  eventTime: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '3px'
  },
  event: {
    position: 'absolute',
    width: 'calc(100% - 26px)',
    left: '13px',
    zIndex: 3,
    borderBottom: '1px solid #f1ebeb',
    borderRadius: '4px',
    paddingLeft: '8px',
    overflow: 'hidden',

    '& .event-service': {
      display: 'block'
    },

    '& .event-note': {
      display: 'none'
    },

    '&:hover': {
      '& .event-service': {
        display: 'none'
      },

      '& .event-note': {
        display: 'block'
      },
    }
  },
  selectingTime: {
    zIndex: 0,
  },
  eventTitle: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '3px',
    color: '#fff'
  },
  eventContent: {
    display: 'none',
    fontSize: '12px',
    padding: '3px',
    color: '#fff'
  },
  resizeTopArea: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    height: '5px',
    cursor: 'row-resize',
    zIndex: 2,
  },
  resizeBottArea: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    height: '5px',
    cursor: 'row-resize',
    zIndex: 2,
  },
  hiddenEvent: {
    visibility: 'hidden'
  },
  eventSelection: {
    opacity: 0.5,
    width: 'calc(100% - 26px)',
    left: '13px',

  },
  onlyBlockedTimeEventSelection: {
    background: 'rgba(226, 33, 33, 0.4)'
  },
  dayBodySelecting: {
    cursor: 'row-resize',
  },
  editEventIcon: {
    position: 'absolute',
    top: 0,
    right: '-4px',
    zIndex: 5,
    '& i': {
      color: '#fff'
    }
  },
  warningIcon: {
    position: 'absolute',
    top: '-3px',
    right: '20px',
    zIndex: 5,
    '& i': {
      color: '#fff',
      rotate: '180deg',
    }
  },
  showEventLink: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  notesWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '10px',
    height: '100%',
    zIndex: 5,
    transition: '0.2s',

    '&:hover': {
      width: '22px'
    }
  },
  bookingNote: {
    position: 'absolute',
    background: 'rgba(84, 185, 38, 0.62)',
    borderRadius: '10px',
    width: '100%',
    border: '1px solid rgba(186, 152, 152, 0.82)'
  },
  notesDescrWrapper: {
    position: 'absolute',
    left: '100%',
    width: '180px',
    transform: 'translateY(-50%)',
    padding: '8px'
  },
  noteDescription: {
    position: 'relative',
    marginBottom: '8px',
    padding: '8px 4px',
    background: 'rgba(84, 185, 38, 1)',
    borderRadius: '4px',
  },
  noteTitle: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '3px',
    color: '#fff'
  },
  noteContent: {
    display: 'block',
    fontSize: '12px',
    padding: '3px',
    color: '#fff'
  },
  onlyBlockedTimeEvent: {
    background: 'rgba(226, 33, 33, 0.7)'
  },
  paidMark: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '8px',
    height: '100%',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  paidSuccessfully: {
    background: '#3fb56e'
  },
  paymentInProgress: {
    background: '#eb9019'
  },
  dataMessage: {
    textAlign: 'center',
    fontSize: '22px'
  },
  calendarActions: {
    width: '120px',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 'auto'
  }
}));
