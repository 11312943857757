import { useEffect, useState } from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Form } from 'Components';
import { useForm } from 'Hooks';
import { checkRequired } from 'Helpers/validation';
import { SelectField } from './SelectField';
import { InputField } from './InputField';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0 16px',
    maxWidth: '500px',
    margin: '0 auto',

    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginBottom: '30px',
    },
  },
  title: {
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
    },
  },
  confirmationText: {
    textAlign: 'center',
  },
  input: {
    marginBottom: '16px',
  },
  btn: {
    width: '100%',
  },
}));

const DEFAULT_ITEM = {
  comment: '',
};

const NOTIFICATION_TYPES = {
  alert: 'warning',
  error: 'error',
  notice: 'info',
  success: 'success',
};

const INPUT_FIELD_TYPE = 'input';
const SELECT_FIELD_TYPE = 'drop_down';

export const ClientBookingConfirmation = ({
  confirm_url,
  confirmation_notification,
  custom_fields,
}) => {
  const classes = useStyles();

  const [validators, setValidators] = useState({});
  const [selectFieldsValues, setSelectFieldValues] = useState({});

  const {
    item,
    setItem,
    action,
    method,
    submit,
    setServerErrors,
    isItemInvalid,
    validationMessagesToDisplay,
  } = useForm(DEFAULT_ITEM, validators, confirm_url);

  useEffect(() => {
    const newValidators = custom_fields ? custom_fields.reduce(
      (previousValidators, customField) => {
        return {
          ...previousValidators,
          ...(customField.required && {
            [customField.id]: checkRequired(`${customField.id}`),
          }),
        };
      },
      {}
    ) : {};

    const dataValidationObject = custom_fields ? custom_fields.reduce(
      (previousValidationObject, custom_field) => {
        return {
          ...previousValidationObject,
          ...(!item[custom_field.id] && {
            [custom_field.id]: '',
          }),
        };
      }, {}
    ) : {};

    const dropDownCustomFields = custom_fields ? custom_fields.filter(
      (custom_field) => custom_field.field_type === SELECT_FIELD_TYPE
    ) : [];
    const newSelectFieldsValues = dropDownCustomFields.reduce(
      (prevValues, custom_field) => {
        const optionsArray = custom_field.option_values.split('\n');
        return {
          ...prevValues,
          [custom_field.id]: optionsArray,
        };
      }, {}
    );

    setSelectFieldValues(newSelectFieldsValues);
    setValidators(newValidators);
    setItem({ ...item, ...dataValidationObject });
  }, [custom_fields]);

  const [alert, setAlert] = React.useState({
    message: '',
    type: '',
  });

  function onSuccessSubmit(res) {
    if (res.flash_message) {
      setAlert({
        message: res.flash_message,
        type: res.flash_type,
      });
    }
  }

  const onInputChange = (e, id) => {
    setItem({ ...item, [id]: e.target.value });
  };

  const onSelectChange = (e, custom_field) => {
    setItem({
      ...item,
      [custom_field.id]: selectFieldsValues[custom_field.id][e.target.value],
    });
  };

  const onFormSubmit = () => {
    const responsesArray = custom_fields ? custom_fields.map((custom_field) => {
      return {
        booking_custom_field_id: custom_field.id,
        value: item[custom_field.id],
      };
    }) : [];

    setItem({
      ...item,
      booking_custom_field_responses_attributes: responsesArray,
    });
  };

  const renderCustomFields = (custom_field) => {
    switch (custom_field.field_type) {
      case INPUT_FIELD_TYPE:
        return (
          <InputField
            key={custom_field.id}
            custom_field={custom_field}
            onChange={onInputChange}
            validationMessages={validationMessagesToDisplay[custom_field.id]}
          />
        );
      case SELECT_FIELD_TYPE:
        return (
          <SelectField
            key={custom_field.id}
            custom_field={custom_field}
            onChange={onSelectChange}
            validationMessages={validationMessagesToDisplay[custom_field.id]}
          />
        );
    }
  };

  const fields = custom_fields || [];

  return (
    <Box className={classes.content}>
      <h1 className={classes.title}>
        Перевірте деталі та підтвердіть замовлення
      </h1>
      {!!confirmation_notification && (
        <div
          className={classes.confirmationText}
          dangerouslySetInnerHTML={{ __html: confirmation_notification }}
        ></div>
      )}
      {alert.message && (
        <Alert
          style={{ margin: '10px auto', width: '90%' }}
          variant="outlined"
          severity={NOTIFICATION_TYPES[alert.type]}
        >
          {alert.message}
        </Alert>
      )}
      <Form
        action={action}
        method={method}
        item={item}
        onSubmit={submit}
        onError={setServerErrors}
        onSuccessSubmit={onSuccessSubmit}
        name="client_confirmation"
      >
        {fields.map(renderCustomFields)}
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          type="submit"
          onClick={onFormSubmit}
          disabled={isItemInvalid}
        >
          Підтвердити
        </Button>
      </Form>
    </Box>
  );
};
