import MAutocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, Box, FormHelperText } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { FormInput } from 'Components';
import { AJAX } from 'Helpers';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative'
  },
  selectValue:{
    position: 'absolute',
    top: '55%',
    left: '14px',
    transform: 'translateY(-55%)'
  }
}));

let request = null;
let timeout = null;

export const AjaxAutocomplete = ({
  label, getOptionLabel, value, onChange, searchUrl, getRequestBody,
  method = 'post', required, validationMessages
}) => {
  
  const { t } = useTranslation();
  const classes = useStyles();

  const [inputValue, setInputValue] = React.useState('');
  const [state, setState] = React.useState({
    options: [],
    loading: false
  })

  function fetchData(searchValue) {
    setState({ ...state, loading: true });
    if (!!request) {
      request.abort();
    }
  
    request = AJAX[method](searchUrl, {
      body: {
        ...(!!getRequestBody ? getRequestBody(searchValue) : { searchValue }),
      }
    })
  
    request.then(res => {
      setState({
        ...state,
        options: res.data,
        loading: false
      });
 
      request = null;
      timeout = null;
    })
  }

  const clearInput = () => {
    setInputValue('')
  }

  React.useEffect(() => {
      if(value) {
        clearInput()
      }
  }, [value])

  React.useEffect(() => {
      if (timeout) {
        clearTimeout(timeout);
    }
      
    timeout = setTimeout(() => {
      fetchData(inputValue);
    }, 500);
  }, [inputValue])

  const handleInputChange = (_, inputValue) => {
    setInputValue(inputValue);
  }

  const onSelect = (event, value) => {
    onChange(event, value);
  }

  return (
    <MAutocomplete
      autoHighlight
      fullWidth
      onChange={onSelect}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      loadingText={t('Loading...')}
      noOptionsText={t('Nothing Found')}
      getOptionSelected={(option, value) => option.id === value.id}
      forcePopupIcon={false}
      {...state}
      renderInput={(params) => (
      <Box className={classes.wrapper}>
        <FormInput
          {...params}
            label={label}
            required={required}
            error={!!validationMessages}
            autoComplete='new-password'
            placeholder={!value && t('Client')}
            InputLabelProps={{
            shrink: true,
            }}
            inputProps={{
              ...params.inputProps,
              onFocus: () => clearInput()
          }}
        />
          <FormHelperText error={!!validationMessages}>{validationMessages}</FormHelperText>
          {!inputValue && (
            <Box className={classes.selectValue}>
              {getOptionLabel(value)}
            </Box>
          )}
        </Box>
      )}
    />
  );
}
