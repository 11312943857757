import { Button, Typography, makeStyles, Box, Grid, Card, Container } from '@material-ui/core';
import {
  ListWrapper,
  Preloader
} from 'Components';
import { Alert } from '@material-ui/lab';
import { AJAX } from 'Helpers';
import UnsubscribeConfirm from './Confirm';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  content: {
    width: '100%'
  },
  sidebar: {
    width: '400px',
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  listButton: {
    marginLeft: theme.spacing(1)
  },
  listWrapper: {
    marginBottom: theme.spacing(2),
    padding: 0
  },
  listInfoWrapper: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sidebarTitle: {
    marginBottom: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.87)',
  },
  unSubscribeButton: {
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)}px auto`,
      display: 'block'
    }
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center'
  },
  cards: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(3)}px 0 `,
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 20px',
    minHeight: '110px',
    background: '#e4f9f8',
  },
  lastVisitDaysAgo: {
    fontSize: '20px'
  },
  lastVisitCardValue: {
    fontSize: '31px'
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  message: {
    color: theme.palette.primary.main
  },
  navigationItem: {
    background: theme.palette.primary.main,
    marginRight: '12px'
  },
  listTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  list: {
    background: '#e4f9f8',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },

}));

function getItems(url) {
  return AJAX.get(url)
}

const Subscriptions = ({
  active_subscriptions_url, not_active_services_url, active_services_url,
  active_discounts_url, last_activities_url, online_services_url, services_url,
  booking_url, my_booking_url, ...visitsInfo
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    isLoading: true,
    data: {
      active_subscriptions: [],
      not_active_services: [],
      active_services: [],
      active_discounts: [],
      last_activities: [],
    }
  });

  const unsubscribeConfirm = React.createRef(null)

  const unsubscribe = (subscription) => () => {
    unsubscribeConfirm.current.open(subscription)
  }

  async function getData() {
    const [
      active_subscriptions,
      not_active_services,
      active_services,
      active_discounts,
      last_activities,
    ] = await Promise.all([
      getItems(active_subscriptions_url),
      getItems(not_active_services_url),
      getItems(active_services_url),
      getItems(active_discounts_url),
      getItems(last_activities_url),
    ]);

    setState({
      isLoading: false,
      data: {
        active_subscriptions,
        not_active_services,
        active_services,
        active_discounts,
        last_activities,
      }
    })
  }

  React.useEffect(() => {
    getData()
  }, [])


  const day = React.useMemo(() => {
    let num = Math.abs(visitsInfo.last_visit_days_ago);
    num %= 100;
    if (num >= 5 && num <= 20) {
      return 'днів';
    }
    num %= 10;
    if (num === 1) {
      return 'день';
    }
    if (num >= 2 && num <= 4) {
      return 'дні';
    }

    return 'днів';
  }, [visitsInfo.last_visit_days_ago])

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Container className={classes.cards}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Card variant="outlined" className={classes.card}>
                <Typography component="h3">
                  Останній візит
                </Typography>
                <Typography variant="h3" className={classes.lastVisitCardValue} color="textPrimary" component="p">
                  {!!visitsInfo.last_visit_date && (
                    <>
                      {visitsInfo.last_visit_date} <br />
                      <span className={classes.lastVisitDaysAgo}>({visitsInfo.last_visit_days_ago} {day} тому)</span>
                    </>
                  ) || '-'}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card variant="outlined" className={classes.card}>
                <Typography component="h3">
                  Кількість тренувань за останній місяць
                </Typography>
                <Typography variant="h3" color="textPrimary" component="h3">
                  {visitsInfo.visits_count_for_last_month || '-'}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card variant="outlined" className={classes.card}>
                <Typography component="h3">
                  Кількість тренувань за останні 6 місяців
                </Typography>
                <Typography variant="h3" color="textPrimary" component="h3">
                  {visitsInfo.visits_count_for_last_six_months || '-'}
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {state.isLoading && (
          <Box className={classes.preloader}>
            <Preloader />
          </Box>
        )}
        {!state.isLoading && (
          <>
            {!!state.data.active_subscriptions.length && (
              <Box className={classes.listWrapper}>
                <ListWrapper title="Активнi Пiдписки" mb={2} className={classes.list}
                  titleClassName={classes.listTitle}>
                  <Box className={classes.listInfoWrapper}>
                    {state.data.active_subscriptions.map(subscription => (
                      <div key={subscription.unsubscribe_url} className={classes.flex}>
                        <Box>
                          <Typography variant="h4" component="h4"><div dangerouslySetInnerHTML={{ __html: subscription.name }}></div></Typography>
                          {!!subscription.message && (
                            <Alert variant="outlined" severity="warning" style={{ border: 'none' }}>{subscription.message}</Alert>
                          )}
                        </Box>
                        <div className={classes.buttonWrapper}>
                          <Button className={classes.unSubscribeButton}
                            onClick={unsubscribe(subscription)} color="primary"
                            variant="contained">
                            відписатися
                          </Button>
                        </div>
                      </div>
                    ))}
                  </Box>
                </ListWrapper>
              </Box>
            )}

            {!!state.data.active_services.length && (
              <Box className={classes.listWrapper}>
                <ListWrapper title="Активні Сервiси" className={classes.list}
                  titleClassName={classes.listTitle}>
                  <ul className={classes.listInfoWrapper}>
                    {state.data.active_services.map(service => (
                      <li key={service.id}>
                        {service.name}&nbsp;
                        {!!service.expiration_date && (`дiйсний до ${service.expiration_date}`)}
                        {!!service.remaining_visits_count && (`, залишилось візитів - ${service.remaining_visits_count}`)}
                      </li>
                    ))}
                  </ul>
                </ListWrapper>
              </Box>
            )}

            {!!state.data.not_active_services.length && (
              <Box className={classes.listWrapper}>
                <ListWrapper title="Придбанi Сервiси" className={classes.list}
                  titleClassName={classes.listTitle}>
                  <ul className={classes.listInfoWrapper}>
                    {state.data.not_active_services.map(service => (
                      <li key={service.id}>
                        {service.name}&nbsp;
                      </li>
                    ))}
                  </ul>
                </ListWrapper>
              </Box>
            )}

            {!!state.data.active_discounts.length && (
              <Box className={classes.listWrapper}>
                <ListWrapper title="Знижки" className={classes.list}
                  titleClassName={classes.listTitle}>
                  <ul className={classes.listInfoWrapper}>
                    {state.data.active_discounts.map(discount =>
                      <li key={discount.id}>{discount.name}</li>
                    )}
                  </ul>
                </ListWrapper>
              </Box>
            )}

            {!!state.data.last_activities && (
              <ListWrapper title="Останнi Операцiї" className={classes.list}
                titleClassName={classes.listTitle}>
                <ul className={classes.listInfoWrapper}>
                  {state.data.last_activities.map((activity, index) => (
                    <li key={`${activity.service_name}${activity.use_time}${index}`}>
                      {activity.use_time}, {activity.action_type} <strong>"{activity.service_name}"</strong>
                    </li>
                  ))}
                </ul>
              </ListWrapper>
            )}
          </>
        )}
      </Box>
      <UnsubscribeConfirm ref={unsubscribeConfirm} />
    </Box>
  );
}

export default Subscriptions;