import {
  forwardRef,
  useState,
  useImperativeHandle,
  ChangeEvent,
  SyntheticEvent,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';

import { Button, DatePicker } from 'Components';
import { AJAX } from 'Helpers';

import {
  CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY,
  CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
  PREVIOUSLY_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
} from 'queries';

import { FreezeServiceDialogProps, FreezeServiceDialogState } from './types';

const INITIAL_STATE = {
  id: null,
  start_date: '',
  end_date: '',
};

const FreezeServiceDialog = forwardRef(
  (props: FreezeServiceDialogProps, ref) => {
    const { url } = props;
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const [state, setState] = useState<FreezeServiceDialogState>(INITIAL_STATE);

    const onOpen = (freezeServiceDialogData: FreezeServiceDialogState) => {
      const { id } = freezeServiceDialogData;
      return setState({
        id,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: '',
      });
    };

    const onStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      setState({ ...state, start_date: target.value });
    };

    const onEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      setState({ ...state, end_date: target.value });
    };

    const onClose = () => {
      setState(INITIAL_STATE);
    };

    const onFreezeServiceSubmitChanges = async (event: SyntheticEvent) => {
      event.preventDefault();
      const { start_date, end_date, id } = state;
      if (!start_date || !end_date) {
        return;
      }
      const res = await AJAX.put(`${url}/purchased_services/${id}/freeze`, {
        body: { freeze_period: { start_date, end_date } },
      });

      if (res.flash_type == 'success') {
        queryClient.invalidateQueries([
          CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
        ]);
        queryClient.invalidateQueries([CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY]);
        queryClient.invalidateQueries([
          PREVIOUSLY_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
        ]);
        onClose();
      }
    };

    useImperativeHandle(ref, () => ({
      onOpen,
      onClose,
    }));

    return (
      <Dialog
        open={!!state.id}
        onClose={onClose}
        fullWidth
      >
        <DialogTitle>{t('Freeze service')}</DialogTitle>
        <form onSubmit={onFreezeServiceSubmitChanges}>
          <DialogContent>
            <DatePicker
              label={t('From')}
              value={state.start_date}
              onChange={onStartDateChange}
            />
            <DatePicker
              label={t('Till')}
              value={state.end_date}
              onChange={onEndDateChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('Close')}</Button>
            <Button
              color="primary"
              type="submit"
              disabled={!state.start_date || !state.end_date}
            >
              {t('Freeze')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
);

export default FreezeServiceDialog;