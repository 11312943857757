import { IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ListWrapper, Table, Button } from 'Components';
import { useList, } from 'Hooks';
import { AJAX, findInArrayBy } from 'Helpers';
import { ConfirmDialog } from './ConfirmDialog';

const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'asc'
}

export const BookingRecurringEvents = ({ create_recurring_event_url, fetch_url }) => {
  const { t, i18n } = useTranslation();

  const deleteRecurringEventConfirm = React.useRef(null)

  const {
    pending, data, records_total, paginationParams, handleChangePagination,
    updateList
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  const tableConfig = [
    {
      title: t('Provider'),
      accessor: 'booking_provider_name',
    },
    {
      title: t('Booking Service'),
      accessor: 'booking_service_name',
    },
    {
      title: t('Time Period'),
      accessor: 'time_period',
    },
    {
      title: t('Week Days'),
      accessor: 'week_days',
    },
    {
      title: t('Frequency'),
      accessor: 'week_frequency',
      cellComponent: ({ week_frequency }) => {
        return findInArrayBy(WEEK_FREQUENCY_OPTIONS, week_frequency).name
      },
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }) => {
        function handleDelete() {
          deleteRecurringEventConfirm.current.open(id)
        }

        return (
          <IconButton
            size="small"
            onClick={handleDelete}
          >
            <i className="icon-trash-empty"></i>
          </IconButton>
        )
      },
    },
  ];

  async function deleteRecurringEvent(id, completely) {
    const res = await AJAX.delete(`${fetch_url}/${id}`, {
      body: JSON.stringify({
        delete_only_recurring: !completely
      })
    });

    if (res.flash_type == 'success') {
      updateList()
    }
  }

  //TODO: should be moved to common according DRY
  const WEEK_FREQUENCY_OPTIONS = React.useMemo(function () {
    return [
      { id: 1, name: `${t('Every one')} ${t('week')}` },
      { id: 2, name: `${t('Every')} 2 ${t('weeks')}` },
      { id: 3, name: `${t('Every')} 3 ${t('weeks')}` },
      { id: 4, name: `${t('Every')} 4 ${t('weeks')}` }
    ]
  }, [i18n.language])

  return (
    <>
      <ListWrapper
        title={`${t('Recurring Events')} (${records_total})`}
        headerAddon={
          <div>
            <Button
              href={create_recurring_event_url}
              variant="contained"
              color="primary"
            >
              {t('Create Recurring Event')}
            </Button>
          </div>
        }
      >
        <Table
          config={tableConfig}
          pending={pending}
          data={data}
          pagination={{ records_total, paginationParams, handleChangePagination }}
        />
      </ListWrapper>
      <ConfirmDialog
        ref={deleteRecurringEventConfirm}
        onConfirm={deleteRecurringEvent}
      />
    </>
  );
};
