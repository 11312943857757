import { IconButton, } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { ListWrapper, Table, ListActions, Button, } from 'Components';
import { AJAX } from 'Helpers';
import { useList } from 'Hooks';

const DEFAULT_SORT_PARAMS = {
  sort_column: 'last_name',
  sort_direction: 'asc'
}

export const Users = ({ fetch_url, url, new_item_url, }) => {
  const { t } = useTranslation();
  const {
    pending, data, records_total, paginationParams, handleChangePagination, updateList
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  function bindToggleDisableUser(item) {
    return async function () {
      await AJAX.put(`${fetch_url}/${item.id}/lock`);
      updateList()
    }
  }

  const tableConfig = [
    {
      title: t('Name'),
      accessor: 'last_name',
      cellComponent: ({ first_name, last_name }) => `${last_name} ${first_name}`,
      sortable: true,
    },
    {
      title: t('Role'),
      accessor: 'role',
    },
    {
      title: t('Locked'),
      accessor: 'locked_at',
      cellComponent: ({ locked_at }) => locked_at ? 'true' : 'false',
    },
    {
      title: t('Actions'),
      cellComponent: (item) => (
        <ListActions editUrl={`${url}/${item.id}/edit`}>
          <IconButton size="small" onClick={bindToggleDisableUser(item)}>
            <i className={`icon-${item.locked_at ? 'lock' : 'lock-open'}`}></i>
          </IconButton>
        </ListActions>
      )
    }
  ];

  return (
    <ListWrapper title={`${t('Users')} (${records_total})`} headerAddon={(
      <div>
        <Button href={new_item_url} variant="contained" color="primary">
          {t('Create User')}
        </Button>
      </div>
    )}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
}
