import { useRef } from 'react';
import { useTranslation, } from 'react-i18next';
import { makeStyles, IconButton } from '@material-ui/core';

import { ListWrapper, Table, Button, SearchInput } from 'Components';
import { useList } from 'Hooks';

import OrderReceiptDialog from 'pages/clients/show/OrderReceiptDialog';

const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'desc'
}

const useStyles = makeStyles(theme => ({
  search: { marginRight: theme.spacing(3) },
  service: {
    color: theme.palette.primary.main,
    '&::after': {
      content: '", "',
    },
    "&:last-child": {
      '&::after': {
        display: 'none'
      }
    }
  },
  overedService: {
    color: theme.palette.error.main
  }
}))

export const Certificates = ({ fetch_url, new_item_url, }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const orderReceiptDialog = useRef(null);

  const {
    pending, data, records_total, paginationParams, handleChangePagination,
    changeSearch
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  const tableConfig = [
    {
      title: t('Code'),
      accessor: 'code',
    },
    {
      title: t('Services'),
      accessor: 'services',
      cellComponent: ({ services }) => (
        <div>
          {services.map(({ name, id, initial_count, current_count }) => (
            <span key={id} className={classnames(classes.service, {
              [classes.overedService]: current_count === 0,
            })}>
              {name} ({initial_count - current_count} {t('out of')} {initial_count})
            </span>
          ))}
        </div>
      ),
    },
    {
      title: t('Comment'),
      accessor: 'comment',
    },
    {
      title: t('Created'),
      accessor: 'created_at',
      sortable: true,
    },
    {
      title: t('Receipt'),
      cellComponent: (certificate) => (
        <>
          {certificate.has_receipt && (
            <IconButton
              size="small"
              onClick={() => onReceiptShow(certificate)}
            >
              <i className="icon-bill"></i>
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const onReceiptShow = (certificate) => {
    orderReceiptDialog.current?.open(certificate);
  };

  return (
    <ListWrapper title={`${t('Certificates')} (${records_total})`} headerAddon={(
      <div>
        <span className={classes.search}>
          <SearchInput onChange={changeSearch} name="value" />
        </span>
        <Button href={new_item_url} variant="contained" color="primary">
          {t('Create Certificate')}
        </Button>
      </div>
    )}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
      <OrderReceiptDialog ref={orderReceiptDialog} />
    </ListWrapper>
  );
}
