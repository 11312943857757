import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { Button, Select, } from 'Components';

const useStyles = makeStyles(() => ({
  select: {
    '& p, & h1, & h2, & h3, & h4, & h5, & h6': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  filterSubmitButton: {
    marginTop: '8px',
    marginBottom: '4px'
  },
}));

const ProviderForm = ({ booking_providers, locations, onSelect, loading }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  //set first location as default if user bind only with 1 location
  const [state, setState] = React.useState({
    provider_id: '',
    location_id: locations.length === 1 ? locations[0].id : '',
  })


  function handleChangeProvider({ target }) {
    setState({
      ...state,
      provider_id: target.value
    })
  }

  function handleChangeLocation({ target }) {
    setState({
      ...state,
      location_id: target.value
    })
  }

  const providerOptions = React.useMemo(() => {
    return booking_providers.map(({ name, id }) => {
      return {
        id,
        name: (<div className={classes.select} dangerouslySetInnerHTML={{ __html: name }}></div>)
      }
    })
  }, [booking_providers]);

  function selectProvider() {
    onSelect(state)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} >
        <Select value={state.provider_id} options={providerOptions}
          label={t('Provider')} onChange={handleChangeProvider} required />
      </Grid>
      <Grid item xs={12} sm={4} >
        <Select value={state.location_id} options={locations}
          label={t('Location')} onChange={handleChangeLocation} required />
      </Grid>
      <Grid item xs={12} sm={4} >
        <Button type="button" onClick={selectProvider} color="primary"
          className={classes.filterSubmitButton} fullWidth
          disabled={!state.provider_id || !state.location_id || loading}>
          {t('Search')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default ProviderForm
