import RichTextEditor, { createEmptyValue, createValueFromString } from 'react-rte';
import './style.scss';

const TOOL_BAR_CONFIG = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Strikethrough', style: 'STRIKETHROUGH' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ]
};

const emptyValue = createEmptyValue().toString('html')

export const TextEditor = React.forwardRef(({ value, disabled, multipartFormData, name }, ref) => {

  const [inputValue, changeValue] = React.useState(value ? createValueFromString(value, 'html') : createEmptyValue())

  const handleChange = (value) => {
    changeValue(value);
  }

  React.useImperativeHandle(ref, function () {
    return {
      value: inputValue.toString('html'),
    }
  });

  const hiddenInputValue = React.useMemo(() => {
    const htmlValue = inputValue.toString('html');

    return htmlValue === emptyValue ? '' : htmlValue;
  }, [multipartFormData, inputValue])

  return (
    <>
      <RichTextEditor value={inputValue} onChange={handleChange} disabled={disabled}
        toolbarConfig={TOOL_BAR_CONFIG} />
      {multipartFormData && (
        <input value={hiddenInputValue} name={name} hidden readOnly />
      )}
    </>
  )
});

