import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ListWrapper, Button, FormInput, DatePicker, Form, ResponsibilityForm } from 'Components';
import {
  Table as MaterialTable, TableContainer, TableBody, TableCell, TableRow, makeStyles, Box,
} from '@material-ui/core';
import confirmationStore from 'Helpers/confirmation';
import { AJAX } from 'Helpers';
import { useForm } from 'Hooks';
import { DISPLAY_DATES_FORMAT, FORM_LANG } from 'Constants';
import { checkRequired } from 'Helpers/validation';

const useStyles = makeStyles((theme) => ({
  successBtn: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    marginRight: '20px',
  },
  errorBtn: {
    backgroundColor: theme.palette.error.light,
    color: '#fff',
    marginRight: '20px',
  },
  buttonsWrapper: {
    padding: '20px 0',
    display: 'flex',
  },
  formButtonsWrapper: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  editBtn: {
    marginLeft: 'auto'
  },
  printBtn: {
    color: '#fff',
    marginRight: '20px',
    background: theme.palette.primary.light
  },
  printFirstBtn: {
    color: '#fff',
    marginRight: '20px',
    marginLeft: 'auto',
    background: theme.palette.primary.light
  }
}));

const validators = {
  first_name: checkRequired('first_name'),
  last_name: checkRequired('last_name'),
  email: checkRequired('email'),
  phone: checkRequired('phone'),
};

export const NewClientForm = ({ confirm_url, api_url, reject_url, client }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    item,
    changeValue,
    setItem,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    setServerErrors,
  } = useForm(client, validators, api_url);

  function handleConfirm() {
    confirmationStore.open(function () {
      AJAX.post(confirm_url)
    });
  }

  function handleReject() {
    confirmationStore.open(function () {
      AJAX.post(reject_url)
    });
  }

  const [isEditMode, changeIsEditMode] = React.useState(false);

  const responsibilityForm = React.useRef(null);

  React.useEffect(() => {
    ReactDOM.render((<ResponsibilityForm ref={responsibilityForm} client={item} />),
      document.getElementById('form-of-responsibility'))
  }, [isEditMode])

  function setEditMode() {
    changeIsEditMode(true)
  }

  function onCancelEdit() {
    changeIsEditMode(false);
    setItem(client)
  }

  function handleChangeFirstName({ target }) {
    changeValue('first_name', target.value);
  }

  function handleChangeLastName({ target }) {
    changeValue('last_name', target.value);
  }

  function handleChangeMiddleName({ target }) {
    changeValue('middle_name', target.value);
  }

  function handleChangeEmail({ target }) {
    changeValue('email', target.value);
  }

  function handleChangePhone({ target }) {
    changeValue('phone', target.value);
  }

  function handleChangeBirthday({ target }) {
    changeValue('birthday', target.value || '');
  }

  function onSuccessSubmit() {
    changeIsEditMode(false);
  }

  function handlePrintEngForm() {
    responsibilityForm.current.print(FORM_LANG.en)
  }

  function handlePrintUkForm() {
    responsibilityForm.current.print(FORM_LANG.uk)
  }


  return (
    <ListWrapper title={`${t('New Client')}`}>
      {!isEditMode && (
        <>
          <TableContainer>
            <MaterialTable>
              <TableBody>
                <TableRow>
                  <TableCell >
                    {t('First name')}
                  </TableCell>
                  <TableCell >
                    {item.first_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Last name')}
                  </TableCell>
                  <TableCell >
                    {item.last_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Middle name')}
                  </TableCell>
                  <TableCell >
                    {item.middle_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Email')}
                  </TableCell>
                  <TableCell >
                    {item.email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Phone')}
                  </TableCell>
                  <TableCell >
                    {item.phone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Birthday')}
                  </TableCell>
                  <TableCell >
                    {item.birthday && moment(item.birthday).format(DISPLAY_DATES_FORMAT)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Date and time of creation')}
                  </TableCell>
                  <TableCell >
                    {item.created_at}
                  </TableCell>
                </TableRow>
              </TableBody>
            </MaterialTable>
          </TableContainer>
          <Box className={classes.buttonsWrapper}>
            <Button variant="contained" className={classes.successBtn} onClick={handleConfirm}>
              {t('confirm')}
            </Button>
            <Button variant="contained" className={classes.errorBtn} onClick={handleReject}>
              {t('reject')}
            </Button>

            <Button variant="contained" className={classes.printFirstBtn} onClick={handlePrintUkForm}>
              {t('print uk form')}
            </Button>
            <Button variant="contained" className={classes.printBtn} onClick={handlePrintEngForm}>
              {t('print eng form')}
            </Button>
            <Button variant="contained" className={classes.editBtn} color="primary" onClick={setEditMode}>
              {t('edit')}
            </Button>
          </Box>
        </>
      )}
      {isEditMode && (
        <Form
          name="new_client"
          action={action}
          method={method}
          validators={validators}
          disabled={isItemInvalid}
          item={item}
          onSuccessSubmit={onSuccessSubmit}
          onSubmit={submit}
          onError={setServerErrors}
        >
          <TableContainer>
            <MaterialTable>
              <TableBody>
                <TableRow>
                  <TableCell >
                    {t('First name')} *
                  </TableCell>
                  <TableCell >
                    <FormInput
                      value={item.first_name}
                      onChange={handleChangeFirstName}
                      validationMessages={validationMessagesToDisplay.first_name}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Last name')} *
                  </TableCell>
                  <TableCell >
                    <FormInput
                      value={item.last_name}
                      validationMessages={validationMessagesToDisplay.last_name}
                      onChange={handleChangeLastName}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Middle name')}
                  </TableCell>
                  <TableCell >
                    <FormInput
                      value={item.middle_name}
                      onChange={handleChangeMiddleName}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Email')} *
                  </TableCell>
                  <TableCell >
                    <FormInput
                      value={item.email}
                      type='email'
                      validationMessages={validationMessagesToDisplay.email}
                      onChange={handleChangeEmail}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Phone')} *
                  </TableCell>
                  <TableCell >
                    <FormInput
                      value={item.phone}
                      validationMessages={validationMessagesToDisplay.phone}
                      onChange={handleChangePhone}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    {t('Birthday')}
                  </TableCell>
                  <TableCell >
                    <DatePicker
                      onChange={handleChangeBirthday}
                      value={item.birthday}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </MaterialTable>
          </TableContainer>
          <Box className={classes.formButtonsWrapper}>
            <Button variant="contained" type="submit" className={classes.successBtn}>
              {t('Save')}
            </Button>
            <Button variant="contained" type='button' className={classes.errorBtn} onClick={onCancelEdit}>
              {t('Cancel')}
            </Button>
          </Box>
        </Form>
      )}

    </ListWrapper>
  );
};
