export const Cashiers = {
  en: {
    'Cashiers': 'Cashiers',
    'Create Cashier': 'Create Cashier',
    'Name': 'Name',
    'Login': 'Login'
  },
  ru: {
    'Cashiers': 'Кассиры',
    'Create Cashier': 'Создать кассира',
    'Name': 'Имя',
    'Login': 'Логин'
  },
  uk: {
    'Cashiers': 'Касири',
    'Create Cashier': 'Створити касира',
    'Name': "Ім'я",
    'Login': 'Логін'
  },
};

export const CashierForm = {
  en: {
    ...Cashiers.en,
    'New Cashier': 'New Cashier',
    'Edit Cashier': 'Edit Cashier',
    'Update Cashier': 'Update Cashier',
    'Password': 'Password',
    'Cash Register License Key': 'Cash Register License Key'
  },
  ru: {
    ...Cashiers.ru,
    'New Cashier': 'Новый Кассир',
    'Edit Cashier': 'Редактирование Кассира',
    'Update Cashier': 'Обновить Кассира',
    'Password': 'Пароль',
    'Cash Register License Key': 'Ключ лицензии кассы'
  },
  uk: {
    ...Cashiers.uk,
    'New Cashier': 'Новий Касир',
    'Edit Cashier': 'Редагування Касира',
    'Update Cashier': 'Оновити Касира',
    'Password': 'Пароль',
    'Cash Register License Key': 'Ключ ліцензії каси'
  },
};