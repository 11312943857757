import { KeyboardDatePicker } from '@material-ui/pickers';
import { SEND_DATES_FORMAT, DISPLAY_DATES_FORMAT } from 'Constants';
import { useDatePicker } from 'Hooks';

export const DatePicker = ({ value, onChange, name, dateFormat = SEND_DATES_FORMAT, displayDateFormat = DISPLAY_DATES_FORMAT, ...props }) => {
  const {inputValue, handleChange} = useDatePicker(value, onChange, dateFormat, displayDateFormat);
  return (
    <>
      <input type="hidden" name={name} value={value} />
      <KeyboardDatePicker
        fullWidth
        margin="dense"
        variant="inline"
        format={displayDateFormat}
        onChange={handleChange}
        inputVariant="outlined"
        value={inputValue}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...props}
      />
    </>
  );
};
