import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ListWrapper, FormInput, Button, Select } from 'Components';
import { AJAX } from 'Helpers';
import confirmationStore from 'Helpers/confirmation';
import { ReportDialog } from './ReportDialog';

const LOADERS_INITIAL_STATE = {
  registrarConnection: false,
  serviceIn: false,
  xReport: false,
  closeShift: false,
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  formRow: {
    marginBottom: theme.spacing(2),
  },
  formSubmit: {
    marginLeft: theme.spacing(2),
  },
  reportButton: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  successBtn: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  closeShift: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  openShift: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  errerBtn: {
    backgroundColor: theme.palette.error.light,
    color: '#fff',
  },
}));

export const FiscalRegistrars = ({
  location_name,
  selected_cashier_name,
  current_shift_information,
  cashiers,
  current_cashier_id,
  open_shift_path,
  close_shift_path,
  create_service_receipt_path,
  x_report_path,
  switch_mode_path,
  set_cashier_path,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [serviceEntity, setServiceEntity] = React.useState('');
  const [dialogInfo, setDialogInfo] = React.useState(null);
  const [fiscalRegistrarEnabled, setFiscalRegistrarEnabled] = React.useState(
    props.fiscal_registrar_enabled
  );

  const [loadings, setLoadings] = React.useState(LOADERS_INITIAL_STATE);
  const [cashierId, setCashierId] = React.useState(current_cashier_id);

  const reportDialog = React.useRef(null)

  const setLoading = (name, value) => {
    setLoadings({
      ...loadings,
      [name]: value ?? !StaticRange.loadings[name],
    });
  }

  function handleCleanDialogInfo() {
    setDialogInfo(null);
  }

  function handleChangeServiceEntity({ target }) {
    setServiceEntity(target.value);
  }

  function handleSubmitCashier(e) {
    e.preventDefault();
    if (!cashierId) {
      return;
    }
    confirmationStore.open(function () {
      setLoading('cashier', true);
      AJAX.post(set_cashier_path, {
        body: {
          cashier_id: cashierId,
        },
      });
    });
  }

  function handleChangeCashier({ target }) {
    setCashierId(target.value);
  }

  function handleSubmitServiceEntity(e) {
    e.preventDefault();
    if (!serviceEntity) {
      return;
    }
    confirmationStore.open(function () {
      setLoading('serviceIn', true);
      AJAX.post(create_service_receipt_path, {
        body: {
          value: serviceEntity,
        },
      }).then((body) => {
        setLoading('serviceIn', false);
      });
    });
  }

  function handleGenerateXReport() {
    confirmationStore.open(function () {
      setLoading('xReport', true);
      AJAX.post(x_report_path).then((body) => {
        setLoading('xReport', false);
        reportDialog.current.open(t('TypedReport', { type: 'X' }), body.x_report)
      });
    });
  }

  function handleOpenShift() {
    confirmationStore.open(function () {
      setLoading('openShift', true);
      AJAX.post(open_shift_path).then((body) => {
        setLoading('openShift', false);
      });
    });
  }

  function handleCloseShift() {
    confirmationStore.open(function () {
      setLoading('closeShift', true);
      AJAX.post(close_shift_path).then((body) => {
        setLoading('closeShift', false);
        reportDialog.current.open(t('TypedReport', { type: 'Z' }), body.z_report)
      });
    });
  }

  function toggleRegistrarConnection() {
    setLoading('registrarConnection', true);
    AJAX.post(switch_mode_path).then((({ fiscal_registrar_enabled }) => {
      setLoading('registrarConnection', false);
      setFiscalRegistrarEnabled(fiscal_registrar_enabled);
    }));
  }
  return (
    <>
      <ListWrapper title={`${t('Fiscal Registrars')} ${location_name}`} headerAddon={(
        <Button onClick={toggleRegistrarConnection}
          loading={loadings.registrarConnection}
          className={fiscalRegistrarEnabled ? classes.successBtn : classes.errorBtn}>
          {t(fiscalRegistrarEnabled ? 'Registrar Connected To Sales' : 'Registrar Isn\'t Connected To Sales')}
        </Button>
      )}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  {t('Cashier')}
                </TableCell>
                <TableCell colSpan={2}>
                  {current_shift_information.cashier_name || '_'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  {t('Shift')}
                </TableCell>
                <TableCell colSpan={2}>
                  {current_shift_information.shift_status || t("Doesn't exist")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  ID
                </TableCell>
                <TableCell colSpan={2}>
                  {current_shift_information.id || '_'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <form className={classes.form} onSubmit={handleSubmitCashier}>
          <Select
            name="service[discount_type]"
            label={t('Cashier')}
            options={cashiers}
            value={cashierId}
            onChange={handleChangeCashier}
            required
          />
          <Button
            className={classes.formSubmit}
            loading={loadings.serviceIn}
            type="submit"
            color="primary"
            disabled={cashierId == current_cashier_id}
          >
            {t('Confirm')}
          </Button>
        </form>

        <form className={classes.form} onSubmit={handleSubmitServiceEntity}>
          <FormInput
            value={serviceEntity}
            disabled={loadings.serviceIn}
            label={t('Service In / Out')}
            onChange={handleChangeServiceEntity}
          />
          <Button
            className={classes.formSubmit}
            loading={loadings.serviceIn}
            type="submit"
            color="primary"
            disabled={!serviceEntity}
          >
            {t('Confirm')}
          </Button>
        </form>
        <div>
          <Button
            loading={loadings.openShift}
            className={classnames(classes.reportButton, classes.openShift)}
            onClick={handleOpenShift}
          >
            {t('Open Shift')}
          </Button>
          <Button
            loading={loadings.xReport}
            className={classnames(classes.reportButton, classes.successBtn)}
            onClick={handleGenerateXReport}
          >
            {t('Generate X Report')}
          </Button>
          <Button
            loading={loadings.closeShift}
            className={classnames(classes.reportButton, classes.closeShift)}
            onClick={handleCloseShift}
          >
            {t('Close Shift')}
          </Button>
        </div>
      </ListWrapper>
      <Dialog
        open={!!dialogInfo}
        onClose={handleCleanDialogInfo}
        aria-labelledby="alert-dialog-title"
      >
        {!!dialogInfo && (
          <>
            <DialogTitle id="alert-dialog-title">
              {dialogInfo.title}
            </DialogTitle>
            <DialogContent>
              <dl>
                {Object.keys(dialogInfo.body).map((key) => (
                  <React.Fragment key={key}>
                    <dt>{key}</dt>
                    <dd>{dialogInfo.body[key]}</dd>
                  </React.Fragment>
                ))}
              </dl>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleCleanDialogInfo}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
      <ReportDialog ref={reportDialog} />
    </>
  );
};
