import { Card, Box } from '@material-ui/core';
import useStyles from './BookingCardsStyles';
import { Description } from './Description';

export const CardItem = ({ href, description, name, children, openDescriptionDialog}) => {
  const classes = useStyles();

  function showDescription() {
    openDescriptionDialog({ title: name, content: description})
  }

  return (
      <Card className={classes.card} elevation={8}>
        <Box component='a' href={href} className={classes.cardLink}>
          {children}
        </Box>
        <Description text={description} showDescription={showDescription} />
      </Card>
  )
}
