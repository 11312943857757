import {
  forwardRef,
  useState,
  useImperativeHandle,
  SyntheticEvent,
  ChangeEvent,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';

import { Button, DatePicker } from 'Components';
import { AJAX } from 'Helpers';
import { DISPLAY_DATES_FORMAT, SEND_DATES_FORMAT } from 'Constants';

import {
  CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY,
  CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
  PREVIOUSLY_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
} from 'queries';

import { ExpirationDateDialogProps, ExpirationDateDialogState } from './types';

const INITIAL_STATE = {
  id: null,
  expiration_date: '',
};

const ExpirationDateDialog = forwardRef(
  (props: ExpirationDateDialogProps, ref) => {
    const { url } = props;
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const [state, setState] =
      useState<ExpirationDateDialogState>(INITIAL_STATE);

    const onOpen = (expiratioDateData: ExpirationDateDialogState) => {
      const { id, expiration_date } = expiratioDateData;
      return setState({
        id,
        expiration_date: moment(expiration_date, DISPLAY_DATES_FORMAT).format(
          SEND_DATES_FORMAT
        ),
      });
    };

    const onExpirationDateChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      setState({ ...state, expiration_date: target.value });
    };

    const onClose = () => {
      setState(INITIAL_STATE);
    };

    const onExpirationDateSubmitChanges = async (event: SyntheticEvent) => {
      event.preventDefault();
      const { expiration_date, id } = state;
      if (!expiration_date) {
        return;
      }
      const res = await AJAX.put(
        `${url}/purchased_services/${id}/update_expiration_date`,
        {
          body: { purchased_service: { expiration_date } },
        }
      );

      if (res.flash_type == 'success') {
        queryClient.invalidateQueries([
          CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
        ]);
        queryClient.invalidateQueries([CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY]);
        queryClient.invalidateQueries([
          PREVIOUSLY_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
        ]);
        onClose();
      }
    };

    useImperativeHandle(ref, () => ({
      onOpen,
      onClose,
    }));

    return (
      <Dialog
        open={!!state.id}
        onClose={onClose}
        fullWidth
      >
        <DialogTitle>{t('Change Expiration Date')}</DialogTitle>
        <form onSubmit={onExpirationDateSubmitChanges}>
          <DialogContent>
            <DatePicker
              label={t('Expiration')}
              value={state.expiration_date}
              onChange={onExpirationDateChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('Close')}</Button>
            <Button
              color="primary"
              type="submit"
              disabled={!state.expiration_date}
            >
              {t('Submit')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
);

export default ExpirationDateDialog;