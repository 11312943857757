export const BookingServices = {
  en: {
    'Position': 'Position',
    'Name': 'Name',
    'Create Booking Service': 'Create Booking Service',
    'Duration': 'Duration',
    'Dur': 'Dur.',
    'Color': 'Color',
    'Online Payment Key':'Online Payment Key'
  },
  ru: {
    'Position': 'Позиция',
    'Name': 'Название',
    'Duration': 'Длительность',
    'Create Booking Service': 'Создать букинг сервис',
    'Dur': 'Длит.',
    'Color': 'Цвет',
  },
  uk: {
    'Position': 'Позиція',
    'Name': 'Назва',
    'Create Booking Service': 'Створити букінг сервіс',
    'Duration': 'Тривалість',
    'Dur': 'Трив.',
    'Color': 'Колір',
    'Online Payment Key': 'Платіжний ключ'
  },
};

export const BookingServiceForm = {
  en: {
    ...BookingServices.en,
    'New Booking Service': 'New Booking Service',
    'Edit Booking Service': 'Edit Booking Service',
    'Update Booking Service': 'Update Booking Service',
    'Description': 'Description',
    'Service type': 'Service type',
    Total: 'Total',
    'for single booking': 'For single booking',
    'for multiple bookings': 'For multiple bookings',
    'Image': 'Image',
    'Choose Image File': 'Choose Image File',
    'Online Payment Key': 'Online Payment Key'
  },
  ru: {
    ...BookingServices.ru,
    'New Booking Service': 'Новый Букинг Сервис',
    'Edit Booking Service': 'Редактирование Букинг Сервиса',
    'Update Booking Service': 'Обновить Букинг Сервис',
    'Description': 'Описание',
    'Service type': 'Тип сервиса',
    Total: 'Итого',
    'for single booking': 'Для индивидуального бронирования',
    'for multiple bookings': 'Для групового бронирования',
    'Image': 'Изображение',
    'Choose Image File': 'Выбрать изображение',
  },
  uk: {
    ...BookingServices.uk,
    'New Booking Service': 'Новий Букінг Сервіс',
    'Edit Booking Service': 'Редагування Букінг Сервісу',
    'Update Booking Service': 'Оновити Букінг Сервіс',
    'Description': 'Опис',
    'Service type': 'Тип сервісу',
    Total: 'Разом',
    'for single booking': 'Для індивідуального бронювання',
    'for multiple bookings': 'Для групового бронювання',
    'Image': 'Зображення',
    'Choose Image File': 'Вибрати зображення',
    'Online Payment Key': 'Платіжний ключ'
  },
};
