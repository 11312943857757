import { makeStyles, Chip, Grid, Typography } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import {
  ListWrapper,
  Button,
  DatePicker,
  Autocomplete,
  ServicesPickerDialog,
  FormControl,
} from 'Components';
import { AJAX, removeFromArray } from 'Helpers';
import { VisitsTable } from './VisitsTable'

function getOptionLabel(option) {
  return !!option ? `${option.first_name} ${option.last_name}` : '';
}

function clientsRequestBody(value) {
  return {
    page: 1,
    size_per_page: 10,
    sort_column: 'created_at',
    sort_direction: 'desc',
    search: {
      value
    }
  }
}

const useStyles = makeStyles(() => ({
  subTitle: {
    textAlign: 'center',
    marginTop: '16px',
    marginBottom: '16px'
  },
  servicesFormGroup: {
    width: '100%',
    cursor: 'pointer',
  },
  submitRow: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  chip: {
    height: 'auto',
    justifyContent: 'space-between',
    marginBottom: '2px'
  },
  submitRow: {
    marginBottom: '20px',
    marginTop: '20px',

    '& button': {
      display: 'block',
      marginLeft: 'auto'
    }
  },
}))

export const AverageServiceVisits = ({ fetch_url, filter_data, clients_search_url }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const servicesPicker = React.useRef(null);

  const [serviceData, setServiceData] = React.useState({})

  const [filter, setFilter] = React.useState({
    clients: [],
    services: [],
    from: moment().subtract(1, 'months').format(),
    till: moment().add(1, 'day').format(),
  });

  function onServicesDialogOpen() {
    servicesPicker.current.open(filter.services, onServicesChange)
  }

  function onClientsChange({ target }) {
    setFilter({
      ...filter,
      clients: target.value ? [target.value] : []
    })
  }

  function onServicesChange(services) {
    setFilter({
      ...filter,
      services
    })
  }

  function onStartPeriodChange({ target }) {
    setFilter({
      ...filter,
      from: target.value
    })
  }

  function onEndPeriodChange({ target }) {
    setFilter({
      ...filter,
      till: target.value
    })
  }

  function onServiceDelete(id) {
    setFilter({ ...filter, services: removeFromArray(filter.services, id) });
  }

  async function fetchData() {
    const { from, till, activated_at, clients, services, ...filterData } = filter;
    const res = await AJAX.get(fetch_url, {
      body: {
        filters: {
          ...filterData,
          ...(!!clients.length && { clients }),
          activated_at: {
            from: from,
            till: till,
          },
          services: services.map(({ id }) => id),
        }
      }
    })

    if (!res.data) {
      return setServiceData({
        ...serviceData
      })
    }

    setServiceData(res.data)
  }

  const isEmpty = React.useMemo(() => {
    return Object.keys(serviceData).length === 0
  }, [serviceData])

  return (
    <ListWrapper title={t('Average Service Visits')}>
      <Typography
        className={classes.subTitle}
        component="h2"
        variant="h5"
      >
        {t('Filtering by dates means filtering by service activated at date')}
      </Typography>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Autocomplete
            label={t('Client')}
            name='clients'
            getRequestBody={clientsRequestBody}
            valueAsArray
            searchUrl={clients_search_url}
            getOptionLabel={getOptionLabel}
            onChange={onClientsChange}
            fetchOnInit
            method='get'
            apiName="data"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <DatePicker
            label={t('From')}
            value={filter.from}
            name='from'
            onChange={onStartPeriodChange}
            required
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <DatePicker
            label={t('Till')}
            value={filter.till}
            onChange={onEndPeriodChange}
            required
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <FormControl
            className={classes.servicesFormGroup}
            onClick={onServicesDialogOpen}
            label={t('Services')}
            name='services'
            isRequired
          >
            {filter.services.map(({ id, name }) => (
              <Chip
                key={id}
                className={classes.chip}
                label={(<p className={classes.chipLabel}>{name}</p>)}
                variant="outlined"
                onDelete={() => onServiceDelete(id)}
              />
            ))}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.submitRow}
        >
          <Button
            type="button"
            onClick={fetchData}
          >
            {t('Filter')}
          </Button>
        </Grid>
      </Grid>
      {!isEmpty && (
        <VisitsTable {...serviceData} />
      )}
      <ServicesPickerDialog
        ref={servicesPicker}
        services={filter_data.services}
      />
    </ListWrapper>
  )
}
