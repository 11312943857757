import moment from 'moment';
import logo from './logo.svg';
import { DISPLAY_DATES_FORMAT, FORM_LANG } from 'Constants';

export const ResponsibilityForm = React.forwardRef(({ client }, ref) => {
  const [state, changeState] = React.useState({
    lang: FORM_LANG.uk,
    isOpened: false,
  });

  function print(lang) {
    changeState({
      lang,
      isOpened: true
    })
  }

  React.useEffect(() => {
    if (state.isOpened) {
      window.print()
      changeState({
        ...state,
        isOpened: false
      })
    }
  }, [state.isOpened])


  React.useImperativeHandle(ref, () => ({
    print,
  }));

  return (
    <div className="responsibility-form">
      {state.lang === FORM_LANG.en && (
        <ResponsibilityFormEng client={client} logo={logo} />
      )}

      {state.lang === FORM_LANG.uk && (
        <ResponsibilityFormUkr client={client} logo={logo} />
      )}
    </div>
  )
})

function ResponsibilityFormUkr({ client, logo }) {
  return (
    <>
      <div className="responsibility-form__header">
        <h3 className="responsibility-form__title">Форма відповідальності № 1</h3>
        <img className="responsibility-form__logo" src={logo} />
      </div>
      <div className="responsibility-form__clear-both"></div>
      <p className="responsibility-form__client-data">Я, {client.first_name}&nbsp;{client.last_name}</p>
      {!!client.birthday && (
        <p className="responsibility-form__client-data">Дата народження: {moment(client.birthday).format(DISPLAY_DATES_FORMAT)}</p>
      )}
      {!client.birthday && (
        <p className="responsibility-form__client-data form-empty-line">
          <span className="form-empty-line__key">Дата народження:</span>
          <span className="form-empty-line__line"></span>
        </p>
      )}
      <p className="responsibility-form__client-data">Тел: {client.phone}. Email: {client.email}</p>
      <p className="responsibility-form__info-title">Підтверджую, що:</p>
      <ol className="responsibility-form__info-list">
        <li className="responsibility-form__info-list-item">Обізнаний, що скелелазіння травмонебезпечний вид спорту і заняття ним може спричинити травми різних ступенів тяжкості.</li>
        <li className="responsibility-form__info-list-item">Ознайомлений (а) з правилами відвідування і технікою безпеки, які діють на скеледромі, і забов'язуюсь неухильно їх дотримуватися.</li>
        <li className="responsibility-form__info-list-item">Скеледром не несе відповідальності в разі отримання мною травм або інших ушкоджень під час тренувань або перебування на території скеледрому.</li>
      </ol>
      <p className="responsibility-form__client-data">Своїм підписом засвідчую згоду на обробку моїх персональних даних – прізвища, імені, імені по батькові, дати народження, контактних даних, зображення – будь-яким законним способом.</p>
      <div className="responsibility-form__signature-wrapper">
        <p>_________________________<span>Дата</span> </p>
        <p>__________________<span>Підпис</span></p>
        <p>_________________________<span>Прiзвище та iнiцiали</span></p>
      </div>
    </>
  )
}

function ResponsibilityFormEng({ client, logo }) {

  return (
    <>
      <div className="responsibility-form__header">
        <h3 className="responsibility-form__title">Form of responsibility №1</h3>
        <img className="responsibility-form__logo" src={logo} />
      </div>
      <div className="responsibility-form__clear-both"></div>
      <p className="responsibility-form__client-data">
        Name, Surname: {client.first_name}&nbsp;{client.last_name}
      </p>
      {!!client.birthday && (
        <p className="responsibility-form__client-data">Day of birthday: {moment(client.birthday).format(DISPLAY_DATES_FORMAT)}</p>
      )}
      {!client.birthday && (
        <p className="responsibility-form__client-data form-empty-line">
          <span className="form-empty-line__key">Day of birthday:</span>
          <span className="form-empty-line__line"></span>
        </p>
      )}

      <p className="responsibility-form__client-data">Phone number: {client.phone}. Email: {client.email}</p>
      <p className="responsibility-form__info-title">I certify that:</p>
      <ol className="responsibility-form__info-list">
        <li className="responsibility-form__info-list-item">I admit that climbing is a traumatic dangerous sport and can cause injuries of varying severity.</li>
        <li className="responsibility-form__info-list-item">I am familiar with the rules of attendance and safety rules applicable to the climbing walls, and I undertake to strictly adhere to them.</li>
        <li className="responsibility-form__info-list-item">The climbing hall is not responsible in case of injuries or other injuries during training or stay on the climbing area.</li>
      </ol>
      <p className="responsibility-form__client-data">By my signature I certify consent to the processing of my personal data - surname, first name, patronymic, date of birth, contact details, images - by any legal means.</p>
      <div className="responsibility-form__signature-wrapper">
        <p>_________________________<span>Date</span> </p>
        <p>__________________<span>Signature</span></p>
        <p>_________________________<span>Full name</span></p>
      </div>
    </>
  )
}