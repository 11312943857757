import {
  Typography,
  makeStyles,
  Box,
  Container
} from '@material-ui/core';

import {
  ListWrapper,
  Button,
} from 'Components';
import BuyDialog from './buyDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  content: {
    width: '100%'
  },
  sidebar: {
    width: '400px',
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    padding: `${theme.spacing(2)}px 0`,
    '& a': {
      color: theme.palette.primary.main
    }
  },
  listButton: {
    marginLeft: theme.spacing(1)
  },
  sidebarTitle: {
    marginBottom: theme.spacing(2)
  },
  backButton: {
    color: '#fff'
  },
  buttonContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  }
}));

const OnlineService = ({
  name, price, subscription, description, new_client_online_order_url, back_url
}) => {
  const classes = useStyles();
  const buyDialog = React.useRef(null);

  const openDialog = async () => {
    buyDialog.current.open();
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Container className={classes.buttonContainer}>
            <Button
              component="a"
              href={back_url}
              variant="contained"
              color="primary"
              size="small"
              className={classes.backButton}
              startIcon={<i className="icon-left-open"></i>}>
              Повернутись
            </Button>
          </Container>
          <ListWrapper title={(<div dangerouslySetInnerHTML={{ __html: name }}></div>)}>
            <Box className={classes.description}>
              <Button color="primary" size="large" onClick={openDialog}>Придбати</Button>
              <p>
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: description }}></Typography>
              </p>
            </Box>
          </ListWrapper>

        </Box>
      </Box>
      <BuyDialog ref={buyDialog} fetchUrl={new_client_online_order_url} name={name} price={price} subscription={subscription} />
    </>
  )
};

export default OnlineService;