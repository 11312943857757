import {
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Collapse, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FormInput, Form, Button } from 'Components';
import { useForm } from 'Hooks';
import { checkRequired } from 'Helpers/validation';

import { AddCardNumberFormProps, CardNumberAdd } from './type';

const validators = {
  value: checkRequired('value'),
};

const useStyles = makeStyles((theme) => ({
  actionButton: {
    margin: theme.spacing(1),
  },
}));

const AddCardNumberForm = forwardRef((props: AddCardNumberFormProps, ref) => {
  const { url, onAddCardNumber } = props;
  const [isOpen, setIsOpen] = useState(false);
  const cardNumberInput = useRef(null);
  const {
    item,
    changeValue,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    unsubmit,
    setServerErrors,
  } = useForm({ value: '' }, validators, url);
  const { t } = useTranslation();
  const classes = useStyles();

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    changeValue('value', '');
    unsubmit();
  };

  const onCardNumberValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    changeValue('value', target.value);
  };

  const onCardNumberAdd = (res: CardNumberAdd) => {
    if (res.card_number) {
      const { id, value } = res.card_number;
      onAddCardNumber({ id, card_number: value });
      onClose();
    }
  };

  useImperativeHandle(ref, () => ({
    onOpen,
    onClose,
  }));

  return (
    <>
      <Collapse in={isOpen}>
        <Form
          name="card_number"
          method={method}
          disabled={isItemInvalid}
          item={item}
          action={action}
          onSuccessSubmit={onCardNumberAdd}
          onError={setServerErrors}
          onSubmit={submit}
        >
          <FormInput
            value={item.value}
            onChange={onCardNumberValueChange}
            ref={cardNumberInput}
            label={t('Card Number')}
            validationMessages={validationMessagesToDisplay.value}
          />
          <Button
            className={classes.actionButton}
            onClick={onClose}
          >
            {t('Close')}
          </Button>
          <Button
            className={classes.actionButton}
            color="primary"
            type="submit"
          >
            {t('Create')}
          </Button>
        </Form>
      </Collapse>
      <Collapse in={!isOpen}>
        <Button
          fullWidth
          onClick={onOpen}
        >
          {t('Add Card Number')}
        </Button>
      </Collapse>
    </>
  );
});

export default AddCardNumberForm;