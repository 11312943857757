import { IconButton, } from '@material-ui/core';
import confirmationStore from 'Helpers/confirmation';
export const ListActions = ({ children, editUrl, onDelete, }) => {
  function handleDelete() {
    confirmationStore.open(onDelete);
  }
  return (
    <>
      {children}
      {!!editUrl && (
        <IconButton size="small" href={editUrl}><i className="icon-edit"></i></IconButton>
      )}
      {!!onDelete && (
        <IconButton size="small" onClick={handleDelete}>
          <i className="icon-trash-empty"></i>
        </IconButton>
      )}
    </>
  );
}
