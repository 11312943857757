import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FormInput, Checkbox, Select } from 'Components';

import { PaymentTypeProps } from '../types';

const useStyles = makeStyles(() => ({
  paymentType: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paymentTypeInput: {
    width: '50%',
  },
}));

const PaymentType = ({
  paymentType,
  disabled,
  onPaymentIsActiveToggle,
  onPaymentAmountChange,
  onPaymentCashlessAgentChange,
}: PaymentTypeProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { type, name, isActive, amount, cashless_agents, cashless_agent_id } =
    paymentType;

  const isCashlessAgentsSelectDisplayed = !!cashless_agents && isActive;

  return (
    <>
      <div className={classes.paymentType}>
        <Checkbox
          label={t(name)}
          name={type}
          checked={isActive}
          onChange={onPaymentIsActiveToggle}
          disabled={disabled}
        />
        <FormInput
          className={classes.paymentTypeInput}
          name={type}
          value={amount}
          disabled={disabled || !isActive}
          onChange={onPaymentAmountChange}
        />
      </div>
      {isCashlessAgentsSelectDisplayed && (
        <Select
          value={cashless_agent_id || ''}
          options={cashless_agents}
          label={`${t(name)}:`}
          onChange={onPaymentCashlessAgentChange}
          name={type}
          required
        />
      )}
    </>
  );
};

export default PaymentType;
