export const Payments = {
  en: {
    'Service': 'Service',
    'Category': 'Category',
    'User': 'User',
    'Client': 'Client',
    'Purchased Service Id': 'PS Id',
    'Certificate Code': 'Cert',
    'Payment Type': 'P Type',
    'Price': 'Price',
    'Price With Discount': 'Price With Discount',
    'Discount': 'Dscnt',
    'Created At': 'Create',
    'Cancel': 'Cancel',
    'Discount service ids': 'Dscnt service ids',
    'CSV Files': 'CSV Files'
  },
  ru: {
    'Service': 'Сервис',
    'Category': 'Категория',
    'User': 'Польз-ль',
    'Client': 'Клиент',
    'Purchased Service Id': 'Id сер.',
    'Certificate Code': 'Серт.',
    'Payment Type': 'Тип Опл.',
    'Price': 'Цена',
    'Price With Discount': 'Цена Со Скид.',
    'Discount': 'Скидка',
    'Created At': 'Создан',
    'Cancel': 'Отмена',
    'Discount service ids': 'Ids использованных скидок',
    'CSV Files': 'CSV Файлы'
  },
  uk: {
    'Service': 'Сервіс',
    'Category': 'Категорія',
    'User': 'К-вач',
    'Client': 'Клієнт',
    'Purchased Service Id': 'Id сер.',
    'Certificate Code': 'Серт.',
    'Payment Type': 'Тип Опл.',
    'Price': 'Ціна',
    'Price With Discount': 'Ціна З Знижкою',
    'Discount': 'Знижка',
    'Created At': 'С-ний',
    'Cancel': 'Відміна',
    'Discount service ids': 'Ids використаних знижок',
    'CSV Files': 'CSV Файли'
  },
};
