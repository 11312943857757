import {
  Select, Chip, MenuItem, InputLabel, FormControl, FormHelperText, makeStyles, Checkbox,
  ListItemText
} from '@material-ui/core';
import { v1 as uuidv1 } from 'uuid';

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  mainWrapper: {
    marginTop: '8px',
    marginBottom: '4px'
  }
}));


export const MultiSelect = ({ options, value = [], label, validationMessages, valueAsArray, required, ...props }) => {
  const labelId = uuidv1();
  const { chips, chip, mainWrapper } = useStyles();

  const optionsToDisplay = options.map(({ id, name }) => (
    <MenuItem key={id} value={id}>
      <Checkbox color="primary" checked={value.indexOf(id) > -1} />
      <ListItemText primary={name} />
    </MenuItem>
  ))

  const selectedOptions = (value || []).map(id => options.filter(item => item.id === id)[0]);

  return (
    <FormControl variant="outlined" fullWidth label={label} error={!!validationMessages} className={mainWrapper}>
      <InputLabel id={labelId} required={required}>{label}</InputLabel>
      <Select variant="outlined" fullWidth multiple value={value} labelId={labelId}
        label={label} renderValue={(_) => (
          <div className={chips}>
            {selectedOptions.map(({ id, name }) => (
              <Chip key={id} label={name} className={chip} />
            ))}
          </div>
        )}
        {...props}>
        {optionsToDisplay}
      </Select>
      <FormHelperText>{validationMessages}</FormHelperText>
    </FormControl>
  )
}
