import { createContext, useContext } from 'react';

import { ClientShowProviderProps, ClientShowContextType } from '../type';
import { useClientQuery } from 'queries';

const ClientShowContext = createContext<ClientShowContextType>(null!);

export const useClientContext = () => {
  const context = useContext(ClientShowContext);

  if (!context) {
    throw new Error(
      'useClientContext must be used within an <ClientProvider />'
    );
  }

  return context;
};

const ClientShowProvider = ({ children, data }: ClientShowProviderProps) => {
  const clientUrl = `${data?.url}/${data?.client.id}`;
  const clientData = useClientQuery(clientUrl);

  if (clientData.isLoading) {
    return null;
  }

  return (
    <ClientShowContext.Provider value={clientData.data}>
      {children}
    </ClientShowContext.Provider>
  );
};

export default ClientShowProvider;
