import { Table, TableContainer } from '@material-ui/core';

import { IncomeTableHead } from './IncomeTableHead';
import { IncomeTableBody } from './IncomeTableBody';

import { IncomeTableProps } from '../types';

export const IncomeTable = ({
  incomesByLocations,
}: IncomeTableProps) => {
  if (!incomesByLocations || !incomesByLocations.length) return null;

  return (
    <TableContainer>
      <Table>
        <IncomeTableHead incomesByLocations={incomesByLocations} />
        <IncomeTableBody incomesByLocations={incomesByLocations} />
      </Table>
    </TableContainer>
  );
};
