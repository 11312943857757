import { InitializedPaymentType, SerializedPaymentType } from 'hooks/types';

export const getSerializedPaymentTypes = (
  paymentTypes: InitializedPaymentType[]
): SerializedPaymentType[] => {
  return paymentTypes.reduce((serializedPaymentTypes, paymentType) => {
    const { isActive, cashless_agent_id, amount, type } = paymentType;

    if (!isActive) return serializedPaymentTypes;

    return [
      ...serializedPaymentTypes,
      {
        type,
        amount,
        ...(!!cashless_agent_id && { cashless_agent_id }),
      },
    ];
  }, [] as SerializedPaymentType[]);
};
