import { useTranslation, } from 'react-i18next';
import { BookingAnalyticsChart } from 'Components';

export const BookingsByPaymentDates = ({ fetch_url }) => {
  const { t } = useTranslation();

  return (
    <BookingAnalyticsChart
      title={t('Paid Bookings By Payment Dates')}
      fetch_url={fetch_url}
    />
  );
}
