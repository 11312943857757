import {
  FormControl as MFormControl,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  FormWrapper,
  Form,
  Checkbox,
  TextEditor,
  FormControl,
  Select,
  Autocomplete,
  ImageUpload,
  MultiSelect,
  FormInput
} from 'Components';
import { findInArrayBy, AJAX } from 'Helpers';
import { useForm } from 'Hooks';
import { useMemo, useRef, useState, useEffect } from 'react';

const useStyles = makeStyles(() => ({
  select: {
    '& p, & h1, & h2, & h3, & h4, & h5, & h6': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  imagePreview: {
    maxHeight: '350px',
    objectFit: 'contain',
  },
}));

function getOptionLabel(option) {
  return option?.full_name;
}

export const BookingProviderForm = ({
  url,
  api_url,
  booking_provider,
  trainers,
  booking_services_api_url,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    item,
    changeValue,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    setServerErrors,
    setItem,
  } = useForm(booking_provider, {}, api_url);

  const PROVIDER_TYPES = useMemo(() => {
    return [
      { id: 'trainer', name: t('Trainer') },
      { id: 'spot', name: t('Spot') },
    ];
  }, []);

  const [bookingServices, setBookingServices] = useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const onRequestStart = () => setIsFormSubmitting(true);
  const onRequestEnd = () => setIsFormSubmitting(false);

  const bookingServicesToDisplay = useMemo(() => {
    return bookingServices.map(({ name, id }) => {
      return {
        id,
        name: (
          <div
            className={classes.select}
            dangerouslySetInnerHTML={{ __html: name }}
          ></div>
        ),
      };
    });
  }, [bookingServices]);

  const descriptionTextEditor = useRef(null);
  const nameTextEditor = useRef(null);

  const onActiveChange = ({ target }) => {
    changeValue('active', target.checked);
  };

  function onProviderTypeChange({ target }) {
    setItem({
      ...item,
      provider_type: target.value,
      trainer_id: '',
      booking_service_ids: [],
    });
  }

  function onTrainerIdChange(_, val) {
    setItem({
      ...item,
      trainer_id: val?.id,
      booking_service_ids: [],
    });
  }

  const onOrderPositionChange = ({target}) => {
    changeValue('order_position', target.value);

  }

  function onBookingServiceIdsChange({ target }) {
    changeValue('booking_service_ids', target.value);
  }

  const isProviderTypeTrainer = useMemo(() => {
    return item.provider_type === 'trainer';
  }, [item.provider_type]);

  const selectedTrainer = useMemo(() => {
    return findInArrayBy(trainers, item.trainer_id);
  }, [item.provider_type, item.trainer_id]);

  function onPhotoChange(photo) {
    changeValue('photo', photo);
  }

  useEffect(() => {
    fetchBookingServices();
  }, [selectedTrainer, item.provider_type]);

  async function fetchBookingServices() {
    if ((isProviderTypeTrainer && !selectedTrainer) || !item.provider_type) {
      return;
    }
    const res = await AJAX.get(booking_services_api_url, {
      ...(selectedTrainer && {
        body: {
          filters: {
            locations: selectedTrainer.location_ids,
          },
        },
      }),
    });
    if (!!res.data) {
      setBookingServices(res.data);
    }
  }

  const serializedItem = useMemo(
    function () {
      const { trainer_id, ...itemValues } = item;

      return {
        ...itemValues,
        ...(isProviderTypeTrainer && {
          trainer_id,
        }),
      };
    },
    [item]
  );

  return (
    <>
      <Form
        name="booking_provider"
        action={action}
        method={method}
        disabled={isItemInvalid}
        onSubmit={submit}
        onRequestStart={onRequestStart}
        onRequestEnd={onRequestEnd}
        item={serializedItem}
        onError={setServerErrors}
        encType="multipart/form-data"
      >
        <FormWrapper
          backUrl={url}
          title="Booking Provider"
          item={item}
          disabled={isFormSubmitting}
        >
          <FormControl
            fullWidth
            label={t('Name')}
            validationMessages={validationMessagesToDisplay.name}
          >
            <TextEditor
              ref={nameTextEditor}
              value={item.name}
              name="booking_provider[name]"
              multipartFormData
            />
          </FormControl>
          <FormControl
            fullWidth
            label={t('Description')}
          >
            <TextEditor
              ref={descriptionTextEditor}
              value={item.description}
              name="booking_provider[description]"
              multipartFormData
            />
          </FormControl>
          <Select
            validationMessages={validationMessagesToDisplay.provider_type}
            value={item.provider_type}
            options={PROVIDER_TYPES}
            label={t('Provider Type')}
            onChange={onProviderTypeChange}
            required
            name="booking_provider[provider_type]"
          />
          {isProviderTypeTrainer && (
            <>
              <Autocomplete
                label={t('Trainer')}
                value={selectedTrainer?.full_name || ''}
                onChange={onTrainerIdChange}
                options={trainers}
                getOptionLabel={getOptionLabel}
                initialInputValue={selectedTrainer?.full_name || ''}
                validationMessages={validationMessagesToDisplay.trainer}
                required
              />
              <input
                name="booking_provider[trainer_id]"
                value={item.trainer_id || ''}
                readOnly
                hidden
              />
            </>
          )}
          <>
            <FormInput
              type="number"
              validationMessages={validationMessagesToDisplay.order_position}
              value={item.order_position}
              label={t('Position')}
              onChange={onOrderPositionChange}
              name="booking_service[order_position]"
            />
            <input
              name="booking_provider[order_position]"
              value={item.order_position || ''}
              hidden
              readOnly
            />
          </>
          {!!bookingServices.length && (
            <MultiSelect
              options={bookingServicesToDisplay}
              label={t('Booking services')}
              value={item.booking_service_ids}
              onChange={onBookingServiceIdsChange}
              validationMessages={validationMessagesToDisplay.booking_services}
            />
          )}
          {item.booking_service_ids.map((id) => (
            <input
              key={id}
              name="booking_provider[booking_service_ids][]"
              value={id}
              hidden
              readOnly
            />
          ))}
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <FormControl
                label={t('Image')}
                validationMessages={validationMessagesToDisplay.photo}
                fullWidth
              >
                <ImageUpload
                  value={item.photo}
                  url={item.photo_url}
                  label={t('Choose Image File')}
                  onChange={onPhotoChange}
                  name="booking_provider[photo]"
                  previewClassName={classes.imagePreview}
                  hasSizeLimit
                />
              </FormControl>
            </Grid>
          </Grid>
          <MFormControl fullWidth>
            <Checkbox
              checked={item.active || false}
              onChange={onActiveChange}
              value={item.active || 'false'}
              label={t('Active')}
            />
            <input
              name="booking_provider[active]"
              value={item.active ? 'true' : 'false'}
              hidden
              readOnly
            />
          </MFormControl>
        </FormWrapper>
      </Form>
    </>
  );
};

BookingProviderForm.defaultProps = {
  booking_provider: {
    name: '',
    description: '',
    provider_type: '',
    active: true,
    trainer_id: '',
    photo: '',
    booking_service_ids: [],
  },
};
