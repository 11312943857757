import {
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  Grid,
  CircularProgress,
  makeStyles,
  SlideProps,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';

import { Button, FormControl } from 'Components';
import { findInArrayBy, removeFromArray, pushToArray, AJAX, getSerializedPaymentTypes } from 'Helpers';
import { usePaymentTypesForm } from 'Hooks';
import notificationsStore from 'Helpers/notifications';

import ServicesList from './ServicesList';
import Cart from './Cart';
import PaymentTypes from './PaymentTypes';
import {
  CLIENT_ORDERS_QUERY_KEY,
  CLIENT_QUERY_KEY,
  CURRENT_PURCHASED_NOT_ACTIVE_SERVICES_QUERY_KEY,
} from 'queries';

import {
  BuyServiceDialogProps,
  BuyServiceDialogState,
  SerializedServicesType,
} from './types.js';
import { Service } from '../types';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  height100: {
    height: '100%',
    maxHeight: '100%',
  },
  servicesColumn: {
    maxHeight: '100%',
  },
  fullHeight: {
    height: '100%',
    maxHeight: '100%',
  },
  cartNPafmentType: {
    display: 'flex',
    flexDirection: 'column',
  },
  cartContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  paymentTypeContainer: {
    marginTop: theme.spacing(2),
  },
  loaderContainer: {
    background: 'rgba(255, 255, 255, 0.7)',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: 10000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const INITIAL_STATE = {
  isOpen: false,
  cart: [],
  isSubmitted: false,
  expandedCategory: null,
  loading: false,
};

const Transition = forwardRef((props: SlideProps, ref) => (
  <Slide
  direction="up"
  ref={ref}
  {...props}
  />
));

const BuyServiceDialog = forwardRef((props: BuyServiceDialogProps, ref) => {
  const { services, client_user_order_url, payment_types } = props;
  const classes = useStyles();
  const [state, setState] = useState<BuyServiceDialogState>({
    ...INITIAL_STATE,
  });
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const paymentTypesForm = usePaymentTypesForm(payment_types, state.cart);

  const {totalPrice, paymentTypesValidationMessages, resetPaymentTypes} = paymentTypesForm;

  const onOpen = () =>
    setState({
      ...state,
      isOpen: true,
    });

  const onClose = () => {
    setState(INITIAL_STATE);
    resetPaymentTypes();
  }

  const onCategoryExpandToggle = (category: string) =>
    setState({
      ...state,
      expandedCategory: state.expandedCategory === category ? null : category,
    });

  const onServiceCheckedToogle = (service: Service) => {
    const cart = findInArrayBy(state.cart, service.id)
      ? removeFromArray(state.cart, service.id)
      : pushToArray(state.cart, service);
    setState({ ...state, cart });
  };

  const serializedServices = useMemo(
    () =>
      services.reduce((res, service) => {
        const { service_category_name } = service;
        if (!!res[service_category_name]) return res;
        return {
          ...res,
          [service_category_name]: services.filter(
            (service) => service.service_category_name === service_category_name
          ),
        };
      }, {} as SerializedServicesType),
    [services]
  );

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const validationMessages = useMemo(
    () => {
      const isCartNotEmpty = !!state.cart.length ? null : t("Cart can't be empty");
      return {
        ...paymentTypesValidationMessages,
        cart: isCartNotEmpty
      }
    },
    [state.cart, paymentTypesForm.paymentTypes]
  );

  const onServicesBuy = async () => {
    setState({ ...state, isSubmitted: true });
    if (!!validationMessages.cart || !!validationMessages.payment_types) {
      return;
    }
    setState({ ...state, loading: true });
    
    const res = await AJAX.post(client_user_order_url, {
      body: {
        client_user_order: {
          payment_types: getSerializedPaymentTypes(paymentTypesForm.paymentTypes),
          service_ids: state.cart.map(({ id }) => id),
        },
      },
    });

    if (res.status === 'error') {
      setState({ ...state, loading: false })
      return notificationsStore.add({ type: 'error', text: res.errors });
    }

    queryClient.invalidateQueries([CLIENT_ORDERS_QUERY_KEY]);
    queryClient.invalidateQueries([
      CURRENT_PURCHASED_NOT_ACTIVE_SERVICES_QUERY_KEY,
    ]);
    queryClient.invalidateQueries([CLIENT_QUERY_KEY]);
    onClose()
  };

  return (
    <Dialog
      fullScreen
      open={state.isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <i className="icon-cancel"></i>
          </IconButton>
          <Typography
            variant="h6"
            className={classes.title}
          >
            {t('Buy New Service')}
          </Typography>
          <Button
            color="inherit"
            variant="text"
            onClick={onServicesBuy}
          >
            {t('Buy Service')}
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid
          container
          spacing={2}
          className={classes.fullHeight}
        >
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.height100}
          >
            <FormControl
              label={t('Service')}
              className={classes.height100}
              fullWidth
              validationMessages={
                !!state.isSubmitted ? validationMessages.cart : null
              }
            >
              <ServicesList
                services={serializedServices}
                cart={state.cart}
                expandedCategory={state.expandedCategory!}
                onCategoryExpandToggle={onCategoryExpandToggle}
                onServiceCheckedToogle={onServiceCheckedToogle}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={cn(classes.height100, classes.cartNPafmentType)}
          >
            <FormControl
              className={classes.cartContainer}
              validationMessages={
                !!state.isSubmitted ? validationMessages.cart : null
              }
              label={
                <div>
                  <i className="icon-basket"></i>&nbsp; {t('Cart')}
                </div>
              }
              fullWidth
            >
              <Cart
                services={state.cart}
                totalPrice={totalPrice}
                onServiceRemove={onServiceCheckedToogle}
                />
            </FormControl>
            <FormControl
              label={t('Payment Types')}
              className={classes.paymentTypeContainer}
              validationMessages={
                !!state.isSubmitted ? validationMessages.payment_types : null
              }
            >
              <PaymentTypes payment_types={paymentTypesForm} />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      {!!state.loading && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}
    </Dialog>
  );
});

export default BuyServiceDialog;
