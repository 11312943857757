export const PaymentCards = {
  en: {
    Name: 'Name',
    'Create Card': 'Create card',
    'Payment cards':'Payment cards',
    Number: 'Number',
  },
  uk: {
    Name: 'Назва',
    'Create Card': 'Створити картку',
    'Payment cards':'Платіжні картки',
    Number: 'Номер',
  },
};

export const PaymentCardForm = {
  en: {
    ...PaymentCards.en,
    'New Card': 'New Card',
    'Edit Card': 'Edit Card',
    'Update Card': 'Update Card',
    Number: 'Number',
    'Must be 16 characters': 'Must be 16 characters'
  },
  uk: {
    ...PaymentCards.uk,
    'New Card': 'Нова картка',
    'Edit Card': 'Редагування картки',
    Number: 'Номер',
    'Update Card': 'Оновити картку',
    'Must be 16 characters': 'Повинно бути 16 цифр',
  },
};
