import { Card, Box, Typography, makeStyles } from '@material-ui/core';
import bookingCardsClasses from './BookingCardsStyles';

const useStyles = makeStyles((theme) => ({
  serviceTitle: {
    fontSize: '22px',
    fontWeight: 700,
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  serviceDescription: {
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
}));

export const TypeCardItem = ({url, description, children}) => {
  const classes = useStyles();
  const cardsClasses = bookingCardsClasses();

  return (
    <Card elevation={8} className={cardsClasses.card}>
      <Box component='a' href={url} className={cardsClasses.cardLink} >
        <Typography className={classes.serviceTitle}>{children}</Typography>
        <div className={classes.serviceDescription} dangerouslySetInnerHTML={{ __html: description }}></div>
      </Box>
    </Card>
  )
}
