export const ServicesRating = {
  en: {
    Name: 'Name',
    'Top 20 the most purchased services': 'Top-20 the most purchased services',
    'Location': 'Location',
    'Count of purchases': 'Count of purchases',
  },
  ru: {
    Name: 'Название',
    'Top 20 the most purchased services': 'Топ-20 самых востребованных услуг',
    'Location': 'Локация',
    'Count of purchases': 'Количество Приобретений',
  },
  uk: {
    Name: 'Назва',
    'Top 20 the most purchased services': 'Топ-20 найпопулярніших послуг',
    'Location': 'Локація',
    'Count of purchases': 'Кількість придбань',
  },
};

