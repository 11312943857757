import { Grid, Button, Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  ListWrapper,
  Filters,
  Chart,
  DatePicker,
} from 'Components';
import { AJAX } from 'Helpers';
import { MONTH_FORMAT, DISPLAY_MONTH_FORMAT } from 'Constants';

const useStyles = makeStyles(() => ({
  recalcWrapper: {
    marginBottom: '20px'
  }
}))

function getSeries(locations, categories, data, valueKeyName) {
  return locations.map(({ name, id }) => {
    return {
      name,
      data: categories.map((date) => {
        return data[date][id][valueKeyName]
      })
    }
  })
}

const DEFAULT_FILTER = {
  from: moment().subtract(3, 'months').startOf('month').format('YYYY-MM'),
  till: moment().subtract(1, 'months').startOf('month').format('YYYY-MM'),
}

export const MonthlyIncome = ({ fetch_url, recalculate_url, locations }) => {
  const classes = useStyles();

  const filters = React.useRef(null);

  const [state, setState] = React.useState({
    loading: true,
    monthlyInclimeData: {},
    monthlyInclimeWithoutTrainers: {}
  });

  const { t } = useTranslation();

  async function fetchData() {
    const { from, till } = filters.current.values;
    setState({
      loading: true,
      monthlyInclimeData: {},
      monthlyInclimeWithoutTrainers: {}
    })
    const res = await AJAX.get(fetch_url, {
      body: {
        sort_column: 'year_month', sort_direction: 'asc', page: 1, size_per_page: 100,
        filters: { year_month: { from, till } }
      }
    })

    if (!res.data) {
      setState({
        ...state,
        loading: false,
      })
      return
    }

    const data = res.data.reduce((val, item) => {
      return {
        ...val,
        [item.year_month]: {
          ...val[item.year_month],
          [item.location_id]: {
            value: item.value,
            value_without_trainer_payouts: item.value_without_trainer_payouts
          },
        }
      }
    }, {})

    const categories = Object.keys(data);

    const dataToChart = {
      monthlyInclimeData: {
        categories,
        series: getSeries(locations, categories, data, 'value')
      },
      monthlyInclimeWithoutTrainers: {
        categories,
        series: getSeries(locations, categories, data, 'value_without_trainer_payouts')
      }
    }

    setState({
      ...dataToChart,
      loading: false,
    })
  }

  const [dateValue, changeDateValue] = React.useState('');

  const handleChangeDateValue = ({ target }) => {
    changeDateValue(target.value || '');
  }

  async function handleRecalculate() {
    await AJAX.post(recalculate_url, {
      body: {
        year_month: dateValue
      }
    });

    fetchData();
  }

  return (
    <ListWrapper title={t('Monthly Income')}>
      <Filters
        ref={filters}
        onSubmit={fetchData}
        initialValues={DEFAULT_FILTER}
        onInit={fetchData}
      >
        <DatePicker
          openTo="month"
          views={["year", "month"]}
          label={t('From')}
          name="from"
          dateFormat={MONTH_FORMAT}
          displayDateFormat={DISPLAY_MONTH_FORMAT}
        />
        <DatePicker
          openTo="month"
          views={["year", "month"]}
          label={t('Till')}
          name="till"
          dateFormat={MONTH_FORMAT}
          displayDateFormat={DISPLAY_MONTH_FORMAT}
        />
      </Filters>

      <Grid item xs={12} sm={12}>
        <Chart
          label={t('Monthly Income')}
          type="column"
          tooltipHeaderText={t('Month')}
          yText={t('Amount')}
          tooltip={{
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          }}
          crosshair
          pending={state.loading}
          {...state.monthlyInclimeData}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Chart
          label={t('Monthly Income Without Trainer Payouts')}
          type="column"
          tooltipHeaderText={t('Month')}
          yText={t('Amount')}
          tooltip={{
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          }}
          crosshair
          pending={state.loading}
          {...state.monthlyInclimeWithoutTrainers}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box className={classes.recalcWrapper}>
          <DatePicker
            openTo="month"
            views={["year", "month"]}
            label={t('Select Month')}
            onChange={handleChangeDateValue}
            value={dateValue}
            dateFormat={MONTH_FORMAT}
            displayDateFormat={DISPLAY_MONTH_FORMAT}
          />
          <Button
            variant="contained"
            color="primary"
            component="button"
            onClick={handleRecalculate}
          >{t('Recalculate')}</Button>
        </Box>
      </Grid>
    </ListWrapper>
  );
};
