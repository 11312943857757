export const MAX_PAGES = 5;
export const PAGE_SIZES = [10, 25, 50, 100];
export const CLIENT_PAGE_TABLES_PAGE_SIZES = [5, ...PAGE_SIZES];
export const DEFAULT_PAGE_SIZE = 100;
export const DEFAULT_PAGINATION_PARAMS = {
  page: 1,
  size_per_page: DEFAULT_PAGE_SIZE
}
export const DEFAULT_SORT_PARAMS = {
  sort_column: 'name',
  sort_direction: 'asc'
}
