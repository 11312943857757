import { Avatar, Link, makeStyles, Box } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { ListWrapper, Table } from 'Components';
import { useList, } from 'Hooks';


const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'desc'
}

const useStyles = makeStyles(theme => ({
  name: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  search: { marginRight: theme.spacing(3) },
}))


export const NewClients = ({ url, fetch_url }) => {
  const classes = useStyles();
  const { t, i18n, } = useTranslation();
  const {
    pending, data, records_total, paginationParams, handleChangePagination
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  const tableConfig = React.useMemo(function () {
    return [
      {
        title: t('Name'),
        accessor: 'last_name',
        cellComponent: function ({ first_name, last_name, photo_url }) {
          const initials = `${first_name[0]}${last_name[0]}`.toUpperCase();
          return (
            <Box className={classes.name}>
              <Avatar aria-label={initials} src={photo_url} className={classes.avatar}>
                {initials}
              </Avatar>
              {first_name}&nbsp;{last_name}
            </Box>
          );
        }
      },
      {
        title: t('Birthday'),
        accessor: 'birthday',
      },
      {
        title: t('Phone'),
        cellComponent: ({ phone }) => !!phone ? (<Link href={`tel:${phone}`}>{phone}</Link>) : null,
        accessor: 'phone',
      },
      {
        title: t('Email'),
        accessor: 'email',
        cellComponent: ({ email }) => !!email ? (<Link href={`mailto:${email}`} >{email}</Link>) : null,
      },
      {
        title: t('Created At'),
        accessor: 'created_at',
        sortable: true,
      },
      {
        title: t('Actions'),
        cellComponent: ({ id }) => (
          <Link href={`${url}/${id}/edit`}>{t('Check Data')}</Link>
        )
      }
    ];
  }, [i18n.language])

  return (
    <ListWrapper title={`${t('New Clients')} (${records_total})`}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
}
