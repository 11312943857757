import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { CertificateProps } from './type';

const Certificate = (props: CertificateProps) => {
  const { service, onCertificateCheckedToggle, classes } = props;
  const { t } = useTranslation();
  const disabled = service.current_count === 0;

  return (
    <ListItem
      dense
      button
      onClick={() => onCertificateCheckedToggle(service)}
      disabled={disabled}
    >
      <ListItemIcon>
        <Checkbox
          edge="start"
          color="primary"
          checked={service.checked}
          tabIndex={-1}
          disableRipple
          disabled={disabled}
        />
      </ListItemIcon>
      <ListItemText id={`service-${service.id}-label`}>
        <div className={classes.serviceNameWrapper}>
          <div>{service.name}</div>
          <div
            className={cn(classes.service, {
              [classes.overedService]: disabled,
            })}
          >
            {t('SertificateServiceInfo', {
              usage_count: service.initial_count - service.current_count,
              initial_count: service.initial_count,
            })}
          </div>
        </div>
      </ListItemText>
    </ListItem>
  );
};

export default Certificate;