import { Box, Grid} from '@material-ui/core';
import bookingCardsClasses from '../BookingCardsStyles';
import { LocationCard } from '../LocationCard';
import DescriptionDialog from '../DescriptionDialog';

export const ClientBookingLocations = ({ locations, url }) => {
  const classes = bookingCardsClasses();

  const descriptionDialogRef = React.useRef(null);

  function openDescriptionDialog(descriptionData) {
    descriptionDialogRef.current.open(descriptionData)
  }

  return (
    <Box className={classes.cards}>
      <h1>Оберіть локацію</h1>
      <Grid
        container
        spacing={2}
        className={classes.cardsContainer}
      >
        {locations.map(({ id, name, photo_url, booking_description }) => (
          <Grid
            key={id}
            item xs={12}
            sm={6}
          >
            <LocationCard
              href={`${url}/${id}/booking_services`}
              name={name}
              photo_url={photo_url}
              booking_description={booking_description}
              openDescriptionDialog={openDescriptionDialog}
            />
          </Grid>
        ))}
      </Grid>
      <DescriptionDialog ref={descriptionDialogRef} />
    </Box>
  )
}
