import {
  Box,
  makeStyles,
  FormHelperText,
  FormControl as MFormControl,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FormInput, Checkbox, Button, FormControl, Select } from 'Components';
import { checkRequired } from 'Helpers/validation';
import { useValidation, useFormSubmit as useAddField } from 'Hooks';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  buttonActions: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  form: {
    marginTop: '4px',
  },
  formLabel: {
    fontSize: '0.75em',
  },
}));

const INPUT_FIELD_TYPE = 'Input';
const SELECT_FIELD_TYPE = 'Select';
const INPUT_FIELD_ID = '0';
const SELECT_FIELD_ID = '1';
const FIELD_TYPES = {
  [INPUT_FIELD_ID]: 'input',
  [SELECT_FIELD_ID]: 'drop_down',
};

const validators = {
  field_type: checkRequired('field_type'),
  label: (state) => {
    if (!state.field_type) {
      return null;
    }
    return checkRequired('label')(state);
  },
  option_values: (state) => {
    if (state.field_type !== SELECT_FIELD_ID) {
      return null;
    }
    return checkRequired('option_values')(state);
  },
};

const DEFAULT_STATE = {
  field_type: '',
  label: '',
  required: true,
  option_values: '',
};

export const CustomFieldForm = ({ onCustomFieldAdd }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, setState] = useState(DEFAULT_STATE);
  const submit = useAddField();
  const { isItemInvalid, validationMessagesToDisplay } = useValidation(
    state,
    validators,
    submit.submitted,
    []
  );

  const onCustomFieldFormClear = () => {
    setState(DEFAULT_STATE);
  };

  const onFieldTypeChange = (e) => {
    setState({ ...DEFAULT_STATE, field_type: e.target.value });
    submit.unsubmit();
  };

  const onFieldLabelChange = (e) => {
    setState({ ...state, label: e.target.value });
  };

  const onRequiredChange = (e) => {
    setState({ ...state, required: e.target.checked });
  };

  const onSelectOptionsChange = (e) => {
    setState({ ...state, option_values: e.target.value });
  };

  const fieldTypes = [
    { id: INPUT_FIELD_ID, name: t(INPUT_FIELD_TYPE) },
    { id: SELECT_FIELD_ID, name: t(SELECT_FIELD_TYPE) },
  ];

  const onCustomFieldCreate = () => {
    submit.submit();
    if (isItemInvalid) return;
    onCustomFieldAdd({
      field_type: FIELD_TYPES[state.field_type],
      label: state.label,
      required: state.required,
      option_values: state.option_values || null,
    });
    submit.unsubmit();
    onCustomFieldFormClear();
  };

  return (
    <FormControl
      fullWidth
      label={
        <span className={classes.formLabel}>{t('Create custom field')}</span>
      }
      className={classes.form}
    >
      <Select
        label={t('Pick field type')}
        onChange={onFieldTypeChange}
        value={state.field_type}
        options={fieldTypes}
        validationMessages={validationMessagesToDisplay.field_type}
      />
      <Box className={classes.buttonActions}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={onCustomFieldCreate}
        >
          {t('Add field')}
        </Button>
      </Box>
      {!!state.field_type && (
        <FormControl
          fullWidth
          label={
            <span className={classes.formLabel}>
              {t('Custom field settings')}
            </span>
          }
        >
          <MFormControl>
            <FormInput
              label={t('Field label')}
              value={state.label}
              onChange={onFieldLabelChange}
              required
              validationMessages={validationMessagesToDisplay.label}
            />
          </MFormControl>
          <Checkbox
            checked={state.required}
            onChange={onRequiredChange}
            label={t('Required')}
          />
          {state.field_type === SELECT_FIELD_ID && (
            <MFormControl>
              <FormInput
                label={t('Drop-down list items')}
                value={state.option_values}
                onChange={onSelectOptionsChange}
                required
                multiline
                validationMessages={validationMessagesToDisplay.option_values}
              />
              <FormHelperText>
                {t('Enter each new value on a new line')}
              </FormHelperText>
            </MFormControl>
          )}
        </FormControl>
      )}
    </FormControl>
  );
};
