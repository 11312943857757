import { useQuery } from '@tanstack/react-query';

import { HttpClient } from 'network/HttpClient';

import { FetchDataParams } from 'schemas';

export const CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY =
  'currentPurchasedActiveServices';

export const useCurrentPurchasedActiveServicesQuery = ({
  fetchUrl,
  paginationParams,
  filters,
}: FetchDataParams) => {
  return useQuery(
    [CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY, paginationParams],
    () =>
      HttpClient.getPurchasedServices({
        fetchUrl,
        paginationParams,
        filters,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};
