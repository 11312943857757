import { Avatar, Link, makeStyles, } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { ListWrapper, Table, ListActions, SearchInput, Button, } from 'Components';
import { useList } from 'Hooks';
import { allowResponsiveView } from 'Helpers';

const savedSortParams = localStorage.getItem('clients_sorting_params');

const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'desc',
  ...(!!savedSortParams && JSON.parse(savedSortParams))
}


const useStyles = makeStyles(theme => ({
  nameLink: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  search: { marginRight: theme.spacing(3) },
  notes: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}))


export const Clients = ({ url, fetch_url, new_item_url }) => {
  const classes = useStyles();
  const { t, i18n, } = useTranslation();
  const {
    pending, data, records_total, paginationParams, handleChangePagination, changeSearch,
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  React.useEffect(() => {
    allowResponsiveView()
  }, [])

  const tableConfig = React.useMemo(function () {
    return [
      {
        title: t('Name'),
        accessor: 'last_name',
        sortable: true,
        cellComponent: function ({ id, first_name, last_name, photo_url }) {
          const initials = `${first_name[0]}${last_name[0]}`.toUpperCase();
          return (
            <Link href={`${url}/${id}`} className={classes.nameLink}>
              <Avatar aria-label={initials} src={photo_url} className={classes.avatar}>
                {initials}
              </Avatar>
              {first_name}&nbsp;{last_name}
            </Link>
          );
        }
      },
      {
        title: t('Birthday'),
        accessor: 'birthday',
      },
      {
        title: t('Created'),
        accessor: 'created_at',
        sortable: true
      },
      {
        title: t('Last Visit'),
        accessor: 'last_visit',
        sortable: true
      },
      {
        title: t('Phone'),
        cellComponent: ({ phone }) => !!phone ? (<Link href={`tel:${phone}`}>{phone}</Link>) : null,
        accessor: 'phone',
      },
      {
        title: t('Email'),
        accessor: 'email',
        cellComponent: ({ email }) => !!email ? (<Link href={`mailto:${email}`} >{email}</Link>) : null,
      },
      {
        title: t('Important Notes'),
        accessor: 'important_notes',
        cellComponent: ({ important_notes }) => !!important_notes &&
          (<div className={classes.notes}>{important_notes}</div>)
      },
      {
        title: t('Actions'),
        cellComponent: ({ id }) => (
          <ListActions editUrl={`${url}/${id}/edit`} />
        )
      }
    ];
  }, [i18n.language]);

  function onChangeTableSettings({ sort_column, sort_direction, ...pagination }) {
    if (!sort_column) {
      return handleChangePagination(pagination)
    }

    localStorage.setItem('clients_sorting_params', JSON.stringify({ sort_column, sort_direction }));

    handleChangePagination({ sort_column, sort_direction })
  }

  return (
    <ListWrapper title={`${t('Clients')} (${records_total})`} headerAddon={(
      <div>
        <span className={classes.search}>
          <SearchInput onChange={changeSearch} autoFocus name="value" />
        </span>
        <Button href={new_item_url} variant="contained" color="primary">
          {t('Create Client')}
        </Button>
      </div>
    )}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{
          records_total, paginationParams,
          handleChangePagination: onChangeTableSettings
        }}
      />
    </ListWrapper>
  );
}
