import {
  Card, CardContent, Grid, makeStyles, Box, Container, Typography, Tabs,
  Tab
} from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { Preloader, TabPanel } from 'Components';
import { findInArrayBy, updateItemInArray, AJAX } from 'Helpers';
import WeeklySchedule from './WeeklySchedule';
import ScheduleByDates from './ScheduleByDates';
import ProviderForm from './ProviderForm';

const useStyles = makeStyles(() => ({
  preloader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px'
  },
}));

export const BookingProviderWorkTime = ({
  booking_providers, company_api_url, locations, work_days
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selectedProvider, setSelectedProvider] = React.useState({
    provider_id: '',
    location_id: ''
  })

  React.useEffect(() => {
    if (selectedProvider.provider_id && selectedProvider.location_id) {
      getProviderShedule()
    }
  }, [selectedProvider])

  const apiUrl = `${company_api_url}/booking_settings/locations/${selectedProvider.location_id}/booking_providers/${selectedProvider.provider_id}`;
  const workTimeApiUrl = `${apiUrl}/booking_provider_work_times`;

  async function getProviderShedule() {
    setSchedule({
      loading: true,
      data: null
    })

    const res = await AJAX.get(workTimeApiUrl)

    if (!!res.flash_type && !!res.flash_message) {
      return setSchedule({
        loading: false,
        data: null
      })
    }
    setSchedule({
      loading: false,
      data: res
    })
  }

  const [schedule, setSchedule] = React.useState({
    data: null,
    loading: false
  });

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  function handleChangeTab(_, tabIndex) {
    setActiveTabIndex(tabIndex)
  }

  function changeScheduleItem(weekdayId, id, active) {
    const item = findInArrayBy(schedule.data[weekdayId], id)
    const updatedItem = {
      ...item,
      active
    }
    const updatedWeekDay = updateItemInArray(schedule.data[weekdayId], updatedItem, id)
    setSchedule({
      ...schedule,
      data: {
        ...schedule.data,
        [weekdayId]: updatedWeekDay
      }
    })
  }

  return (
    <Container >
      <Typography component="h1" variant="h4">{t('Provider Work Time')}</Typography>
      <Card>
        <CardContent>
          <ProviderForm booking_providers={booking_providers} locations={locations}
            onSelect={setSelectedProvider} loading={schedule.loading} />
          <Grid container spacing={2}>
            {!!schedule.loading && (
              <Box className={classes.preloader}>
                <Preloader />
              </Box>
            )}
            {schedule.data && !schedule.loading && (
              <Grid item sm={12} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={activeTabIndex} onChange={handleChangeTab} >
                    <Tab label={t('Work Schedule')} id="tab-0" aria-controls="tabpanel-0" />
                    <Tab label={t('Work Schedule By Dates')} id="tab-1" aria-controls="tabpanel-1" />
                  </Tabs>
                </Box>
                <TabPanel value={activeTabIndex} index={0}>
                  <WeeklySchedule schedule={schedule.data} workDays={work_days}
                    api_url={workTimeApiUrl} changeScheduleItem={changeScheduleItem}
                    fetchShedule={getProviderShedule} />
                </TabPanel>
                <TabPanel value={activeTabIndex} index={1}>
                  <ScheduleByDates apiUrl={apiUrl} />
                </TabPanel>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}
