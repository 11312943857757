import { ThemeProvider } from "@material-ui/styles";
import {
  Grid, Paper, createMuiTheme, CssBaseline, makeStyles, Box, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as localization from 'ClientLocalization';
import * as pages from 'Pages/client';
import { CLIENT_APP_LOCALES_LIST } from 'Constants';
import { transformLocales, initI18n } from 'Helpers/client';
import Header from '../pages/client/components/Header';
import { Trans } from 'react-i18next';
import 'Styles/client-sign-in';
import { Notifications } from "Components";
import { toPhoneNumberFormat } from 'Helpers';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#19456B',
    },
  }
});

const useCommonStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

document.addEventListener('DOMContentLoaded', () => {
  const { component_name, ...props } = JSON.parse(
    document.getElementById('page-content').getAttribute('data')
  );

  const { flash } = JSON.parse(
    document.getElementById('flash-root').getAttribute('data')
  );

  const commonData = JSON.parse(
    document.getElementById('main-info').getAttribute('data')
  );

  const locales = transformLocales([
    localization.COMMON, localization[component_name]
  ], CLIENT_APP_LOCALES_LIST);

  initI18n(locales, commonData.locale);

  const Component = pages[component_name];

  ReactDOM.render((
    <Layout commonData={commonData} flash={flash}>
      <Component {...props} useCommonStyles={useCommonStyles} />
    </Layout>
  ), document.getElementById('page-root'));
});


function Layout({ commonData, flash, children }) {
  const { t } = useTranslation();

  const headerNavigation = {
    logo_url: commonData.logo_url,
    booking_types_url: commonData.booking_types_url,
  }

  const { company } = commonData;

  const isCompanyBaseSettingsExist = Object.keys(company.base_settings).length > 0;

  return (
    <>
      <Header navigation={headerNavigation} />
      <div className="client-sign-in__main">
        <div className="client-sign-in__content">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box style={{ flex: 1, margin: '0 auto', maxWidth: '600px', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '16px' }}>
                {t('authTitle', { companyName: commonData.company.name || '' })}
              </Typography>
              <Grid
                container
                component={Paper}
                square
                style={{ height: '100%', alignItems: 'flex-start', background: '#e4f9f8', border: '2px solid #19456B', boxShadow: 'none' }}
              >
                <Notifications notifications={flash}>
                  {children}
                </Notifications>
                {isCompanyBaseSettingsExist && 
                  <Typography variant="h6" component="p" style={{ fontSize: '18px', padding: '16px', marginTop: 'auto' }}>
                    Виникли питання – звертайтеся до служби пiдтримки: <br />

                    {!!company.base_settings.phone && 
                      <>
                        Телефон: 
                        <a href={`tel:${company.base_settings.phone}`} style={{ color: '#11698E' }}>
                         {toPhoneNumberFormat(company.base_settings.phone)}
                         </a>{!!company.base_settings.work_time && `, з ${company.base_settings.work_time}`} <br />
                      </>
                    }

                    {!!company.base_settings.email && 
                      <>
                        E-mail: <a href={`mailto:${company.base_settings.email}`} style={{ color: '#11698E' }}>{company.base_settings.email}</a>
                      </>
                    }
                  </Typography>
                }
              </Grid>
            </Box>
          </ThemeProvider>
        </div>
        <aside className="client-sign-in__subscribtion-info">
          <Trans i18nKey="authSidebar" values={{ companyName: commonData.company.name || '' }} ></Trans>
          <a href={commonData.agreement_file_url} className="client-sign-in__agreement-link" target="_blank">Публічна оферта</a>
        </aside>
      </div>
    </>
  )
}
