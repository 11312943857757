import { ChangeEvent, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { AJAX } from 'Helpers';

import { useClientContext } from '../../providers/ClientShowProvider';

import { RopeStatusProps } from './type';
import { CLIENT_QUERY_KEY } from 'queries';

const useRopeStatusStyles = makeStyles((theme: Theme) => ({
  Yes: {
    color: theme.palette.success.main,
  },
  No: {
    color: theme.palette.error.main,
  },
  NoInfo: {
    color: theme.palette.text.secondary,
  },
}));

const RopeStatus = (props: RopeStatusProps) => {
  const clientData = useClientContext();
  const queryClient = useQueryClient();
  const { client, api_client_url } = clientData;
  const { name } = props;
  const checked = client[name as keyof typeof RopeStatus];

  const { t } = useTranslation();
  const classes = useRopeStatusStyles();

  const onRopeStatusChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const res = await AJAX.put(api_client_url, {
      body: {
        client: {
          [name]: event.target.checked,
        },
      },
    });
    if (res.flash_type === 'success') {
      queryClient.invalidateQueries([CLIENT_QUERY_KEY]);
    }
  };

  const label = useMemo(() => {
    if (checked === null) {
      return 'NoInfo';
    }
    return !!checked ? 'Yes' : 'No';
  }, [checked]);

  const status = cn(
    {
      [classes.Yes]: checked,
      [classes.No]: !checked,
    },
    [checked]
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="default"
          checked={checked || false}
          onChange={onRopeStatusChange}
          className={status}
        />
      }
      label={t(label)}
      className={classes[label]}
    />
  );
};

export default RopeStatus;
