import { Box, makeStyles} from '@material-ui/core';
import { AJAX } from 'Helpers';
import { Preloader } from 'Components';
import { EventCardItem } from '../EventCardItem';
import DescriptionDialog from '../DescriptionDialog';

const useStyles = makeStyles((theme) => ({
  classesContainer: {
    marginBottom: '16px',
    padding: '8px',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const UpcomingEvents = ({ url }) => {
  const classes = useStyles()

  const descriptionDialogRef = React.useRef(null);

  function openDescriptionDialog(descriptionData) {
    descriptionDialogRef.current.open(descriptionData)
  }

  const [events, setEvents] = React.useState({
        pending: true,
        data: []
  })
  
  React.useEffect(() => {
    fetchUpcomingEvents()
  }, [])
    
  async function fetchUpcomingEvents() {
    setEvents({
      ...events,
      pending: true,
    })
    
    const res = await AJAX.get(url,{
      body: {
        format: 'json'
      }
    });
      
    return setEvents({
       data: res?.data || [],
       pending: false,
    })
  }

  return (
    <Box>
      <h1>Оберіть подію</h1>
      {events.pending && (
        <Box className={classes.preloader}>
          <Preloader />
        </Box>
      )}
      {!events.pending && events.data.map((event) => (
        <Box
          key={event.id}
          className={classes.classesContainer}
        >
          <EventCardItem
            openDescriptionDialog={openDescriptionDialog}
            {...event}
          />
        </Box>
      ))}
      {!events.pending && !events.data.length && (
        <p>Найближчим часом подій не заплановано</p>
      )}
      <DescriptionDialog ref={descriptionDialogRef} />
    </Box>
  )
}
