import {
  Select as MSelect, MenuItem, Hidden, FormControl, InputLabel, FormHelperText,
} from '@material-ui/core';
import { v1 as uuidv1 } from 'uuid';

export const Select = ({ options, children, label, margin, validationMessages, required, emptyOption, valueAsArray, ...props }) => {
  const labelId = React.useMemo(function () { return uuidv1(); }, []);
  const controlId = React.useMemo(function () { return props.id || uuidv1() }, []);

  return (
    <FormControl
      variant="outlined"
      error={!!validationMessages}
      fullWidth
      required={required}
      margin={margin}>
      <Hidden only={['xs', 'sm']}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <MSelect
          labelId={labelId}
          value={''}
          label={label}
          {...props}
        >
          {!!emptyOption && (
            <MenuItem value=''></MenuItem>
          )}
          {!!options && options.map(({ id, name, }) => (
            <MenuItem key={id} value={id}>{name}</MenuItem>
          ))}
          {children}
        </MSelect>
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <InputLabel htmlFor={controlId}>{label}</InputLabel>
        <MSelect label={label} native {...props} inputProps={{
          name: props.name, id: controlId,
        }} id={controlId} >
          <option hidden></option>
          {!!emptyOption && (
            <option value=''></option>
          )}
          {!!options && options.map(({ id, name, }) => (
            <option key={id} value={id}>{name}</option>
          ))}
          {children}
        </MSelect>
      </Hidden>
      {!!validationMessages && (
        <FormHelperText>{validationMessages}</FormHelperText>
      )}
    </FormControl>
  );
}

Select.defaultProps = {
  margin: 'dense',
}
