import {
  Dialog, DialogTitle, DialogContent, makeStyles, IconButton, DialogActions,
  Button, FormControl
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AjaxAutocomplete, MultiSelect, Checkbox } from 'Components';
import { useForm } from 'Hooks';
import { checkRequired, checkRequiredArray } from 'Helpers/validation';
import { AJAX } from 'Helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    width: '52px',
    height: '52px',
  },
}));

function clientsRequestBody(value) {
  return {
    page: 1,
    size_per_page: 10,
    sort_column: 'created_at',
    sort_direction: 'desc',
    search: {
      value
    }
  }
}

function getOptionLabel(option) {
  return !!option ? `${option.first_name} ${option.last_name} (${option.birthday ? option.birthday : ' - '})` : '';
}

const validators = {
  client: checkRequired('client'),
  service_ids: checkRequiredArray('service_ids'),
};

const DEFAULT_STATE = {
  isOpened: false,
  services: [],
  assign_services_url: ''
}

const DEFAULT_ITEM = {
  client: null,
  service_ids: [],
  use_after_assign: true,
  assign_services_url: null
}


//TODO: add reset state logic to useForm instead of reinit dialog on success submit
export const AssignDialog = React.forwardRef(({ clients_api_url, onSubmit }, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, changeState] = React.useState(DEFAULT_STATE);

  const {
    item, changeValue, submit, setServerErrors,
    validationMessagesToDisplay, setItem, isItemInvalid,
  } = useForm(DEFAULT_ITEM, validators, '');

  const open = (services, assign_services_url, client) => {
    changeState({
      isOpened: true,
      services,
      assign_services_url,
    });
    setItem({
      ...DEFAULT_ITEM,
      client: client?.id ? client : ''
    });
  }

  function close() {
    changeState({
      ...state,
      isOpened: false
    });
  }


  function handleChangeClient(_, client) {
    changeValue('client', client)
  }

  function handleChangeServiceIds({ target }) {
    changeValue('service_ids', target.value);
  }

  function handleChangeUseAfterAssign({ target }) {
    changeValue('use_after_assign', target.checked);
  }


  React.useImperativeHandle(ref, () => ({
    open,
  }));

  function onSuccessSubmit() {
    onSubmit()
    close()
  }

  async function handleSubmit(e) {
    e.preventDefault();
    submit();

    if (!!isItemInvalid) {
      return
    }

    const { client, service_ids, use_after_assign } = item;

    const res = await AJAX.post(state.assign_services_url, {
      body: {
        client_id: client.id,
        service_ids,
        use_after_assign,
      }
    });

    if (res.errors) {
      return setServerErrors(res.errors);
    }

    onSuccessSubmit(res);
  }

  return (
    <Dialog
      open={state.isOpened}
      onClose={close}
      fullWidth
    >
      <DialogTitle className={classes.root}>
        {t('Assign services to client')}
        <IconButton
          size="medium"
          className={classes.closeButton}
          onClick={close}>
          <i className="icon-cancel"></i>
        </IconButton>
      </DialogTitle>
      <form
        onSubmit={handleSubmit}
        noValidate>
        <DialogContent>
          {state.isOpened && (
            <AjaxAutocomplete
              label={t('Client')}
              value={item.client}
              getRequestBody={clientsRequestBody}
              searchUrl={clients_api_url}
              getOptionLabel={getOptionLabel}
              onChange={handleChangeClient}
              method='get'
              required
              validationMessages={validationMessagesToDisplay.client} />
          )}
          <MultiSelect
            options={state.services}
            label={t('Services')}
            onChange={handleChangeServiceIds}
            value={item.service_ids}
            validationMessages={validationMessagesToDisplay.service_ids}
            required
          />
          <FormControl fullWidth>
            <Checkbox
              checked={item.use_after_assign}
              onChange={handleChangeUseAfterAssign}
              label={t('Use after assign')}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            {t('Assign')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
})