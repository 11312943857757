export const BookingProviders = {
  en: {
    'Name': 'Name',
    'Create Booking Provider': 'Create Booking Provider',
    'Provider': 'Provider',
    'spot': 'spot',
    'trainer': 'trainer',
  },
  ru: {
    'Name': 'Название',
    'Create Booking Provider': 'Создать букинг провайдер',
    'Provider': 'Провайдер',
    'spot': 'место',
    'trainer': 'тренер'
  },
  uk: {
    'Name': 'Назва',
    'Create Booking Provider': 'Створити букінг провайдер',
    'Provider': 'Провайдер',
    'spot': 'місце',
    'trainer': 'тренер'
  },
};

export const BookingProviderForm = {
  en: {
    ...BookingProviders.en,
    'New Booking Provider': 'New Booking Provider',
    'Edit Booking Provider': 'Edit Booking Provider',
    'Update Booking Provider': 'Update Booking Provider',
    'Provider Type': 'Provider Type',
    'Description': 'Description',
    'Choose Image File': 'Choose Image File',
    'Trainer': 'Trainer',
    'Spot': 'Spot',
    'Booking services': 'Booking services'
  },
  ru: {
    ...BookingProviders.ru,
    'New Booking Provider': 'Новый Провайдер',
    'Edit Booking Provider': 'Редактирование Провайдера',
    'Update Booking Provider': 'Обновить Провайдер',
    'Provider Type': 'Тип провайдера',
    'Description': 'Описание',
    'Choose Image File': 'Выбрать изображение',
    'Trainer': 'Tренер',
    'Spot': 'Место',
  },
  uk: {
    ...BookingProviders.uk,
    'New Booking Provider': 'Новий Провайдер',
    'Edit Booking Provider': 'Редагування Провайдера',
    'Update Booking Provider': 'Оновити Провайдер',
    'Provider Type': 'Тип провайдера',
    'Description': 'Опис',
    'Choose Image File': 'Вибрати зображення',
    'Trainer': 'Tренер',
    'Spot': 'Місце',
    'Booking services': 'Букінг сервіси'
  },
};
