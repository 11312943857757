import { useTranslation, } from 'react-i18next';
import { ListWrapper, Table } from 'Components';
import { AJAX } from 'Helpers';
import { FilterForm } from './FilterForm';

const DEFAULT_STATE = {
  data: [],
  pending: false
}

export const ServicesRating = ({ fetch_url, locations }) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState(DEFAULT_STATE)

  const tableConfig = [
    {
      title: t('Name'),
      accessor: 'name',
    },
    {
      title: t('Count of purchases'),
      accessor: 'count_of_purchased',
    },
  ];

  async function fetchData(filter) {
    setState({
      ...state,
      pending: true,
    })
    const { location_id, date_from, date_till } = filter;
    const res = await AJAX.get(fetch_url, {
      body: {
        location_id,
        dates: {
          from: date_from,
          till: date_till
        }
      }
    });

    if (res.flash_type === 'error') {
      return setState(DEFAULT_STATE)
    }

    setState({ data: res.data, pending: false })
  }

  return (
    <ListWrapper title={`${t('Top 20 the most purchased services')}`}>
      <FilterForm locations={locations} fetchData={fetchData} />
      <Table config={tableConfig} data={state.data} pending={state.pending} />
    </ListWrapper>
  );
}
