export const BookingCalendar = {
  en: {
    'Current Date': 'Current Date',
    'Show Today': 'Show Today',
    'Create Event': 'Create Event',
    'Event': 'Event',
    'Note': 'Note',
    'Location': 'Location',
    'Provider': 'Provider',
    'Booking Service': 'Booking Service',
    'Group': 'Group',
    'Date': 'Date',
    'Members limit': 'Members limit',
    'Time Period': 'Time Period',
    'From Time': 'From Time',
    'Till Time': 'Till Time',
    'Slot Name': 'Slot Name',
    'Comment': 'Comment',
    'Notes': 'Notes',
    'Comment for provider': 'Comment for provider',
    'Recurring Event': 'Recurring Event',
    'Frequency': 'Frequency',
    'Week Days': 'Week Days',
    'Every one': 'Every',
    'week': 'week',
    'Every': 'Every',
    'weeks': 'weeks',
    'Update Event': 'Update Event',
    'Block Time Only': 'Block Time Only',
    'Last Updated by': 'Last Updated by',
    'Create Note': 'Create Note',
    'Update Note': 'Update Note',
    'Client': 'Client',
    'Create custom field': 'Create custom field',
    'Add field':'Add field',
    'Field label':'Field label',
    'Custom field settings':'Custom field settings',
    'Pick field type':'Pick field type',
    'Required': 'Required',
    'Select': 'Drop-down list',
    'Input':'Text input',
    'Values for select': 'Values for select',
    'Field type': 'Field type',
    'Drop-down list items': 'Drop-down list items',
    'Is field required?': 'Is field required?',
    'Yes': 'Yes',
    'No': 'No',
    'Custom fields list': 'Custom fields list',
    'Enter each new value on a new line': 'Enter each new value on a new line',
    'Field values can\'t be blank': 'Field values can\'t be blank',
    'Label can\'t be blank': 'Label can\'t be blank',
    'Option values can\'t be blank': 'Option values can\'t be blank',
    'Problem with member payment': "There is a problem with the member's payment",
  },
  ru: {
    'Current Date': 'Текущая дата',
    'Show Today': 'Показать сегодня',
    'Create Event': 'Создать cобытие',
    'Event': 'Cобытие',
    'Note': 'Заметка',
    'Location': 'Локация',
    'Provider': 'Провайдер',
    'Booking Service': 'Букинг Сервис',
    'Group': 'Группа',
    'Members limit': 'Ограничение кол-ва участников',
    'Time Period': 'Период времени',
    'From Time': 'Начиная От',
    'Till Time': 'До',
    'Comment': 'Комментарий',
    'Recurring Event': 'Повторяющееся событие',
    'Frequency': 'Периодичность',
    'Week Days': 'Дни недели',
    'Every one': 'Каждую',
    'week': 'неделю',
    'Every': 'Каждые',
    'weeks': 'недели',
    'Update Event': 'Обновить cобытие',
    'Block Time Only': 'Только блокировка времени',
    'Last Updated by': 'Обновил',
    'Create Note': 'Создать заметку',
    'Update Note': 'Обновить заметку',
    'Client': 'Клиент',
    'Create custom field': 'Создать дополнительное поле',
    'Add field': 'Добавить поле',
    'Field label': 'Название поля',
    'Custom field settings': 'Настройки дополнительного поля',
    'Pick field type':'Выберите тип поля',
    'Required': 'Обязательное к заполнению',
    'Select': 'Выпадающий список',
    'Input':'Текстовое поле',
    'Values for select': 'Значения выпадающего списка',
    'Field type': 'Тип поля',
    'Drop-down list items': 'Элементы выпадающего списка',
    'Is field required?': 'Поле обязательно к заполнению?',
    'Yes': 'Да',
    'No': 'Нет',
    'Custom fields list': 'Дополнительные поля',
    'Enter each new value on a new line': 'Введите каждое новое значение с новой строки',
    'Field values can\'t be blank': 'Значения поля не могут быть пустыми',
    'Label can\'t be blank': 'Название поля не может быть пустым',
    'Option values can\'t be blank': 'Элементы выпадающего списка не могут быть пустыми'
  },
  uk: {
    'Current Date': 'Поточна дата',
    'Show Today': 'Показати сьогодні',
    'Create Event': 'Створити подію',
    'Event': 'Подія',
    'Note': 'Нотатка',
    'Location': 'Локація',
    'Provider': 'Провайдер',
    'Booking Service': 'Букінг Сервіс',
    'Group': 'Група',
    'Date': 'Дата',
    'Members limit': 'Обмеження кількості учасників',
    'Time Period': 'Період часу',
    'From Time': 'Починаючи з',
    'Till Time': 'До',
    'Slot Name': 'Назва групи',
    'Comment': 'Коментар',
    'Notes': 'Нотатки',
    'Comment for provider': 'Коментар для провайдера',
    'Recurring Event': 'Подія що повторюється',
    'Frequency': 'Періодичність',
    'Week Days': 'Дні тижня',
    'Every one': 'Кожен',
    'week': 'тиждень',
    'Every': 'Кожні',
    'weeks': 'тижні',
    'Update Event': 'Оновити подію',
    'Block Time Only': 'Тільки блокування часу',
    'Last Updated by': 'Оновив',
    'Create Note': 'Створити нотатку',
    'Update Note': 'Оновити нотатку',
    'Delete all future events': 'Видалити всі майбутні бронювання',
    'Client': 'Клієнт',
    'Create custom field': 'Створити додаткове поле',
    'Add field': 'Додати поле',
    'Field label': 'Назва поля',
    'Custom field settings': 'Налаштування додаткового поля',
    'Pick field type':'Оберіть тип поля',
    'Required': 'Обовʼязкове до заповнення',
    'Select': 'Випадний список',
    'Input': 'Текстове поле',
    'Values for select': 'Значення випадного списку',
    'Field type': 'Тип поля',
    'Drop-down list items': 'Елементи випадного списку',
    'Is field required?': 'Поле обовʼязкове до заповнення?',
    'Yes': 'Так',
    'No': 'Ні',
    'Custom fields list': 'Додаткові поля',
    'Enter each new value on a new line': 'Введіть кожне нове значення з нового рядка',
    'Field values can\'t be blank': 'Значення поля не можуть бути порожні',
    'Label can\'t be blank': 'Назва поля не може бути порожньою',
    'Option values can\'t be blank': 'Елементи випадного списку не можуть бути порожні',
    'Problem with member payment': 'Виникла проблема з оплатою учасника',
  },
};

