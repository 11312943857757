export const OnlinePaymentKeys = {
  en: {
    Name: 'Name',
    'Keys': 'Payment keys',
    'Create Key': 'Create Key',
    'In use': 'In use'
  },
  uk: {
    Name: 'Назва',
    'Keys': 'Платіжні ключі',
    'Create Key': 'Створити ключ',
    'In use': 'Використовується'
  },
};

export const OnlinePaymentKeyForm = {
  en: {
    ...OnlinePaymentKeys.en,
    'New Key': 'New Key',
    'Edit Key': 'Edit Key',
    'Public key': 'Public key',
    'Private key': 'Private key'
  },
  uk: {
    ...OnlinePaymentKeys.uk,
    'New Key': 'Новий ключ',
    'Edit Key': 'Редагування ключа',
    'Update Key': 'Оновити ключ',
    'Public key': 'Публічний ключ',
    'Private key': 'Приватний ключ'
  },
};
