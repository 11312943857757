import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  makeStyles,
  DialogActions,
  Button
} from '@material-ui/core';
import { AJAX } from 'Helpers';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    width: '52px',
    height: '52px',
  },
}));

const DEFAULT_STATE = {
  isOpen: false, subscription: {}
}

const UnsubscribeConfirm = React.forwardRef((_, ref) => {
  const classes = useStyles();

  const [state, changeState] = React.useState(DEFAULT_STATE);

  function open(subscription) {
    changeState({ subscription, isOpen: true })
  }

  function close() {
    changeState(DEFAULT_STATE)
  }

  async function handleConfirm() {
    const res = await AJAX.post(state.subscription.unsubscribe_url)
    if (res.flash_type === "success") {
      location.reload()
    }
  }

  React.useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Dialog open={state.isOpen} onClose={close} fullWidth>
      <DialogTitle className={classes.root}>
        <IconButton size="medium" className={classes.closeButton} onClick={close}><i className="icon-cancel"></i></IconButton>
      </DialogTitle>
      <DialogContent>
        Ви збираєтеся відписатися від <span dangerouslySetInnerHTML={{ __html: state.subscription.name }}></span>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleConfirm}>
          відписатися
        </Button>
      </DialogActions>
    </Dialog>
  );
});


export default UnsubscribeConfirm;
