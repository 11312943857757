import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  cards: {
    padding: '0 8px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    }
  },
  cardsContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 20px',
    minHeight: '110px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
  cardLink: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textDecoration: 'none',
    color: 'inherit'
  },
  btn: {
    marginTop: 'auto',
    background: theme.palette.primary.main,
  },
  cardName: {
    fontSize: '22px',
    marginBottom: '8px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    }
  },
  scalableImageWrapper: {
    position: 'relative',
    marginBottom: '16px',
    '&::before': {
      content: '""',
      position: 'relative',
      display: 'block',
      paddingTop: '70%'
    },

    '&--squared': {
      '&::before': {
        paddingTop: '100%'
      },
    }
  },
  scalableImage: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
}));
