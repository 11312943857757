import { Button, Link, Typography, Box, makeStyles } from '@material-ui/core';
import { FormInput } from 'Components';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles(() => ({
  notification: {
    fontSize: '18px',
    padding: '10px 16px 0 16px',
  },
}));

export default ({
  useCommonStyles,
  authenticity_token,
  login_url,
  forgot_password_url,
  auth_url,
  client_email,
  company
}) => {
  const classes = useCommonStyles();
  const logInClasses = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" component="p" className={logInClasses.notification}>
        {t('infoTitle')}
      </Typography>
      <Typography variant="h6" component="p" className={logInClasses.notification}>
        <Trans i18nKey="info" values={{ companyName: company?.name || '' }} ></Trans>
      </Typography>
      <form className={classes.form} action={login_url} method="post">
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticity_token}
        />
        <input type="hidden" name="client[email]" value={client_email} />
        <FormInput value={client_email} disabled required label="Email" />
        <FormInput
          name="client[password]"
          label={t('password')}
          type="password"
          required
          autoComplete="current-password"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t('signIn')}
        </Button>
        <Button
          type="link"
          href={forgot_password_url}
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('setupPassword')}
        </Button>
        <Box pt={2}>
          <Typography>
            <Link href={auth_url}>{t('specifyAnotherEmail')}</Link>
          </Typography>
        </Box>
      </form>
    </>
  );
};
