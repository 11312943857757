import { useTranslation } from 'react-i18next';
import { FormWrapper, Form, FormInput, Checkbox } from 'Components';
import { checkRequired } from 'Helpers/validation';
import { useForm } from 'Hooks';
import { ChangeEvent } from 'react';
import { PaymentCardType, PaymentCardFormProps } from './types';
import { toCardNumberFormat } from 'Helpers';

const validators = {
  name: checkRequired('name'),
  number: (item: PaymentCardType) => {
    if (item.id) return null;
    return checkRequired('number')(item);
  },
};

export const PaymentCardForm = ({
  url,
  api_url,
  payment_card,
}: PaymentCardFormProps) => {
  const { t } = useTranslation();
  const {
    item,
    setItem,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    setServerErrors,
  } = useForm(payment_card, validators, api_url);

  const onNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setItem({ ...item, name: target.value });
  };

  const onCardNumberChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value.length > 19) return;
    setItem({
      ...item,
      number: toCardNumberFormat(target.value),
    });
  };

  const onActiveChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setItem({
      ...item,
      active: target.checked,
    });
  };

  return (
    <Form
      name="payment_card"
      action={action}
      method={method}
      validators={validators}
      disabled={isItemInvalid}
      item={item}
      onSubmit={submit}
      onError={setServerErrors}
    >
      <FormWrapper
        title="Card"
        item={item}
        backUrl={url}
      >
        <FormInput
          validationMessages={validationMessagesToDisplay.name}
          value={item.name}
          label={t('Name')}
          autoFocus
          onChange={onNameChange}
          required
        />
        <FormInput
          validationMessages={validationMessagesToDisplay.number}
          value={item.number}
          label={t('Number')}
          onChange={onCardNumberChange}
          required
          type="text"
        />
        <Checkbox
          checked={item.active || false}
          label={t('Active')}
          onChange={onActiveChange}
        />
      </FormWrapper>
    </Form>
  );
};

PaymentCardForm.defaultProps = {
  payment_card: {
    active: false,
    name: '',
    number: '',
  },
};
