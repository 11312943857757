import {
  MenuList, Tooltip, Popper, Grow, Paper, ClickAwayListener, makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 5000
  },
}));

export const ButtonWithMenu = React.forwardRef(({ trigger, menuPaperStyle, children, tooltip, }, ref) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  function toggleIsOpen() {
    setOpen(!open);
    return ref;
  }

  function handleClose() {
    if (!anchorRef.current || !anchorRef.current.contains(event.target)) {
      setOpen(false);
    }
    return ref;
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  React.useImperativeHandle(ref, function () {
    return {
      isOpen: open,
      toggle: toggleIsOpen,
      close: handleClose,
    }
  });

  function handleChildClick({ disabled, onClick }) {
    if (!disabled) {
      handleClose();
      if (!!onClick) {
        onClick();
      }
    }
  }

  const displayedChildren = React.Children.toArray(children).flat().map((child, index) => {
    return React.cloneElement(child, {
      onClick: () => handleChildClick(child.props),
      key: index
    });
  });

  const triggerComponent = React.useMemo(function () {
    const triggerElement = (
      <trigger.type ref={anchorRef} {...trigger.props}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true" onClick={toggleIsOpen}
      />);
    if (!!tooltip) {
      return (
        <Tooltip title={tooltip}>
          {triggerElement}
        </Tooltip>
      );
    }
    return triggerElement;
  }, [open]);
  return (
    <>
      {triggerComponent}
      <Popper className={classes.popper} open={open} anchorEl={anchorRef.current} role={undefined}
        placement="bottom-end" transition>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper style={menuPaperStyle}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onClick={handleClose} onKeyDown={handleListKeyDown}>
                  {displayedChildren}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
});

ButtonWithMenu.propTypes = {
  trigger: PropTypes.element.isRequired,
  paperStyle: PropTypes.object,
}
