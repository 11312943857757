import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  mainWrapper: {
    display: 'flex'
  },
  calendarWrapper: {
    width: '350px',
    flexShrink: 0,
    position: 'relative',
    overflow: 'hidden',
    marginRight: '36px'
  },
  dayScheduleWrapper: {
    width: '100%'
  },
  daySchedule: {
    display: 'flex',
    position: 'relative'
  },
  timescale: {
    width: '50px',
    flexShrink: 0
  },
  hour: {
    display: 'block',
    height: '24px',
    fontSize: '0.8125rem',
    marginBottom: '3px',
    padding: '5px 0',
    boxSizing: 'border-box'
  },
  scheduleColumn: {
    width: '220px',
    marginRight: '26px',
    padding: '0 12px',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    flexShrink: 0
  },
  scheduleHourWrapper: {
    display: 'flex',
    marginBottom: '3px',
    width: '50%',
    padding: '0 2px',
    boxSizing: 'border-box',

    '& > *': {
      width: '100%',
      margin: '0'
    },
  },
  scheduleOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 50,
  },
  dayWrapper: {
    position: 'relative'
  },
  dayMark: {
    position: 'absolute',
    top: '1px',
    right: '3px',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    background: '#f50057',
    zIndex: '20',
    border: '2px solid #fff'
  },
  scheduleTitle: {
    marginBottom: '24px'
  },
  preloader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px'
  },
  deleteBtn: {
    marginLeft: '12px'
  }
}));
