import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Price from '../Price';

import { CartProps } from './types';

const useStyles = makeStyles(() => ({
  list: {
    width: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
  },
  itemTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cartRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  totalList: {
    width: '100%',
  },
}));

const Cart = (props: CartProps) => {
  const { services, totalPrice, onServiceRemove } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.cartRoot}>
        <List className={classes.list}>
          {services.map((service) => (
            <ListItem key={service.id}>
              <ListItemIcon>
                <IconButton onClick={() => onServiceRemove(service)}>
                  <i className="icon-trash-empty"></i>
                </IconButton>
              </ListItemIcon>
              <ListItemText>
                <div className={classes.itemTitle}>
                  <div>{service.name}</div>
                  <Price {...service} />
                </div>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        {!!services.length && (
          <List className={classes.totalList}>
            <ListItem>
              <ListItemIcon></ListItemIcon>
              <ListItemText>
                <div className={classes.itemTitle}>
                  <b>{t('Total')}</b>
                  <b>
                    <Price {...totalPrice} />
                  </b>
                </div>
              </ListItemText>
            </ListItem>
          </List>
        )}
      </div>
    </>
  );
};

export default Cart;
