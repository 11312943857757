import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  makeStyles,
} from '@material-ui/core';

import Price from '../../Price';

import { ServiceProps } from './types';

const useServicesStyles = makeStyles(() => ({
  categoryService: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Service = (props: ServiceProps) => {
  const { service, isChecked, onServiceCheckedToogle } = props;

  const classes = useServicesStyles();

  const labelId = `service-${service.id}-label`;

  return (
    <ListItem
      dense
      button
      onClick={() => onServiceCheckedToogle(service)}
    >
      <ListItemIcon>
        <Checkbox
          edge="start"
          color="primary"
          checked={isChecked}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId}>
        <div className={classes.categoryService}>
          <div>{service.name}</div>
          <Price {...service} />
        </div>
      </ListItemText>
    </ListItem>
  );
};

export default Service;