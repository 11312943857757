import { Button, makeStyles, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffectWithSkip, } from 'Hooks';
import { IMAGE_SIZE_LIMIT_B, IMAGE_SIZE_LIMIT_MB } from 'Constants';
import classnames from 'classnames';
import notificationsStore from 'Helpers/notifications';


const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid  ${theme.palette.text.disabled}`,
    borderRadius: '4px',
  },
  preview: {
    width: '100%',
    margin: `0 auto ${theme.spacing(1)}px auto`,
  }
}));
export const ImageUpload = ({ label = 'Choose File', value = '', url = '',
  previewClassName, onChange, hasSizeLimit, ...inputProps }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [{ file, imgUrl, inputLabel }, setState] = React.useState({ inputLabel: value, imgUrl: url });
  function handleChange({ target }) {
    if (!target.files && !target.files[0]) {
      return
    }

    if (hasSizeLimit && target.files[0].size > IMAGE_SIZE_LIMIT_B) {
      return notificationsStore.add({ type: 'error', text: t('fileWeightMsg', { size: IMAGE_SIZE_LIMIT_MB }) });
    }

    const reader = new FileReader();
    setState({ imgUrl, file: target.files[0], inputLabel: target.value });
    reader.onload = (e) => {
      setState({ inputLabel: target.value, file, imgUrl: e.target.result, });
    }

    reader.readAsDataURL(target.files[0]);
  }

  useEffectWithSkip(function () {
    if (!!onChange) {
      onChange(inputLabel);
    }
  }, [file], 1);

  return (
    <div className={classes.root} margin='dense'>
      {!!imgUrl && (
        <img src={imgUrl || ''} className={classnames(classes.preview, previewClassName)} />
      )}
      <Button
        variant="contained"
        component="label"
        fullWidth
      >
        {label}
        <input accept="image/*"
          onChange={handleChange}
          type="file"
          value={inputLabel || ''}
          style={{ display: "none", }}
          {...inputProps}
        />
      </Button>
    </div>
  )
}
