import { Typography, Box, Grid, Card, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cards: {
    height: '100%',
    flex: '1',
    marginRight: '24px',
    width: '75%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(3)}px 0 `,
      width: '100%',
    },
  },
  card: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 20px',
    minHeight: '110px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',

    '&:hover': {
      background: '#e4f9f8',
    },
  },
  service: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    textDecoration: 'none',
    color: 'inherit',
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  serviceName: {
    textTransform: 'uppercase',
  },
  servicePrice: {
    color: '#11698E',
    fontSize: '1.3rem',
    textTransform: 'uppercase',
  },
  info: {
    fontSize: '20px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  oldPrice: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through',
    fontSize: '0.8em',
  },
}));

export const OnlineServices = ({ services, servicesUrl }) => {
  const classes = useStyles();

  const renderOnlineService = (service) => {
    const isPriceWithDiscount = service.price_with_discount != service.price;
    return (
      <Grid key={service.id} item xs={12} sm={12} md={6}>
        <Card elevation={8} className={classes.card}>
          <Box
            component="a"
            href={`${servicesUrl}${service.id}`}
            className={classes.service}
          >
            <Typography>
              <div
                className={classes.serviceName}
                dangerouslySetInnerHTML={{ __html: service.name }}
              ></div>
            </Typography>
            {isPriceWithDiscount && (
              <Typography>
                <strong className={classes.oldPrice}>
                  {service.price} грн{service.subscription && '/місяць'}
                </strong>
              </Typography>
            )}
            <Typography>
              <strong className={classes.servicePrice}>
                {isPriceWithDiscount
                  ? service.price_with_discount
                  : service.price}{' '}
                грн{service.subscription && '/місяць'}
              </strong>
            </Typography>
          </Box>
        </Card>
      </Grid>
    );
  };

  return (
    <Box className={classes.cards}>
      <Grid container spacing={2}>
        {services.map(renderOnlineService)}
      </Grid>
      {!services.length && (
        <p className={classes.info}>
          Немає клубних карток та абонементів по обраних фільтрах
        </p>
      )}
    </Box>
  );
};
