import { useTranslation, } from 'react-i18next';
import { FormWrapper, Form, FormInput, Checkbox, } from 'Components';
import { checkRequired, } from 'Helpers/validation';
import { useForm, } from 'Hooks';

const validators = {
  name: checkRequired('name'),
};

export const ServiceCategoryForm = ({ url, api_url, service_category }) => {
  const { t, } = useTranslation();
  const { item, changeValue, action, method, isItemInvalid,
    validationMessagesToDisplay, submit
  } = useForm(service_category, validators, api_url);

  function handleChangeName({ target }) {
    changeValue('name', target.value);
  }

  function handleChangeVisible({ target }) {
    changeValue('visible', target.checked);
  }

  return (
    <Form name="service_category" action={action} method={method} validators={validators}
      disabled={isItemInvalid} onSubmit={submit} item={item} >
      <FormWrapper backUrl={url} title="Service Category" item={item}>
        <FormInput name="service_category[name]" required
          validationMessages={validationMessagesToDisplay.name} value={item.name}
          label={t('Name')} autoFocus onChange={handleChangeName} />
        <Checkbox checked={item.visible} onChange={handleChangeVisible} label={t('Visible')} />
      </FormWrapper>
    </Form>
  );
}

ServiceCategoryForm.defaultProps = {
  service_category: {
    name: '',
    visible: true,
  },
};
