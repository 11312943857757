import { useTranslation } from 'react-i18next';
import { PurchasedServicesChart } from 'Components';
import { AJAX } from 'Helpers';

export const ActivePurсhasedServicesByService = ({ fetch_url, filter_data }) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    pending: false,
    data: {},
  });

  async function fetchData(filter) {
    setState({
      pending: true,
      data: {},
    })

    const { services, from, till, ...filterData } = filter;

    const res = await AJAX.get(fetch_url, {
      body: {
        filters: {
          ...filterData,
          dates: {
            from,
            till
          },
          services: services.map(({ id }) => id),
        }
      }
    });

    if (!res.data) {
      return setState({
        ...state,
        pending: false,
      })
    }

    const data = res.data.reduce((val, item) => {
      return {
        categories: [...val.categories, item.date],
        data: [...val.data, item.count_of_active_purchased_services]
      }
    }, { categories: [], data: [] });

    setState({
      data,
      pending: false,
    })
  }

  const { data, pending } = state;

  return (
    <PurchasedServicesChart
      title={t('Active Purchased Services By Service')}
      servicesToDisplay={filter_data.services}
      fetchData={fetchData}
      pending={pending}
      data={data}
    />
  )
}
