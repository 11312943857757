import { useMemo } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormControl, makeStyles } from '@material-ui/core';
import {
  FormWrapper,
  Form,
  FormInput,
  Checkbox,
  Select,
  MoneyInput,
} from 'Components';
import { findInArrayBy, replaceItemInArray } from 'Helpers';
import { checkRequired, checkNumberRequired } from 'Helpers/validation';
import { useForm } from 'Hooks';
import { MultiSelect } from 'Components';

const DISCOUNT_SERVICE_TYPE = 'discount';
const LIMITED_SERVICE_TYPE = 'limited';

const useStyles = makeStyles((theme) => ({
  discountsRoot: {
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: '4px',
    padding: `${theme.spacing(1)}px`,
    width: '100%',
  },
}));
const validators = {
  name: checkRequired('name'),
  service_type: checkRequired('service_type'),
  service_category_id: checkRequired('service_category_id'),
  visits_count: (item) => {
    if (item.service_type === LIMITED_SERVICE_TYPE) {
      return checkRequired('visits_count')(item);
    }
    return null;
  },
  price: checkNumberRequired('price'),
  duration_in_days: checkRequired('duration_in_days'),
  trainer_payout: (item) => {
    if (item.assigned_to_trainer) {
      return checkRequired('trainer_payout')(item);
    }
    return null;
  },
};

export const ServiceForm = ({
  url,
  api_url,
  service,
  service_types,
  service_categories,
  locations,
  is_not_staff,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    item,
    setItem,
    changeValue,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    setServerErrors,
  } = useForm(service, validators, api_url);
  
  const handleChangeName = ({ target }) => {
    changeValue('name', target.value);
  };

  const handleChangeNameUA = ({ target }) => {
    changeValue('name_ua', target.value);
  };

  const handleChangeServiceType = ({ target }) => {
    let {
      discount_service_categories_attributes,
      assigned_to_trainer,
      trainer_payout,
      ...newItem
    } = item;
    const service_type = target.value;

    setItem({
      ...newItem,
      service_type,
      visits_count: '',
      ...(service_type === DISCOUNT_SERVICE_TYPE && {
        discount_service_categories_attributes: service_categories.map(
          ({ id }) => ({
            service_category_id: id,
            value: '',
          })
        ),
      }),
      ...(service_type !== DISCOUNT_SERVICE_TYPE && {
        assigned_to_trainer,
        trainer_payout,
      }),
    });
  };

  const handleChangeServiceCategory = ({ target }) => {
    changeValue('service_category_id', target.value);
  };

  const handleChangeVisitsCount = ({ target }) => {
    changeValue('visits_count', Number(target.value));
  };

  const handleChangePrice = ({ target }) => {
    changeValue('price', target.value);
  };

  const handleChangeDurationInDays = ({ target }) => {
    changeValue('duration_in_days', target.value);
  };

  const handleChangeAchive = ({ target }) => {
    changeValue('active', target.checked);
  };

  const handleChangeVisible = ({ target }) => {
    changeValue('visible', target.checked);
  };

  const handleChangeLocationIds = ({ target }) => {
    changeValue('location_ids', target.value);
  };

  const handleChangeClubCard = ({ target }) => {
    changeValue('club_card', target.checked);
  };

  const handleChangeAssignedToTrainer = ({ target }) => {
    const { trainer_payout, ...newItem } = item;
    setItem({
      ...newItem,
      assigned_to_trainer: target.checked,
      ...(!!target.checked && {
        trainer_payout: '',
      }),
    });
  };

  const handleChangeTrainerPayout = ({ target }) => {
    changeValue('trainer_payout', target.value);
  };

  const bindHandleChangeDiscountServiceCategoryAttr = (index) => {
    return ({ target }) => {
      changeValue(
        'discount_service_categories_attributes',
        replaceItemInArray(
          item.discount_service_categories_attributes,
          {
            ...item.discount_service_categories_attributes[index],
            value: target.value,
          },
          index
        )
      );
    };
  };

  const renderDiscountCategoriesInput = (
    discountServiceCategoryAttribute,
    index
  ) => {
    const { name } = findInArrayBy(
      service_categories,
      discountServiceCategoryAttribute.service_category_id
    );
    return (
      <Grid
        item
        xs={12}
        sm={4}
        key={discountServiceCategoryAttribute.service_category_id}
      >
        <FormInput
          label={t(name)}
          value={discountServiceCategoryAttribute.value}
          onChange={bindHandleChangeDiscountServiceCategoryAttr(index)}
        />
      </Grid>
    );
  };

  const discountServiceCategoriesAttributes = useMemo(
    function () {
      if (item.service_type !== DISCOUNT_SERVICE_TYPE) {
        return null;
      }
      return (
        <div className={classes.discountsRoot}>
          <Typography>{t('Discounts')}</Typography>
          <Grid
            container
            spacing={2}
          >
            {item.discount_service_categories_attributes.map(
              renderDiscountCategoriesInput
            )}
          </Grid>
        </div>
      );
    },
    [item.service_type, item.discount_service_categories_attributes]
  );

  const showVisitsCountInput = item.service_type === LIMITED_SERVICE_TYPE;

  const showTrainerRequired = useMemo(() => {
    return !!is_not_staff && item.service_type !== DISCOUNT_SERVICE_TYPE;
  }, [item.service_type]);

  const serializedItem = useMemo(
    function () {
      const { service_category_name, price, ...formData } = item;
      return {
        ...formData,
        price: Number(price),
      };
    },
    [item]
  );

  return (
    <Form
      name="service"
      action={action}
      method={method}
      validators={validators}
      disabled={isItemInvalid}
      item={serializedItem}
      onSubmit={submit}
      onError={setServerErrors}
    >
      <FormWrapper
        title="Service"
        item={item}
        backUrl={url}
      >
        <FormInput
          name="service[name]"
          required
          validationMessages={validationMessagesToDisplay.name}
          value={item.name}
          label={t('Name')}
          autoFocus
          onChange={handleChangeName}
        />
        <FormInput
          name="service[name_ua]"
          value={item.name_ua}
          label={t('Name UA')}
          autoFocus
          onChange={handleChangeNameUA}
        />
        <Select
          name="service[service_type]"
          label={t('Service Type')}
          options={service_types}
          value={item.service_type}
          onChange={handleChangeServiceType}
          required
          validationMessages={validationMessagesToDisplay.service_type}
        />
        {discountServiceCategoriesAttributes}
        <Select
          name="service[service_category_id]"
          label={t('Service Category')}
          options={service_categories}
          value={item.service_category_id}
          onChange={handleChangeServiceCategory}
          required
          validationMessages={validationMessagesToDisplay.service_category_id}
        />
        {showVisitsCountInput && (
          <FormInput
            name="service[visits_count]"
            required
            validationMessages={validationMessagesToDisplay.visits_count}
            value={item.visits_count}
            label={t('Visits Count')}
            onChange={handleChangeVisitsCount}
          />
        )}
        <MoneyInput
          name="service[price]"
          value={item.price}
          onChange={handleChangePrice}
          label={t('Price')}
          required
          validationMessages={validationMessagesToDisplay.price}
        />
        <FormInput
          name="service[duration_in_days]"
          required
          type="number"
          validationMessages={validationMessagesToDisplay.duration_in_days}
          value={item.duration_in_days}
          label={t('Duration In Days')}
          onChange={handleChangeDurationInDays}
        />
        <MultiSelect
          required
          options={locations}
          label={t('Locations')}
          value={item.location_ids}
          onChange={handleChangeLocationIds}
          validationMessages={validationMessagesToDisplay.locations}
        />
        <FormControl fullWidth>
          <Checkbox
            checked={item.active || false}
            onChange={handleChangeAchive}
            label={t('Active')}
            name="service[active]"
          />
        </FormControl>
        <FormControl fullWidth>
          <Checkbox
            checked={item.visible || false}
            onChange={handleChangeVisible}
            label={t('Visible')}
            name="service[visible]"
          />
        </FormControl>
        {!!showTrainerRequired && (
          <>
            <FormControl fullWidth>
              <Checkbox
                checked={item.assigned_to_trainer || false}
                name="service[assigned_to_trainer]"
                onChange={handleChangeAssignedToTrainer}
                label={t('Trainer Required')}
              />
            </FormControl>
            {!!item.assigned_to_trainer && (
              <MoneyInput
                required
                label={t('Trainer Fee')}
                value={item.trainer_payout}
                onChange={handleChangeTrainerPayout}
                validationMessages={validationMessagesToDisplay.trainer_payout}
              />
            )}
          </>
        )}
        <FormControl fullWidth>
          <Checkbox
            checked={item.club_card || false}
            onChange={handleChangeClubCard}
            label={t('Club card')}
            name="service[club_card]"
          />
        </FormControl>
      </FormWrapper>
    </Form>
  );
};

ServiceForm.defaultProps = {
  service: {
    name: '',
    name_ua: '',
    service_type: '',
    service_category_id: '',
    visits_count: '',
    price: '',
    duration_in_days: '',
    active: false,
    visible: true,
    assigned_to_trainer: false,
    location_ids: [],
    club_card: false,
  },
};
