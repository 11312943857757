import { Link, makeStyles, Box } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { ListWrapper, Table, Filters, Autocomplete, DatePicker, Button, MultiSelect } from 'Components';
import { useList, } from 'Hooks';
import { AJAX } from 'Helpers';

const useStyles = makeStyles(theme => ({
  canceled: {
    backgroundColor: theme.palette.text.disabled,
  },
  button: { marginLeft: theme.spacing(3) },
}));

const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'desc'
}

export const ServiceUsageHistoryAnalytics = ({ filter, export_url, export_api_url, fetch_url }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  function rowClassFn(item) {
    return item.canceled_at ? classes.canceled : null;
  }

  const tableConfig = [
    {
      title: t('Service Id'),
      accessor: 'purchased_service_id',
    },
    {
      title: t('Service'),
      accessor: 'service_name'
    },
    {
      title: t('Trainer'),
      accessor: 'trainer'
    },
    {
      title: t('Use Time'),
      accessor: 'use_time'
    },
    {
      title: t('Client'),
      accessor: 'client_name',
      cellComponent: ({ client_name, client_path }) => (
        <Link href={client_path}>{client_name}</Link>
      ),
    },
  ];

  const {
    pending, data, records_total, paginationParams, handleChangePagination, setFilters
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  function onSubmitFilters({ from, locations, till, ...filters }) {
    setFilters({
      ...filters,
      ...(!!locations.length && { locations }),
      ...(!!from || !!till) && {
        created_at: {
          ...(!!from) && { from },
          ...(!!till) && { till }
        }
      }
    });
  }

  const filterRef = React.useRef(null)

  function handleExportCSV() {
    const { locations, from, till, ...other } = filterRef.current.values;
    const filterToFetch = {
      ...other,
      ...(!!locations.length && { locations: locations }),
      ...(!!from || !!till) && {
        created_at: {
          ...(!!from) && { from },
          ...(!!till) && { till }
        }
      }
    }
    AJAX.post(export_api_url, { body: { filters: filterToFetch } })
  }

  return (
    <ListWrapper title={`${t('Service Usage History')} (${records_total})`}
      headerAddon={(
        <Box>
          <Button color="primary" onClick={handleExportCSV} className={classes.button}>
            {t('Export CSV')}
          </Button>
          <Button color="primary" href={export_url} className={classes.button}>
            {t('CSV Files')}
          </Button>
        </Box>
      )}>
      <Filters ref={filterRef} onSubmit={onSubmitFilters}
        initialValues={{ locations: [] }}>
        <Autocomplete label={t('Service')} options={filter.services} name="services"
          valueAsArray
        />
        <Autocomplete label={t('Trainer')} options={filter.trainers} name="trainers"
          valueAsArray
        />
        <DatePicker label={t('From')} name="from" />
        <DatePicker label={t('Till')} name="till" />
        <MultiSelect
          options={filter.locations}
          label={t('Locations')}
          name="locations"
        />
      </Filters>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
        rowClassFn={rowClassFn}
      />
    </ListWrapper>
  );
}
