export const TIME_POINTS = Array.from(Array(48)).map((_, i) => {
  return {
    point: i + 1,
    value: `${('0' + (Math.ceil(i / 2))).slice(-2)}:${i % 2 ? "00" : "30"}`
  }
});

export const HOURS = Array.from(Array(24)).map((_, i) => {
  return `${('0' + Math.ceil(i)).slice(-2)}:00`
});

export const TIME_PERIODS = Array.from(Array(48)).map((_, i) => {
  return `${('0' + (Math.ceil((i - 1) / 2))).slice(-2)}:${!(i % 2) ? "00" : "30"}`
});
