export const NewClients = {
  en: {
    'New Clients': 'New Clients',
    Name: 'Name',
    Birthday: 'Birthday',
    'Date and time of creation': 'Date and time of creation',
    Phone: 'Phone',
    Email: 'Email',
    'Check Data': 'Check Data',
    'Created At': 'Created At'
  },
  ru: {
    'New Clients': 'Новые Клиенты',
    Name: 'Имя',
    Birthday: 'Дата рождения',
    'Date and time of creation': 'Дата и время создания',
    Phone: 'Телефон',
    Email: 'Почта',
    'Check Data': 'Проверить данные',
    'Created At': 'Создан'
  },
  uk: {
    'New Clients': 'Нові Клієнти',
    Name: "Ім'я",
    Birthday: 'Дата народження',
    'Date and time of creation': 'Дата і час створення',
    Phone: 'Телефон',
    Email: 'Пошта',
    'Check Data': 'Перевірити дані',
    'Created At': 'Створений'
  },
};

export const NewClientForm = {
  en: {
    ...NewClients.en,
    'New Client': 'New Client',
    'confirm': 'confirm',
    'reject': 'reject',
    'First name': 'First name',
    'Last name': 'Last name',
    'Middle name': 'Middle name',
    'edit': 'edit',
    'print eng form': 'print eng form',
    'print uk form': 'print uk form',
    'Save': 'Save',
    'Cancel': 'Cancel',
  },
  ru: {
    ...NewClients.ru,
    'New Client': 'Новый Клиент',
    'confirm': 'Подтвердить',
    'reject': 'Отклонить',
    'First name': 'Имя',
    'Last name': 'Фамилия',
    'Middle name': 'Отчество',
    'edit': 'Редактировать',
    'print eng form': 'Анкета ENG',
    'print uk form': 'Анкета УКР',
    'Save': 'Сохранить',
    'Cancel': 'Отменить'
  },
  uk: {
    ...NewClients.uk,
    'New Client': 'Новий Клієнт',
    'confirm': 'Підтвердити',
    'reject': 'Відхилити',
    'First name': "Ім'я",
    'Last name': 'Прізвище',
    'Middle name': 'По батькові',
    'edit': 'Редагувати',
    'print eng form': 'Анкета ENG',
    'print uk form': 'Анкета УКР',
    'Save': 'Зберегти',
    'Cancel': 'Відмінити'
  },
};
