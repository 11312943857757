import { useTranslation } from 'react-i18next';

import { useFilters, usePagination } from 'Hooks';

import CurrentPurchasedServiceTable from '../CurrentPurchasedServiceTable';
import { useClientContext } from '../providers/ClientShowProvider';
import { DEFAULT_SORT_PARAMS, PAGINATION_PARAMS } from '../ClientShow';

import { useCurrentPurchasedActiveServicesQuery } from 'queries';

const initialFilters = {
  current_active_or_frozen_without_discounts: true,
};

const CurrentPurchasedActiveServices = () => {
  const { t } = useTranslation();
  const { purchased_services_url } = useClientContext();

  const paginationData = usePagination(PAGINATION_PARAMS, DEFAULT_SORT_PARAMS);
  const { paginationParams, handleChangePagination } = paginationData;
  const { filters } = useFilters(initialFilters);

  const { data: currentPurchasedServices, isLoading } =
    useCurrentPurchasedActiveServicesQuery({
      fetchUrl: purchased_services_url,
      paginationParams,
      filters,
    });

  const { data: services, records_total } = currentPurchasedServices || {};

  return (
    <CurrentPurchasedServiceTable
      title={t('Current Active Purchased Services')}
      recordsTotal={records_total}
      services={services}
      onChangePaginationParams={handleChangePagination}
      paginationParams={paginationParams}
      pending={isLoading}
      freezeActions={true}
    />
  );
};

export default CurrentPurchasedActiveServices;