import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  makeStyles,
} from '@material-ui/core';

import { findInArrayBy, toMoneyFormat } from 'Helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  servicesList: {
    width: '100%',
  },
}));

const useServicesStyles = makeStyles(() => ({
  categoryService: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));



export const ServicesList = React.memo(({ services, selectedServices, expandedCategory, onCategoryClick, onServiceClick }) => {
  const classes = useStyles();

  function bindCategoryClick(category) {
    return function () {
      onCategoryClick(category);
    };
  }

  return (
    <div className={classes.root}>
      {Object.keys(services).map((category) => (
        <Accordion
          key={category}
          expanded={expandedCategory === category}
          onChange={bindCategoryClick(category)}
        >
          <AccordionSummary expandIcon={<i className=" icon-down-open"></i>}>
            <Typography className={classes.heading}>{category}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List className={classes.servicesList}>
              {services[category].map((service) => (
                <Service
                  key={service.id}
                  service={service}
                  selectedServices={selectedServices}
                  onClick={onServiceClick}
                />
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
});

const Service = ({ service, selectedServices, onClick }) => {
  const classes = useServicesStyles();

  function handleClick() {
    onClick(service);
  }

  const checked = React.useMemo(() => {
    return !!findInArrayBy(selectedServices, service.id);
  }, [selectedServices]);

  const labelId = `service-${service.id}-label`;

  return (
    <ListItem role={undefined} dense button onClick={handleClick}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          color="primary"
          checked={checked}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId}>
        <div className={classes.categoryService}>
          <div>{service.name}</div>
          <div className={classes.price}>{toMoneyFormat(service.price)}</div>
        </div>
      </ListItemText>
    </ListItem>
  );
};