import { Card, Box, Typography, Button, makeStyles } from '@material-ui/core';
import moment from 'moment';
import {DISPLAY_DATES_FORMAT} from 'Constants'
import { Description } from './Description';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '24px'
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textDecoration: 'none',
    color: 'inherit'
  },
  eventInfoWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  providerInfo: {
    fontSize: '28px',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  dateInfo: {
    fontSize: '26px',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  slotsInfo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  availableClass: {
    display: 'block',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  imageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  image: {
    display: 'block',
    width: '100%',
    objectFit: 'cover',
  },
  group: {
    display: 'block',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  btn: {
    background: theme.palette.primary.main,
    marginRight: '8px',
    marginBottom: '8px',
    fontSize: '18px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      maxWidth: '130px'
    },
  },
}));

export const EventCardItem = ({
booking_provider_description, booking_provider_name,
provider_photo_url, date, time_slots, openDescriptionDialog
}) => {
  
  const classes = useStyles();

  function dateFormat(date) {
    return moment(date).format(DISPLAY_DATES_FORMAT)
  }

  function showDescription() {
    openDescriptionDialog({ title: booking_provider_name, content: booking_provider_description })
  }

  return (
      <Card className={classes.card} elevation={8}>
        <Box className={classes.cardContent}>
          <Box className={classes.eventInfoWrapper}>
            <Typography className={classes.providerInfo}>{booking_provider_name}</Typography>
            <Typography className={classes.dateInfo}>{dateFormat(date)}</Typography>
            <Box className={classes.slotsInfo}>
              {time_slots.map(({ time_period, confirmation_url, current_members_count, members_limit, name }) => (
                <div>
                  <Button
                    className={classes.btn}
                    href={confirmation_url}
                    disabled={!(current_members_count < members_limit)}
                    variant="contained"
                    color="primary" component="a">
                      {name}
                      <br />
                      {time_period}
                  </Button>
                  <Typography className={classes.availableClass}>
                    {`Вільних місць ${members_limit - current_members_count} з ${members_limit}`}
                  </Typography>
                </div>
              ))}  
            </Box>
          </Box>
          <Box className={classes.imageWrapper}>
            <img src={provider_photo_url} className={classes.image} />
          </Box>
        </Box>
          <Description text={booking_provider_description}
            showDescription={showDescription} />
      </Card>
  )
}
