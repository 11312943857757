import { useQuery } from '@tanstack/react-query';

import { HttpClient } from 'network/HttpClient';

import { FetchDataParams } from 'schemas';

export const CLIENT_ORDERS_QUERY_KEY = 'clientOrders';

export const useClientOrdersQuery = ({
  fetchUrl,
  paginationParams,
}: FetchDataParams) => {
  return useQuery(
    [CLIENT_ORDERS_QUERY_KEY, paginationParams],
    () => HttpClient.getClientOrders({ fetchUrl, paginationParams }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};
