import { makeStyles, Box, Grid, Card } from '@material-ui/core';
import { CardItem } from '../CardItem';
import DescriptionDialog from '../DescriptionDialog';
import bookingCardsClasses from '../BookingCardsStyles';

const useStyles = makeStyles(() => ({
  col: {
    display: 'flex'
  },
  cardLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '110px'
  },
  providerName: {
    marginBottom: 'auto',
    fontWeight: 700,
    fontSize: '20px',
  },
  anyProvider: {
    textAlign: 'center',
    padding: '10px 20px'
  }
}));

export const ClientBookingProviders = ({ booking_providers, url }) => {
  const classes = useStyles();
  const cardsClasses = bookingCardsClasses();

  const descriptionDialogRef = React.useRef(null);

  function openDescriptionDialog(descriptionData) {
    descriptionDialogRef.current.open(descriptionData)
  }

  return (
    <Box className={cardsClasses.cards}>
      <h1>Оберіть iнструктора</h1>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className={classes.col}
        >
          <Card
            component='a'
            href={`${url}/any/booking_periods`}
            className={classes.cardLink}
            elevation={8}
          >
            <h2 className={classes.anyProvider}>Показати всі доступні варіанти</h2>
          </Card>
        </Grid>
        {booking_providers.map(({ id, name, description, photo_url }) => (
          <Grid
            key={id}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.col}
          >
            <CardItem
              href={`${url}/${id}/booking_periods`}
              description={description}
              name={name}
              openDescriptionDialog={openDescriptionDialog}
            >
              <Box
                className={classes.providerName}
                dangerouslySetInnerHTML={{ __html: name }}
              >
              </Box>
              <Box className={classnames(cardsClasses.scalableImageWrapper,
                `${cardsClasses.scalableImageWrapper}--squared`)}>
                <img
                  src={photo_url}
                  className={cardsClasses.scalableImage}
                  alt=""
                />
              </Box>
            </CardItem>
          </Grid>
        ))}
      </Grid>
      <DescriptionDialog ref={descriptionDialogRef} />
    </Box>
  )
}
