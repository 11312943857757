import { Box, Chip, Typography, IconButton } from '@material-ui/core';
import { Calendar } from '@material-ui/pickers'
import moment from 'moment';
import { useTranslation, } from 'react-i18next';
import { Preloader, } from 'Components';
import { updateItemInArray, AJAX } from 'Helpers';
import { SEND_DATES_FORMAT, HOURS } from 'Constants';
import { useStyles } from './ScheduleByDatesStyles';
import confirmationStore from 'Helpers/confirmation';

const INITIAL_DATE = moment().format(SEND_DATES_FORMAT);
const COLUMN_ITEMS_COUNT = HOURS.length / 2;
const SCHEDULE_TIME_COLUMNS = [
  [
    ...HOURS.slice(0, COLUMN_ITEMS_COUNT),
  ],
  [
    ...HOURS.slice(COLUMN_ITEMS_COUNT),
  ]
];

//Calendar doc is here: https://material-ui-pickers.dev/api/Calendar

const ScheduleByDates = ({ apiUrl }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [currentDate, changeCurrentDate] = React.useState(INITIAL_DATE);
  const [selectedDate, changeSelectedDate] = React.useState(INITIAL_DATE);

  const [currentSchedule, changeCurrentSchedule] = React.useState({
    pending: false,
    data: null
  });
  const [calendarData, setCalendarData] = React.useState({
    pending: false,
    changedDates: []
  });

  function handleChange(date) {
    changeSelectedDate(date.format(SEND_DATES_FORMAT))
  }

  function onMonthChange(date) {
    changeCurrentDate(date.format(SEND_DATES_FORMAT))
  }

  async function getChangedSchedule(currentDate) {
    setCalendarData({
      pending: true,
      changedDates: []
    });

    const from = moment(currentDate).startOf('month').format(SEND_DATES_FORMAT);
    const till = moment(currentDate).endOf('month').format(SEND_DATES_FORMAT);

    const res = await AJAX.get(`${apiUrl}/booking_periods/changed_calendar_days`, {
      body: { from, till }
    })

    setCalendarData({
      pending: false,
      changedDates: res
    })
  }


  async function getSchedule(date) {
    changeCurrentSchedule({
      pending: true,
      data: null
    })

    const res = await AJAX.get(`${apiUrl}/booking_periods/specific_day`, {
      body: { date }
    })

    changeCurrentSchedule({
      pending: false,
      data: res
    })
  }

  React.useEffect(() => {
    getChangedSchedule(currentDate)
  }, [currentDate])


  React.useEffect(() => {
    getSchedule(selectedDate)
  }, [selectedDate]);


  function handleClickTime(item) {
    return async () => {
      const { id, active, ...itemInfo } = item;

      changeCurrentSchedule({
        ...currentSchedule,
        pending: true,
      });

      const res = await AJAX.put(`${apiUrl}/booking_periods/${id}`, {
        body: {
          booking_period: { active: !active }
        }
      });

      if (res.flash_type == 'success') {
        const updatedItem = {
          ...itemInfo,
          ...res.booking_period,
        }

        const updatedData = updateItemInArray(currentSchedule.data, updatedItem, id)
        return changeCurrentSchedule({
          data: updatedData,
          pending: false
        })
      }

      changeCurrentSchedule({
        ...currentSchedule,
        pending: false,
      });
    }
  }

  function renderDate(day, _, isInCurrentMonth, dayComponent) {
    const isMarkDisplayed = isInCurrentMonth && calendarData.changedDates.includes(day.format(SEND_DATES_FORMAT))
    return (
      <Box className={classes.dayWrapper}>
        {dayComponent}
        {isMarkDisplayed && (
          <Box className={classes.dayMark}></Box>
        )}
      </Box>
    );
  }

  const currentScheduleColumns = React.useMemo(() => {
    const { data } = currentSchedule;

    if (data) {
      return [
        [
          ...data.slice(0, COLUMN_ITEMS_COUNT * 2),
        ],
        [
          ...data.slice(COLUMN_ITEMS_COUNT * 2),
        ]
      ];
    }
  }, [currentSchedule.data]);

  function handleClearDate() {
    confirmationStore.open(async function () {
      changeCurrentSchedule({
        ...currentSchedule,
        pending: true,
      });
      const res = await AJAX.put(`${apiUrl}/booking_periods/disable_date`, {
        body: {
          date: selectedDate
        }
      });

      if (res.flash_type == 'success') {
        return getSchedule(selectedDate)
      }

      changeCurrentSchedule({
        ...currentSchedule,
        pending: false,
      });
    })

  }

  const scheduleHeader = React.useMemo(() => {
    return (
      <Typography className={classes.scheduleTitle} component="h5" variant="h5">
        {moment(selectedDate).format('DD.MM.YYYY')}
        <IconButton className={classes.deleteBtn} onClick={handleClearDate} size="small">
          <i className="icon-trash-empty"></i>
        </IconButton>
      </Typography>
    )
  }, [selectedDate])

  return (
    <Box className={classes.mainWrapper}>
      <Box className={classes.calendarWrapper}>
        <Calendar date={moment(selectedDate)} onChange={handleChange} onMonthChange={onMonthChange}
          renderDay={renderDate} />
      </Box>
      <Box className={classes.dayScheduleWrapper}>
        {scheduleHeader}
        {currentSchedule.data && (
          <Box className={classes.daySchedule}>
            <Box className={classes.timescale}>
              {SCHEDULE_TIME_COLUMNS[0].map((hour) => (
                <span className={classes.hour} key={hour}>{hour}</span>
              ))}
            </Box>
            <Box className={classes.scheduleColumn}>
              {currentScheduleColumns[0].map((item) => (
                <Box key={item.id} className={classes.scheduleHourWrapper}>
                  <Chip label={item.time_range_start} color={item.active ? "primary" : 'default'} size="small"
                    onClick={handleClickTime(item)} />
                </Box>
              ))}
            </Box>
            <Box className={classes.timescale}>
              {SCHEDULE_TIME_COLUMNS[1].map((hour) => (
                <span className={classes.hour} key={hour}>{hour}</span>
              ))}
            </Box>
            <Box className={classes.scheduleColumn}>
              {currentScheduleColumns[1].map((item) => (
                <Box key={item.id} className={classes.scheduleHourWrapper}>
                  <Chip label={item.time_range_start} color={item.active ? "primary" : 'default'} size="small"
                    onClick={handleClickTime(item)} />
                </Box>
              ))}
            </Box>
            {currentSchedule.pending && (
              <Box className={classes.scheduleOverlay}></Box>
            )}
          </Box>
        )}
        {!currentSchedule.data && (
          <Box className={classes.preloader}>
            <Preloader />
          </Box>
        )}
      </Box>
    </Box>
  )
}


export default ScheduleByDates