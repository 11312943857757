import { TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { IncomeTableRow } from './IncomeTableRow';

import { IncomeTableProps } from '../types';

const useStyles = makeStyles(() => ({
  incomeSourceName: {
    display: 'inline-block',
    maxWidth: 'min-content',
  },
  total: {
    backgroundColor: '#f5f5f5',
  },
}));

const INCOME_SOURCE_NAMES = {
  cash: 'Cash',
  terminal: 'Terminal',
  transfer_to_card: 'Transfer to card',
  online: 'Online',
  Total: 'Total',
};

export const IncomeTableBody = ({ incomesByLocations }: IncomeTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TableBody>
      {incomesByLocations.map((incomeByLocations, index) => {
        const subRows = incomeByLocations.find((row) => row.agents)?.agents;
        const isBackgroundNeed = index % 2 === 0;
        return !subRows ? (
          <IncomeTableRow
            key={index}
            rowData={incomeByLocations}
            className={classNames({ [classes.total]: isBackgroundNeed })}
          />
        ) : (
          <>
            <TableRow
              className={classNames({ [classes.total]: isBackgroundNeed })}
            >
              <TableCell
                rowSpan={subRows.length + 1}
                align="center"
              >
                <span className={classes.incomeSourceName}>
                  {t(
                    INCOME_SOURCE_NAMES[
                      incomeByLocations[0]
                        .source as keyof typeof INCOME_SOURCE_NAMES
                    ]
                  )}
                </span>
              </TableCell>
            </TableRow>
            {subRows.map((subRow, index) => {
              return (
                <IncomeTableRow
                  key={index}
                  subRow
                  rowData={subRow}
                  className={classNames({ [classes.total]: isBackgroundNeed })}
                />
              );
            })}
          </>
        );
      })}
    </TableBody>
  );
};
