import { useTranslation, } from 'react-i18next';
import { setObjectValue, } from 'Helpers';

export function useFormValue(initialValue = {}) {
  const [item, changeItem] = React.useState(initialValue);

  function changeValue(key, value) {
    const newItem = setObjectValue(item, key, value);
    changeItem(newItem);
  }

  return [item, changeValue, changeItem];
}

export function useFormSubmit() {
  const [submitted, changeSubmitted] = React.useState(false);

  function submit() {
    changeSubmitted(true);
  }

  function unsubmit() {
    changeSubmitted(false);
  }
  return { submitted, submit, unsubmit };
}

export function useValidation(item, validators, formSubmitted, serverErrors) {
  const { t, } = useTranslation();
  const validationMessages = React.useMemo(function () {
    if (!item || !validators) {
      return {};
    }
    return Object.keys(validators).reduce(function (res, key) {
      const msg = t(validators[key](item));
      if (!!msg) {
        res = { ...res, [key]: msg };
      } else {
        delete res[key];
      }
      return res;
    }, {});
  }, [item, formSubmitted]);

  const isItemInvalid = React.useMemo(function () {
    return !!Object.keys(validationMessages).length || !!Object.keys(serverErrors).length;
  }, [validationMessages, serverErrors]);

  const validationMessagesToDisplay = React.useMemo(function () {
    return formSubmitted ? { ...validationMessages, ...serverErrors } : {};
  }, [validationMessages, serverErrors]);

  return { validationMessages, isItemInvalid, validationMessagesToDisplay, };
}

export function useServerErrorsForm(item) {
  const [serverErrors, changeServerErrors] = React.useState([]);

  function setServerErrors(errors) {
    changeServerErrors(errors)
  }

  function clearServerErrors() {
    changeServerErrors([]);
  }

  React.useEffect(() => {
    const hasServerErrrors = !!Object.keys(serverErrors).length;
    if (hasServerErrrors) {
      clearServerErrors();
    }
  }, [item]);

  return { serverErrors, setServerErrors, clearServerErrors };
}

export function useForm(initialItem, validators, url) {
  const [item, changeValue, setItem] = useFormValue(initialItem);
  const method = React.useMemo(function () {
    return item.id ? 'put' : 'post';
  }, [item.id]);
  const action = React.useMemo(function () {
    return item.id ? `${url}/${item.id}` : url
  }, [item.id]);
  const submit = useFormSubmit();
  const {
    serverErrors,
    setServerErrors,
    clearServerErrors,
  } = useServerErrorsForm(item);
  const validation = useValidation(item, validators, submit.submitted, serverErrors);
  return {
    action, method, item, changeValue, setItem, ...submit, ...validation,
    setServerErrors, clearServerErrors
  };
}
