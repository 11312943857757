import { Box, IconButton, makeStyles, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  listItemWrapper: {
    position: 'relative',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.38)',
    padding: '5px',
  },
  deleteBtnWrapper: {
    position: 'absolute',
    top: '50%',
    right: '5px',
    transform: 'translate(0, -50%)',
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
  },
  old: {
    background: 'rgba(109, 140, 183, 0.1)',
  },
  deleteBtn: {
    '& i': {
      color: 'rgb(214, 23, 9)',
    },
  },
}));

const FIELD_TYPES = {
  input: 'Input',
  drop_down: 'Select'
}

export const CustomField = (props) => {
  const {
    field_type,
    label,
    required,
    option_values,
    index,
    id,
    isRemoval,
    onCustomFieldDelete,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <div
        className={classNames(classes.listItemWrapper, id ? classes.old : '')}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {t('Field type')}: {t(FIELD_TYPES[field_type])}
          </Grid>
          <Grid item xs={12}>
            {t('Field label')}: {label}
          </Grid>
          <Grid item xs={12}>
            {t('Is field required?')}: {t(`${required ? 'Yes' : 'No'}`)}
          </Grid>
          {option_values && (
            <Grid item xs={12}>
              {t('Drop-down list items')}:{' '}
              {option_values.split('\n').join(', ')}
            </Grid>
          )}
        </Grid>
        {isRemoval && (
          <Box className={classes.deleteBtnWrapper}>
            <IconButton
              className={classes.deleteBtn}
              onClick={() => onCustomFieldDelete(id, index)}
              size="small"
            >
              <i className="icon-trash-empty"></i>
            </IconButton>
          </Box>
        )}
      </div>
    </Grid>
  );
};
