export const ActivePurchasedServices = {
  en: {
    'Date': 'Date',
    'Name': 'Name',
    'Visits Count': 'Visits Count',
    'Client': 'Client',
    'Actual Visits on Date': 'Actual Visits on Date',
    'Activated At': 'Activated At',
    'Expiration': 'Expiration'
  },
  ru: {

    'Date': 'Дата',
    'Name': 'Название',
    'Visits Count': 'Кол-во посещений',
    'Client': 'Клиент',
    'Actual Visits on Date': 'Фактические посещения на дату',
    'Activated At': 'Активирован',
    'Expiration': 'Окончание'
  },
  uk: {
    'Date': 'Дата',
    'Name': 'Назва',
    'Visits Count': 'Кількість відвідувань',
    'Client': 'Клієнт',
    'Actual Visits on Date': 'Фактичні відвідування на дату',
    'Activated At': 'Активован',
    'Expiration': 'Закінчення'
  },
};
