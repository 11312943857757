export const BookingProviderWorkTime = {
  en: {
    'Provider': 'Provider',
    'Location': 'Location',
    'Search': 'Search',
    'Work Schedule': 'Work Schedule',
    'Work Schedule By Dates': 'Work Schedule By Dates',
    'Refresh Current Schedule': 'Refresh Current Schedule',
    'info': 'The schedule is set for two weeks in advance. Any changes to the schedule will be applied for future creations of schedule for day. If you want to change the current schedule(except the current date) click "refresh current schedule" button'
  },
  ru: {
    'Provider': 'Провайдер',
    'Location': 'Локация',
    'Search': 'Поиск',
    'Work Schedule': 'График работы',
    'Work Schedule By Dates': 'График работы по датам',
    'Refresh Current Schedule': 'Обновить текущий график',
    'info': 'График формируется на 2 недели вперед. любые изменения в графике будут пременены через 2 недели. Eсли вы хотите изменить текущий график, нажмите кнопку "обновить"'
  },
  uk: {
    'Provider': 'Провайдер',
    'Location': 'Локація',
    'Search': 'Пошук',
    'Work Schedule': 'Графік роботи',
    'Work Schedule By Dates': 'Графік роботи по датах',
    'Refresh Current Schedule': 'Оновити поточний графік',
    'info': 'Графік сформований на 2 тижні наперед. Будь-які зміни у цьому графіку будуть застосовані при майбутньому створенні нових днів. Якщо ви хочете застосувати зміни до поточного графіку(окрім сьогоднішнього дня) - натисніть кнопку "оновити поточний графік"'
  },
};
