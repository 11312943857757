
export const useDatePicker = (value, onChange, dateFormat, displayDateFormat) => {

  const inputValue = React.useMemo(
    function () {
      const momentValue = !!value ? moment(value, dateFormat) : null;
      return momentValue;
    },
    [value]
  );

  function handleChange(value) {
    const momentValue = !!value ? moment(value, displayDateFormat) : value;
    if (!!onChange) {
      onChange({
        target: {
          value: momentValue ? momentValue.format(dateFormat) : '',
        },
      });
    }
  }

  return { inputValue, handleChange };
};