import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as localization from 'Localization';
import { transformLocales, initI18n } from 'Helpers';
import { AppLayout, ConfirmDialog, Notifications, InternalNavigation, LocationPickerDialog } from 'Components';
import * as Pages from 'Pages';
/**
 * Function render component in container element with props in container 'data' attribute
 * @param {*} componentFn Root component of page
 * @param {*} container Dom element with component props in 'data' attribute
 */
export function renderComponent(
  componentFn,
  container = document.getElementById('react-content')
) {
  const props = JSON.parse(container.getAttribute('data'));
  ReactDOM.render(React.createElement(componentFn, props), container);
}

export function renderLayout(
  pageContainer = document.getElementById('page-content'),
  headerContainer = document.getElementById('page-header'),
  renderContainer = document.getElementById('page-root'),
  flashContainer = document.getElementById('flash-root'),
  internalNavigation = document.getElementById('internal-navigation')
) {
  const { component_name, ...pageProps } = JSON.parse(
    pageContainer.getAttribute('data')
  );

  const headerParams = JSON.parse(headerContainer.getAttribute('data'));
  const locales = transformLocales(
    localization.common,
    localization.header,
    localization[component_name]
  );

  const messages = flashContainer ? JSON.parse(flashContainer.getAttribute('data')).flash : [];
  const internalNavData = internalNavigation ? JSON.parse(internalNavigation.getAttribute('data')) : [];

  initI18n(locales);

  const { is_location_selection_required } = headerParams;

  ReactDOM.render(
    <Notifications notifications={messages}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppLayout {...headerParams}>
          {internalNavigation && (
            <InternalNavigation {...internalNavData} />
          )}
          {React.createElement(Pages[component_name], pageProps)}
          <ConfirmDialog />
          {is_location_selection_required && 
            <LocationPickerDialog 
              locations={headerParams.location_props.locations}
              apiUrl={headerParams.location_props.url}
            />
          }
        </AppLayout>
      </MuiPickersUtilsProvider>
    </Notifications>,
    renderContainer
  );
}
