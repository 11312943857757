export const Locations = {
  en: {
    'Locations': 'Locations',
    'Create Location': 'Create Location',
    'Name': 'Name',
  },
  ru: {
    'Locations': 'Локации',
    'Create Location': 'Создать локацию',
    'Name': 'Название',
  },
  uk: {
    'Locations': 'Локації',
    'Create Location': 'Створити локацію',
    'Name': 'Назва',
  },
};

export const LocationForm = {
  en: {
    ...Locations.en,
    'New Location': 'New Location',
    'Edit Location': 'Edit Location',
    'Update Location': 'Update Location',
    'Website': 'Website',
    'Location on Google Maps': 'Location on Google Maps',
    'Choose Image File': 'Choose Image File',
    'Active For Booking': 'Active For Booking',
    'VAT enabled': 'VAT enabled',
    'Booking description': 'Description for booking page',
  },
  ru: {
    ...Locations.ru,
    'New Location': 'Новая Локация',
    'Edit Location': 'Редактирование Локации',
    'Update Location': 'Обновить Локацию',
    'Choose Image File': 'Выбрать изображение',
    'Active For Booking': 'Активный для бронирования',
    'Booking description': 'Описание для страницы бронирования',
  },
  uk: {
    ...Locations.uk,
    'New Location': 'Нова Локація',
    'Edit Location': 'Редагування Локації',
    'Update Location': 'Оновити Локацію',
    'Website': 'Сайт',
    'Location on Google Maps': 'Локація на Google Maps',
    'Choose Image File': 'Вибрати зображення',
    'Active For Booking': 'Активний для бронювання',
    'VAT enabled': 'ПДВ ввімкнено',
    'Booking description': 'Опис для сторінки бронювання',
  },
}