import { useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  Link,
  IconButton,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import { Button, FormControl, AjaxAutocomplete } from 'Components';
import { AJAX } from 'Helpers';
import confirmationStore from 'Helpers/confirmation';

import BuyThings from '../BuyThings/BuyThings';
import RopeStatus from './RopeStatus';
import { useClientContext } from '../providers/ClientShowProvider';

import { ClientsRequestBody, Client } from './type';

const clientsRequestBody = (value: string): ClientsRequestBody => {
  return {
    page: 1,
    size_per_page: 10,
    sort_column: 'created_at',
    sort_direction: 'asc',
    search: {
      value,
    },
  };
};

const getOptionLabel = (client: Client) => 
  !!client ? `${client.first_name} ${client.last_name} (ID: ${client.id}), ${client.phone}, ${client.email}` : '';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomRope: {
      marginTop: -theme.spacing(2),
    },
    buyThingsContainer: {
      marginTop: theme.spacing(2),
    },
    photosContainer: {
      textAlign: 'center',
    },
    clientPhoto: {
      width: '100%',
      maxWidth: '100%',
    },
    signaturePhotoButton: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    multilineText: {
      whiteSpace: 'pre-wrap',
    },
    clientHeaderInfo: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    clientAutocomplete: {
      minWidth: '280px',
      flexShrink: 0,
    },
    searchBtn: {
      marginLeft: 'auto',
      display: 'block',
    },
    inputWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const ClientInfo = () => {
  const clientData = useClientContext();
  const { client, clients_fetch_url, merge_account_url } = clientData;
  const { t } = useTranslation();
  const classes = useStyles();
  const initials =
    `${client.first_name[0]}${client.last_name[0]}`.toUpperCase();

  const onAutoCompleteChange = (_: any, selectedClient: Client) => {
    if (!selectedClient) {
      return;
    }

    const onClientMerge = () => {
      AJAX.post(merge_account_url, {
        body: { client_id_to_merge: selectedClient.id },
      });
    };

    const message = t('MergeClientText', {
      clientName: `${client.first_name} ${client.last_name}(ID: ${client.id})`,
      selectedClientName: `${selectedClient.first_name} ${selectedClient.last_name}(ID: ${selectedClient.id})`,
    });

    confirmationStore.open(onClientMerge, message);
  };

  const [isMergeInputOpened, changeIsMergeInputOpened] = useState(false);

  const onOpenClientSearchInput = () => {
    changeIsMergeInputOpened(true);
  };

  const onCloseClientSearchInput = () => {
    changeIsMergeInputOpened(false);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Link href={client.photo_url || undefined}>
            <Avatar
              aria-label={initials}
              src={client.photo_url || undefined}
            >
              {initials}
            </Avatar>
          </Link>
        }
        title={
          <Box className={classes.clientHeaderInfo}>
            <Box>
              {`${client.first_name} ${client.last_name} ${
                client.middle_name || ''
              }`}
              <IconButton
                size="small"
                href={clientData.edit_client_url}
              >
                <i className="icon-edit"></i>
              </IconButton>
              {!!client.signature_photo_url && (
                <>
                  |&nbsp;
                  <Link href={client.signature_photo_url}>
                    ({t('Client Signature')})
                  </Link>
                </>
              )}

              <Box>
                <Link href={`tel:${client.phone}`}>{client.phone}</Link>
                &nbsp;|&nbsp;
                <Link href={`mailto:${client.email}`}>{client.email}</Link>
              </Box>
              <div>ID: {client.id}</div>
            </Box>
            <Box>
              {!!clientData.client.has_active_club_card && (
                <Alert severity="success">{t('Has club card')}</Alert>
              )}
            </Box>
            <Box>
              {!!clientData.client.insurance_signed && (
                <Alert severity="success">{t('Insurance signed')}</Alert>
              )}
            </Box>
            <Box className={classes.clientAutocomplete}>
              {!isMergeInputOpened && (
                <Button
                  color="primary"
                  className={classes.searchBtn}
                  onClick={onOpenClientSearchInput}
                >
                  {t('Merge to')}
                </Button>
              )}

              {isMergeInputOpened && (
                <Box className={classes.inputWrapper}>
                  <AjaxAutocomplete
                    label={t('Client')}
                    getRequestBody={clientsRequestBody}
                    searchUrl={clients_fetch_url}
                    getOptionLabel={getOptionLabel}
                    onChange={onAutoCompleteChange}
                    method="get"
                  />
                  <IconButton
                    size="small"
                    onClick={onCloseClientSearchInput}
                  >
                    <i className="icon-cancel"></i>
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        }
      />
      <CardContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={4}
            className={classes.photosContainer}
          >
            {!!client.photo_url && (
              <Link href={client.photo_url}>
                <img
                  src={client.photo_url}
                  className={classes.clientPhoto}
                />
              </Link>
            )}
            {!!client.signature_photo_url && (
              <div>
                <Button
                  href={client.signature_photo_url}
                  className={classes.signaturePhotoButton}
                >
                  {t('Client Signature')}
                </Button>
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <span>{t('Birthday')}:&nbsp;</span>
                    {client.birthday}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <span>{t('Phone')}:&nbsp;</span>
                    <Link href={`tel:${client.phone}`}>{client.phone}</Link>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <span>{t('Email')}:&nbsp;</span>
                    <Link href={`mailto:${client.email}`}>{client.email}</Link>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <span>{t('Top Rope')}:&nbsp;</span>
                    <RopeStatus name="top_rope" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes.bottomRope}
                  >
                    <span>{t('Bottom Rope')}:&nbsp;</span>
                    <RopeStatus name="bottom_rope" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Grid
                  container
                  spacing={2}
                >
                  {!!client.important_notes && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormControl
                        label={t('Important Notes')}
                        fullWidth
                      >
                        <div className={classes.multilineText}>
                          {client.important_notes}
                        </div>
                      </FormControl>
                    </Grid>
                  )}
                  {!!client.comments && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormControl
                        label={t('Comments')}
                        fullWidth
                      >
                        <div className={classes.multilineText}>
                          {client.comments}
                        </div>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.buyThingsContainer}
              >
                <BuyThings />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClientInfo;