import { useTranslation } from 'react-i18next';
import { 
    makeStyles, 
    Table as MaterialTable,
    TableContainer, 
    TableBody, 
    TableCell, 
    TableRow, 
    Box, 
    IconButton 
  } from '@material-ui/core';
  import { toMoneyFormat} from 'Helpers';

  const useStyles = makeStyles(() => ({
    settingsCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
  }));

export const ServicesTable = ({services, onServiceDelete}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderService = (service) => {
    return (
      <TableRow key={service.id}>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => onServiceDelete(service.id)}
          >
            <i className="icon-trash-empty"></i>
          </IconButton>
            {service.name}
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          <Box className={classes.settingsCell}>
            {toMoneyFormat(service.price)}
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  const totalPrice = React.useMemo(() => {
    return services.reduce((sum, service) => {
      return sum + service.price;
    }, 0)
  }, [services])

return (
  <TableContainer>
    <MaterialTable>
      <TableBody>
        {services.map(renderService)}
        <TableRow>
          <TableCell>
            {t('Total Price')}
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            {toMoneyFormat(totalPrice)}
          </TableCell>
        </TableRow>
      </TableBody>
    </MaterialTable>
</TableContainer>
)}
