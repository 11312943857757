import { IconButton } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { ListWrapper, Table, ListActions, Button, } from 'Components';
import { useList, } from 'Hooks';
import { DEFAULT_SORT_PARAMS } from 'Constants'


export const Locations = ({ url, new_item_url, fetch_url, }) => {
  const { t } = useTranslation();
  const {
    pending, data, page, items_per_page, total,
    setPage, setPageSize
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  const tableConfig = [
    {
      title: t('Name'),
      accessor: 'name',
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }) => (
        <>
          <IconButton size="small" href={`${url}/${id}/fiscal_registrars`}>
            <i className="icon-money"></i>
          </IconButton>
          <ListActions editUrl={`${url}/${id}/edit`} />
        </>
      )
    }
  ];

  return (
    <ListWrapper title={`${t('Locations')}`} headerAddon={(
      <div>
        <Button href={new_item_url} variant="contained" color="primary">
          {t('Create Location')}
        </Button>
      </div>
    )}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        page={page}
        items_per_page={items_per_page}
        total={total}
        setPage={setPage}
        setPageSize={setPageSize}
        hidePagination
      />
    </ListWrapper>
  );
}
