import {
  Button,
  Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'Components';

export default ({ useCommonStyles, authenticity_token, auth_url, auth_client_email, new_confirmation_url, restore_password_url, errors }) => {
  const { t } = useTranslation();
  const classes = useCommonStyles();
  const [emial, setEmail] = React.useState(auth_client_email);
  return (
    <>
      <form className={classes.form} action={restore_password_url} method="post">
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticity_token}
        />
        <FormInput
          name="client[email]"
          label={t('Email')}
          autoComplete="email"
          value={emial}
          onChange={(target)=>{setEmail(target.value);}}
          required
          autoFocus
          validationMessages={errors?.email} />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Відправити iнструкцiю для змiни пароля
        </Button>
        <Link href={auth_url}>Вхiд</Link>
        <br />
        <Link href={new_confirmation_url}>Не отримали інструкцій щодо змiни пароля?</Link>
      </form>
    </>
  )
}