import { Link, Chip, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  chip: {
    marginLeft: '10px',
    background: '#f44336',
  },
}));

export const ClientCell = ({
  client_first_name,
  client_last_name,
  confirmed_client,
  client_id,
  clients_url,
  new_clients_path,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!client_id) {
    return '-';
  }

  const url = !!confirmed_client
    ? `${clients_url}/${client_id}`
    : `${new_clients_path}/${client_id}/edit`;

  return (
    <>
      <Link href={url} target="_blank">
        {client_first_name} {client_last_name}
      </Link>
      {!confirmed_client && (
        <Chip
          label={t('New client')}
          color="secondary"
          size="small"
          className={classes.chip}
        />
      )}
    </>
  );
};
