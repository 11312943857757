import { Button as MUIButton, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}))

export const Button = React.forwardRef(({loading, children, ...props}, ref) => {
  const classes = useStyles();
  return (
  <>
    <MUIButton variant="contained" {...props} disabled={props.disabled || loading}
      ref={ref}>
      {children}
      {!!loading && (
        <CircularProgress size={24} className={classes.progress} />
      )}
    </MUIButton>
  </>
)})
