export const BookingSettings = {
    en: {
        'Edit Booking Settings': 'Edit Booking Settings',
        'Name': 'Name',
        'Description': 'Description',
        'Client book time': 'Client book time',
        'Client book class': 'Client book class',
        'Client book spot event': 'Client book spot event',
        'Confirmation notification': 'Confirmation notification',
        'Confirmation notification for spot event': 'Confirmation notification for spot event',
        'Minimum time before booking event': 'Minimum time before booking event',
        'min': 'min'
    },
    uk: {
        'Edit Booking Settings': 'Редагування налаштувань',
        'Name': 'Назва',
        'Description': 'Опис',
        'Client book time': 'Забронювати тренування',
        'Client book class': 'Записатись на клас',
        'Client book spot event': 'Зареєструватися на подію',
        'Confirmation notification': 'Сповіщення про підтвердження',
        'Confirmation notification for spot event': 'Сповіщення про підтвердження події',
        'Minimum time before booking event': 'Мінімальний час для бронювання перед подією',
        'min': 'хв'
  },
};

export const BookingSettingsForm = {
    en: {
        ...BookingSettings.en,
        'Update Booking Settings': 'Update Booking Settings',
    },
    uk: {
        ...BookingSettings.uk,
        'Update Booking Settings': 'Оновити налаштування',
  },
}
