export const header = {
  en: {
    Clients: 'Clients',
    Certificates: 'Certificates',
    Services: 'Services',
    'Service Categories': 'Service categories',
    'General Statistics': 'General statistics',
    'Income Statistics': 'Income statistics',
    Payments: 'Payments',
    'Orders Info': 'Orders Info',
    'User Logs': 'User logs',
    Users: 'Users',
    Trainers: 'Trainers',
    'Service Usage History': 'Service usage history',
    Companies: 'Companies',
    'Log Out': 'Log out',
    'Returned Clients': 'Returned clients',
    'New Clients': 'New clients',
    'Month Income': 'Month income statistics',
    'Subscriptions Statistics': 'Subscriptions statistics',
    'Online Services': 'Online services',
    'Locations': 'Locations',
    'Cashiers': 'Cashiers',
    'CSV Files': 'CSV Files',
    'Services Rating': 'Services Rating',
    'Average Service Visits': 'Average Service Visits',
    'Active Purchased Services By Service': 'Active Purсhased Services By Service',
    'Expiring Purchased Services': 'Expiring Purchased Services',
    'Booking': 'Booking',
    'Bookings By Payment Dates': 'Bookings By Payment Dates',
    'Bookings By Booking Dates': 'Bookings By Booking Dates',
    'Booking Services': 'Booking Services',
    'Booking Providers': 'Booking Providers',
    'Provider Work Time': 'Provider Work Time',
    'Recurring Events': 'Recurring Events',
    'Calendar': 'Calendar',
    'All Bookings': 'All Bookings',
    'Coming soon': 'Coming soon ...',
    'Booking Settings': 'Booking Settings',
    'Email Templates': 'Email Templates',
    'Income Payment Settings': 'Income payment settings',
    'Payment Cards': 'Payment Cards',
    'Online Payment Keys': 'Online Payment Keys'
  },
  uk: {
    Clients: 'Клієнти',
    Certificates: 'Сертифікати',
    Services: 'Сервіси',
    'Service Categories': 'Категорії сервісів',
    'General Statistics': 'Загальна статистика',
    'Income Statistics': 'Статистика доходів',
    Payments: 'Платежі',
    'Orders Info': 'Інформація щодо замовлень',
    'User Logs': 'Журнал',
    Users: 'Користувачі',
    Trainers: 'Тренери',
    'Service Usage History': 'Історія використання сервісів',
    Companies: 'Компанії',
    'Log Out': 'Вийти',
    'Returned Clients': 'Клієнти, які повернулися',
    'New Clients': 'Нові клієнти',
    'Month Income': 'Статистика по місячному доходу',
    'Subscriptions Statistics': 'Статистика по підпискам',
    'Online Services': 'Онлайн сервіси',
    'Locations': 'Локації',
    'Cashiers': 'Касири',
    'CSV Files': 'CSV Файли',
    'Services Rating': 'Рейтинг сервісів',
    'Average Service Visits': 'Середня кількість відвідувань по сервісу',
    'Active Purchased Services By Service': 'Кількість активних куплених сервісів',
    'Expiring Purchased Services': 'Придбані сервіси, які закінчуються',
    'Booking': 'Букінг',
    'Bookings By Payment Dates': 'Сплачені бронювання по датах платежів',
    'Bookings By Booking Dates': 'Сплачені бронювання по датах бронювання',
    'Booking Services': 'Букінг сервіси',
    'Booking Providers': 'Букінг провайдери',
    'Provider Work Time': 'Робочий час провайдера',
    'Recurring Events': 'Повторювані події',
    'Email templates': 'Емейл шаблони',
    'Calendar': 'Календар',
    'All Bookings': 'Всі бронювання',
    'Coming soon': 'В розробці ...',
    'Booking Settings': 'Налаштування',
    'Income Payment Settings': 'Налаштування платежів',
    'Payment Cards': 'Платіжні картки',
    'Online Payment Keys': 'Платіжні ключі',
  },
};
