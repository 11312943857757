import { Typography, makeStyles, Box, Card } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  cardsWrapper: {
    paddingBottom: '12px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    marginBottom: '12px',
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
  },
  cardsWrapper__item: {
    width: '100%',
    padding: '0 8px',
    display: 'flex',
    boxSizing: 'border-box',
    marginBottom: '16px',

    [theme.breakpoints.down('sm')]: {
      width: '50%'
    },

    [theme.breakpoints.down('xs')]: {
      width: '50%'
    }
  },
  card: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px',
    minHeight: '75px',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      padding: '8px',
      minHeight: 'auto',
    }
  },
  cardPrimary: {
    background: 'rgba(107, 205, 193, 0.2)'
  },
  cardFocused: {
    borderColor: theme.palette.primary.main
  },
  titlePrimary: {
    color: theme.palette.primary.main
  },
  cardTitle: {
    marginBottom: '8px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      marginBottom: 0,
    }
  },
  cardLink: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  imageWrapper: {
    paddingLeft: '16px',
    flexShrink: 0,

    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  image: {
    display: 'block',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      width: '50px',
      height: '50px',
    }
  },
  cardValue: {
    [theme.breakpoints.down('md')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  }
}));

export const BookingLayout = ({ children, layout_data }) => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.cardsWrapper}>
        {layout_data.cards.map(({ name, value, is_selecting, url, photo_url }) => (
          <Box key={name} className={classes.cardsWrapper__item} >
            <Card variant="outlined" className={classnames(classes.card, {
              [classes.cardPrimary]: !!value,
              [classes.cardFocused]: is_selecting
            })}>
              {!!url && (
                <a href={url} className={classes.cardLink}></a>
              )}
              <Box>
                <Typography component="h3" className={classnames(classes.cardTitle, {
                  [classes.titlePrimary]: is_selecting
                })}>
                  {name}
                </Typography>
                <Typography variant="h5" component="p" className={classes.cardValue}>
                  {value}
                </Typography>
              </Box>
              {photo_url && (
                <Box className={classes.imageWrapper}>
                  <img src={photo_url} className={classes.image} />
                </Box>
              )}
            </Card>
          </Box>
        ))}
      </Box>
      {children}
    </div>
  )
}
