import { Card, Box, Typography, makeStyles } from '@material-ui/core';
import { Description } from './Description';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '16px'
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textDecoration: 'none',
    color: 'inherit'
  },
  timeInfo: {
    marginRight: '24px',
    fontSize: '22px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  providerInfo: {
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  serviceInfo: {
    display: 'block',
    fontSize: '22px',
    fontWeight: 700,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      textDecoration: 'none',
    },
  },
  availableClass: {
    display: 'block',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px'
  },
  disabledText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  imageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  image: {
    display: 'block',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  disabledImage: {
    opacity: '0.5'
  },
}));

export const ClassCardItem = ({
  booking_provider_name, booking_provider_photo_url,
  booking_service_name, booking_service_description,
  time_period, confirmation_url, current_members_count, members_limit,
  available_for_booking, openDescriptionDialog
}) => {
  const classes = useStyles();

  const typographyClassName = { [classes.disabledText]: !available_for_booking }

  const boxProps = React.useMemo(() => {
    if (current_members_count >= members_limit || !available_for_booking) {
      return {}
    }

    return {
      component: 'a',
      href: confirmation_url
    }
  }, [current_members_count, members_limit, available_for_booking])

  function showDescription() {
    openDescriptionDialog({ title: booking_service_name, content: booking_service_description })
  }

  return (
    <Card className={classes.card} elevation={8}>
      <Box className={classes.cardContent} {...boxProps}>
        <div>
          <Typography className={classnames(classes.serviceInfo, typographyClassName)}>
            {booking_service_name}
          </Typography>
          <Typography className={classnames(classes.timeInfo, typographyClassName)}>
            {time_period}
          </Typography>
          <Typography className={classnames(classes.providerInfo, typographyClassName)}>
            {booking_provider_name}
          </Typography>
          <Typography className={classes.availableClass}>
            {`Вільних місць ${members_limit - current_members_count} з ${members_limit}`}
          </Typography>
        </div>
        <Box className={classes.imageWrapper}>
          <img src={booking_provider_photo_url} className={classnames(classes.image,
            { [classes.disabledImage]: !available_for_booking })} />
        </Box>
      </Box>
      <Description text={booking_service_description}
        showDescription={showDescription} />
    </Card>
  )
}
