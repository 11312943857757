import { makeStyles, Tab, Tabs, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '24px',
  },
  tab: {
    maxWidth: '100%',
  }
}));

export const InternalNavigation = ({ navigation }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const activeTabIndex = navigation.findIndex(function (item) {
    return item.active;
  });

  return (
    <Tabs value={activeTabIndex} indicatorColor="primary" textColor="primary"
      className={classes.container} centered>
      {navigation.map(({ href, text }, index) => (
        <Tab className={classes.tab} key={index} label={t(text)} href={href}
          component={Link} />
      ))}
    </Tabs>
  );
};
