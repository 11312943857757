import {
  Dialog, DialogTitle, DialogContent, IconButton, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    width: '52px',
    height: '52px',
  },
  title: {
    '& p, h1, h2, h3, h4, h5, h6': {
      margin: 0
    }
  },
  content: {
    marginBottom: '16px'
  }
}));

const DEFAULT_STATE = {
  isOpen: false,
  title: '',
  content: '',
}

const DescriptionDialog = React.forwardRef((_, ref) => {
  const classes = useStyles();

  const [state, changeState] = React.useState(DEFAULT_STATE);

  const open = ({ title, content }) => {
    changeState({ isOpen: true, title, content })
  }

  function close() {
    changeState(DEFAULT_STATE)
  }

  React.useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Dialog open={state.isOpen} onClose={close} fullWidth>
      <DialogTitle className={classes.root}>
        <div className={classes.title} dangerouslySetInnerHTML={{ __html: state.title }}></div>
        <IconButton size="medium" className={classes.closeButton} onClick={close}>
          <i className="icon-cancel"></i>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.content} dangerouslySetInnerHTML={{ __html: state.content }}></div>
      </DialogContent>
    </Dialog>
  );
});

export default DescriptionDialog;
