export const MonthlyIncome = {
  en: {
    'Monthly Income': 'Monthly Income',
    'Select Month': 'Select Month',
    'Recalculate': 'Recalculate',
    'Till': 'Till',
    'Monthly Income Without Trainer Payouts': 'Monthly Income Without Trainer Payouts'
  },
  ru: {
    'Monthly Income': 'Месячный Доход',
    'Select Month': 'Выберите Месяц',
    'Recalculate': 'Пересчитать',
    'Till': 'До',
    'Monthly Income Without Trainer Payouts': 'Месячный Доход Без Выплат Тренерам'
  },
  uk: {
    'Monthly Income': 'Місячний Дохід',
    'Select Month': 'Виберіть Місяць',
    'Recalculate': 'Перерахувати',
    'Till': 'До',
    'Monthly Income Without Trainer Payouts': 'Місячний Дохід Без Виплат Тренерам'
  },
};
