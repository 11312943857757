import { useTranslation } from 'react-i18next';
import { makeStyles, Box, Grid, Chip } from '@material-ui/core';
import {
  FormWrapper, Form, FormInput, Select, FormControl
} from 'Components';
import { checkRequired, checkRequiredArray } from 'Helpers/validation';
import { useForm } from 'Hooks';
import { AJAX, findIndexInArrayBy, findInArrayBy } from 'Helpers';

const validators = {
  week_frequency: checkRequired('week_frequency'),
  week_days: checkRequiredArray('week_days'),
  booking_provider_id: checkRequired('booking_provider_id'),
  booking_service: checkRequired('booking_service_id'),
  members_limit: checkRequired('members_limit'),
  start_period_id: checkRequired('start_period_id'),
  end_period_id: checkRequired('end_period_id'),
};

const DEFAULT_RECURRING_EVENT = {
  week_frequency: '',
  members_limit: '',
  notes: '',
  booking_provider_id: '',
  booking_service_id: '',
  week_days: [],
  start_period_id: '',
  end_period_id: ''
}

const useStyles = makeStyles(() => ({
  weekDays: {
    display: 'flex',
  },
  weekDay: {
    marginRight: '6px'
  }
}));

const DEFAULT_TIME_PERIOD_OPTIONS = {
  from: [],
  till: []
}

export const BookingRecurringEventForm = ({
  api_url, back_url, booking_providers, booking_services_api_url,
  booking_time_ranges, work_days
}) => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const {
    item,
    changeValue,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    setItem,
  } = useForm(DEFAULT_RECURRING_EVENT, validators, api_url);

  const [bookingServices, setBookingServices] = React.useState([])

  React.useEffect(() => {
    getBookingServices()
  }, [item.booking_provider_id])

  //set default end period id by service duration logic
  React.useEffect(() => {
    if (item.start_period_id && item.booking_service_id && timePeriodOptions.from.length) {
      const selectedService = findInArrayBy(bookingServices, item.booking_service_id);
      const startPeriodIndex = findIndexInArrayBy(timePeriodOptions.from, item.start_period_id);
      const endPeriodIndexId = startPeriodIndex + selectedService.duration_units_count - 1
      changeValue('end_period_id', timePeriodOptions.till[endPeriodIndexId].id);
    }
  }, [item.booking_service_id, item.start_period_id, bookingServices])

  async function getBookingServices() {
    const res = await AJAX.get(booking_services_api_url, {
      body: {
        filters: {
          booking_providers: item.booking_provider_id,
          active: true,
          booking_service_type: 'for_multiple_bookings'
        }
      }
    })

    if (!res.data) {
      return
    }

    setBookingServices(res.data)
  }

  const handleChangeWeekFrequency = ({ target }) => {
    changeValue('week_frequency', target.value);
  }

  const handleChangeWeekDays = (dayIndex) => {
    return () => {
      const index = item.week_days.indexOf(dayIndex);

      if (index === -1) {
        return changeValue('week_days', [...item.week_days, dayIndex]);
      }

      changeValue('week_days', [
        ...item.week_days.slice(0, index),
        ...item.week_days.slice(index + 1),
      ]);
    }
  }

  const handleChangeProviderId = ({ target }) => {
    setItem({
      ...item,
      booking_provider_id: target.value,
      start_period_id: '',
      end_period_id: '',
    })
  }

  const handleChangeBookingServiceId = ({ target }) => {
    changeValue('booking_service_id', target.value);
  }

  const handleChangeMembersLimit = ({ target }) => {
    changeValue('members_limit', target.value);
  }

  const handleChangeStartPeriodId = ({ target }) => {
    changeValue('start_period_id', target.value);
  }

  const handleChangeEndPeriodId = ({ target }) => {
    changeValue('end_period_id', target.value);
  }

  const handleChangeNotes = ({ target }) => {
    changeValue('notes', target.value);
  }

  //TODO: should be moved to common according DRY
  const WEEK_FREQUENCY_OPTIONS = React.useMemo(function () {
    return [
      { id: 1, name: `${t('Every one')} ${t('week')}` },
      { id: 2, name: `${t('Every')} 2 ${t('weeks')}` },
      { id: 3, name: `${t('Every')} 3 ${t('weeks')}` },
      { id: 4, name: `${t('Every')} 4 ${t('weeks')}` }
    ]
  }, [i18n.language])

  const timePeriodOptions = React.useMemo(() => {
    return booking_time_ranges.reduce((acc, res) => {
      return {
        from: [...acc.from, { id: res.id, name: res.start_time }],
        till: [...acc.till, { id: res.id, name: res.end_time }]
      }
    }, DEFAULT_TIME_PERIOD_OPTIONS);

  }, [booking_time_ranges])

  const getSerializedItem = () => {
    const { end_period_id, start_period_id, ...itemValues } = item;

    const startPeriodIndex = findIndexInArrayBy(booking_time_ranges, start_period_id);
    const endPeriodIndex = findIndexInArrayBy(booking_time_ranges, end_period_id);
    const bookingPeriodsRange = booking_time_ranges.slice(startPeriodIndex, endPeriodIndex + 1);

    return {
      ...itemValues,
      booking_time_range_ids: bookingPeriodsRange.map(({ id }) => id),
    }
  }

  return (
    <Form
      name="booking_recurring_event"
      action={action}
      method={method}
      validators={validators}
      disabled={isItemInvalid}
      onSubmit={submit}
      getSerializedItem={getSerializedItem}
    >
      <FormWrapper backUrl={back_url} title={"Recurring Event"} item={item}>
        <Select value={item.week_frequency} options={WEEK_FREQUENCY_OPTIONS}
          validationMessages={validationMessagesToDisplay.week_frequency}
          label={t('Frequency')} onChange={handleChangeWeekFrequency} required />
        <FormControl fullWidth label={t('Week Days')}
          validationMessages={validationMessagesToDisplay.week_days} isRequired>
          <Box className={classes.weekDays}>
            {work_days.map(({ id, name }, index) => (
              <Box key={id} className={classes.weekDay}>
                <Chip label={name} onClick={handleChangeWeekDays(index)}
                  color={item.week_days.indexOf(index) !== -1 ? "primary" : 'default'}
                  disabled={!!item.id}
                />
              </Box>
            ))}
          </Box>
        </FormControl>
        <Select validationMessages={validationMessagesToDisplay.booking_provider_id}
          value={item.booking_provider_id} options={booking_providers}
          label={t('Provider')} onChange={handleChangeProviderId} required />
        <Select validationMessages={validationMessagesToDisplay.booking_service}
          value={item.booking_service_id} options={bookingServices}
          label={t('Booking Service')} onChange={handleChangeBookingServiceId}
          required />
        <FormInput validationMessages={validationMessagesToDisplay.members_limit}
          value={item.members_limit} label={t('Members limit')} type="number"
          onChange={handleChangeMembersLimit} required />

        <FormControl fullWidth label={t('Time Period')}
          validationMessages={validationMessagesToDisplay.booking_time_range_ids}>
          <Grid container spacing={2} >
            <Grid item xs={6} sm={6}>
              <Select label={t('From Time')} onChange={handleChangeStartPeriodId}
                validationMessages={validationMessagesToDisplay.start_period_id}
                value={item.start_period_id} options={timePeriodOptions.from}
                required />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Select label={t('Till Time')} onChange={handleChangeEndPeriodId}
                validationMessages={validationMessagesToDisplay.end_period_id}
                value={item.end_period_id} options={timePeriodOptions.till}
                required />
            </Grid>
          </Grid>
        </FormControl>
        <FormInput value={item.notes} label={t('Comment')}
          onChange={handleChangeNotes} multiline />
      </FormWrapper>
    </Form>
  );
};
