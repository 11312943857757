import {
  makeStyles, Box, IconButton, Grid, Chip, Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Form, FormInput, Checkbox, Button, FormControl, Select
} from 'Components';
import { AJAX, findIndexInArrayBy } from 'Helpers';
import { checkRequired } from 'Helpers/validation';
import { useForm, } from 'Hooks';
import confirmationStore from 'Helpers/confirmation';

const validators = {
  booking_provider_id: checkRequired('booking_provider_id'),
  start_period_id: checkRequired('start_period_id'),
  end_period_id: checkRequired('end_period_id'),
};

const useStyles = makeStyles(() => ({
  select: {
    '& p, & h1, & h2, & h3, & h4, & h5, & h6': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  form: {
    position: 'relative',
  },
  deleteBtn: {
    position: 'absolute',
    top: '-25px',
    right: 0,
    zIndex: 3,

    '& i': {
      color: 'rgb(214, 23, 9)'
    }
  },
  buttonActions: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));

const DEFAULT_TIME_PERIOD_OPTIONS = {
  from: [],
  till: []
}

export const NoteForm = ({
  note, providers, api_url, onSubmit, getProviderPeriods
}) => {
  const classes = useStyles();
  const { t, } = useTranslation();

  const {
    item, changeValue, method, isItemInvalid, validationMessagesToDisplay,
    submit, setServerErrors, setItem, action
  } = useForm(note, validators, api_url);

  const [timePeriods, changeTimePeriods] = React.useState([]);

  React.useEffect(() => {
    if (item.booking_provider_id) {
      const periods = getProviderPeriods(item.booking_provider_id)

      changeTimePeriods(periods)
    }
  }, [item.booking_provider_id]);

  const timePeriodOptions = React.useMemo(() => {
    return timePeriods.reduce((acc, res) => {
      return {
        from: [...acc.from, { id: res.id, name: res.start_time }],
        till: [...acc.till, { id: res.id, name: res.end_time }]
      }
    }, DEFAULT_TIME_PERIOD_OPTIONS);

  }, [timePeriods])

  const handleChangeProviderId = ({ target }) => {
    setItem({
      ...item,
      booking_provider_id: target.value,
      start_period_id: '',
      end_period_id: '',
    })
  }

  const handleChangeStartPeriodId = ({ target }) => {
    changeValue('start_period_id', target.value);
  }

  const handleChangeEndPeriodId = ({ target }) => {
    changeValue('end_period_id', target.value);
  }


  const handleChangeMessage = ({ target }) => {
    changeValue('message', target.value);
  }


  const bookingProviderOptions = React.useMemo(() => {
    return providers.map(({ name, id }) => {
      return {
        id,
        name: (<div className={classes.select} dangerouslySetInnerHTML={{ __html: name }}></div>)
      }
    })
  }, [])

  const getSerializedItem = () => {
    const { message, start_period_id, end_period_id, } = item;
    const startPeriodIndex = findIndexInArrayBy(timePeriods, start_period_id);
    const endPeriodIndex = findIndexInArrayBy(timePeriods, end_period_id);
    const bookingPeriodsRange = timePeriods.slice(startPeriodIndex, endPeriodIndex + 1);

    return {
      message,
      booking_period_ids: bookingPeriodsRange.map(({ id }) => id)
    }
  }

  const onSuccessSubmit = (res) => {
    if (res.flash_type == 'success') {
      onSubmit()
    }
  }

  function handleDelete() {
    confirmationStore.open(deleteNote);
  }

  async function deleteNote() {
    const res = await AJAX.delete(action)

    onSuccessSubmit(res)
  }

  return (

    <Form className={classes.form} name="booking_note" action={action} method={method} validators={validators}
      disabled={isItemInvalid} onSubmit={submit} getSerializedItem={getSerializedItem}
      additionalParams={{ booking_provider_id: item.booking_provider_id }}
      onError={setServerErrors} onSuccessSubmit={onSuccessSubmit}>
      {item.id && (
        <IconButton className={classes.deleteBtn} onClick={handleDelete} size="medium">
          <i className="icon-trash-empty"></i>
        </IconButton>
      )}
      <Grid container spacing={2} >
        {item.creator && (
          <Grid item xs={12} sm={12}>
            <Typography><strong>{t('Last Updated by')}:</strong> {item.creator}</Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Select validationMessages={validationMessagesToDisplay.booking_provider_id}
            value={item.booking_provider_id} options={bookingProviderOptions}
            label={t('Provider')} onChange={handleChangeProviderId} required />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInput value={item.message} label={t('Comment')}
            onChange={handleChangeMessage} multiline />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth label={t('Time Period')}
            validationMessages={validationMessagesToDisplay.booking_time_range || validationMessagesToDisplay.booking_periods}>
            <Grid container spacing={2} >
              <Grid item xs={6} sm={6}>
                {!!timePeriodOptions.from.length && (
                  <Select label={t('From Time')} onChange={handleChangeStartPeriodId}
                    validationMessages={validationMessagesToDisplay.start_period_id}
                    value={item.start_period_id} options={timePeriodOptions.from}
                    required />
                )}
              </Grid>
              <Grid item xs={6} sm={6}>
                {!!timePeriodOptions.till.length && (
                  <Select label={t('Till Time')} onChange={handleChangeEndPeriodId}
                    validationMessages={validationMessagesToDisplay.end_period_id}
                    value={item.end_period_id} options={timePeriodOptions.till}
                    required />
                )}
              </Grid>
            </Grid>
          </FormControl>
        </Grid>

      </Grid>
      <Box className={classes.buttonActions}>
        <Button variant="contained" color="primary" type="submit">
          {t(`${item.id ? 'Update' : 'Create'} Note`)}
        </Button>
      </Box>
    </Form>
  );
}