import { useTranslation, } from 'react-i18next';
import {
  ListWrapper, Table, Filters, Autocomplete, Select, DatePicker, MultiSelect,
} from 'Components';
import { useList } from 'Hooks';

function getOptionLabel(option) {
  return !!option ? `${option.first_name} ${option.last_name}` : '';
}

function clientsRequestBody(value) {
  return {
    page: 1,
    size_per_page: 10,
    sort_column: 'created_at',
    sort_direction: 'desc',
    search: {
      value
    }
  }
}

const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'desc'
}

export const UserActivityLogs = ({ fetch_url, filter, locations }) => {

  const { t } = useTranslation();
  const tableConfig = [
    {
      title: t('Time'),
      accessor: 'time',

    },
    {
      title: t('User'),
      accessor: 'user'
    },
    {
      title: t('Client'),
      accessor: 'client'
    },
    {
      title: t('Service'),
      accessor: 'service'
    },
    {
      title: t('Action Type'),
      accessor: 'action_type'
    },
    {
      title: t('Purchased Service Id'),
      accessor: 'purchased_service_id',
    },
    {
      title: t('Service Usage History Id'),
      accessor: 'service_usage_history_id',
    },
    {
      title: t('Payment Id'),
      accessor: 'payment_id',
    },
  ];

  const {
    pending, data, records_total, paginationParams, handleChangePagination, setFilters
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  function onSubmitFilters({ from, locations, till, ...filters }) {
    setFilters({
      ...filters,
      ...(!!locations.length && { locations }),
      ...(!!from || !!till) && {
        created_at: {
          ...(!!from && { from }),
          ...(!!till && { till })
        }
      }
    });
  }

  return (
    <ListWrapper title={`${t('User Activity Logs')} (${records_total})`}>
      <Filters
        onSubmit={onSubmitFilters}
        initialValues={{ locations: [] }}
      >
        <Autocomplete
          label={t('User')}
          options={filter.users}
          name="users"
          valueAsArray
        />
        <Autocomplete
          label={t('Client')}
          name="clients"
          getRequestBody={clientsRequestBody}
          valueAsArray searchUrl={filter.clients_search_url}
          getOptionLabel={getOptionLabel}
          fetchOnInit
          method='get'
          apiName="data"
        />
        <Select
          label={t('Action Type')}
          options={filter.action_types}
          name="action_types"
          valueAsArray
          emptyOption
        />
        <DatePicker
          label={t('From')}
          name="from"
        />
        <DatePicker
          label={t('Till')}
          name="till"
        />
        <MultiSelect
          options={locations}
          label={t('Locations')}
          name="locations"
        />
      </Filters>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
}
