import { Box } from '@material-ui/core';

export function TabPanel({ children, value, index, name = 'tabpanel', ...other }) {
  return (
    <div role={name} id={`${name}-${index}`} hidden={value !== index}
      aria-labelledby={`tab-${index}`} {...other}>
      {value === index && (
        <Box py={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
