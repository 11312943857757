import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PaymentType,
  InitializedPaymentType,
  PaymentTypesForm,
  Service,
} from './types';

const DEFAULT_TOTAL_PRICE = { price: 0, price_with_discount: 0 };

const DEPOSIT_PAYMENT_TYPE = 'deposit';
const TRANSFER_TO_CARD_PAYMENT_TYPE = 'transfer_to_card';
const CASH_PAYMENT_TYPE = 'cash';

const getInitializedPaymentTypes = (
  payment_types: PaymentType[]
): InitializedPaymentType[] => {
  return payment_types.map((payment_type) => {
    const isCashlessAgentsPresent = payment_type.cashless_agents?.length;
    const isTransferToCardPaymentType =
      payment_type.type === TRANSFER_TO_CARD_PAYMENT_TYPE;

    return {
      ...payment_type,
      amount: 0,
      isActive: false,
      isDisplayed: !isTransferToCardPaymentType,
      ...(!!isCashlessAgentsPresent && { cashless_agent_id: null }),
    };
  });
};

const getSumOfPaymentTypesAmount = (
  paymentTypes: InitializedPaymentType[]
): number => {
  return paymentTypes.reduce(
    (amount, paymentType) => amount + paymentType.amount,
    0
  );
};

const useTotalPrice = (services: Service[]) => {
  const totalPrice = useMemo(() => {
    if (!services.length) return DEFAULT_TOTAL_PRICE;
    return services.reduce((totalPrice, service) => {
      const { price, price_with_discount } = service;
      const currentPriceWithDiscount =
        price_with_discount != undefined ? price_with_discount : price;
      const serviceCountMultiplier = service.initial_count || 1;

      return {
        price: totalPrice.price + price * serviceCountMultiplier,
        price_with_discount:
          totalPrice.price_with_discount +
          currentPriceWithDiscount * serviceCountMultiplier,
      };
    }, DEFAULT_TOTAL_PRICE);
  }, [services]);

  const price = Math.min(totalPrice.price, totalPrice.price_with_discount);

  const isAllServicesWithZeroPrice = services.every(
    (service) => service.price === 0
  );

  return {
    totalPrice,
    price,
    isCartEmpty: services.length === 0,
    isAllServicesWithZeroPrice,
  };
};

export const usePaymentTypesState = (
  payment_types: PaymentType[],
  services: Service[]
): {
  paymentTypes: InitializedPaymentType[];
  setPaymentTypes: (paymentTypes: InitializedPaymentType[]) => void;
  onPaymentTypesSwitch: () => void;
  isPaymentTypesSwitchable: boolean;
  resetPaymentTypes: () => void;
  isPaymentTypeDisabled: boolean;
} => {
  const [paymentTypes, setPaymentTypes] = useState(
    getInitializedPaymentTypes(payment_types)
  );

  const { isCartEmpty, isAllServicesWithZeroPrice } = useTotalPrice(services);

  const isPaymentTypeDisabled = isCartEmpty || isAllServicesWithZeroPrice;

  const onPaymentTypesSwitch = () => {
    const switchedPaymentTypes = paymentTypes.map((paymentType) => {
      return {
        ...paymentType,
        isDisplayed: !paymentType.isDisplayed,
      };
    });

    setPaymentTypes(switchedPaymentTypes);
  };

  const resetPaymentTypes = () =>
    setPaymentTypes(getInitializedPaymentTypes(payment_types));

  const isPaymentTypesSwitchable = useMemo(
    () => !!services.length,
    // !!services.length &&
    // services.every(
    //   (service) => service.service_type === DEPOSIT_PAYMENT_TYPE
    // )
    [services]
  );

  const { onCashPaymentTypeSelect } = usePaymentTypesHandlers(
    paymentTypes,
    services,
    setPaymentTypes
  );

  useEffect(() => {
    const isTransferToCardDisplayed = paymentTypes.find(
      (paymentType) => paymentType.type === TRANSFER_TO_CARD_PAYMENT_TYPE
    )?.isDisplayed;

    if (
      isCartEmpty ||
      !isAllServicesWithZeroPrice ||
      (isTransferToCardDisplayed && !isPaymentTypesSwitchable)
    ) {
      resetPaymentTypes();
    }

    if (!isCartEmpty && isAllServicesWithZeroPrice) {
      onCashPaymentTypeSelect();
    }
  }, [services]);

  return {
    paymentTypes,
    setPaymentTypes,
    onPaymentTypesSwitch,
    isPaymentTypesSwitchable,
    isPaymentTypeDisabled,
    resetPaymentTypes,
  };
};

export const usePaymentTypesHandlers = (
  paymentTypes: InitializedPaymentType[],
  services: Service[],
  setPaymentTypes: (paymentTypes: InitializedPaymentType[]) => void
): {
  onPaymentAmountChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onPaymentCashlessAgentChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onPaymentIsActiveToggle: (event: ChangeEvent<HTMLInputElement>) => void;
  onCashPaymentTypeSelect: () => void;
} => {
  const { price } = useTotalPrice(services);
  const sumOfPaymentTypesAmount = useMemo(
    () => getSumOfPaymentTypesAmount(paymentTypes),
    [paymentTypes]
  );

  const onCashPaymentTypeSelect = () => {
    const updatedPaymentTypes = paymentTypes.map((paymentType) => {
      return {
        ...paymentType,
        amount: 0,
        isActive: paymentType.type === CASH_PAYMENT_TYPE,
      };
    });
    setPaymentTypes(updatedPaymentTypes);
  };

  const onPaymentAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (isNaN(+value)) return;
    const updatedPaymentTypes = paymentTypes.map((paymentType) => {
      const isCurrentPaymentType = paymentType.type === name;

      return {
        ...paymentType,
        amount: isCurrentPaymentType ? +value : paymentType.amount,
      };
    });
    setPaymentTypes(updatedPaymentTypes);
  };

  const onPaymentCashlessAgentChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const updatedPaymentTypes = paymentTypes.map((paymentType) => {
      const isCurrentPaymentType = paymentType.type === name;

      return {
        ...paymentType,
        cashless_agent_id: isCurrentPaymentType
          ? +value
          : paymentType.cashless_agent_id,
      };
    });

    setPaymentTypes(updatedPaymentTypes);
  };

  const onPaymentIsActiveToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const depositPaymentType = name === DEPOSIT_PAYMENT_TYPE;
    const isAnyDepositServicePresent = services.some(
      (service) => service.service_type === DEPOSIT_PAYMENT_TYPE
    );

    if (depositPaymentType && isAnyDepositServicePresent) return;

    const updatedPaymentTypes = paymentTypes.map((paymentType) => {
      const isCurrentPaymentType = paymentType.type === name;

      if (!isCurrentPaymentType) return paymentType;

      return {
        ...paymentType,
        amount: checked ? price - sumOfPaymentTypesAmount : 0,
        isActive: checked,
      };
    });

    setPaymentTypes(updatedPaymentTypes);
  };

  return {
    onPaymentAmountChange,
    onPaymentCashlessAgentChange,
    onPaymentIsActiveToggle,
    onCashPaymentTypeSelect,
  };
};

export const usePaymentTypesValidation = (
  paymentTypes: InitializedPaymentType[],
  clientDeposit: number,
  services: Service[]
): { payment_types: string | null } => {
  const { t } = useTranslation();

  const { price, isCartEmpty } = useTotalPrice(services);
  const sumOfPaymentTypesAmount = useMemo(
    () => getSumOfPaymentTypesAmount(paymentTypes),
    [paymentTypes]
  );

  return useMemo(() => {
    const isMoneyDistributedCorrectly =
      !isCartEmpty && price === sumOfPaymentTypesAmount
        ? null
        : t('Money is distributed incorrectly');

    const isAnyPaymentTypeChoosen = paymentTypes.some(
      (paymentType) => paymentType.isActive
    );

    const isPaymentTypeChoosen = isAnyPaymentTypeChoosen
      ? null
      : t('Select payment type');

    const depositPaymentType = paymentTypes.find(
      (paymentType) => paymentType.type === DEPOSIT_PAYMENT_TYPE
    );

    const isClientDepositEnough =
      !!depositPaymentType?.amount && clientDeposit < depositPaymentType.amount
        ? t('Not enough deposit')
        : null;

    const transferToCardPaymentType = paymentTypes.find(
      (paymentType) => paymentType.type === TRANSFER_TO_CARD_PAYMENT_TYPE
    );

    const isTransferToCardCashlessAgentSelect =
      !!transferToCardPaymentType?.amount &&
      !transferToCardPaymentType?.cashless_agent_id
        ? t('Select a payment card')
        : null;

    const paymentTypesErrors =
      isMoneyDistributedCorrectly ||
      isClientDepositEnough ||
      isTransferToCardCashlessAgentSelect ||
      isPaymentTypeChoosen;

    return {
      payment_types: paymentTypesErrors,
    };
  }, [
    paymentTypes,
    sumOfPaymentTypesAmount,
    price,
    clientDeposit,
    services,
    isCartEmpty,
  ]);
};

export const usePaymentTypesForm = (
  payment_types: PaymentType[],
  services: Service[],
  clientDeposit: number = 0
): PaymentTypesForm => {
  const { totalPrice, price } = useTotalPrice(services);

  const {
    paymentTypes,
    setPaymentTypes,
    onPaymentTypesSwitch,
    isPaymentTypesSwitchable,
    isPaymentTypeDisabled,
    resetPaymentTypes,
  } = usePaymentTypesState(payment_types, services);

  const sumOfPaymentTypesAmount = useMemo(
    () => getSumOfPaymentTypesAmount(paymentTypes),
    [paymentTypes]
  );

  const {
    onPaymentAmountChange,
    onPaymentCashlessAgentChange,
    onPaymentIsActiveToggle,
  } = usePaymentTypesHandlers(paymentTypes, services, setPaymentTypes);

  const paymentTypesValidationMessages = usePaymentTypesValidation(
    paymentTypes,
    clientDeposit,
    services
  );

  return {
    paymentTypes,
    totalPrice,
    price,
    sumOfPaymentTypesAmount,
    isPaymentTypesSwitchable,
    isPaymentTypeDisabled,
    onPaymentTypesSwitch,
    onPaymentAmountChange,
    onPaymentCashlessAgentChange,
    onPaymentIsActiveToggle,
    resetPaymentTypes,
    paymentTypesValidationMessages,
  };
};
