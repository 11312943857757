import { useTranslation } from 'react-i18next';
import { FormControl, } from '@material-ui/core';
import { FormWrapper, Form, FormInput, Checkbox, ImageUpload } from 'Components';
import { checkRequired } from 'Helpers/validation';
import { useForm } from 'Hooks';

const validators = {
  name: checkRequired('name'),
};

export const CompanyForm = ({ url, api_url, company, is_user_system_owner, clients_url }) => {
  const { t } = useTranslation();
  const {
    item,
    changeValue,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
  } = useForm(company, validators, api_url);

  function handleChangeName({ target }) {
    changeValue('name', target.value);
  }

  function handleChangeRequiredClientSignaturePhoto({ target }) {
    changeValue('required_client_signature_photo', target.checked);
  }

  function handleChangeClientInsuranceSignatureIncluded({ target }) {
    changeValue('client_insurance_signature_included', target.checked);
  }

  function handleChangeLogo(image) {
    changeValue('logo', image);
  }
  
  function handleChangeForceLocationSelection({ target }) {
    changeValue('force_location_selection', target.checked);
  }

  return (
    <Form
      name="company"
      action={action}
      method={method}
      validators={validators}
      disabled={isItemInvalid}
      onSubmit={submit}
      item={item}
      encType="multipart/form-data"
    >
      <FormWrapper backUrl={is_user_system_owner ? url : clients_url} title="Company" item={item}>
        <FormInput
          required
          validationMessages={validationMessagesToDisplay.name}
          value={item.name}
          label={t('Name')}
          name="company[name]"
          autoFocus
          onChange={handleChangeName}
          disabled={!is_user_system_owner}
        />
        <FormControl label={t('Logo')} validationMessages={validationMessagesToDisplay.logo}
          fullWidth>
          <ImageUpload name="company[logo]" value={item.logo} url={item.logo_url}
            label={t('Choose Image File')} onChange={handleChangeLogo} />
        </FormControl>
        <FormControl fullWidth>
          <Checkbox
            checked={item.required_client_signature_photo}
            onChange={handleChangeRequiredClientSignaturePhoto}
            label={t('Required client signature photo')}
          />
        </FormControl>
        <FormControl fullWidth>
          <Checkbox
            checked={item.client_insurance_signature_included}
            onChange={handleChangeClientInsuranceSignatureIncluded}
            label={t('Client insurance signature included')}
          />
        </FormControl>
        <FormControl fullWidth>
          <Checkbox
            checked={item.force_location_selection}
            onChange={handleChangeForceLocationSelection}
            label={t('Force location selection')}
          />
        </FormControl>
        <input name="company[required_client_signature_photo]"
          value={item.required_client_signature_photo ? 'true' : 'false'}
          hidden readOnly />
        <input name="company[client_insurance_signature_included]"
          value={item.client_insurance_signature_included ? 'true' : 'false'}
          hidden readOnly />
        <input name="company[force_location_selection]"
          value={item.force_location_selection ? 'true' : 'false'}
          hidden readOnly />
      </FormWrapper>
    </Form>
  );
};

CompanyForm.defaultProps = {
  company: {
    name: '',
    required_client_signature_photo: false,
    client_insurance_signature_included: false,
  },
};
