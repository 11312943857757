import { useEffect, useMemo } from 'react';

import { Container, makeStyles } from '@material-ui/core';

import { AJAX } from 'Helpers';

import ClientInfo from './Client';
import { CurrentPurchasedNotActiveServices } from './CurrentPurchasedNotActiveServices';
import CurrentPurchasedActiveServices from './CurrentPurchasedActiveServices';
import PreviouslyPurchasedActiveServices from './PreviouslyPurchasedActiveServices';
import CurrentActiveDiscounts from './CurrentActiveDiscounts';
import PreviouslyActiveDiscounts from './PreviouslyActiveDiscounts';
import ServiceUsageHistoryTable from './ServiceUsageHistoryTable';
import ClientOrderTable from './ClientOrderTable';
import ClientShowProvider from './providers/ClientShowProvider';
import QueryProvider from './providers/QueryProvider';

import { ClientShowProps } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

export const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'desc',
};

export const PAGINATION_PARAMS = {
  page: 1,
  size_per_page: 5,
};

export const ClientShow = (props: ClientShowProps) => {
  const classes = useStyles();
  const client_name = useMemo(
    () =>
      `${props.client.first_name} ${props.client.last_name} ${
        props.client.middle_name || ''
      }`,
    [props.client]
  );

  const ClientShowProviderData = {
    ...props,
    client_name,
  };

  return (
    <>
      <QueryProvider>
        <ClientShowProvider data={ClientShowProviderData}>
          <Container className={classes.root}>
            <ClientInfo />
          </Container>
          <CurrentPurchasedNotActiveServices />
          <CurrentPurchasedActiveServices />
          <CurrentActiveDiscounts />
          <PreviouslyPurchasedActiveServices />
          <PreviouslyActiveDiscounts />
          <ClientOrderTable />
          <ServiceUsageHistoryTable />
        </ClientShowProvider>
      </QueryProvider>
    </>
  );
};
