import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import {
  ListWrapper,
  Button,
  Chart,
  DatePicker,
} from 'Components';
import { AJAX } from 'Helpers';

const useStyles = makeStyles(() => ({
  submitRow: {
    marginBottom: '20px',
    marginTop: '20px',

    '& button': {
      display: 'block',
      marginLeft: 'auto'
    }
  },
}))

export const BookingAnalyticsChart = ({ fetch_url, title }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [filter, setFilter] = React.useState({
    from: moment().format(),
    till: moment().add(1, 'month').format(),
  });

  const [state, setState] = React.useState({
    pending: false,
    data: {},
  });

  function onStartPeriodChange({ target }) {
    setFilter({
      ...filter,
      from: target.value
    })
  }

  function onEndPeriodChange({ target }) {
    setFilter({
      ...filter,
      till: target.value
    })
  }

  async function fetchData() {
    const { from, till } = filter;

    setState({
      pending: true,
      data: {},
    });

    const res = await AJAX.get(fetch_url, {
      body: {
        sort_column: "created_at",
        sort_direction: "asc",
        filters: {
          ...((!!from || !!till) && {
            dates: {
              ...(!!from && { from }),
              ...(!!till && { till }),
            },
          }),
        },
      }
    })

    if (!res.data) {
      return setState({
        ...state,
        pending: false,
      })
    }

    const data = res.data.reduce((val, item) => {
      return {
        categories: [...val.categories, item.date],
        data: [...val.data, item.count_of_success_bookings]
      }
    }, { categories: [], data: [] });

    setState({
      data,
      pending: false,
    })
  }

  return (
    <ListWrapper title={`${t(title)}`}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={4}
        >
          <DatePicker
            label={t('From')}
            value={filter.from}
            name='from'
            onChange={onStartPeriodChange}
            required
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <DatePicker
            label={t('Till')}
            value={filter.till}
            onChange={onEndPeriodChange}
            required
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.submitRow}
      >
        <Button
          type="button"
          onClick={fetchData}
        >
          {t('Filter')}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
      >
        <Chart
          type="column"
          tooltipHeaderText={t('Date')}
          yText={t('Count')}
          tooltip={{
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            footerFormat: '</table>',
            useHTML: true
          }}
          yAxis={{
            stackLabels: {
              enabled: true,

            }
          }}
          plotOptions={{
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false
              }
            }
          }}
          pending={state.pending}
          {...state.data}
        />
      </Grid>
    </ListWrapper>
  );
}
