export const ServiceUsageHistoryAnalytics = {
  en: {
    'Service Id': 'Service Id',
    'Service': 'Service',
    'Trainer': 'Trainer',
    'Use Time': 'Use Time',
    'Client': 'Client',
  },
  ru: {
    'Service Id': 'Id Сервиса',
    'Service': 'Сервис',
    'Trainer': 'Тренер',
    'Use Time': 'Время Использования',
    'Client': 'Клиент',
  },
  uk: {
    'Service Id': 'Id Сервісу',
    'Service': 'Сервіс',
    'Trainer': 'Тренер',
    'Use Time': 'Дата Використання',
    'Client': 'Клієнт',
  },
};
