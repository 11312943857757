import { FormControl } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { FormWrapper, Form, FormInput, MultiSelect, Checkbox, } from 'Components';
import { checkRequired, } from 'Helpers/validation';
import { useForm, } from 'Hooks';


const validators = {
  first_name: checkRequired('first_name'),
  last_name: checkRequired('last_name'),
};

export const TrainerForm = ({ url, api_url, trainer, locations }) => {
  const { t, } = useTranslation();

  const { item, changeValue, action, method, isItemInvalid,
    validationMessagesToDisplay, submit, setServerErrors
  } = useForm(trainer, validators, api_url);


  function handleChangeFirstName({ target }) {
    changeValue('first_name', target.value);
  }

  function handleChangeLastName({ target }) {
    changeValue('last_name', target.value);
  }

  const handleChangeLocationIds = ({ target }) => {
    changeValue('location_ids', target.value);
  }

  const handleChangeActive = ({ target }) => {
    changeValue('active', target.checked);
  }


  const handleChangePhone = ({ target }) => {
    changeValue('phone', target.value);
  }

  return (
    <Form name="trainer" action={action} method={method} validators={validators}
      disabled={isItemInvalid} onSubmit={submit} item={item} onError={setServerErrors}>
      <FormWrapper title="Trainer" item={item} backUrl={url}>
        <FormInput name="trainer[first_name]" required
          validationMessages={validationMessagesToDisplay.first_name} value={item.first_name}
          label={t('First Name')} autoFocus onChange={handleChangeFirstName} />
        <FormInput name="trainer[last_name]" required
          validationMessages={validationMessagesToDisplay.last_name} value={item.last_name}
          label={t('Last Name')} onChange={handleChangeLastName} />

        <MultiSelect
          required
          options={locations}
          label={t('Locations')}
          value={item.location_ids}
          onChange={handleChangeLocationIds}
          validationMessages={validationMessagesToDisplay.locations}
        />
        <FormInput name="trainer[phone]"
          validationMessages={validationMessagesToDisplay.phone}
          value={item.phone || ''} label={t('Phone')} onChange={handleChangePhone} />
        <FormControl fullWidth>
          <Checkbox
            checked={item.active}
            onChange={handleChangeActive}
            label={t('Active')}
          />
        </FormControl>
      </FormWrapper>
    </Form>
  );
}

TrainerForm.defaultProps = {
  trainer: {
    first_name: '',
    last_name: '',
    location_ids: [],
    active: true,
    phone: '',
  },
};
