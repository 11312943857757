import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { AJAX } from 'Helpers';

import { Location, LocationPickerDialogProps } from './types';

const useStyles = makeStyles(() => ({
  listItem: {
    borderBottom: '1px solid black',
  },
  dialogTitle: {
    textAlign: 'center',
  },
}));

export const LocationPickerDialog = ({
  locations,
  apiUrl
}: LocationPickerDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onLocationSelect = async (id: number) => {
    const res = await AJAX.post(apiUrl, {
      body: {
        user_session: { current_location_id: id },
      },
    });
    if (res.status === 'ok') {
      window.location.reload();
    }
  };

  return (
    <Dialog
      open={true}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {t('Select Location')}
      </DialogTitle>
      <DialogContent>
        <List>
          {locations.map((location: Location) => (
            <ListItem
              button
              onClick={() => onLocationSelect(location.id)}
              key={location.id}
              className={classes.listItem}
            >
              <ListItemText primary={location.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};
