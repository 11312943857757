import i18next from 'i18next';
import { findInArrayBy } from 'Helpers';
import ukraineImg from './img/ukraine.png';
import unitedKingdom from './img/united-kingdom.png';
import './index.scss';

const locales = [
  {
    name: 'uk',
    image: ukraineImg,
  },
  {
    name: 'en',
    image: unitedKingdom,
  }
]

export const LocaleSwitcher = ({ url, className }) => {

  const [isOpened, setIsOpened] = React.useState(false)

  const currentLocale = findInArrayBy(locales, i18next.languages[0], 'name')

  function onChange(locale) {
    return function () {
      // AJAX.post(url, {
      //   body: {
      //     locale,
      //   },
      // });
      // i18next.changeLanguage(locale);
    };
  }

  function toogleOptions() {
    setIsOpened(!isOpened)
  }

  function hideOptions() {
    setIsOpened(false)
  }


  return (
    <div className={classnames('lang-picker', className)}>
      <button className="lang-picker-action lang-picker__action" onClick={toogleOptions}>
        <img src={currentLocale.image} className="lang-picker-action__image" />
      </button>
      {isOpened && (
        <Options currentLocale={currentLocale} locales={locales} hideOptions={hideOptions}
          onChange={onChange} />
      )}
    </div>
  );
};

function Options({ currentLocale, locales, hideOptions, onChange }) {

  React.useEffect(() => {
    window.addEventListener('click', hideOptions)

    return () => {
      window.removeEventListener('click', hideOptions)
    }
  }, [])

  function stopPropagation(e) {
    e.stopPropagation();
  }

  return (
    <ul className="lang-picker-options lang-picker__options" onClick={stopPropagation}>
      {locales.map(({ name, image }) => (
        <li key={name} className="lang-picker-options__item">
          <button className={classnames('lang-picker-options__btn', {
            'lang-picker-options__btn--active': name === currentLocale.name
          })} onClick={onChange(name)}>
            <img className="lang-picker-options__img" src={image} />
          </button>
        </li>
      ))}
    </ul>
  )
}