import { TextField, } from '@material-ui/core';

export const FormInput = React.forwardRef(({ validationMessages, ...props }, ref) => (
  <TextField fullWidth error={!!validationMessages} variant="outlined"
    helperText={validationMessages} ref={ref} {...props} />
)
);

FormInput.defaultProps = {
  margin: 'dense',
};
