export const Login = {
  en: {
    info: `Account has been found. 
      If you didn’t register yourself but have had training, 
      your profile created automatically. To set a password, click 
      "Forgot Password" and follow the instructions.`,
    registration: 'Register with another email address'
  },
  uk: {
    infoTitle: 'Є-є-є-є! Ми тебе знайшли :)',
    info: `Якщо ти не знаєш або забув пароль від кабінету {{companyName}}, 
      натисни <strong>"Встановити пароль"</strong> та чекай на лист у пошті`,
    registration: 'Зареєструватися з іншою електронною адресою',
    setupPassword: 'Встановити пароль',
    specifyAnotherEmail: 'Вказати іншу адресу'
  }

};
