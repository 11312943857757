export const Users = {
  en: {
    'Name': 'Name',
    'Role': 'Role',
    'Locked': 'Locked',
    'Create User': 'Create User',
  },
  ru: {
    'Name': 'Имя',
    'Role': 'Роль',
    'Locked': 'Заблокирован',
    'Create User': 'Создать Пользователя',
  },
  uk: {
    'Name': "Ім'я",
    'Role': 'Роль',
    'Locked': 'Заблокований',
    'Create User': 'Створити Користувача',
  },
}

export const UserForm = {
  en: {
    ...Users.en,
    'New User': 'New User',
    'Edit User': 'Edit User',
    'Update User': 'Update User',
    'Minimal Password length is 6': 'Minimal Password length is 6',
    'Passwords does not match': 'Passwords does not match',
    'Locations': 'Locations',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Email': 'Email',
    'Password': 'Password'
  },
  ru: {
    ...Users.ru,
    'New User': 'Новый Пользователь',
    'Edit User': 'Редактирование Пользователя',
    'Update User': 'Обновить Пользователя',
    'Minimal Password length is 6': 'Пароль должен состоять не менее из 6 символов',
    'Passwords does not match': 'Пароли не совпадают',
    'Locations': 'Локации',
    'First Name': 'Имя',
    'Last Name': 'Фамилия',
    'Email': 'Почта',
    'Password': 'Пароль'
  },
  uk: {
    ...Users.uk,
    'New User': 'Новий Користувач',
    'Edit User': 'Редагування Користувача',
    'Update User': 'Оновити Користувача',
    'Minimal Password length is 6': 'Пароль повинен бути не менше ніж 6 символів',
    'Passwords does not match': 'Паролі не співпадають',
    'Locations': 'Локації',
    'First Name': "Ім'я",
    'Last Name': 'Прізвище',
    'Email': 'Пошта',
    'Password': 'Пароль'
  },
};
