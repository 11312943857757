import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import { useForm } from 'Hooks';
import { checkRequired } from 'Helpers/validation';
import {
  ListWrapper,
  Button,
  AjaxAutocomplete,
  Form,
  FormInput,
  Select,
} from 'Components';

import {
  BookingServiceType,
  DefaultStateType,
  OptionLabelType,
  TestEmailFormProps,
} from './type';

const useStyles = makeStyles(() => ({
  testEmail: {
    margin: '12px',
    width: '70%',
    marginInline: 'auto',
  },
}));

function getOptionLabel(option: OptionLabelType) {
  return option.name || '';
}

const DEFAULT_STATE: DefaultStateType = {
  email: '',
  first_name: '',
  booking_service: null,
  template_type: '',
  multiple_bookings: false,
};

const requestBody = (value: string) => ({
  size_per_page: 100,
  sort_column: 'created_at',
  sort_direction: 'desc',
  search: {
    value,
  },
});

const MULTIPLE_BOOKINGS_SERVICE_TYPE = 'for_multiple_bookings';

const validators = {
  template_type: checkRequired('template_type'),
  email: checkRequired('email'),
  first_name: checkRequired('first_name')
};

export const TestEmailForm = ({
  send_test_email_api_url,
  booking_services_search_url,
}: TestEmailFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    item,
    changeValue,
    submit,
    setServerErrors,
    validationMessagesToDisplay,
    isItemInvalid,
    action,
    method,
  } = useForm(DEFAULT_STATE, validators, send_test_email_api_url);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const onRequestStart = () => setIsFormSubmitting(true);
  const onRequestEnd = () => setIsFormSubmitting(false);

  const emailTemplateTypes = {
    for_single_booking: [{ id: 'book_time', name: t('Template for training') }],
    for_multiple_bookings: [
      { id: 'book_class', name: t('Template for class') },
      { id: 'book_spot', name: t('Template for spot') },
    ],
  };

  const onBookingServiceChange = (
    _: any,
    booking_service: BookingServiceType
  ) => {
    changeValue('booking_service', booking_service);
  };

  const onFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeValue('first_name', e.target.value);
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeValue('email', e.target.value);
  };

  const onTemplateTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeValue('template_type', e.target.value);
  };

  const templateTypeOptions = useMemo(() => {
    if (!item.booking_service) return [];

    return emailTemplateTypes[
      item.booking_service.service_type as keyof typeof emailTemplateTypes
    ];
  }, [item.booking_service]);

  const getSerializedItem = () => {
    return {
      ...item,
      multiple_bookings:
        item.booking_service?.service_type === MULTIPLE_BOOKINGS_SERVICE_TYPE,
    };
  };

  return (
    <Form
      name="booking_test_email"
      action={action}
      method={method}
      getSerializedItem={getSerializedItem}
      onRequestStart={onRequestStart}
      onRequestEnd={onRequestEnd}
      onSubmit={submit}
      onError={setServerErrors}
      className={classes.testEmail}
    >
      <ListWrapper
        title={t('Test email form')}
        headerAddon={
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isItemInvalid || isFormSubmitting}
          >
            {t('Send email')}
          </Button>
        }
      >
        <AjaxAutocomplete
          required
          label={t('Booking services')}
          value={item.booking_service || {}}
          searchUrl={booking_services_search_url}
          getOptionLabel={getOptionLabel}
          getRequestBody={requestBody}
          onChange={onBookingServiceChange}
          method="get"
        />
        {item.booking_service && (
          <>
            <Select
              label={t('Template type')}
              onChange={onTemplateTypeChange}
              value={item.template_type}
              options={templateTypeOptions}
              validationMessages={validationMessagesToDisplay.template_type}
              required
            />
            <FormInput
              value={item.first_name}
              label={t('First name')}
              onChange={onFirstNameChange}
              required
              validationMessages={validationMessagesToDisplay.first_name}
            />
            <FormInput
              value={item.email}
              label={t('Email')}
              onChange={onEmailChange}
              required
              validationMessages={validationMessagesToDisplay.email}
            />
          </>
        )}
      </ListWrapper>
    </Form>
  );
};
