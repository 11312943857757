import { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Box,
} from '@material-ui/core';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, Preloader } from 'Components';
import { AJAX } from 'Helpers';

import { Order, OrderReceiptDialogProps } from './type';

const useStyles = makeStyles((theme) => ({
  sendBtn: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  preloader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
  },
}));

const DEFAULT_STATE = {
  isOpened: false,
  receipt: '',
  order: {
    send_receipt_url: '',
  },
  isLoading: false,
};

const OrderReceiptDialog = forwardRef(({abilityToSendEmail}: OrderReceiptDialogProps, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, setState] = useState(DEFAULT_STATE);

  const open = async (order: Order) => {
    setState({
      ...state,
      isOpened: true,
      order,
      isLoading: true,
    });

    const res = await fetch(order.receipt_url, {
      headers: { Accept: 'text/html' },
    });

    const htmlStr = await res.text();

    setState((prevState) => ({
      ...prevState,
      receipt: htmlStr,
      isLoading: false,
    }));
  };

  const close = () => {
    setState(DEFAULT_STATE);
  };

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  const onOrderReceiptSendToEmail = () => {
    setState({ ...state, isLoading: true });
    AJAX.post(state.order.send_receipt_url);
    setState({ ...state, isLoading: false });
  };

  return (
    <>
      <Dialog
        open={state.isOpened}
        onClose={close}
        fullWidth
      >
        <DialogContent>
          <div
            className="receipt-wrapper"
            dangerouslySetInnerHTML={{ __html: state.receipt }}
          ></div>
          {state.isLoading && (
            <Box className={cn('hidden-on-print', classes.preloader)}>
              <Preloader />
            </Box>
          )}
        </DialogContent>
        <DialogActions className="hidden-on-print">
          <Button onClick={close}>{t('Close')}</Button>
          {abilityToSendEmail && <Button
            className={classes.sendBtn}
            onClick={onOrderReceiptSendToEmail}
            disabled={state.isLoading}
          >
            {t('Send to client’s email')}
          </Button>}
          <Button
            color="primary"
            onClick={() => window.print()}
            disabled={state.isLoading}
          >
            {t('Print')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default OrderReceiptDialog;