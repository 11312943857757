import { toQueryString } from 'Helpers';
import notificationsStore from 'Helpers/notifications';

const isNoContent = ({ status }) => {
  return status === 204
}

export function getMetaTagValue(name) {
  const selector = document.head.querySelector(`meta[name="${name}"]`);
  if (!selector) {
    return null;
  }
  return selector.getAttribute('content');
}

export function getToken() {
  const key = getMetaTagValue('csrf-param');
  const value = getMetaTagValue('csrf-token');

  return [key, value];
}

export class AJAX {
  static defaultParams = {
    headers: {
      'Accept': 'application/json',
      ['X-CSRF-Token']: getToken()[1],
    },
    method: 'GET',
    cache: 'default',
  }

  static getUrl(url, searchParams) {
    let resUrl = url;
    if (!!searchParams) {
      const paramsStr = toQueryString(searchParams);
      if (!!paramsStr.length) {
        resUrl = `${resUrl}?${paramsStr}`;
      }
    }
    return resUrl;
  }

  static sendRequest(url, { searchParams, notifyOnFailedResponse, notifyOnSuccesResponse, ...params }) {
    let headers = {
      ...AJAX.defaultParams.headers,
      ...params.headers,
    };
    let controller = new AbortController();
    let signal = controller.signal;
    if (!!params.body && !(params.body instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }
    const request = new Promise((resolve, reject) => {
      fetch(AJAX.getUrl(url, searchParams), { ...AJAX.defaultParams, ...params, signal, headers })
        .then((res) => {
          if (isNoContent(res)) {
            return res
          }
          if (res.status === 401) {
            return window.location.href = '/';
          }
          if (res.ok) {
            return res.json();
          }
          if (!!notificationsStore.add) {
            notificationsStore.add({ type: 'error', text: res.status });
          }
          resolve({});
        })
        .then(res => {
          if (res.redirect) {
            location.href = res.redirect;
          }
          if (!!res.flash_type && !!res.flash_message && !!notificationsStore.add) {
            notificationsStore.add({ type: res.flash_type, text: res.flash_message });
          }
          resolve(res)
        })
        .catch(function (err) {
          console.log('There has been a problem with your fetch operation: ' + err.message);
        });
    });
    request.abort = controller.abort.bind(controller);
    return request;
  }

  static get(url, additionalParams = {}) {
    const { body, ...params } = additionalParams;
    return AJAX.sendRequest(url, { ...params, searchParams: body, method: 'GET' });
  }

  static post(url, params = {}) {
    return AJAX.sendRequest(url, {
      ...params,
      body: params.body instanceof FormData ? params.body : JSON.stringify(params.body),
      method: 'POST'
    }
    );
  }

  static put(url, params = {}) {
    return AJAX.sendRequest(url, {
      ...params,
      body: params.body instanceof FormData ? params.body : JSON.stringify(params.body),
      method: 'PUT'
    });
  }

  static patch(url, params = {}) {
    return AJAX.sendRequest(url, {
      ...params,
      body: params.body instanceof FormData ? params.body : JSON.stringify(params.body),
      method: 'PATCH'
    });
  }

  static delete(url, params) {
    return AJAX.sendRequest(url, { ...params, method: 'DELETE' });
  }
}

export function wrapPromise(promise) {
  let status = 'pending'
  let response

  const suspender = promise.then(
    (res) => {
      status = 'success'
      response = res
    },
    (err) => {
      status = 'error'
      response = err
    },
  );
  const read = () => {
    switch (status) {
      case 'pending':
        throw suspender
      case 'error':
        throw response
      default:
        return response
    }
  }

  return { read }
}
