import { Fragment } from 'react';
import { useTranslation, } from 'react-i18next';
import { ListWrapper, Table, ListActions, Button, } from 'Components';
import { useList, } from 'Hooks';
import { deleteItem, toMoneyFormat, findInArrayBy } from 'Helpers';
import { TIME_POINTS } from 'Constants';

export const DEFAULT_SORT_PARAMS = {
  sort_column: 'order_position',
  sort_direction: 'asc'
}

export const BookingServices = ({ url, fetch_url, new_item_url, services_url }) => {
  const { t } = useTranslation();

  const {
    pending, data, records_total, paginationParams, handleChangePagination, updateList,
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  function onDelete(res) {
    if (res.flash_type == "success") {
      updateList()
    }
  }

  const tableConfig = [
    {
      title: t('Position'),
      accessor: 'order_position',
      sortable: true
    },
    {
      title: t('Name'),
      accessor: 'name',
      sortable: true,
      cellComponent: (item) => {
        return (<div dangerouslySetInnerHTML={{ __html: item.name }}></div>)
      }
    },
    {
      title: t('Services'),
      accessor: 'services',
      cellComponent: (item) => {
        return item.services.map((service, index) => {
          return (
            <Fragment key={service.id}>
              <a target="_blank" href={`${services_url}/${service.id}/edit`}>
                {service.service_name} ({service.initial_count})
              </a>
              {index !== item.services.length - 1 && ', '}
            </Fragment>
          )
        });
      }
    },
    {
      title: t('Dur'),
      accessor: 'duration_units_count',
      cellComponent: ({ duration_units_count }) => {
        const currentPoint = findInArrayBy(TIME_POINTS, duration_units_count, 'point')

        return currentPoint?.value
      }
    },
    {
      title: t('Price'),
      accessor: 'price',
      cellComponent: item => toMoneyFormat(item.price)
    },

    {
      title: t('Active'),
      accessor: 'active',
      cellComponent: item => item.active ? '+' : '-',
    },
    {
      title: t('Online Payment Key'),
      accessor: 'online_payment_key',
      sortable: true,
    },
    {
      title: t('Color'),
      accessor: 'color',
      cellComponent: item => (
        <input type="color" value={item.color} disabled/>
      )
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }) => (
        <ListActions editUrl={`${url}/${id}/edit`} onDelete={deleteItem(fetch_url, { id }, onDelete)} />
      )
    }
  ];

  return (
    <ListWrapper title={`${t('Booking Services')} (${data.length})`} headerAddon={(
      <div>
        <Button href={new_item_url} variant="contained" color="primary">
          {t('Create Booking Service')}
        </Button>
      </div>
    )}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
}
