import { useTranslation } from 'react-i18next';
import { ListWrapper, Table, ListActions, Button, } from 'Components';
import { useList, } from 'Hooks';
import { DEFAULT_SORT_PARAMS } from 'Constants';

export const Cashiers = ({ url, new_item_url, fetch_url, }) => {
  const { t } = useTranslation();
  const {
    pending, data, page, items_per_page, total,
    setPage, setPageSize
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  const tableConfig = [
    {
      title: t('Name'),
      accessor: 'name',
    },
    {
      title: t('Login'),
      accessor: 'login',
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }) => (
        <>
          <ListActions editUrl={`${url}/${id}/edit`} />
        </>
      )
    }
  ];

  return (
    <ListWrapper title={`${t('Cashiers')}`} headerAddon={(
      <div>
        <Button href={new_item_url} variant="contained" color="primary">
          {t('Create Cashier')}
        </Button>
      </div>
    )}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        page={page}
        items_per_page={items_per_page}
        total={total}
        setPage={setPage}
        setPageSize={setPageSize}
        hidePagination
      />
    </ListWrapper>
  );
}
