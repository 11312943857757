
export const ReturnedClientsAnalytics = {
  en: {
    'Returned Clients Analytics': 'Returned Clients Analytics',
    'Locations': 'Locations',
    'First Period': 'First Period',
    'Second Period': 'Second Period',
    'Till': 'Till(Including)',
    'Trainer': 'Trainer',
    'Services': 'Services',
    'firstUsagesText': 'Number of unique clients in first period',
    'secondUsagesText': 'Number of intersections for two periods',
    'percentageText': 'Percentage of returned clients',
    'averageMoneySpent': 'he average amount of spent money by client',
    'averageMoneySpentExpanded': 'The average amount of spent money on services that choose in second period filter. Payments are created in time interval from first visit(not included) till end of the second period',
    'Save Filter': 'Save Filter',
    'Show Filter Settings': 'Show Filter Settings',
    'Create Filter': 'Create Filter',
    'Name': 'Name',
    'Filter Settings': 'Filter Settings',
    'Apply': 'Apply',
    'Active Services Only': 'Only Active Services In Filters',
    'filterInfoRow1': "Only services with type 'unlimited' and 'limited' would be processed.",
    'filterInfoRow2': "If you don’t choose trainers in filters it means that visits сan be to any trainer or visits without trainer.",
    'filterInfoRow3': "If you don’t choose services in filters it means that visits can be with any service.",
    'filterInfoRow4': "If you don’t choose locations in filter it means that visits can be to any location.",
    'filterInfoRow5': "If you choose several trainers in filter in first period it means that first visits will be divided by trainers. Several trainers in filter in second period means that you expect return of the visitor to any of chosen trainers.",
  },
  ru: {
    'Locations': 'Локации',
    'Returned Clients Analytics': 'Аналитика Вернувшихся клиентов',
    'First Period': 'Первый Период',
    'Second Period': 'Второй Период',
    'Till': 'До(Включая)',
    'Trainer': 'Инструктор',
    'Services': 'Сервисы',
    'firstUsagesText': 'Количество уникальных посетителей в первый период',
    'secondUsagesText': 'Количество пересечений в двух периодах',
    'percentageText': 'Процентная доля вернувшихся клиентов',
    'averageMoneySpent': 'Средняя сумма денег, потраченная клиентами',
    'averageMoneySpentExpanded': 'Средняя сумма потраченных денег на услуги которые выбранные в фильтре во втором периоде. Все оплаты в интервале времени с первого посещения клиента(не включено) до конца второго периода',
    'Save Filter': 'Сохранить Фильтр',
    'Show Filter Settings': 'Показать настройки фильтров',
    'Create Filter': 'Создать фильтр',
    'Name': 'Название',
    'Filter Settings': 'Настройки Фильтров',
    'Apply': 'Применить',
    'Active Services Only': 'Только активные сервисы в фильтрах',
    'filterInfoRow1': "Будут обрабатываться только сервисы с 'unlimited' и 'limited' типом.",
    'filterInfoRow2': "Если вы не выбираете тренеров в фильтрах - это значит, что учитываются посещения к любому тренеру или посещения без тренера.",
    'filterInfoRow3': "Если вы не выбираете сервисы в фильтрах - это значит, что учитываются посещения с любым сервисом.",
    'filterInfoRow4': "Если вы не выбираете локации в фильтрах - это значит, что учитываются посещения в любой локации.",
    'filterInfoRow5': "Если вы выберете несколько тренеров в фильтре в первом периоде - это значит, что первые посещения клиентов будут разделены по тренерам. Несколько тренеров в фильтре во втором периоде означает, что вы ожидаете возвращения посетителя к любому из выбранных тренеров.",
  },
  uk: {
    'Locations': 'Локації',
    'Returned Clients Analytics': 'Аналітика клієнтів, які повернулися',
    'First Period': 'Перший Період',
    'Second Period': 'Другий Період',
    'Till': 'До(Включаючи)',
    'Trainer': 'Тренер',
    'Services': 'Сервіси',
    'firstUsagesText': 'Кількість унікальних відвідувачів в перший період',
    'secondUsagesText': 'Кількість перетинів в двох періодах',
    'percentageText': 'Відсоткова частка клієнтів, які повернулись',
    'averageMoneySpent': 'Середня сума грошей, витрачена клієнтами',
    'averageMoneySpentExpanded': 'Середня сума витрачених коштів на послуги, які вибрані в фільтрі в другому періоді. Всі оплати проведені в інтервалі часу з першого відвідування клієнта(не включно) до кінця другого періоду',
    'Save Filter': 'Зберегти Фільтр',
    'Show Filter Settings': 'Показати налаштування фільтрів',
    'Create Filter': 'Створити фільтр',
    'Name': 'Назва',
    'Filter Settings': 'Налаштування Фільтрів',
    'Apply': 'Застосувати',
    'Active Services Only': 'Тільки активні сервіси в фільтрах',
    'filterInfoRow1': "Оброблятимуться лише сервіси з 'unlimited' і 'limited' типом.",
    'filterInfoRow2': "Якщо ви не вибираєте тренерів у фільтрах - це означає, що враховуються відвідування до будь-якого тренера або відвідування без тренера.",
    'filterInfoRow3': "Якщо ви не вибираєте сервіси у фільтрах - це означає, що враховуються відвідування з будь-яким сервісом.",
    'filterInfoRow4': "Якщо ви не вибираєте локації у фільтрах - це означає, що враховуються відвідування у будь-якій локації.",
    'filterInfoRow5': "Якщо ви виберете декілька тренерів у фільтрі в першому періоді - це означає, що перші відвідування клієнтів будуть поділені за тренерами. Декілька тренерів у фільтрі в другому періоді означає, що ви очікуєте повернення відвідувача до будь-якого з обраних тренерів.",
  },
};

