import { useMemo } from 'react';
import { useTranslation, } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { v1 as uuidv1 } from 'uuid';
import { ListWrapper, Button, Table } from 'Components';

const useStyles = makeStyles(() => ({
  itemCol: {
    minWidth: '250px'
  }
}));

export const BookingSettings = ({edit_url, booking_setting}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    client_book_time_description,
    client_book_class_description,
    client_book_spot_event_description,
    confirmation_notification,
    min_time_before_booking_event,
    spot_event_confirmation_notification
  } = booking_setting

const tableConfig = [
    {
    title: t('Name'),
    accessor: 'name',
    cellComponent: ({name}) => {
        return (
          <div className={classes.itemCol}>{name}</div>
        )
      }
    },
    {
      title: t('Description'),
      accessor: 'description',
      cellComponent: ({description}) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
        )
      }
    }
  ]

  const rows = useMemo(() => {
    return (
      [
        {
          id: uuidv1(),
          name: `${t('Client book time')}`,
          description: client_book_time_description
        },
        {
          id: uuidv1(),
          name: `${t('Client book class')}`,
          description: client_book_class_description
        },
        {
          id: uuidv1(),
          name: `${t('Client book spot event')}`,
          description: client_book_spot_event_description
        },
        {
          id: uuidv1(),
          name: `${t('Confirmation notification')}`,
          description: confirmation_notification
        },
        {
          id: uuidv1(),
          name: `${t('Confirmation notification for spot event')}`,
          description: spot_event_confirmation_notification
        },
        {
          id: uuidv1(),
          name: `${t('Minimum time before booking event')}`,
          description: `${min_time_before_booking_event} ${t('min')}`
        },
      ]
    );
  }, [
    client_book_time_description,
    client_book_class_description,
    client_book_spot_event_description,
    confirmation_notification,
    min_time_before_booking_event,
    spot_event_confirmation_notification,
    t
  ]) 

  return (
    <ListWrapper
      title={`${t('Booking Settings')}`}
      headerAddon={(
        <div>
          <Button
            href={edit_url}
            variant="contained"
            color="primary"
          >
            {t('Edit Booking Settings')}
          </Button>
        </div>
      )}>
      <Table
        config={tableConfig}
        data={rows}
        pagination={false}
      />
    </ListWrapper>
  );
}
