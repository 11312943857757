import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListWrapper, Table, ListActions, Button } from 'Components';
import { useList } from 'Hooks';
import { AJAX } from 'Helpers';

import { TestEmailForm } from './TestEmailForm';

import {
  BookingEmailTemplateType,
  BookingEmailTemplatesPropsType,
  TemplateTypes,
} from './type';

export const DEFAULT_SORT_PARAMS = {
  sort_column: 'template_type',
  sort_direction: 'asc',
};

const TEMPLATE_TYPES: TemplateTypes = {
  book_time: 'Template for training',
  book_class: 'Template for class',
  book_spot: 'Template for spot',
};

export const BookingEmailTemplates = ({
  fetch_url,
  url,
  new_templates_url,
  booking_services_search_url,
  send_test_email_api_url,
}: BookingEmailTemplatesPropsType) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { pending, data } = useList({
    fetchUrl: fetch_url,
    sorting: DEFAULT_SORT_PARAMS,
  });

  const tableConfig = [
    {
      title: t('Name'),
      accessor: 'template_type',
      cellComponent: (item: BookingEmailTemplateType) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                TEMPLATE_TYPES[item.template_type as keyof TemplateTypes]
              ),
            }}
          ></div>
        );
      },
    },
    {
      title: t('Active'),
      accessor: 'active',
      cellComponent: (item: BookingEmailTemplateType) =>
        item.active ? '+' : '-',
    },
    {
      title: t('Actions'),
      cellComponent: (item: BookingEmailTemplateType) => (
        <ListActions editUrl={`${url}/${item.id}/edit`} />
      ),
    },
  ];

  const onTemplatesCreate = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setLoading(true);
    const res = await AJAX.post(new_templates_url);
    if (res.status || res.flash_type) setLoading(false);
  };

  const createNewTemplatesButton = !data.length ? (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={onTemplatesCreate}
        disabled={loading || pending}
      >
        {t('Create Booking Email Templates')}
      </Button>
    </div>
  ) : null;

  return (
    <div>
      <ListWrapper
        title={`${t('Email templates')}`}
        headerAddon={createNewTemplatesButton}
      >
        <Table
          config={tableConfig}
          data={data}
          pending={pending}
          pagination={false}
        />
        {!!data.length && (
          <TestEmailForm
            send_test_email_api_url={send_test_email_api_url}
            booking_services_search_url={booking_services_search_url}
          />
        )}
      </ListWrapper>
    </div>
  );
};
