/**
 * Custom hook for make some actions for all updates except mount
 * @param {func} effect callback, which will run on any update except mount
 * @param {array} deps array off dependencies, which will be watched for update
 */
export function useEffectWithSkip(effect, deps, skipSteps = 1) {
  const isFirstRun = React.useRef(skipSteps);
  function reset(skipSteps = 1) {
    isFirstRun.current = skipSteps;
  }
  React.useEffect(() => {
    if (!!isFirstRun.current) {
      isFirstRun.current = isFirstRun.current - 1;
      return;
    }
    effect();
  }, deps);

  return reset;
}
