import { makeStyles } from '@material-ui/core';

import { toMoneyFormat } from 'Helpers';

import { PriceProps } from './types';

const useStyles = makeStyles((theme) => ({
  price: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through',
    fontSize: '0.7rem',
  },
  priceWithDiscount: {
    color: theme.palette.success.main,
  },
}));

const Price = (props: PriceProps) => {
  const { price, price_with_discount } = props;

  const classes = useStyles();

  const isDiscountExists = price !== price_with_discount;

  return (
    <div>
      <div className={isDiscountExists ? classes.price : ''}>
        {toMoneyFormat(price)}
      </div>
      {isDiscountExists && (
        <div className={classes.priceWithDiscount}>
          {toMoneyFormat(price_with_discount)}
        </div>
      )}
    </div>
  );
};

export default Price;