import {
  FormControl as MFormControl,
  makeStyles,
  Box,
  IconButton,
  Grid,
  FormHelperText,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Form,
  FormInput,
  Checkbox,
  Button,
  FormControl,
  Select,
  AjaxAutocomplete,
  DatePicker,
} from 'Components';
import { checkRequired } from 'Helpers/validation';
import { AJAX, findIndexInArrayBy, findInArrayBy } from 'Helpers';
import { useForm } from 'Hooks';
import confirmationStore from 'Helpers/confirmation';
import { CustomFieldForm } from './custom_fields/CustomFieldForm';
import { CustomFieldList } from './custom_fields/CustomFieldList';
import { useEffect, useMemo, useState } from 'react';

const validators = {
  booking_provider_id: checkRequired('booking_provider_id'),
  start_period_id: checkRequired('start_period_id'),
  end_period_id: checkRequired('end_period_id'),
};

const useStyles = makeStyles(() => ({
  select: {
    '& p, & h1, & h2, & h3, & h4, & h5, & h6': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  weekDays: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonActions: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  commonErrors: {
    textAlign: 'right',
    color: '#f44336',
  },
  form: {
    position: 'relative',
  },
  formLabel: {
    fontSize: '0.75em',
  },
  settingsWrapper: {
    position: 'absolute',
    top: '-25px',
    right: 0,
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
  },
  deleteBtn: {
    '& i': {
      color: 'rgb(214, 23, 9)',
    },
  },
  error: {
    color: '#f44336',
  },
  recurringEventTitle: {
    fontSize: '18px',
    fontWeight: 600,
    marginLeft: '24px',
  },
  recurringEventTableContainer: {
    width: '75%',
    marginLeft: '32px',
  },
}));

const DEFAULT_TIME_PERIOD_OPTIONS = {
  from: [],
  till: [],
};

function getClientOptionLabel(option) {
  return !!option
    ? `${option.first_name} ${option.last_name} (${
        option.birthday ? option.birthday : ' - '
      })`
    : '';
}

function clientsRequestBody(value) {
  return {
    page: 1,
    size_per_page: 10,
    sort_column: 'created_at',
    sort_direction: 'desc',
    search: {
      value,
    },
  };
}

function createTableRow(name, info) {
  return { name, info };
}

export const BasicEventForm = ({
  basicEvent,
  fetchProvidersUrl,
  providers: initialProviders = [],
  services_api_url,
  api_url,
  onSubmit,
  getProviderPeriods,
  clients_api_url,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [providers, setProviders] = useState(initialProviders);

  const {
    item,
    changeValue,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    setServerErrors,
    setItem,
    action,
  } = useForm(basicEvent, validators, api_url);
  const [assignedClient, changeAssignedClient] = useState('');

  function onClientChange(_, client) {
    changeAssignedClient(client);
  }

  const [bookingServices, setBookingServices] = useState([]);

  useEffect(() => {
    getBookingServices();
  }, [item.multiple_bookings, item.booking_provider_id]);

  useEffect(() => {
      if(item.date){
      getProviders()
    }
  }, [item.date]);

  const getProviders = async () => {
    const {date, id} = item;
    const res = await AJAX.get(fetchProvidersUrl, {
      body: { 
        date,
        booking_event_id: id
      }
    });
    if (!res.data.booking_providers) {
      return;
    }

    setProviders(res.data.booking_providers);
  }

  const getBookingServices = async () => {
    const res = await AJAX.get(services_api_url, {
      body: {
        filters: {
          booking_providers: item.booking_provider_id,
          active: true,
          booking_service_type: item.multiple_bookings
            ? 'for_multiple_bookings'
            : 'for_single_booking',
        },
      },
    });

    if (!res.data) {
      return;
    }

    const { booking_service, multiple_bookings } = basicEvent;

    if (!booking_service || item.multiple_bookings !== multiple_bookings) {
      return setBookingServices(res.data);
    }

    /*
      push deleted service if service has been removed from provider to avoid 
      issues which refers to booking service in prev events
    */
    const isServiceDeleted = !findInArrayBy(res.data, item.booking_service.id);
    if (isServiceDeleted) {
      res.data.push(booking_service);
    }
    setBookingServices(res.data);
  }

  const [timePeriods, changeTimePeriods] = useState([]);

  useEffect(() => {
    if (item.booking_provider_id) {
      const periods = getProviderPeriods(item.booking_provider_id, providers);

      changeTimePeriods(periods);
    }
  }, [item.booking_provider_id]);

  const timePeriodOptions = useMemo(() => {
    return timePeriods.reduce((acc, res) => {
      return {
        from: [...acc.from, { id: res.id, name: res.start_time }],
        till: [...acc.till, { id: res.id, name: res.end_time }],
      };
    }, DEFAULT_TIME_PERIOD_OPTIONS);
  }, [timePeriods]);

  //set default end period id by service duration logic
  useEffect(() => {
    if (item.id) return;
    const isBookingServiceAvailable =
      item.start_period_id &&
      item.booking_service_id &&
      timePeriodOptions.from.length;
    if (isBookingServiceAvailable) {
      const selectedService = findInArrayBy(
        bookingServices,
        item.booking_service_id
      );
      const startPeriodIndex = findIndexInArrayBy(
        timePeriodOptions.from,
        item.start_period_id
      );
      const endPeriodIndexId =
        startPeriodIndex + selectedService.duration_units_count - 1;
      changeValue(
        'end_period_id',
        timePeriodOptions.till[endPeriodIndexId]?.id || ''
      );
    }
  }, [item.booking_service_id, item.start_period_id, bookingServices]);

  const onBookingServiceIdChange = ({ target }) => {
    changeValue('booking_service_id', target.value);
  };

  const onProviderIdChange = ({ target }) => {
    setItem({
      ...item,
      booking_provider_id: target.value,
      start_period_id: '',
      end_period_id: '',
    });
  };

  const onMembersLimitChange = ({ target }) => {
    changeValue('members_limit', target.value);
  };

  // group logic logic will be implemented later
  const onIsGroupChange = ({ target }) => {
    setItem({
      ...item,
      multiple_bookings: target.checked,
      booking_service_id: '',
      time_slot_name: '',
    });
  };

  const onDateChange = ({ target }) => {
    changeValue('date', target.value);
  };

  const onStartPeriodIdChange = ({ target }) => {
    changeValue('start_period_id', target.value);
  };

  const onEndPeriodIdChange = ({ target }) => {
    changeValue('end_period_id', target.value);
  };

  const onCustomFieldDelete = (id, index) => {
    let updatedCustomFieldsAttributes;
    if (!id) {
      updatedCustomFieldsAttributes =
        item.booking_custom_fields_attributes.filter(
          (_, prevIndex) => prevIndex !== index
        );
    } else {
      updatedCustomFieldsAttributes = item.booking_custom_fields_attributes.map(
        (field) => {
          if (field.id === id) field._destroy = true;
          return field;
        }
      );
    }
    setItem({
      ...item,
      booking_custom_fields_attributes: updatedCustomFieldsAttributes,
    });
  };

  const onCustomFieldAdd = (customField) => {
    setItem({
      ...item,
      booking_custom_fields_attributes: [
        ...item.booking_custom_fields_attributes,
        customField,
      ],
    });
  };

  const onNotesChange = ({ target }) => {
    changeValue('notes', target.value);
  };

  const onCommentChange = ({ target }) => {
    changeValue('comment', target.value);
  };

  const onSlotNameChange = ({ target }) => {
    changeValue('time_slot_name', target.value);
  };

  const bookingProviderOptions = useMemo(() => {
    return providers.map(({ name, id }) => {
      return {
        id,
        name: (
          <div
            className={classes.select}
            dangerouslySetInnerHTML={{ __html: name }}
          ></div>
        ),
      };
    });
  }, [providers]);

  const onSuccessSubmit = (res) => {
    if (res.flash_type == 'success') {
      onSubmit();
    }
  };

  function handleDelete() {
    confirmationStore.open(deleteEvent);
  }

  async function deleteEvent() {
    const res = await AJAX.delete(action);

    onSuccessSubmit(res);
  }

  const WEEK_FREQUENCY_OPTIONS = useMemo(
    function () {
      return [
        { id: 1, name: `${t('Every one')} ${t('week')}` },
        { id: 2, name: `${t('Every')} 2 ${t('weeks')}` },
        { id: 3, name: `${t('Every')} 3 ${t('weeks')}` },
        { id: 4, name: `${t('Every')} 4 ${t('weeks')}` },
      ];
    },
    [i18n.language]
  );

  const requrringInfo = useMemo(() => {
    if (!basicEvent.recurring_info) {
      return;
    }

    const {
      booking_provider,
      booking_service,
      time,
      week_days,
      week_frequency,
    } = basicEvent.recurring_info;

    const currentWeekFrequency = findInArrayBy(
      WEEK_FREQUENCY_OPTIONS,
      week_frequency
    ).name;

    const rows = [
      createTableRow(`${t('Booking Service')}`, booking_service),
      createTableRow(`${t('Provider')}`, booking_provider),
      createTableRow(`${t('Time Period')}`, time),
      createTableRow(`${t('Week Days')}`, week_days),
      createTableRow(`${t('Frequency')}`, currentWeekFrequency),
    ];

    return (
      <>
        <Typography className={classes.recurringEventTitle}>
          {t('Recurring Event')}
        </Typography>
        <TableContainer className={classes.recurringEventTableContainer}>
          <Table>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="left">{row.info}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }, [basicEvent.recurring_info, WEEK_FREQUENCY_OPTIONS, i18n.language]);

  const getSerializedItem = () => {
    const {
      week_frequency,
      week_days,
      end_period_id,
      start_period_id,
      booking_provider_id,
      members_limit,
      ...itemValues
    } = item;

    const startPeriodIndex = findIndexInArrayBy(timePeriods, start_period_id);
    const endPeriodIndex = findIndexInArrayBy(timePeriods, end_period_id);
    const bookingPeriodsRange = timePeriods.slice(
      startPeriodIndex,
      endPeriodIndex + 1
    );

    return {
      ...itemValues,
      booking_period_ids: bookingPeriodsRange.map(({ id }) => id),
      ...(item.multiple_bookings && { members_limit }),
      ...(!!assignedClient && {
        booking_member: {
          client_id: assignedClient.id,
        },
      }),
    };
  };

  function getProviderType(providerId) {
    return findInArrayBy(providers, providerId)?.provider_type;
  }

  const isSlotNameFieldVisible = useMemo(() => {
    return (
      item.multiple_bookings &&
      getProviderType(item.booking_provider_id) === 'spot'
    );
  }, [item.multiple_bookings, item.booking_provider_id]);
  const disabled =
    basicEvent.has_active_client_booking_orders || !!item.recurring_info;

  const isFieldVisible = useMemo(
    () =>
      item.booking_custom_fields_attributes.some((field) => !field._destroy),
    [item.booking_custom_fields_attributes]
  );

  const isNewFieldAvailiable = !item.has_active_client_booking_orders;
  return (
    <>
      <Form
        className={classes.form}
        action={action}
        method={method}
        validators={validators}
        name="booking_event_basic"
        getSerializedItem={getSerializedItem}
        disabled={isItemInvalid}
        onSubmit={submit}
        additionalParams={{ booking_provider_id: item.booking_provider_id }}
        onError={setServerErrors}
        onSuccessSubmit={onSuccessSubmit}
      >
        {item.id && (
          <Box className={classes.settingsWrapper}>
            {!!basicEvent.creator && (
              <p>
                {t('Last Updated by')}: {basicEvent.creator}
              </p>
            )}
            <IconButton
              className={classes.deleteBtn}
              onClick={handleDelete}
              size="medium"
            >
              <i className="icon-trash-empty"></i>
            </IconButton>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Select
              validationMessages={
                validationMessagesToDisplay.booking_provider_id
              }
              value={item.booking_provider_id}
              options={bookingProviderOptions}
              label={t('Provider')}
              onChange={onProviderIdChange}
              required
            />
            {/* temporary commented. will be implement later. 'Group' should be renamed to 'Multiple bookings'!!! */}
            <MFormControl fullWidth>
              <Checkbox
                checked={item.multiple_bookings || false}
                onChange={onIsGroupChange}
                label={t('Group')}
                disabled={disabled}
              />
            </MFormControl>
            <Select
              validationMessages={validationMessagesToDisplay.booking_service}
              value={item.booking_service_id}
              options={bookingServices}
              label={t('Booking Service')}
              onChange={onBookingServiceIdChange}
              required
              disabled={disabled}
            />

            {item.multiple_bookings && (
              <FormInput
                validationMessages={validationMessagesToDisplay.members_limit?.join(
                  ', '
                )}
                value={item.members_limit || ''}
                label={t('Members limit')}
                type="number"
                onChange={onMembersLimitChange}
                required
              />
            )}
            {item.date && (
              <DatePicker
                label={t('Date')}
                value={item.date}
                onChange={onDateChange}
              />
            )}
            <FormControl
              fullWidth
              label={
                <span className={classes.formLabel}>{t('Time Period')}</span>
              }
              validationMessages={
                validationMessagesToDisplay.booking_time_range
              }
            >
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  {!!timePeriodOptions.from.length && (
                    <Select
                      label={t('From Time')}
                      onChange={onStartPeriodIdChange}
                      validationMessages={
                        validationMessagesToDisplay.start_period_id
                      }
                      value={item.start_period_id}
                      options={timePeriodOptions.from}
                      required
                    />
                  )}
                </Grid>
                <Grid item xs={6} sm={6}>
                  {!!timePeriodOptions.till.length && (
                    <Select
                      label={t('Till Time')}
                      onChange={onEndPeriodIdChange}
                      validationMessages={
                        validationMessagesToDisplay.end_period_id
                      }
                      value={item.end_period_id}
                      options={timePeriodOptions.till}
                      required
                    />
                  )}
                </Grid>
              </Grid>
            </FormControl>
            {!basicEvent.id && (
              <AjaxAutocomplete
                label={t('Client')}
                value={assignedClient}
                getRequestBody={clientsRequestBody}
                searchUrl={clients_api_url}
                getOptionLabel={getClientOptionLabel}
                onChange={onClientChange}
                method="get"
              />
            )}
            {isNewFieldAvailiable && (
              <CustomFieldForm onCustomFieldAdd={onCustomFieldAdd} />
            )}
            {isFieldVisible && (
              <CustomFieldList
                fields={item.booking_custom_fields_attributes}
                onCustomFieldDelete={onCustomFieldDelete}
                serverErrors={validationMessagesToDisplay}
                isRemoval={!item.has_active_client_booking_orders}
              />
            )}
            {isSlotNameFieldVisible && (
              <FormInput
                label={t('Slot Name')}
                validationMessages={validationMessagesToDisplay.time_slot_name?.join(
                  ', '
                )}
                value={item.time_slot_name || ''}
                onChange={onSlotNameChange}
                required
              />
            )}
            <FormInput
              value={item.notes || ''}
              label={t('Notes')}
              onChange={onNotesChange}
              multiline
            />
            <FormInput
              value={item.comment || ''}
              label={t('Comment for provider')}
              onChange={onCommentChange}
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {requrringInfo}
          </Grid>
        </Grid>
        <Box>
          <FormHelperText className={classes.commonErrors}>
            {validationMessagesToDisplay?.common_errors
              ?.map((error) => error)
              .join(', ')}
          </FormHelperText>
        </Box>
        <Box className={classes.buttonActions}>
          <Button variant="contained" color="primary" type="submit">
            {t(`${item.id ? 'Update' : 'Create'} Event`)}
          </Button>
        </Box>
      </Form>
    </>
  );
};
