import { ButtonGroup } from '@material-ui/core';
import { Button } from 'Components';

export function Counter({ count, id, onChange, disabled}) {
  function handleIncrement() {
    onChange(count += 1, id)
  }

  function handleDecrement() {
    onChange(count -= 1, id)
  }

  return (
    <ButtonGroup size="small" disabled={disabled}>
      <Button color="primary" variant="contained" onClick={handleDecrement} disabled={count <= 1}>-</Button>
      <Button>{count}</Button>
      <Button color="primary" variant="contained" onClick={handleIncrement}>+</Button>
    </ButtonGroup>
  )
}
