import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { Button } from 'Components';


const DEFAULT_STATE = {
  title: "",
  isOpened: false,
  data: null
}

export const ReportDialog = React.forwardRef((_, ref) => {
  const { t, } = useTranslation();
  const [state, setState] = React.useState(DEFAULT_STATE);

  async function open(title, data) {
    setState({
      title,
      data,
      isOpened: true,
    });
  }

  function close() {
    setState(DEFAULT_STATE);
  }

  React.useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Dialog open={state.isOpened} onClose={close} fullWidth>
      <DialogTitle>{state.title}</DialogTitle>
      <DialogContent>
        {!!state.data && (
          <div className="report-wrapper">
            <p className="report-wrapper__title"><span>Реалізація</span></p>
            <p className="report-wrapper__sub-title">Одержано по формах оплати</p>
            <div className="report-wrapper__info-wrapper">
              <p className="report-wrapper__info">
                <span>Картка</span>
                <span>{state.data.sell.cashless}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Готівка</span>
                <span>{state.data.sell.cash}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Загальний оборот</span>
                <span>{state.data.sell.total}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Кількість чеків</span>
                <span>{state.data.sell.receipts_count}</span>
              </p>
            </div>
            <p className="report-wrapper__title"><span>Повернення</span></p>
            <p className="report-wrapper__sub-title">Видано по формах оплати</p>
            <div className="report-wrapper__info-wrapper">
              <p className="report-wrapper__info">
                <span>Картка</span>
                <span>{state.data.return.cashless}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Готівка</span>
                <span>{state.data.return.cash}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Загальний оборот</span>
                <span>{state.data.return.total}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Кількість чеків</span>
                <span>{state.data.return.receipts_count}</span>
              </p>
            </div>
            <p className="report-wrapper__title"><span>Готівкові кошти в касі</span></p>
            <div className="report-wrapper__info-wrapper">
              <p className="report-wrapper__info">
                <span>Початковий залишок</span>
                <span>{state.data.cash_register.initial_cash_value}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Службове внесення</span>
                <span>{state.data.cash_register.service_in}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Службове вилучення</span>
                <span>{state.data.cash_register.service_out}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Кінцевий залишок</span>
                <span>{state.data.cash_register.current_cash_value}</span>
              </p>
            </div>
            <p className="report-wrapper__title"><span>Виручка</span></p>
            <div className="report-wrapper__info-wrapper">
              <p className="report-wrapper__info">
                <span>Готівкою</span>
                <span>{state.data.revenue.cash}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Безготівкова</span>
                <span>{state.data.revenue.cashless}</span>
              </p>
              <p className="report-wrapper__info">
                <span>Всього</span>
                <span>{state.data.revenue.total}</span>
              </p>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  );
})
