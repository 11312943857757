import { useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FormControl } from 'Components';
import { CustomField } from './CustomField';

const useStyles = makeStyles(() => ({
  form: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  formLabel: {
    fontSize: '0.75em',
  },
}));

const LABEL_SERVER_ERROR = 'booking_custom_fields.label';
const OPTION_VALUES_SERVER_ERROR = 'booking_custom_fields.option_values';

export const CustomFieldList = ({
  fields,
  onCustomFieldDelete,
  serverErrors,
  isRemoval,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const errorsFromServer = useMemo(() => {
    const isLabelError = !!serverErrors[LABEL_SERVER_ERROR];
    const isOptionValuesError = !!serverErrors[OPTION_VALUES_SERVER_ERROR];
    if (isLabelError && isOptionValuesError)
      return t("Field values can't be blank");
    if (isLabelError) return t("Label can't be blank");
    if (isOptionValuesError) return t("Option values can't be blank");
  }, [serverErrors]);
  
  return (
    <FormControl
      fullWidth
      label={
        <span className={classes.formLabel}>{t('Custom fields list')}</span>
      }
      validationMessages={errorsFromServer}
      className={classes.form}
    >
      <Grid container spacing={1}>
        {fields.map(
          (field, index) =>
            !field._destroy && (
              <CustomField
                key={field.id || index}
                index={index}
                onCustomFieldDelete={onCustomFieldDelete}
                isRemoval={isRemoval}
                {...field}
              />
            )
        )}
      </Grid>
    </FormControl>
  );
};
