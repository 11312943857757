import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button } from 'Components';
import confirmationStore from 'Helpers/confirmation';
const defoultState = {
  header: '',
  content: '',
  onConfirm: null,
};
export const ConfirmDialog = React.forwardRef((_, ref) => {
  const { t } = useTranslation();
  const [{ header, content, onConfirm }, setData] = React.useState(
    defoultState
  );

  function open(
    onConfirm = function () { },
    content = t('Are You Sure?'),
    header = t('Confirmation')
  ) {
    setData({ content, header, onConfirm });
  }

  function close() {
    setData(defoultState);
  }

  function handleConfirm() {
    onConfirm();
    close();
  }

  React.useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  React.useEffect(function () {
    confirmationStore.register(open);
  }, []);

  return (
    <Dialog
      open={!!content}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">{header}</DialogTitle>
      <DialogContent dividers>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t('Close')}</Button>
        <Button color="primary" onClick={handleConfirm}>
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
