export function checkRequired(key, msg = 'Can\'t be blank') {
  return function (item) {
    if (!item[key] || !Object.values(item[key])) {
      return msg;
    }
    return null;
  }
}

export function checkRequiredArray(key, msg = 'Can\'t be blank') {
  return function (item) {
    if (!item[key].length) {
      return msg;
    }
    return null;
  }
}

export function checkNumberRequired(key, msg = 'Can\'t be blank') {
  return function (item) {
    const invalidValues = [null, NaN, undefined, ""]

    if (invalidValues.includes(item[key])) {
      return msg;
    }
  }
}

//msg text is hardcoded. it should be changed on implement localization task for client pages
export function validateCurrency(key) {
  return function (item) {
    if (!item[key]) {
      return 'Поле не може бути пустим';
    }

    if (item[key].includes('.')) {
      return 'Сума повинна бути цілою';
    }


    return null;
  }
}

export function passwordValidator() {
  return function (item) {
    if (item.password.length < 6 || item.password_confirmation.length < 6) {
      return 'пароль повинен мати не менше 6 символів';
    }

    if (item.password !== item.password_confirmation) {
      return 'паролі не співпадають';
    }

    return null;
  }
}

export function isDateValid(key, msg = 'Invalid date format') {
  return function (item) {

    if (!item[key]) {
      return
    }

    const momentValue = moment(item[key], 'YYYY-MM-DD');
    if (!momentValue.isValid() || !momentValue.isBetween('1900-01-01', '2100-01-01')) {
      return msg;
    }

  }
}
