import { useMemo } from 'react';
import { useTranslation, } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { v1 as uuidv1 } from 'uuid';
import { Table } from 'Components';

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& strong': {
      fontSize: '15px',
      marginRight: '16px'
    }
  }
}))

export const VisitsTable = ({ average_visits_count, service }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const tableConfig = [
    {
      title: t('Average Visits Count'),
      accessor: 'average_visits_count',
      cellComponent: ({ average_visits_count }) => {
        return (
          <div className={classes.item}>
            <strong>{t('Count')}: </strong>
            <p dangerouslySetInnerHTML={{ __html: average_visits_count }}></p>
          </div>
        )
      }
    },
    {
      title: t('Service'),
      accessor: 'service',
      cellComponent: ({ service }) => {
        return (
          <>
            <div className={classes.item}>
              <strong>{t('Service name')}: </strong>
              <p dangerouslySetInnerHTML={{ __html: service?.name }}></p>
            </div>
            <div className={classes.item}>
              <strong>{t('Service type')}: </strong>
              <p dangerouslySetInnerHTML={{ __html: service?.service_type }}></p>
            </div>
            <div className={classes.item}>
              <strong>{t('Duration in days')}: </strong>
              <p dangerouslySetInnerHTML={{ __html: service?.duration_in_days }}></p>
            </div>
            <div className={classes.item}>
              <strong>{t('Visits count')}: </strong>
              <p dangerouslySetInnerHTML={{ __html: service?.visits_count }}></p>
            </div>
          </>
        )
      }

    }
  ]

  const rows = useMemo(() => (
    [
      {
        id: uuidv1(),
        average_visits_count,
        service
      },
    ]
  ), [
    average_visits_count,
    service,
    t
  ])

  return (
    <Table
      config={tableConfig}
      data={rows}
      pagination={false}
    />
  )
}
