export const BookingRecurringEvents = {
  en: {
    'Recurring Events': 'Recurring Events',
    'Create Recurring Event': 'Create Recurring Event',
    'Event': 'Event',
    'Note': 'Note',
    'Location': 'Location',
    'Provider': 'Provider',
    'Booking Service': 'Booking Service',
    'Group': 'Group',
    'Members limit': 'Members limit',
    'Time Period': 'Time Period',
    'From Time': 'From Time',
    'Till Time': 'Till Time',
    'Comment': 'Comment',
    'Recurring Event': 'Recurring Event',
    'Frequency': 'Frequency',
    'Week Days': 'Week Days',
    'Every one': 'Every',
    'week': 'week',
    'Every': 'Every',
    'weeks': 'weeks',
    'Last Updated by': 'Last Updated by',
    'confirmLabel': 'Delete requrring event with all future events',
    'Client': 'Client',
  },
  uk: {
    'Recurring Events': 'Повторювані події',
    'Create Recurring Event': 'Створити правило',
    'Event': 'Подія',
    'Note': 'Нотатка',
    'Location': 'Локація',
    'Provider': 'Провайдер',
    'Booking Service': 'Букінг Сервіс',
    'Group': 'Група',
    'Members limit': 'Обмеження кількості учасників',
    'Time Period': 'Період часу',
    'From Time': 'Починаючи з',
    'Till Time': 'До',
    'Comment': 'Коментар',
    'Recurring Event': 'Повторювана подія',
    'Frequency': 'Періодичність',
    'Week Days': 'Дні тижня',
    'Every one': 'Кожен',
    'week': 'тиждень',
    'Every': 'Кожні',
    'weeks': 'тижні',
    'Last Updated by': 'Оновив',
    'confirmLabel': 'Видалити правило з усіма майбутніми подіями',
    'Client': 'Клієнт',
  },
};

export const BookingRecurringEventForm = {
  en: {
    ...BookingRecurringEvents.en,
    'New Recurring Event': 'New Recurring Event',
    'Create Recurring Event': 'Create Recurring Event'
  },
  uk: {
    ...BookingRecurringEvents.uk,
    'New Recurring Event': 'Створення Правила',
    'Create Recurring Event': 'Створити Правило'
  }
}
