import { Grid, Collapse, makeStyles } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { Button } from 'Components';
import { useValidation } from 'Hooks';
import { isDateValid } from 'Helpers/validation';

function getFilterValue(values, name, isArray) {
  if (!values || !values[name]) {
    return '';
  }
  return isArray ? values[name][0] : values[name]
}

const validators = {
  from: isDateValid('from'),
  till: isDateValid('till'),
};

const useStyles = makeStyles((theme) => ({
  submitRow: {
    textAlign: 'right',
  },
  trigger: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  }
}))

export const Filters = React.forwardRef(({ children, onChange, onSubmit, initialValues = {}, onInit, disabled = false, validators: propsValidators }, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = React.useState(false);
  const [values, setValues] = React.useState(initialValues);

  function toggleFiltersVisibility() {
    setShowFilters(!showFilters);
  }

  function bindHandleChange(name, isArray) {
    return function ({ target }) {
      setValues({
        ...values,
        [name]: isArray ? [target.value] : target.value,
      });
    }
  }


  function serializeChild(child) {
    const { name, valueAsArray } = child.props;
    return React.cloneElement(child, {
      value: getFilterValue(values, name, valueAsArray),
      onChange: bindHandleChange(name, valueAsArray),
    })
  }

  const serializedChildren = React.useMemo(function () {
    if (!children.map) {
      return [serializeChild(children)];
    }
    return children.map(serializeChild);
  }, [children, values]);

  const { validationMessages } = useValidation(values, { ...validators, ...propsValidators }, {}, {})

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(values);
  }

  React.useImperativeHandle(ref, () => ({
    values,
  }))

  React.useEffect(function () {
    if (!!onChange) {
      onChange(values);
    }
  }, [values]);

  React.useEffect(function () {
    if (!!onInit) {
      onInit(values);
    }
  }, []);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Button
          onClick={toggleFiltersVisibility}
          className={classes.trigger}
        >
          {t('Additional Filters')}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Collapse in={showFilters}>
          <form
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid
              container
              spacing={2}
            >
              {serializedChildren.map((child, index) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  key={index}
                >
                  {child}
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.submitRow}
            >
              <Button
                type="submit"
                disabled={!!(validationMessages?.from || validationMessages?.till || disabled)}
              >
                {t('Filter')}
              </Button>
            </Grid>
          </form>
        </Collapse>
      </Grid>
    </Grid >
  );
});

Filters.defaultProps = {
  children: [],
}
