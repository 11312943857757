import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { AJAX, toQueryString } from 'Helpers';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    width: '52px',
    height: '52px',
  },
  payButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(4)
  },
  errorMessage: {
    paddingBottom: theme.spacing(2)
  }
}));

const DEFAULT_STATE = {
  isOpen: false,
  loading: false,
  errorMessage: '',
  html: null,
  url: null,
}

const BuyDialog = React.forwardRef(({ name, fetchUrl, price, subscription }, ref) => {
  const classes = useStyles();

  const [state, changeState] = React.useState(DEFAULT_STATE);

  const open = async () => {
    changeState({ ...state, isOpen: true, loading: true })

    if (!!state.errorMessage) {
      return
    }

    if (!state.url) {
      const res = await AJAX.get(fetchUrl);

      if (res.alert_message) {
        return changeState({ isOpen: true, errorMessage: res.alert_message, html: null, loading: false, url: state.url })
      }

      return getHtml(res.url)
    }

    getHtml(state.url)
  }

  const getHtml = async (url) => {
    const params = {
      payment_action: subscription ? 'subscribe' : 'pay',
      amount: price,
      ...(subscription && {
        subscribe_periodicity: 'month'
      })
    }

    const paramsStr = toQueryString(params);
    const html = await fetch(`${url}?${paramsStr}`, { headers: { Accept: 'text/html' } });
    const htmlStr = await html.text();
    changeState({ ...state, isOpen: true, html: htmlStr, loading: false, url })
  }

  function close() {
    changeState({
      ...DEFAULT_STATE,
      errorMessage: state.errorMessage,
      url: state.url,
    })
  }

  React.useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Dialog open={state.isOpen} onClose={close} fullWidth>
      <DialogTitle className={classes.root}>
        Оплата послуги
        <IconButton size="medium" className={classes.closeButton} onClick={close}><i className="icon-cancel"></i></IconButton>
      </DialogTitle>
      <DialogContent>
        {!!state.errorMessage && (
          <div className={classes.errorMessage}>{state.errorMessage}</div>
        )}
        {!state.errorMessage && (
          <>
            Ви збираєтеся купити <span dangerouslySetInnerHTML={{ __html: name }}></span> на суму {price} грн{subscription && '/місяць'}
            <div className={classes.payButtonWrapper} dangerouslySetInnerHTML={{ __html: state.html }}></div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
});


export default BuyDialog;
