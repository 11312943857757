export const ResponsesCell = ({ custom_field_responses }) => {
  const responses = custom_field_responses || [];
  return (
    <>
      {responses.map(({ label, value, id }) => {
        return (
          !!value && (
            <p key={id}>
              {label}: {value}.
            </p>
          )
        );
      })}
    </>
  );
};
