import {
  Table as MaterialTable, TableContainer, TableBody, TableCell, Collapse,
  TableHead, TableRow, TablePagination, makeStyles, IconButton, Box, TableSortLabel
} from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { Preloader } from 'Components';
import { PAGE_SIZES } from 'Constants';

const useStylesHeaderCell = makeStyles(() => ({
  root: {
    color: '#555',
    textTransform: 'uppercase',
  },
}));

const useTrStyles = makeStyles(() => ({
  collapseContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const useStylesPagination = makeStyles(() => ({
  root: {
    color: '#5a5a5a',
    textTransform: 'capitalize',
  },
  selectRoot: {
    backgroundColor: '#f1f1f1',
    marginRight: 20,
  },
  actions: {
    marginLeft: 0,
  }
}));

const HeaderCell = ({ title, accessor, sortable, handleChangePagination, paginationParams }) => {
  const classes = useStylesHeaderCell();

  const { sort_column, sort_direction } = paginationParams || {};

  const handleClick = () => {
    if (sortable) {
      const sortData = {
        sort_column: accessor,
        sort_direction: sort_direction === 'asc' ? 'desc' : 'asc'
      }

      handleChangePagination(sortData);
    }
  }

  return (
    <TableCell classes={{ root: classes.root }} component="th" scope="row">
      <TableSortLabel direction={sort_direction} active={sort_column && sort_column === accessor}
        onClick={handleClick} hideSortIcon={!sortable}>
        {title}
      </TableSortLabel>
    </TableCell>
  )
}

const THead = ({ config, collapseRow, pagination }) => (
  <TableHead>
    <TableRow>
      {!!collapseRow && (<HeaderCell />)}
      {config.map(col => (
        <HeaderCell key={col.accessor || col.title} {...col} {...pagination} />
      ))}
    </TableRow>
  </TableHead>
);

const Td = ({ data, cellComponent, ...config }) => {
  return (<TableCell>{cellComponent(data, config)}</TableCell>);
}

Td.defaultProps = {
  cellComponent: function (data, { accessor }) { return data[accessor]; },
}

const Tr = ({ className, item, config, collapseRow, }) => {
  const [open, setOpen,] = React.useState(false);
  const classes = useTrStyles();

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <>
      <TableRow className={className}>
        {!!collapseRow && (
          <TableCell>
            <IconButton onClick={toggleOpen} size="small">
              <i className={open ? 'icon-up-open' : ' icon-down-open'}></i>
            </IconButton>
          </TableCell>
        )}
        {config.map(config => (
          <Td key={config.accessor || config.title} data={item} {...config}></Td>
        ))}
      </TableRow>
      {!!collapseRow && (
        <TableRow>
          <TableCell className={classes.collapseContainer} colSpan={config.length}>
            <Box mardin={1}>
              <Collapse in={open}>
                {collapseRow(item)}
              </Collapse>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const TBody = ({ config, data, pending, rowKeyFn, rowClassFn, collapseRow, }) => {
  const { t } = useTranslation();

  if (!!pending) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={config.length} className="text-center">
            <Preloader />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (!data || !data.length) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={config.length}>{t('No Data Provided')}</TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {data.map((item, index) => (
        <Tr
          key={rowKeyFn(item)}
          className={rowClassFn(item, index)}
          collapseRow={collapseRow}
          config={config}
          item={item} />
      ))}
    </TableBody>
  );
}

const Pagination = ({ data, pending, pagination, hidePagination, rowsPerPageOptions }) => {
  if (!pagination || hidePagination) {
    return null
  }

  const { paginationParams, records_total, handleChangePagination } = pagination;
  const { size_per_page, page } = paginationParams

  if (!!pending || !data || !data.length) {
    return null
  }

  const { t, } = useTranslation();
  const handleChangePage = (_, page) => {
    handleChangePagination({ size_per_page, page: page + 1 });
  };

  const handleChangeSizePerPage = (event) => {
    handleChangePagination({ size_per_page: event.target.value, page: 1 });
  };

  const classes = useStylesPagination();
  return (
    <TablePagination
      component="div"
      count={records_total}
      rowsPerPage={size_per_page}
      page={page - 1}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeSizePerPage}
      classes={{ root: classes.root, selectRoot: classes.selectRoot, actions: classes.actions }}
      labelRowsPerPage={t('Rows Per Page')}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  )
}

Pagination.defaultProps = {
  rowsPerPageOptions: PAGE_SIZES,
};

TBody.defaultProps = {
  rowKeyFn: function (item) { return item.id; },
}

export const Table = ({
  rowClassFn, tableProps, containerAttr, hasPagination = true, ...props
}) => {
  return (
    <TableContainer {...containerAttr}>
      <MaterialTable {...tableProps} >
        <THead {...props} />
        <TBody {...props} rowClassFn={rowClassFn} />
      </MaterialTable>
      {!!hasPagination && (
        <Pagination {...props} />
      )}
    </TableContainer>
  );
}

Table.defaultProps = {
  rowClassFn: function () { },
};
