import {
  Button,
  Link,
  Grid,
  Checkbox,
  makeStyles,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';
import { Form, FormInput, BirthdayDatePicker } from 'Components';
import { checkRequired } from 'Helpers/validation';
import { useForm } from 'Hooks';
import ReCAPTCHA from 'react-google-recaptcha';

moment.locale('uk-UA');

const useStyles = makeStyles((theme) => ({
  agreementWrapper: {
    display: 'flex',
  },
  rulesLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  notification: {
    fontSize: '18px',
    padding: '16px',
    marginTop: 'auto',
  },
  buttonLoadingWrapper: {
    marginRight: '15px'
  }
}));

const validators = {
  first_name: checkRequired('first_name', 'Не має бути порожнім'),
  last_name: checkRequired('last_name', 'Не має бути порожнім'),
  phone: checkRequired('phone', 'Не має бути порожнім'),
  birthday: checkRequired('birthday', 'Не має бути порожнім'),
};

export const SignUpForm = ({
  useCommonStyles,
  new_confirmation_url,
  auth_url,
  registration_url,
  auth_client_email,
  client,
}) => {
  const { t } = useTranslation();
  const classes = useCommonStyles();
  const signUpClasses = useStyles();

  client.email = auth_client_email;
  const {
    item,
    changeValue,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    setServerErrors,
  } = useForm(client, validators, registration_url);

  const handleChangeFirstName = ({ target }) => {
    changeValue('first_name', target.value);
  };

  const handleChangeLastName = ({ target }) => {
    changeValue('last_name', target.value);
  };

  const handleChangeMiddleName = ({ target }) => {
    changeValue('middle_name', target.value);
  };

  function handleChangeBirthday({ target }) {
    changeValue('birthday', target.value || '');
  }

  function handleChangePhone({ target }) {
    changeValue('phone', target.value);
  }

  const [agreement, changeAgreement] = React.useState(false);
  const [recaptchaToken, changeRecaptchaToken] = React.useState(null);
  const [isPending, setPending] = React.useState(false);

  const handleChangeAgreement = ({ target }) => {
    changeAgreement(target.checked);
  };

  function onRequestStart() {
    setPending(true)
  }

  function onRequestEnd() {
    setPending(false)
  }

  return (
    <>
      <Typography
        variant="h6"
        component="p"
        className={signUpClasses.notification}
      >
        Такої адреси не знайдено :( <br />
        <Link href={auth_url}> Спробуємо іншу адресу?</Link><br />
        Або заповніть форму рeєстрації:
      </Typography>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="uk-UA">
        <Form
          name="client"
          action={registration_url}
          method="post"
          validators={validators}
          disabled={isItemInvalid}
          item={item}
          onSubmit={submit}
          onError={setServerErrors}
          className={classes.form}
          onRequestStart={onRequestStart}
          onRequestEnd={onRequestEnd}
        >
          <Grid item xs={12}>
            <FormInput
              name="client[email]"
              value={item.email}
              label={t('Email')}
              autoComplete="email"
              disabled
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormInput
                name="client[first_name]"
                label="Ім'я"
                value={item.first_name}
                onChange={handleChangeFirstName}
                autoComplete="first_name"
                required
                autoFocus
                validationMessages={validationMessagesToDisplay.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                name="client[last_name]"
                label="Прізвище"
                value={item.last_name}
                onChange={handleChangeLastName}
                autoComplete="last_name"
                required
                validationMessages={validationMessagesToDisplay.last_name}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormInput
                name="client[middle_name]"
                label="По батькові"
                value={item.middle_name}
                onChange={handleChangeMiddleName}
                autoComplete="middle_name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                name="client[phone]"
                label="Телефон"
                value={item.phone}
                onChange={handleChangePhone}
                required
                validationMessages={validationMessagesToDisplay.phone}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <BirthdayDatePicker
                name="client[birthday]"
                onChange={handleChangeBirthday}
                value={item.birthday}
                label="Дата народження"
                required
                validationMessages={validationMessagesToDisplay.birthday}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={signUpClasses.agreementWrapper} pt={2}>
              <Checkbox
                color="primary"
                checked={agreement}
                onChange={handleChangeAgreement}
              />
              <p>
                Я прочитав і згоден з умовами&nbsp;
                <a
                  href="https://climbingspace.com.ua/security-rules"
                  className={signUpClasses.rulesLink}
                  target="_blank"
                >
                  угоди
                </a>
              </p>
            </Box>
          </Grid>
          <Grid item xs={12}>

            <ReCAPTCHA
              sitekey="6LcyErsiAAAAAMEjQT57w1HbjZkbDXDnB-Wqd76p"
              onChange={changeRecaptchaToken}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!agreement || !recaptchaToken || isPending}
              className={classes.submit}
            >
              {!!isPending && (
                <CircularProgress size={20} className={signUpClasses.buttonLoadingWrapper} />
              )}
              Зареєструватися
            </Button>
          </Grid>
          <Link href={new_confirmation_url}>
            Не отримали інструкцій щодо пiдтвердження?
          </Link>
        </Form>
      </MuiPickersUtilsProvider>
    </>
  );
};

SignUpForm.defaultProps = {
  client: {
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    phone: '',
    birthday: '',
  },
};
