export const BookingsByBookingDates = {
  en: {
    'Paid Bookings By Booking Dates': 'Paid Bookings By Booking Dates',
    'Date': 'Date',
    'Count': 'Count',
  },
  uk: {
    'Paid Bookings By Booking Dates': 'Сплачені бронювання по датах бронювання',
    'Date': 'Дата',
    'Count': 'Кількість',
  },
};
