import { useTranslation } from 'react-i18next';
import { ListWrapper, Table } from 'Components';
import { useList, } from 'Hooks';

const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'desc'
}

export const SystemOwners = ({ fetch_url, }) => {
  const { t } = useTranslation();
  const {
    pending, data, records_total, paginationParams, handleChangePagination,
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  const tableConfig = [
    {
      title: t('Name'),
      accessor: 'last_name',
      cellComponent: ({ first_name, last_name }) => `${last_name} ${first_name}`,
    },
    {
      title: t('Email'),
      accessor: 'email',
    }
  ];

  return (
    <ListWrapper title={`${t('System Owners')} (${records_total})`}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
};
