import {
  Container,
  makeStyles,
  Box,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ListWrapper, Button, Table, SearchInput } from 'Components';
import { useList } from 'Hooks';
import { deleteItem } from 'Helpers';

import { MemberDialog } from './MemberDialog';
import { AssignDialog } from './AssignDialog';
import {
  ActionsCell,
  ClientCell,
  PaymentStatusCell,
  PhoneCell,
  ResponsesCell,
  ServicesCell,
} from './cells';

const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'desc',
};

const useStyles = makeStyles((theme) => ({
  infoBlock: {
    marginBottom: '12px',
  },
  infoContainer: {
    marginBottom: '26px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  },
  search: { marginRight: theme.spacing(3) },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  service: {
    color: theme.palette.error.main,
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  chip: {
    marginLeft: '10px',
    background: '#f44336',
  },
  assignBtn: {
    marginRight: '8px',
  },
  providerInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  providerImg: {
    flexShrink: 0,
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: '12px',
  },
  unassigned: {
    color: '#ff0000',
  },
  assignedAndUsed: {
    color: '#007A00',
  },
  assignedNotUsed: {
    color: '#000000',
  },
  button: { marginLeft: theme.spacing(3) },
}));

export const BookingEvent = ({
  add_member_api_url,
  members_api_url,
  clients_search_url,
  event_id,
  prev_url,
  clients_url,
  booking_service_name,
  time_period,
  booking_provider_name,
  members_limit,
  new_clients_path,
  booking_provider_photo_url,
  notes,
  comment,
  export_api_url,
  multiple_bookings,
  ...props
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const memberDialog = React.useRef(null);
  const assignDialog = React.useRef(null);

  function handleOpenMemberDialog() {
    memberDialog.current.open();
  }

  function handleOpenAssignmentDialog({
    services_to_assign,
    client_first_name,
    client_last_name,
    client_id,
    client_birthday,
    assign_services_url,
  }) {
    return () => {
      //not assigned services
      const freeServices = services_to_assign.filter(
        ({ assigned_client_id }) => !assigned_client_id
      );
      //distinct services
      const services = [
        ...new Map(
          freeServices.map(({ service_name, service_id }) => {
            return [service_id, { id: service_id, name: service_name }];
          })
        ).values(),
      ];

      const client = {
        id: client_id,
        first_name: client_first_name,
        last_name: client_last_name,
        birthday: client_birthday,
      };
      assignDialog.current.open(services, assign_services_url, client);
    };
  }

  const {
    pending,
    data,
    records_total,
    paginationParams,
    handleChangePagination,
    changeSearch,
    updateList,
  } = useList({ fetchUrl: members_api_url, sorting: DEFAULT_SORT_PARAMS });

  function onDelete(res) {
    if (res.flash_type == 'success') {
      updateList();
    }
  }

  const tableConfig = React.useMemo(
    function () {
      return [
        {
          title: t('Client'),
          cellComponent: ({
            client_first_name,
            client_last_name,
            client_id,
            confirmed_client,
          }) => (
            <ClientCell
              client_first_name={client_first_name}
              client_last_name={client_last_name}
              confirmed_client={confirmed_client}
              client_id={client_id}
              clients_url={clients_url}
              new_clients_path={new_clients_path}
            />
          ),
        },
        {
          title: t('Phone'),
          cellComponent: ({ client_phone }) => (
            <PhoneCell client_phone={client_phone} />
          ),
          accessor: 'phone',
        },
        {
          title: t('Services'),
          cellComponent: ({ services_to_assign }) => (
            <ServicesCell
              services_to_assign={services_to_assign}
              clients_url={clients_url}
            />
          ),
        },
        {
          title: t('Comment'),
          accessor: 'comment',
        },
        {
          title: t('Responses'),
          cellComponent: ({ custom_field_responses }) => (
            <ResponsesCell custom_field_responses={custom_field_responses} />
          ),
        },
        {
          title: t('Payment status'),
          accessor: 'payment_status',
          cellComponent: ({ payment_status, liqpay_payment_id }) => (
            <PaymentStatusCell payment_status={payment_status} liqpay_payment_id={liqpay_payment_id} />
          ),
        },
        {
          title: t('Actions'),
          cellComponent: (item) => (
            <ActionsCell
              item={item}
              onClick={handleOpenAssignmentDialog(item)}
              onDelete={deleteItem(
                add_member_api_url,
                { id: item.id },
                onDelete
              )}
            />
          ),
        },
      ];
    },
    [i18n.language]
  );

  return (
    <>
      <Container className={classes.container}>
        <div className={classes.header}>
          <Typography
            component="h1"
            variant="h4"
          >
            <IconButton href={prev_url}>
              <i className="icon-left-big"></i>
            </IconButton>
            {booking_service_name}
          </Typography>
        </div>
        <Grid
          className={classes.infoContainer}
          container
          spacing={2}
        >
          <Grid
            className={classnames(classes.infoBlock, classes.providerInfo)}
            item
            xs={12}
            sm={5}
          >
            <img
              src={booking_provider_photo_url}
              className={classes.providerImg}
            />
            <div>
              <Typography
                component="h5"
                variant="h5"
              >
                {booking_provider_name}
              </Typography>
              {time_period}
              <br />
              <strong>{t('Members limit')}:</strong> {members_limit}
              <br />
              <strong>{t('Notes')}:</strong> {notes}
              <br />
              <strong>{t('Comment for provider')}:</strong> {comment}
              <br />
            </div>
          </Grid>
          <Grid
            className={classes.infoBlock}
            item
            xs={12}
            sm={3}
          ></Grid>
          <Grid
            className={classes.infoBlock}
            item
            xs={12}
            sm={4}
          >
            <strong>{t('Created')}:</strong> {props.creator}, {props.created_at}
            <br />
            <strong>{t('Updated')}:</strong> {props.updater}, {props.updated_at}
          </Grid>
        </Grid>
      </Container>

      <ListWrapper
        title={`${t('Members')} (${records_total})`}
        headerAddon={
          <Box>
            <span className={classes.search}>
              <SearchInput
                onChange={changeSearch}
                placeholder={t('Search')}
                autoFocus
                name="value"
              />
            </span>
            <Button onClick={handleOpenMemberDialog}>{t('Add member')}</Button>
            {multiple_bookings && (
              <Button
                color="primary"
                href={export_api_url}
                className={classes.button}
              >
                {t('Export CSV')}
              </Button>
            )}
          </Box>
        }
      >
        <Table
          config={tableConfig}
          pending={pending}
          data={data}
          pagination={{
            records_total,
            paginationParams,
            handleChangePagination,
          }}
        />
      </ListWrapper>
      <MemberDialog
        ref={memberDialog}
        clients_api_url={clients_search_url}
        apiUrl={add_member_api_url}
        booking_event_id={event_id}
        onSubmit={updateList}
      />
      {!pending && (
        <AssignDialog
          ref={assignDialog}
          clients_api_url={clients_search_url}
          onSubmit={updateList}
        />
      )}
    </>
  );
};
