import {
  Typography,
  makeStyles,
  IconButton,
  Box,
  Container,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import { ImLocation } from 'react-icons/im';
import { BiWorld } from 'react-icons/bi'
import { useList } from 'Hooks';
import { pushToArray } from 'Helpers';
import { Preloader } from 'Components';
import { OnlineServices } from "./OnlineServices";

const useStyles = makeStyles((theme) => ({
  preloader: {
    width: '75%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px',

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(2)
    },
  },
  title: {
    margin: `${theme.spacing(1)}px 0`,
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px'
    },
  },
  listWrapper: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    },
  },
  switch: {
    width: '25%',
    height: '30vh',
    position: '-webkit-sticky',
    position: 'sticky',
    top: '15%',

    [theme.breakpoints.down('xs')]: {
      position: 'static',
      width: '100%',
      height: '100%'
    },
  },
  switchTitle: {
    fontSize: '24px',
    color: 'inherit',
    marginBottom: '16px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '22px'
    }
  },
  switchLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  switchLabel: {
    textTransform: 'uppercase',

    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  icon: {
    color: '#19456B',
    fontSize: '20px',
    padding: '8px'
  }
}));

export const ServicesList = ({ fetch_url, services_url, locations, initialFilters }) => {
  const classes = useStyles();

  const {
    pending, data, filters, setFilters
  } = useList({
    fetchUrl: fetch_url, initialFilters, sorting: {
      sort_column: 'order_position',
      sort_direction: 'asc',
    },
  });

  const onLocationChange = ({ target }) => {
    const value = Number(target.value);

    const locations = !target.checked
      ? filters.locations.filter(id => id !== value)
      : pushToArray(filters.locations, value)

    setFilters({ ...filters, locations })
  }

  const renderServices = (pending) => {
    if (pending) {
      return (
        <Box className={classes.preloader}>
          <Preloader />
        </Box>
      )
    }

    return (
      <OnlineServices
        services={data}
        servicesUrl={services_url}
      />
    )
  }

  const renderLocation = (location) => {
    return (
      <div
        key={location.id}
        className={classes.switchLabelContainer}
      >
        <FormControlLabel
          className={classes.switchLabel}
          control={
            <Switch
              checked={filters.locations.includes(location.id)}
              onChange={onLocationChange}
              value={location.id}
              color="primary"
            />
          }
          label={location.name}
        />
        <div>
          <IconButton
            href={location.address_link}
            target="blank"
            className={classes.icon}
            disabled={!location.address_link}
          >
            <ImLocation />
          </IconButton>
          <IconButton
            href={location.website_link}
            target="blank"
            className={classes.icon}
            disabled={!location.website_link}
          >
            <BiWorld />
          </IconButton>
        </div>
      </div>
    )
  }

  return (
    <Container className={classes.container}>
      <div className={classes.header}>
        <Typography
          className={classes.title}
          component="h1"
          variant="h4"
        >
          Клубні картки та Абонементи
        </Typography>
      </div>
      <Box className={classes.listWrapper}>
        {renderServices(pending)}
        <FormControl className={classes.switch}>
          <FormLabel className={classes.switchTitle}>
            Оберіть локацію
          </FormLabel>
          <FormGroup>
            {locations.map(renderLocation)}
          </FormGroup>
        </FormControl>
      </Box>
    </Container>
  )
}
