import {
  Button,
  Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'Components';

export default ({ useCommonStyles, authenticity_token, confirmation_url, log_in_url, sign_up_url, forgot_password_url, errors }) => {
  const { t } = useTranslation();
  const classes = useCommonStyles();
  return (
    <>
      <form className={classes.form} action={confirmation_url} method="post">
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticity_token}
        />
        <FormInput
          name="client[email]"
          label={t('Email')}
          autoComplete="email"
          validationMessages={errors?.email}
          required
          autoFocus
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Надiслати пiдтвердження
        </Button>
        <Link href={log_in_url}>Вхiд</Link>
        <br />
        <Link href={sign_up_url}>Реєстрація</Link>
        <br />
        <Link href={forgot_password_url}>Забули пароль?</Link>
      </form>
    </>
  )
}