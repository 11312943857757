import { Box, IconButton } from '@material-ui/core';
import { CALENDAR_ITEM_TYPES } from 'Constants';
import classnames from 'classnames';
import { useStyles } from './BookingCalendarStyles';

const EventCard = ({
  item, type, isSelecting, isHidden, onResize, onSelect, providerId, url
}) => {
  const classes = useStyles();
  const isTimeBlockEventType = type === CALENDAR_ITEM_TYPES.TIME_BLOCK_EVENT;

  return (
    <Box className={classnames(classes.event, {
      [classes.hiddenEvent]: isHidden,
      [classes.selectingTime]: isSelecting,
      [classes.onlyBlockedTimeEvent]: isTimeBlockEventType
    })} style={{...item.style, background: item.color}}>
      {!isTimeBlockEventType && (
        <a className={classes.showEventLink} href={url}></a>
      )}
      <Box className={classes.resizeTopArea}
        onMouseDown={onResize(item, providerId, item.end_period_index, type)}></Box>
      {item.problem_with_payment && (
        <IconButton size="small" className={classes.warningIcon}>
          <i className="icon-info"></i>
        </IconButton>
      )}
      <IconButton size="small" className={classes.editEventIcon}
        onClick={onSelect(item, type, providerId)}>
        <i className="icon-edit"></i>
      </IconButton>
      {item.created_by_client && (
        <Box className={classnames(classes.paidMark, {
          [classes.paymentInProgress]: !item.paid,
          [classes.paidSuccessfully]: item.paid,
        })}></Box>
      )}
      <span className={classes.eventTitle}>{item.start_time} - {item.end_time}<br /> {item.client_full_name}</span>
      <span className={classnames(classes.eventContent, 'event-service')}>{item.booking_service_name}</span>
      <span className={classnames(classes.eventContent, 'event-note')}>{item.notes}</span>

      <Box className={classes.resizeBottArea}
        onMouseDown={onResize(item, providerId, item.start_period_index, type)}></Box>
    </Box>
  )
}

export default EventCard
