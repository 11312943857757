import { useMemo, useRef } from 'react';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { ListWrapper, Table } from 'Components';
import { AJAX } from 'Helpers';
import { CLIENT_PAGE_TABLES_PAGE_SIZES } from 'Constants';
import { usePagination } from 'Hooks';
import confirmationStore from 'Helpers/confirmation';
import notificationsStore from 'Helpers/notifications';

import OrderReceiptDialog from '../OrderReceiptDialog';
import { useClientOrdersQuery } from 'queries';
import { useClientContext } from '../providers/ClientShowProvider';
import {
  CLIENT_ORDERS_QUERY_KEY,
  CURRENT_PURCHASED_NOT_ACTIVE_SERVICES_QUERY_KEY,
} from 'queries';
import { DEFAULT_SORT_PARAMS, PAGINATION_PARAMS } from '../ClientShow';

import { ClientOrder } from './type';

const ClientOrderTable = () => {
  const { client_user_order_url, client_name } = useClientContext();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();

  const orderReceiptDialog = useRef<HTMLFormElement>(null);

  const paginationData = usePagination(PAGINATION_PARAMS, DEFAULT_SORT_PARAMS);
  const { paginationParams, handleChangePagination } = paginationData;

  const { data: clientOrdersData, isLoading } = useClientOrdersQuery({
    fetchUrl: client_user_order_url,
    paginationParams,
  });
  const { data: services, records_total } = clientOrdersData || {};

  const onClientOrderRemove = (id: number) => {
    const confirmText = t('CancelOrderConfirmText', {
      order_id: id,
      client_name,
    });

    confirmationStore.open(async () => {
      const res = await AJAX.delete(`${client_user_order_url}/${id}`);
      if (res.status === 'error') {
        return notificationsStore.add({
          type: 'error',
          text: res.errors.join(', '),
        });
      }

      queryClient.invalidateQueries([CLIENT_ORDERS_QUERY_KEY]);
      queryClient.invalidateQueries([
        CURRENT_PURCHASED_NOT_ACTIVE_SERVICES_QUERY_KEY,
      ]);
    }, confirmText);
  };

  const tableConfig = useMemo(() => {
    return [
      {
        title: t('Order ID'),
        accessor: 'id',
      },
      {
        title: t('User'),
        accessor: 'user',
      },
      {
        title: t('Price'),
        accessor: 'price',
      },
      {
        title: t('Price With Discount'),
        accessor: 'price_with_discount',
      },
      {
        title: t('Buy Time'),
        accessor: 'created_at',
        sortable: true,
      },
      {
        title: t('Actions'),
        cellComponent: (clientOrder: ClientOrder) => (
          <>
            {clientOrder.has_receipt && (
              <IconButton
                size="small"
                onClick={() => onReceiptShow(clientOrder)}
              >
                <i className="icon-bill"></i>
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={() => onClientOrderRemove(clientOrder.id)}
            >
              <i className="icon-block"></i>
            </IconButton>
          </>
        ),
      },
    ];
  }, [i18n.language]);

  const onReceiptShow = (clientOrder: ClientOrder) => {
    orderReceiptDialog.current?.open(clientOrder);
  };

  return (
    <ListWrapper title={t('Client Orders')}>
      <Table
        config={tableConfig}
        pending={isLoading}
        data={services}
        rowsPerPageOptions={CLIENT_PAGE_TABLES_PAGE_SIZES}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
      <OrderReceiptDialog abilityToSendEmail ref={orderReceiptDialog} />
    </ListWrapper>
  );
};

export default ClientOrderTable;