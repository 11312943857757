import { Button, makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  descriptionWrapper: {
    marginBottom: '8px'
  },
  description: {
    lineHeight: '150%'
  },
}));

export const Description = ({ showDescription }) => {
  const classes = useStyles();

  return (
    <Box className={classes.descriptionWrapper}>
      <Button className={classes.btn} color="primary" onClick={showDescription}>
        Детальніше
      </Button>
    </Box>
  )
}
