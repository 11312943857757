import { Grid } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import {
  ListWrapper,
  Filters,
  Chart,
  DatePicker,
} from 'Components';
import { AJAX } from 'Helpers';

export const SubscriptionsStatistics = ({ fetch_url, }) => {
  const { t } = useTranslation();

  const filters = React.useRef(null);
  const [state, setState] = React.useState({
    loading: true,
    data: {},
  });

  async function fetchData() {
    const { from, till } = filters.current.values;
    setState({
      loading: true,
      data: {},
    });

    const res = await AJAX.get(fetch_url, {
      body: {
        sort_column: "created_at",
        sort_direction: "asc",
        filters: {
          ...((!!from || !!till) && {
            date: {
              ...(!!from && { from }),
              ...(!!till && { till }),
            },
          }),
        },
      }
    })

    if (!res.data) {
      setState({
        ...state,
        loading: false,
      })
      return
    }

    const data = res.data.reduce((val, item) => {
      return {
        categories: [...val.categories, item.date],
        series: [
          {
            name: t('Active Subscriptions'),
            data: [
              ...(!!val.series[0] ? val.series[0].data : []),
              item.active_subscriptions_count
            ]
          },
          {
            name: t('Suspended Subscriptions'),
            data: [
              ...(!!val.series[1] ? val.series[1].data : []),
              item.suspended_subscriptions_count
            ]
          }
        ]
      }
    }, { categories: [], series: [] });

    setState({
      data,
      loading: false,
    })
  }

  return (
    <ListWrapper title={`${t('Subscriptions Statistics')}`}>
      <Filters
        ref={filters}
        onSubmit={fetchData}
        initialValues={{
          from: moment().subtract(1, 'months').startOf('month').format(),
          till: moment().add(1, 'day').format(),
          locations: []
        }}
        onInit={fetchData}
      >
        <DatePicker label={t('From')} name="from" />
        <DatePicker label={t('Till')} name="till" />
      </Filters>
      <Grid item xs={12} sm={12}>
        <Chart
          type="column"
          tooltipHeaderText={t('Month')}
          yText={t('Count')}
          tooltip={{
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          }}
          yAxis={{
            stackLabels: {
              enabled: true,

            }
          }}
          plotOptions={{
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true
              }
            }
          }}
          pending={state.loading}
          {...state.data}
        />
      </Grid>
    </ListWrapper>
  );
}