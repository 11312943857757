export const Statistics = {
  en: {
    'Visits By Days': 'Visits By Days',
    'Visits By Month': 'Visits By Month',
    'Income By Days': 'Income By Days',
    'Income By Month': 'Income By Month',
    'Income By Card': 'Income By Card',
    'Income By Cash': 'Income By Cash',
    'Income By Online': 'Income By Online',
    'Income By Days Without Trainer Payments':
      'Income By Days Without Trainer Payments',
    'Income By Month Without Trainer Payments':
      'Income By Month Without Trainer Payments',
    'Locations': 'Locations',
    Date: 'Date',
    'Visits Count': 'Visits Count',
    Amount: 'Amount',
    Service: 'Service',
    User: 'User',
    Client: 'Client',
    'Visits for today': 'Visits for today',
    'Income for today': 'Income for today',
    Total: 'Total'
  },
  ru: {
    'Visits By Days': 'Посещения По Дням',
    'Visits By Month': 'Посещения За Месяц',
    'Income By Days': 'Доход По Дням',
    'Income By Month': 'Доход За Месяц',
    'Income By Card': 'Поступления На Карту',
    'Income By Cash': 'Поступления Наличными',
    'Income By Online': 'Поступления Online',
    'Income By Days Without Trainer Payments':
      'Доход За День Без Учёта Выплат Тренерам',
    'Income By Month Without Trainer Payments':
      'Доход За Месяц Без Учёта Выплат Тренерам',
    'Locations': 'Локации',
    Date: 'Дата',
    'Visits Count': 'Количество Посещений',
    Amount: 'Сумма',
    Service: 'Сервис',
    User: 'Пользователь',
    Client: 'Клиент',
    'Visits for today': 'Посещений за сегодня',
    'Income for today': 'Доход за сегодня'
  },
  uk: {
    'Visits By Days': 'Відвідування По Днях',
    'Visits By Month': 'Відвідування За Місяць',
    'Income By Days': 'Дохід По Днях',
    'Income By Month': 'Дохід За Місяць',
    'Income By Card': 'Дохід Карткою',
    'Income By Cash': 'Дохід Готівкою',
    'Income By Online': 'Дохід Online',
    'Income By Days Without Trainer Payments':
      'Дохід По Днях Без Врахування Виплат Тренерам',
    'Income By Month Without Trainer Payments':
      'Дохід По Місяцях Без Врахування Виплат Тренерам',
    'Locations': 'Локації',
    Date: 'Дата',
    'Visits Count': 'Кількість Відвідувань',
    Amount: 'Сума',
    Service: 'Сервіс',
    User: 'Користувач',
    Client: 'Клієнт',
    'Visits for today': 'Відвідування за сьогодні',
    'Income for today': 'Дохід за сьогодні',
    Total: 'Разом'
  },
};
