import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Divider,
  Popover,
} from '@material-ui/core';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ButtonWithMenu } from 'Components';
import { AJAX } from 'Helpers';
import { LOCALES_LIST } from 'Constants';
import { findInArrayBy } from 'Helpers';

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarTitle: {
    marginRight: '15px'
  },
  userName: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      height: '100vh',
      zIndex: '200',
      transform: 'translateX(-100%)'
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      transform: 'translateX(0)'
    }
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },

  },
  menuItemIcon: {
    fontSize: theme.typography.h5.fontSize,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  currentPage: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '& i': {
      color: theme.palette.primary.contrastText,
    },
  },
  devidingLine: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto'
    }
  },
  locationAction: {
    fontSize: '20px',
    textTransform: 'inherit',
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px',
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  appVersion: {
    fontSize: 12
  }
}));

const SideMenuListItem = ({ icon, text, href, className, iconClass }) => {
  return (
    <ListItem component="a" button href={href} className={className}>
      <ListItemIcon>
        <i className={classnames(icon, iconClass)}></i>
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
};

const SideMenuDropdownItem = ({ icon, text, submenu, classes, t }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;
  return (
    <>
      <ListItem button aria-describedby={id}
        onClick={handleClick}>
        <ListItemIcon>
          <i className={classnames(icon, classes.menuItemIcon)}></i>
        </ListItemIcon>
        <ListItemText primary={t(text)} />
      </ListItem>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}>
        <List>
          {submenu.map(({ active, href, text }) => (
            <ListItem key={href} component="a" button href={href}
              className={classnames({ [classes.currentPage]: active })}>
              <ListItemText primary={t(text)} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )

}

const LocaleSwitcher = ({ url }) => {
  const currentLocale = i18next.languages[0];
  function bindClick(locale) {
    return function () {
      AJAX.post(url, {
        body: {
          locale,
        },
      });
      i18next.changeLanguage(locale);
    };
  }
  return (
    <ButtonWithMenu trigger={<Button color="inherit">{currentLocale}</Button>}>
      {LOCALES_LIST.map((locale) => (
        <MenuItem key={locale} onClick={bindClick(locale)}>
          {locale}
        </MenuItem>
      ))}
    </ButtonWithMenu>
  );
};

export const LocationSwitcher = ({ url, locations, current_location_id, btnClassName }) => {
  const currentLocation = React.useMemo(() => {
    return findInArrayBy(locations, current_location_id || locations[0].id)
  }, [locations, current_location_id])

  const onChange = React.useCallback((id) => {
    return async function () {
      const res = await AJAX.post(url, {
        body: {
          user_session: { current_location_id: id }
        },
      });
      if (res.status === 'ok') {
        window.location.reload()
      }
    }
  }, []);

  return (
    <ButtonWithMenu trigger={<Button color="inherit" className={btnClassName}>{currentLocation.name}</Button>}>
      {locations.length > 1 && locations.map(({ id, name }) => (
        <MenuItem key={id} onClick={onChange(id)}>
          {name}
        </MenuItem>
      ))}
    </ButtonWithMenu>
  )
};

export const AppLayout = ({
  main_nav,
  dropdown_nav,
  log_out_props,
  change_languages_url,
  children,
  user_name,
  company_name,
  location_props,
}) => {

  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classnames(classes.appBar, {
          [classes.appBarShift]: isOpen,
        })}
      >
        <Toolbar>
          <IconButton
            edge="start"
            className={classnames(classes.menuButton, {
              [classes.hide]: isOpen,
            })}
            color="inherit"
            aria-label="menu"
            onClick={toggleIsOpen}
          >
            <i className="icon-menu"></i>
          </IconButton>
          {location_props && (
            <LocationSwitcher {...location_props} btnClassName={classes.locationAction} />
          )}
          <Typography className={classes.userName}>{user_name}</Typography>

          <Divider orientation="vertical" flexItem light variant="middle"
            className={classes.devidingLine} />
          <LocaleSwitcher url={change_languages_url} />
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        variant="permanent"
        open={isOpen}
        onClose={toggleIsOpen}
        className={classnames(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: classnames({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={toggleIsOpen}>
            <i className="icon-left-open"></i>
          </IconButton>
        </div>
        <Divider />
        <List>
          {main_nav.map(({ icon, href, text, active }) => (
            <SideMenuListItem
              key={href}
              icon={icon}
              iconClass={classes.menuItemIcon}
              href={href}
              className={classnames({ [classes.currentPage]: active })}
              text={t(text)}
              classes={classes}
            />
          ))}
          {!!dropdown_nav && !!dropdown_nav.length && (
            <>
              <Divider />
              {dropdown_nav.map(({ icon, href, text, active, submenu }, index) => {
                if (!submenu) {
                  return (
                    <SideMenuListItem key={href} icon={icon} iconClass={classes.menuItemIcon}
                      href={href} className={classnames({ [classes.currentPage]: active })}
                      text={t(text)} />
                  )
                }

                return (
                  <SideMenuDropdownItem key={index} icon={icon} text={text}
                    submenu={submenu} classes={classes} t={t} />
                )
              })}
            </>
          )}
          <Divider />
          <form method="post" action={log_out_props.url}>
            <input type="hidden" name="_method" value="delete" />
            <input
              type="hidden"
              name="authenticity_token"
              value={log_out_props.token}
            />
            <ListItem component="button" button type="submit">
              <ListItemIcon>
                <i className={classnames('icon-logout', classes.menuItemIcon)}></i>
              </ListItemIcon>
              <ListItemText primary={t('Log Out')} />
            </ListItem>
          </form>
          <ListItem className={classes.appVersion}>
            <a href='https://docs.google.com/document/d/1nd7aFK0dCXjWXjx0bD-Y2PjnHLxbmpETpLADVm-AqiQ/edit?usp=sharing' target='_blank'>v3.0.0</a>
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};
