import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { SEND_DATES_FORMAT } from 'Constants';

import { ListWrapper, Filters, DatePicker } from 'Components';

import { AJAX } from 'Helpers';

import { IncomeTable } from './Table';

import {
  IncomesByLocations,
  IncomeStatisticsFilters,
  IncomeStatisticsProps,
} from './types';

const DEFAULT_FILTER = {
  from: moment().format(SEND_DATES_FORMAT),
  till: moment().add(1, 'day').format(SEND_DATES_FORMAT),
};

export const IncomeStatistics = ({ fetch_url }: IncomeStatisticsProps) => {
  const { t } = useTranslation();
  const filters = useRef<IncomeStatisticsFilters>(null!);
  const [incomesByLocations, setIncomesByLocations] = useState<
    IncomesByLocations[]
  >([]);

  const fetchData = async () => {
    const { from, till } = filters.current?.values;

    const res = await AJAX.get(fetch_url, {
      body: {
        filters: {
          created_at: {
            from,
            till,
          },
        },
      },
    });

    if (!res.data) {
      return setIncomesByLocations(incomesByLocations);
    }

    setIncomesByLocations(res.data);
  };

  return (
    <ListWrapper title={t('Income Statistics')}>
      <Filters
        ref={filters}
        onSubmit={fetchData}
        initialValues={DEFAULT_FILTER}
        onInit={fetchData}
      >
        <DatePicker
          label={t('From')}
          name="from"
        />
        <DatePicker
          label={t('Till')}
          name="till"
        />
      </Filters>
      {!incomesByLocations.length && t('No Data Provided')}
      <IncomeTable incomesByLocations={incomesByLocations} />
    </ListWrapper>
  );
};
