export const ServiceCategories = {
  en: {
    'Name': 'Name',
    'Visible': 'Visible',
    'Create Service Category': 'Create Service Category',
  },
  ru: {
    'Name': 'Название',
    'Visible': 'Видимый',
    'Create Service Category': 'Создать Категорию Сервиса',
  },
  uk: {
    'Name': 'Назва',
    'Visible': 'Видимий',
    'Create Service Category': 'Створити Категорію Сервісу',
  },
};

export const ServiceCategoryForm = {
  en: {
    ...ServiceCategories.en,
    'New Service Category': 'New Service Category',
    'Edit Service Category': 'Edit Service Category',
    'Update Service Category': 'Update Service Category',
  },
  ru: {
    ...ServiceCategories.ru,
    'New Service Category': 'Создание Категории Сервиса',
    'Edit Service Category': 'Редактирование Категории Сервиса',
    'Update Service Category': 'Обновить Категорию Сервиса',
  },
  uk: {
    ...ServiceCategories.uk,
    'New Service Category': 'Створення Категорії Сервісу',
    'Edit Service Category': 'Редагування Категорії Сервісу',
    'Update Service Category': 'Оновити Категорію Сервісу',
  },
};
