import { Grid, Box, Container, Typography, IconButton } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { Button, Preloader, DatePicker } from 'Components';
import { findInArrayBy, findIndexInArrayBy, AJAX } from 'Helpers';
import { SEND_DATES_FORMAT, TIME_PERIODS, CALENDAR_ITEM_TYPES } from 'Constants';
import { EventDialog } from './event_dialog';
import { useStyles, MIN_COLUMN_WIDTH } from './BookingCalendarStyles';
import CalendarBody from './CalendarBody';

const INITIAL_CURRENT_DATE = moment().format(SEND_DATES_FORMAT);
const SCROLL_VALUE_TO_CHANGE = MIN_COLUMN_WIDTH * 2;

function bookingItemsToState(items, providers) {
  return items.map(({ booking_provider_id, data }) => {
    const currentProvider = findInArrayBy(providers, booking_provider_id);
    const { booking_periods } = currentProvider;

    return {
      booking_provider_id,
      data: data.map((item) => {
        const startPeriodIndex = findIndexInArrayBy(booking_periods, item.booking_period_start_id)
        const endPeriodIndex = findIndexInArrayBy(booking_periods, item.booking_period_end_id)

        //active true for future features. ability to create/edit event at appropriate time
        return {
          ...item,
          active: true,
          start_period_index: startPeriodIndex,
          end_period_index: endPeriodIndex,
          start_time: booking_periods[startPeriodIndex]?.start_time,
          end_time: booking_periods[endPeriodIndex]?.end_time,
        }
      })
    }
  });
}

export const BookingCalendar = ({
  fetch_url, basic_event_api_url, only_time_block_event_api_url, available_booking_providers_url,
  company_url, booking_notes_api_url, booking_services_api_url, clients_search_url,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const calendarRef = React.useRef(null);
  const calendarHeadRef = React.useRef(null);
  const calendarBodyRef = React.useRef(null);

  const [currentDate, setCurrentDate] = React.useState(INITIAL_CURRENT_DATE);
  const [state, setState] = React.useState({
    pending: false,
    data: {},
    timeScale: [],
  })

  React.useEffect(() => {
    getDataByDate()
  }, [currentDate]);

  async function getDataByDate() {
    setState({ ...state, pending: true })
    const res = await AJAX.get(fetch_url, {
      body: { date: currentDate }
    })

    if (!res.data) {
      return setState({ ...state, pending: false })
    }

    const {
      booking_providers, booking_event_basics, booking_event_only_time_blocks, booking_notes
    } = res.data;

    //calculating periods which must be displayed. (from and to index)
    const visibleSchedulePeriodIndexes = booking_providers.reduce((res, { booking_periods }) => {
      const activeValues = booking_periods.map(({ active }) => active);
      let firstActiveIndex = activeValues.indexOf(true);
      let lastActiveIndex = activeValues.lastIndexOf(true);

      if (firstActiveIndex === -1 || lastActiveIndex === -1) {
        return res
      }

      if (booking_periods[firstActiveIndex].start_time.includes('30')) {
        firstActiveIndex -= 1
      }

      if (booking_periods[lastActiveIndex].end_time.includes('30')) {
        lastActiveIndex += 1
      }

      return {
        from: !res.from || firstActiveIndex < res.from ? firstActiveIndex : res.from,
        to: !res.to || lastActiveIndex > res.to ? lastActiveIndex : res.to,
      }

    }, { from: null, to: null });

    if (!visibleSchedulePeriodIndexes.from && !visibleSchedulePeriodIndexes.to) {
      return setState({
        pending: false,
        timeScale: [],
        data: {
          bookingProviders: [],
          basicEvents: [],
          timeBlockEvents: [],
          notes: []
        }
      })
    }

    const bookingProvidersWithPeriods = booking_providers.filter(({ booking_periods }) => !!booking_periods.length)

    const bookingProviders = bookingProvidersWithPeriods.map(({ booking_periods, ...provider }) => {
      return {
        ...provider,
        booking_periods: booking_periods.slice(visibleSchedulePeriodIndexes.from, visibleSchedulePeriodIndexes.to + 1)
      }
    });

    const basicEvents = bookingItemsToState(booking_event_basics, bookingProviders);
    const timeBlockEvents = bookingItemsToState(booking_event_only_time_blocks, bookingProviders);
    const notes = bookingItemsToState(booking_notes, bookingProviders);

    const timeScalePeriod = TIME_PERIODS.slice(visibleSchedulePeriodIndexes.from, visibleSchedulePeriodIndexes.to + 1)
    const timeScale = timeScalePeriod.filter((item) => !item.includes('30'))

    setState({
      pending: false,
      timeScale,
      data: {
        bookingProviders,
        basicEvents,
        timeBlockEvents,
        notes
      }
    })
  }

  function getProviderPeriods(providerId, bookingProviders = state.data.bookingProviders) {
    return findInArrayBy(bookingProviders, providerId)?.booking_periods
  }

  const eventDialog = React.useRef(null);

  function showEventDialog() {
    eventDialog.current.open({}, CALENDAR_ITEM_TYPES.BASIC_EVENT)
  }

  function setInitialCurrentDate() {
    setCurrentDate(INITIAL_CURRENT_DATE)
  }

  function handleChangeCurrentDate({ target }) {
    setCurrentDate(target.value)
  }

  const currentDateHead = React.useMemo(() => {
    const currentMomentDate = moment(currentDate)

    function setPrevDate() {
      setCurrentDate(currentMomentDate.subtract(1, 'days').format(SEND_DATES_FORMAT))
    }

    function setNextDate() {
      setCurrentDate(currentMomentDate.add(1, 'days').format(SEND_DATES_FORMAT))
    }

    return (
      <Box className={classes.dayHeaderWrapper}>
        <IconButton
          className={classes.iconLeft}
          onClick={setPrevDate}
        >
          <i className="icon-left-open"></i>
        </IconButton>
        <Typography
          className={classes.dayHeaderTitle}
          component="h6"
          variant="h6"
        >
          {currentMomentDate.format('dddd, DD.MM.YYYY')}
        </Typography>
        <IconButton
          className={classes.iconRight}
          onClick={setNextDate}
        >
          <i className="icon-left-open"></i>
        </IconButton>
      </Box>
    )
  }, [currentDate]);

  function handleScrollLeft() {
    calendarRef.current.scrollLeft -= SCROLL_VALUE_TO_CHANGE
  }

  function handleScrollRight() {
    calendarRef.current.scrollLeft += SCROLL_VALUE_TO_CHANGE
  }

  function onCloseEventDialog() {
    calendarBodyRef.current.clearSelectedItem()
  }

  function showItemForm(initialData, type) {
    eventDialog.current.open(initialData, type)
  }

  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          className={classes.calendarHeader}
        >
          <Grid
            item
            xs={12}
            sm={4}
          >
            <DatePicker
              value={currentDate}
              onChange={handleChangeCurrentDate}
              label={t('Current Date')}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            {currentDateHead}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            className={classes.headActions}
          >
            <Button
              className={classes.headAction}
              onClick={setInitialCurrentDate}
            >
              {t('Show Today')}
            </Button>
            <Button
              className={classes.headAction}
              variant="contained"
              color="primary"
              onClick={showEventDialog}
            >
              {t('Create')}
            </Button>
          </Grid>
        </Grid>
        {state.pending && (
          <Box className={classes.preloader}>
            <Preloader />
          </Box>
        )}
        {!state.pending && !state.data.bookingProviders?.length && (
          <p className={classes.dataMessage}>{t('No Data Provided')}</p>
        )}
      </Container>
      {!state.pending && !!state.data.bookingProviders?.length && (
        <>
          <Box className={classes.calendarActions}>
            <IconButton
              className={classes.iconLeft}
              onClick={handleScrollLeft}
            >
              <i className="icon-left-open"></i>
            </IconButton>
            <IconButton
              className={classes.iconRight}
              onClick={handleScrollRight}
            >
              <i className="icon-left-open"></i>
            </IconButton>
          </Box>
          <Box
            ref={calendarRef}
            id="calendar"
            className={classes.dayWrapper}
          >
            <Box
              ref={calendarHeadRef}
              id="calendar-head"
              className={classes.dayHead}
            >
              <Box className={classes.emptyHeadCol}></Box>
              <Box className={classes.dayHeadColumns}>
                {state.data.bookingProviders?.map(({ id, name }) => (
                  <Box
                    key={id}
                    className={classes.dayHeadColumn}>
                    <div className={classes.columnTitle}
                      dangerouslySetInnerHTML={{ __html: name }}></div>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={classes.dayBody}>
              <Box className={classes.hours}>
                {state.timeScale.map((hour) => (
                  <span className={classes.hour} key={hour}>{hour}</span>
                ))}
              </Box>
              <CalendarBody
                ref={calendarBodyRef}
                providers={state.data.bookingProviders}
                basicEvents={state.data.basicEvents}
                notes={state.data.notes}
                timeBlockEvents={state.data.timeBlockEvents}
                company_url={company_url}
                showItemForm={showItemForm}
              />
            </Box>
          </Box>
        </>
      )}
      <EventDialog
        ref={eventDialog}
        fetchProvidersUrl={available_booking_providers_url}
        providers={state.data.bookingProviders}
        basic_event_api_url={basic_event_api_url}
        only_time_block_event_api_url={only_time_block_event_api_url}
        onCloseDialog={onCloseEventDialog}
        onSubmit={getDataByDate}
        getProviderPeriods={getProviderPeriods}
        notes_api_url={booking_notes_api_url}
        services_api_url={booking_services_api_url}
        clients_api_url={clients_search_url}
      />
    </>
  );
}

