import { FormInput, } from 'Components';
import { IMaskInput } from 'react-imask';
import { v1 as uuidv1 } from 'uuid';

function TextMaskCustom(props) {
  const input = React.useRef(null);
  const { inputRef, onChange, ...other } = props;
  function handleChange(val) {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("change", false, true);
    const e = {
      ...evt,
      target: {
        ...input.current.element,
        value: val,
      },
    }
    onChange(e);
  }
  return (
    <IMaskInput
      {...other}
      ref={(ref) => {
        input.current = ref ? ref.element : input.current;
        inputRef(ref ? ref.element : null);
      }}
      mask='num'
      unmask={true}
      blocks={{
        num: {
          // nested masks are available!
          mask: Number,
          thousandsSeparator: ' '
        }
      }}
      onAccept={handleChange}
      variant="outlined"
      placeholderChar={'\u2000'}
    />
  );
}

export const MoneyInput = React.memo(({ value, name, onChange, ...props }) => (
  <>
    <input type="hidden" name={name} value={value} />
    <FormInput InputProps={{
      inputComponent: TextMaskCustom,
      onChange,
      value: '' + value || '',
    }} {...props} />
  </>
));

MoneyInput.defaultProps = {
  id: uuidv1(),
  margin: 'dense',
}
