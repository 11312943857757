import { Button, } from '@material-ui/core';
import { FormInput } from 'Components';
import getToken from 'Helpers/getToken';
import { useTranslation } from 'react-i18next';

export default ({ useCommonStyles, auth_url }) => {
  const classes = useCommonStyles();
  const { t } = useTranslation();

  return (
    <>
      <form className={classes.form} action={auth_url} method="post">
        <input
          type="hidden"
          name="authenticity_token"
          value={getToken()}
        />
        Спочатку вкажи свій e-mail і ми спробуємо знайти тебе
        <FormInput
          name="client[email]"
          label="Email"
          autoComplete="email"
          required
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t('next')}
        </Button>
      </form>
    </>
  )
}