import { Fragment } from 'react';
import { useTranslation, } from 'react-i18next';
import { IconButton, makeStyles, Link } from '@material-ui/core';
import { ListWrapper, Table, Filters, Autocomplete, DatePicker, MultiSelect} from 'Components';
import { useList, } from 'Hooks';
import { EVENT_TYPES } from 'Constants';

const useStyles = makeStyles(() => ({
  itemCol: {
    minWidth: '85px'
  },
  itemText: {
    margin: 0
  },
  unassigned: {
    color: '#ff0000'
  },
  assignedAndUsed: {
    color: '#007A00'
  }
}));

export const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'desc'
}

export const AllBookings = ({ fetch_url, booking_providers, locations, booking_services, clients_url, booking_event_basics_url }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const tableConfig = [
    {
      title: t('Location'),
      accessor: 'location_name',
    },
    {
      title: t('Provider'),
      accessor: 'booking_provider_name',
    },
    {
      title: t('Booking Service'),
      accessor: 'booking_service_name',
    },
    {
      title: t('Time'),
      cellComponent: ({ date, time_period }) => {
        return (
          <div className={classes.itemCol}>
            <p className={classes.itemText}>{date}</p>
            <p className={classes.itemText}>{time_period}</p>
          </div>
        )
      }
    },
    {
      title: t('Client'),
      accessor: 'client_full_name',
      cellComponent: ({ client_id, client_full_name }) => {
        return (
          <Link href={`${clients_url}/${client_id}`}>{client_full_name}</Link>
        )
      }
    },
    {
      title: t('Services'),
      cellComponent: (item) => {
        return item.services_to_assign.map(({service_id, status, service_name}) => {

          return (
            <Fragment key={service_id}>
              <p className={classnames(classes.itemText, {
                [classes.unassigned]: EVENT_TYPES.UNASSIGNED === status,
                [classes.assignedAndUsed]: EVENT_TYPES.ASSIGNED_AND_USED === status,
              })}>{service_name}</p>
            </Fragment>
          )
        }
        )
      },
    },
    {
      title: t('Created At'),
      accessor: 'created_at',
      sortable: true,
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }) => {
        return (
          <IconButton size="small" component='a' target="_blank" href={`${booking_event_basics_url}${id}`} >
            <i className="icon-eye"></i>
          </IconButton>
        );
      }
    }
   ];

  const {
    pending, data, records_total, paginationParams, handleChangePagination, updateList, setFilters,
    filters,
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  function onSubmitFilters({ from, till, location_ids, ...filters }) {
    setFilters({
      ...filters,
      ...(!!location_ids.length && {location_ids}),
      ...(!!from || !!till) && {
        dates: {
          ...(!!from && { from }),
          ...(!!till && { till })
        }
      }
    });
  }

  return (
    <ListWrapper title={`${t('All Bookings')} (${data.length})`}
    >
      <Filters onSubmit={onSubmitFilters}
        initialValues={{ location_ids: [] }}>
        <Autocomplete
          label={t('Booking Service')}
          options={booking_services}
          name="booking_service_ids"
          valueAsArray
        />
        <Autocomplete
          label={t('Provider')}
          options={booking_providers}
          name="booking_provider_ids"
          valueAsArray
        />
        <MultiSelect
          options={locations}
          label={t('Locations')}
          name="location_ids"
        />
        <DatePicker label={t('From')} name="from" />
        <DatePicker label={t('Till')} name="till" />
        </Filters>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
}
