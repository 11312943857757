import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, makeStyles, Button, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from 'Hooks';
import { AJAX } from 'Helpers';
import { FormInput } from 'Components';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    width: '52px',
    height: '52px',
  },
}));


const CreateFilterDialog = React.forwardRef(({ filter, apiUrl }, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, changeIsOpen] = React.useState(false);

  const { item, changeValue, action, method, isItemInvalid, validationMessagesToDisplay,
    setServerErrors, submit } = useForm({ name: '' }, {}, apiUrl);

  const open = () => {
    changeIsOpen(true);
  }

  function close() {
    changeIsOpen(false);
  }

  React.useImperativeHandle(ref, () => ({
    open,
  }));

  function handleChangeName({ target }) {
    changeValue('name', target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    submit();
    if (!!isItemInvalid) {
      return
    }

    const { first_period_services, second_period_services, ...filterData } = filter;

    const body = {
      returned_clients_view: {
        name: item.name,
        data: {
          ...filterData,
          first_period_service_ids: first_period_services.map(({ id }) => id),
          second_period_service_ids: second_period_services.map(({ id }) => id),
        }
      }
    }

    const request = await AJAX[method](action, { body });

    if (!!request.errors) {
      return setServerErrors(request.errors);
    }

    close()
  }

  return (
    <Dialog open={isOpen} onClose={close} fullWidth>
      <DialogTitle className={classes.root}>
        {t('Create Filter')}
        <IconButton size="medium" className={classes.closeButton} onClick={close}>
          <i className="icon-cancel"></i>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormInput
          label={t('Name')}
          value={item.name}
          onChange={handleChangeName}
          validationMessages={validationMessagesToDisplay.name}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {t('Create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});


export default CreateFilterDialog;
