export const UserActivityLogs = {
  en: {
    'User Activity Logs': 'User Activity Logs',
    'Time': 'Time',
    'User': 'User',
    'Client': 'Client',
    'Service': 'Service',
    'Action Type': 'Action Type',
    'Purchased Service Id': 'PS Id',
    'Service Usage History Id': 'SUH Id',
    'Payment Id': 'Payment Id',
  },
  ru: {
    'User Activity Logs': 'Журнал',
    'Time': 'Время',
    'User': 'Пользователь',
    'Client': 'Клиент',
    'Service': 'Сервис',
    'Action Type': 'Тип Действия',
    'Purchased Service Id': 'Id сер.',
    'Service Usage History Id': 'SUH Id',
    'Payment Id': 'Id Платежа',
  },
  uk: {
    'User Activity Logs': 'Журнал',
    'Time': 'Час',
    'User': 'Користувач',
    'Client': 'Клієнт',
    'Service': 'Сервіс',
    'Action Type': 'Тип Дії',
    'Purchased Service Id': 'Id сер.',
    'Service Usage History Id': 'SUH Id',
    'Payment Id': 'Id Платежу',
  },
}
