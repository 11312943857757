import { useEffect, useMemo, useRef, useState } from 'react';
import {
  FormControl as MFormControl, makeStyles, Table as MaterialTable,
  TableContainer, TableBody, TableCell, TableRow, Box, IconButton
} from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import {
  FormWrapper, FormInput, Checkbox, MultiSelect, TextEditor, Button,
  ServicesPickerDialog, FormControl, Select, Counter, ImageUpload
} from 'Components';
import { checkRequired, checkRequiredArray } from 'Helpers/validation';
import { findInArrayBy, updateItemInArray, toMoneyFormat, AJAX } from 'Helpers';
import { TIME_POINTS } from 'Constants';
import { useForm, } from 'Hooks';
import notificationsStore from 'Helpers/notifications';

const validators = {
  duration_units_count: checkRequired('duration_units_count'),
  location_ids: checkRequiredArray('location_ids'),
  online_payment_key_id: checkRequired('online_payment_key_id'),
};

const useStyles = makeStyles((theme) => ({
  servicesContainer: {
    border: `1px solid  ${theme.palette.text.disabled}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
  },
  selectServicesBtn: {
    width: '50%'
  },
  settingsCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  imagePreview: {
    maxHeight: '350px',
    objectFit: 'contain'
  },
  formLabel: {
    fontSize: '0.75em',
  },
}));

export const BookingServiceForm = ({
  url, api_url, booking_service, locations, booking_service_types, services_api_url, online_payment_keys
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const formRef = useRef(null);

  const { item, changeValue, action, method, isItemInvalid,
    validationMessagesToDisplay, submit, setServerErrors, setItem
  } = useForm(booking_service, validators, api_url);

  const [services, setServices] = useState([])

  useEffect(() => {
    fetchServices()
  }, [item.location_ids])

  async function fetchServices() {
    if (!!item.location_ids.length) {
      const res = await AJAX.get(services_api_url, {
        body: {
          filters: {
            locations: item.location_ids
          }
        }
      });
      if (!!res.data) {
        setServices(res.data)
      }
    }
  }

  const bookingServiceTypesToDisplay = useMemo(() => {
    return booking_service_types.map(({ id, name }) => {
      return {
        id, name: t(name)
      }
    })
  }, [i18n.language])

  const durationOptions = useMemo(function () {
    return TIME_POINTS.map(({ point, value }) => ({ id: point, name: value }));
  }, []);

  const servicesPicker = useRef(null);

  function showServicesDialog() {
    servicesPicker.current.open(item.services, onServicesChange)
  }

  const descriptionTextEditor = useRef(null);
  const nameTextEditor = useRef(null);

  const onLocationIdsChange = ({ target }) => {
    setItem({
      ...item,
      location_ids: target.value,
      services: []
    })
  }

  const onOrderPositionChange = ({ target }) => {
    changeValue('order_position', target.value);
  }

  const onServiceDelete = (serviceId) => {
    const services = item.services.filter(function ({ id }) {
      return id !== serviceId;
    });

    changeValue('services', services);
  }

  const onServicesChange = (val) => {
    const value = val.map((service) => {
      const existedItem = findInArrayBy(item.services, service.id)

      if (existedItem) {
        return existedItem
      }

      return {
        ...service,
        initial_count: 1
      }
    });

    changeValue('services', value);
  }

  const onBookingServiceTypeChange = ({ target }) => {
    changeValue('booking_service_type', target.value);
  }

  const onPaymentKeyChange = ({ target }) => {
    changeValue('online_payment_key_id', target.value);
  }

  const onDurationUnitsCountChange = ({ target }) => {
    changeValue('duration_units_count', target.value);
  }

  const onActiveChange = ({ target }) => {
    changeValue('active', target.checked);
  }

  const onColorChange = ({ target }) => {
    changeValue('color', target.value);
  };

  const onPhotoChange = (photo) => {
    changeValue('photo', photo);
  }

  const onServiceInitialCountChange = (value, id) => {
    const currentService = findInArrayBy(item.services, id);
    const updatedItem = {
      ...currentService,
      initial_count: value
    }
    const updatedServices = updateItemInArray(item.services, updatedItem, id)
    changeValue('services', updatedServices);
  }

  const totalPrice = useMemo(() => {
    return item.services.reduce((sum, service) => {
      return sum + (service.price * service.initial_count)
    }, 0)
  }, [item.services])


  const onSubmit = (e) => {
    e.preventDefault();
    submit()

    if (!!isItemInvalid) {
      return
    }
    //service values to form data
    const deletedServices = booking_service.services.reduce((res, service) => {
      const isServiceDeleted = !item.services.some(({ id }) => id === service.id)

      if (isServiceDeleted) {
        return [
          ...res,
          {
            id: service.option_id,
            service_id: service.id,
            initial_count: service.initial_count,
            _destroy: true
          }
        ]
      }

      return res
    }, [])

    const selectedServices = item.services.map(({ id, option_id, initial_count }) => {
      return {
        ...(!!option_id && { id: option_id }),
        service_id: id,
        initial_count
      }
    });

    const formData = new FormData(formRef.current);
    [...deletedServices, ...selectedServices].forEach((item) => {
      for (const key in item) {
        formData.append(`booking_service[booking_service_options_attributes][][${key}]`, item[key]);
      }
    });

    item.location_ids.forEach((id) => {
      formData.append('booking_service[location_ids][]', id);
    });

    const request = AJAX[method](action, {
      'content-type': 'multipart/form-data',
      body: formData,
    });

    request.then(({ errors }) => {
      if (errors) {
        setServerErrors(errors);

        if (errors.base) {
          notificationsStore.add({ type: 'error', text: errors.base });
        }
      }
    });
  }

  return (
    <>
      <form ref={formRef} name="booking_service" onSubmit={onSubmit}
        encType="multipart/form-data" noValidate>
        <FormWrapper backUrl={url} title="Booking Service" item={item}>
          <FormControl 
            fullWidth
            label={
              <span className={classes.formLabel}>
                {t('Name')}
              </span>
            }
            validationMessages={validationMessagesToDisplay.name}
          >
            <TextEditor ref={nameTextEditor} value={item.name}
              name="booking_service[name]" multipartFormData />
          </FormControl>
          <FormControl
            fullWidth
            label={
              <span className={classes.formLabel}>
                {t('Description')}
              </span>
            }
          >
            <TextEditor ref={descriptionTextEditor} value={item.description}
              name="booking_service[description]" multipartFormData />
          </FormControl>
          <MultiSelect options={locations} label={t('Locations')}
            onChange={onLocationIdsChange} value={item.location_ids}
            validationMessages={validationMessagesToDisplay.location_ids} required />
          {!!services.length && (
            <FormControl
              fullWidth 
              label={
                <span className={classes.formLabel}>
                  {t('Services')}
                </span>
                }
              validationMessages={validationMessagesToDisplay.booking_service_options}>
              <Button
                color="primary"
                className={classes.selectServicesBtn}
                onClick={showServicesDialog}
                disabled={item.in_use}
              >
                {t('Select services')}
              </Button>
              <TableContainer>
                <MaterialTable>
                  <TableBody>
                    {item.services.map((service) => (
                      <TableRow key={service.id}>
                        <TableCell>
                          <IconButton size="small" onClick={() => onServiceDelete(service.id)}>
                            <i className="icon-trash-empty"></i>
                          </IconButton>
                          {service.name}
                        </TableCell>
                        <TableCell>
                          <Box className={classes.counterWrapper}>
                            <Counter
                              count={service.initial_count}
                              id={service.id}
                              onChange={onServiceInitialCountChange}
                              disabled={item.in_use}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className={classes.settingsCell}>
                            {toMoneyFormat(service.price * service.initial_count)}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>
                        {t('Total')}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {toMoneyFormat(totalPrice)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </MaterialTable>
              </TableContainer>
            </FormControl>
          )}
          <Select validationMessages={validationMessagesToDisplay.booking_service_type}
            value={item.booking_service_type || ''} options={bookingServiceTypesToDisplay}
            label={t('Service type')} onChange={onBookingServiceTypeChange}
            name="booking_service[booking_service_type]" required disabled={item.in_use}/>
          <Select validationMessages={validationMessagesToDisplay.online_payment_key_id}
            value={item.online_payment_key_id || ''} options={online_payment_keys}
            label={t('Online Payment Key')} onChange={onPaymentKeyChange}
            name="booking_service[online_payment_key_id]" required/>
          <FormInput type="number"
            validationMessages={validationMessagesToDisplay.order_position}
            value={item.order_position} label={t('Position')}
            onChange={onOrderPositionChange} name="booking_service[order_position]" />
          <Select validationMessages={validationMessagesToDisplay.duration_units_count}
            value={item.duration_units_count} options={durationOptions}
            label={t('Duration')} onChange={onDurationUnitsCountChange}
            name="booking_service[duration_units_count]" required />
          <FormControl
            label={
              <span className={classes.formLabel}>
                {t('Color')}
              </span>
            }
            validationMessages={validationMessagesToDisplay.color} fullWidth
          >
            <input type="color" value={item.color} onChange={onColorChange} name="booking_service[color]"/>
          </FormControl>
          <FormControl
            fullWidth
            label={
              <span className={classes.formLabel}>
                {t('Image')}
              </span>
            }
            validationMessages={validationMessagesToDisplay.photo}
          >
            <ImageUpload value={item.photo} url={item.photo_url}
              label={t('Choose Image File')} onChange={onPhotoChange}
              name="booking_service[photo]" previewClassName={classes.imagePreview}
              hasSizeLimit />
          </FormControl>
          <MFormControl fullWidth>
            <Checkbox
              checked={item.active || false}
              onChange={onActiveChange}
              label={t('Active')}
            />
            <input name="booking_service[active]" value={item.active ? 'true' : 'false'}
              hidden readOnly />
          </MFormControl>
        </FormWrapper>
      </form>
      <ServicesPickerDialog ref={servicesPicker} services={services} />
    </>
  );
}

BookingServiceForm.defaultProps = {
  booking_service: {
    name: '',
    description: '',
    location_ids: [],
    services: [],
    order_position: '',
    duration_units_count: '',
    color: '#3f51b5',
    photo: '',
    active: true,
  },
};
