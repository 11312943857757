import { TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { IncomeTableProps } from '../types';

const useStyles = makeStyles(() => ({
  headerCell: {
    color: '#555',
    textTransform: 'uppercase',
  },
  locationName: {
    display: 'inline-block',
    maxWidth: 'min-content',
  },
  totalAmount: {
    color: '#3f51b5',
    fontWeight: 'bold',
  },
}));

export const IncomeTableHead = ({ incomesByLocations }: IncomeTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locationNames = incomesByLocations.reduce((names, income) => {
    if (names.length) return names;
    return [
      ...names,
      ...income.map((location) => location.location || '').filter(Boolean),
    ];
  }, [] as string[]);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          rowSpan={2}
          colSpan={2}
          align="center"
          className={classes.headerCell}
        >
          {t('Income Sources')}
        </TableCell>
        <TableCell
          colSpan={locationNames.length + 1}
          align="center"
          className={classes.headerCell}
        >
          {t('Locations')}
        </TableCell>
      </TableRow>
      <TableRow>
        {locationNames.map((locationName) => {
          const isTotalColumn = locationName === 'Total';
          return (
            <TableCell
              key={locationName}
              align="center"
            >
              <span
                className={classnames({
                  [classes.locationName]: true,
                  [classes.totalAmount]: isTotalColumn,
                })}
              >
                {t(locationName)}
              </span>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
