import { useQuery } from '@tanstack/react-query';

import { HttpClient } from 'network/HttpClient';

import { FetchDataParams } from 'schemas';

export const SERVICE_USAGE_HISTORY_QUERY_KEY = 'serviceUsageHistory';

export const useServiceUsageHistoryQuery = ({
  fetchUrl,
  paginationParams,
}: FetchDataParams) => {
  return useQuery(
    [SERVICE_USAGE_HISTORY_QUERY_KEY, paginationParams],
    () =>
      HttpClient.getServiceUsageHistory({
        fetchUrl,
        paginationParams,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};
