import {
  Dialog, DialogTitle, DialogContent, makeStyles, Box, Tabs, Tab, IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Preloader, TabPanel } from 'Components';
import { AJAX } from 'Helpers';
import { BasicEventForm } from './BasicEventForm';
import { NoteForm } from './NoteForm';
import { TimeBlockEventForm } from './TimeBlockEventForm';
import { CALENDAR_ITEM_TYPES } from 'Constants';

const useStyles = makeStyles(() => ({
  preloader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  hidden: {
    display: 'none'
  }
}));

const BASIC_EVENT_TAB_INDEX = 0;
const TIME_BLOCK_EVENT_TAB_INDEX = 1;
const NOTE_TAB_INDEX = 2;


const DEFAULT_BASIC_EVENT = {
  booking_service_id: '',
  booking_provider_id: '',
  members_limit: '',
  multiple_bookings: false,
  date: '',
  start_period_id: '',
  end_period_id: '',
  notes: '',
  comment: '',
  week_frequency: '',
  week_days: [],
  only_time_block: false,
  time_slot_name: '',
  booking_custom_fields_attributes: []
}

const DEFAULT_TIME_BLOCK_EVENT = {
  booking_provider_id: '',
  start_period_id: '',
  end_period_id: '',
  notes: '',
}

const DEFAULT_NOTE = {
  booking_provider_id: '',
  message: '',
  start_period_id: '',
  end_period_id: '',
}

function getActiveTabIndexBy(type) {
  switch (type) {
    case CALENDAR_ITEM_TYPES.TIME_BLOCK_EVENT:
      return TIME_BLOCK_EVENT_TAB_INDEX
    case CALENDAR_ITEM_TYPES.NOTE:
      return NOTE_TAB_INDEX
    default:
      return BASIC_EVENT_TAB_INDEX
  }
}

export const EventDialog = React.forwardRef(({
  services_api_url, fetchProvidersUrl, providers, getProviderPeriods, onCloseDialog,
  onSubmit, notes_api_url, basic_event_api_url, only_time_block_event_api_url,
  clients_api_url
}, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = React.useState({
    isOpened: false,
    activeTabIndex: BASIC_EVENT_TAB_INDEX,
    pending: false,
    basicEvent: {},
    timeBlockEvent: {},
    note: {}
  });

  function close() {
    onCloseDialog()
    setState({ ...state, pending: false, isOpened: false, })
  }

  function open(data = {}, type) {
    const activeTabIndex = getActiveTabIndexBy(type);

    setState({
      activeTabIndex,
      isOpened: true,
      pending: !!data.id,
      basicEvent: {
        ...DEFAULT_BASIC_EVENT,
        ...((!data.id || type === CALENDAR_ITEM_TYPES.BASIC_EVENT) && data)
      },
      timeBlockEvent: {
        ...DEFAULT_TIME_BLOCK_EVENT,
        ...((!data.id || type === CALENDAR_ITEM_TYPES.TIME_BLOCK_EVENT) && data)
      },
      note: {
        ...DEFAULT_NOTE,
        ...((!data.id || type === CALENDAR_ITEM_TYPES.NOTE) && data)
      }
    });
  }

  React.useEffect(() => {
    if (!state.pending) {
      return
    }

    if (!!state.basicEvent.id) {
      fetchBasicEvent()
      return
    }

    if (!!state.timeBlockEvent.id) {
      fetchTimeBlockEvent()
      return
    }

    if (!!state.note.id) {
      fetchNote()
    }

  }, [state.pending, state.basicEvent.id, state.note.id, state.timeBlockEvent.id]);


  async function fetchBasicEvent() {
    const res = await AJAX.get(`${basic_event_api_url}/${state.basicEvent.id}`);

    if (res.booking_event_basic) {
      const {
        booking_time_range_end_id, booking_time_range_start_id,
        ...basicEventData
      } = res.booking_event_basic;

      setState({
        ...state,
        pending: false,
        basicEvent: {
          ...state.basicEvent,
          ...basicEventData,
        }
      })
    }
  }

  async function fetchTimeBlockEvent() {
    const res = await AJAX.get(`${only_time_block_event_api_url}/${state.timeBlockEvent.id}`);

    if (res.booking_event_only_time_block) {
      const {
        booking_time_range_end_id, booking_time_range_start_id, ...timeBlockEventData
      } = res.booking_event_only_time_block;

      setState({
        ...state,
        pending: false,
        timeBlockEvent: {
          ...state.timeBlockEvent,
          ...timeBlockEventData,
        }
      })
    }
  }

  async function fetchNote() {
    const url = `${notes_api_url}/${state.note.id}`;
    const res = await AJAX.get(url);

    if (res.booking_note) {
      const {
        booking_period_start_id, booking_period_end_id,
        booking_provider_id, creator, message,
      } = res.booking_note;

      setState({
        ...state,
        pending: false,
        note: {
          ...state.note,
          start_period_id: booking_period_start_id,
          end_period_id: booking_period_end_id,
          creator,
          booking_provider_id,
          message,
        }
      })
    }
  }

  React.useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  function onSuccessSubmit() {
    close()
    onSubmit()
  }

  function handleChangeTab(_, tabIndex) {
    setState({ ...state, activeTabIndex: tabIndex })
  }

  return (
    <Dialog
      open={state.isOpened}
      maxWidth='lg'
      fullWidth
      PaperProps={{ style: { height: '100%' } }}>
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={state.activeTabIndex}
              onChange={handleChangeTab} >
              <Tab className={classnames({
                [classes.hidden]: state.note.id || state.timeBlockEvent.id
              })}
                label={t('Event')}
              />
              <Tab className={classnames({
                [classes.hidden]: state.basicEvent.id || state.note.id
              })}
                label={t('Block Time Only')}
              />
              <Tab className={classnames({
                [classes.hidden]: state.basicEvent.id || state.timeBlockEvent.id
              })}
                label={t('Note')}
              />
            </Tabs>
          </Box>
          <IconButton
            size="medium"
            className={classes.closeButton}
            onClick={close}
          >
            <i className="icon-cancel"></i>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {state.pending && (
          <Box className={classes.preloader}>
            <Preloader />
          </Box>
        )}
        {!!providers && !state.pending && (
          <>
            <TabPanel
              value={state.activeTabIndex} 
              index={BASIC_EVENT_TAB_INDEX}
            >
              <BasicEventForm
                basicEvent={state.basicEvent}
                services_api_url={services_api_url}
                fetchProvidersUrl={fetchProvidersUrl}
                providers={providers}
                getProviderPeriods={getProviderPeriods}
                onSubmit={onSuccessSubmit}
                api_url={basic_event_api_url}
                clients_api_url={clients_api_url}
              />
            </TabPanel>
            <TabPanel
              value={state.activeTabIndex}
              index={TIME_BLOCK_EVENT_TAB_INDEX}
            >
              <TimeBlockEventForm
                providers={providers}
                timeBlockEvent={state.timeBlockEvent}
                getProviderPeriods={getProviderPeriods}
                onSubmit={onSuccessSubmit}
                api_url={only_time_block_event_api_url}
              />
            </TabPanel>
            <TabPanel
              value={state.activeTabIndex}
              index={NOTE_TAB_INDEX}>
              <NoteForm
                providers={providers}
                note={state.note}
                getProviderPeriods={getProviderPeriods}
                onSubmit={onSuccessSubmit}
                api_url={notes_api_url}
              />
            </TabPanel>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
})
