import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import * as localization from 'ClientLocalization';
import { transformLocales, initI18n } from 'Helpers/client';
import ClientLayout from 'Pages/client/layout';
import { ConfirmDialog, Notifications } from 'Components';
import { CLIENT_APP_LOCALES_LIST } from 'Constants';
import * as ClientPages from 'Pages/client';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#19456B',
      contrastText: '#fff'
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h2',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'span',
      },
    },
  },
});

export function renderClientLayout(
  renderContainer = document.getElementById('page-root'),
  pageContainer = document.getElementById('page-content'),
) {
  const { component_name, layout_name, layout_data, ...pageProps } = JSON.parse(
    pageContainer.getAttribute('data')
  );

  const commonData = JSON.parse(
    document.getElementById('main-info').getAttribute('data')
  );

  const locales = transformLocales([
    localization.COMMON, localization[component_name]
  ], CLIENT_APP_LOCALES_LIST);

  initI18n(locales, commonData.locale);

  ReactDOM.render(
    (
      <ThemeProvider theme={theme}>
        <Notifications>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ClientLayout layout={layout_name} layout_data={layout_data}
              {...commonData.data} >
              {React.createElement(ClientPages[component_name], pageProps)}
            </ClientLayout>
            <ConfirmDialog />
          </MuiPickersUtilsProvider>
        </Notifications>
      </ThemeProvider>
    ),
    renderContainer,
  );
}