export const SubscriptionsStatistics = {
  en: {
    'Subscriptions Statistics': 'Subscriptions Statistics',
    'Active Subscriptions': 'Active Subscriptions Count',
    'Suspended Subscriptions': 'Suspended Subscriptions Count',
    'Date': 'Date',
    'Count': 'Count',
  },
  ru: {
    'Subscriptions Statistics': 'Статистика По Подпискам',
    'Active Subscriptions': 'Количество Активных Подписок',
    'Suspended Subscriptions': 'Количество Приостановленных Подписок',
    'Date': 'Дата',
    'Count': 'Кол-во',
  },
  uk: {
    'Subscriptions Statistics': 'Статистика По Підпискам',
    'Active Subscriptions': 'Кількість Активних Підписок',
    'Suspended Subscriptions': 'Кількість Призупинених Підписок',
    'Date': 'Дата',
    'Count': 'Кількість',
  },
};