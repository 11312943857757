export const Services = {
  en: {
    'Services': 'Services',
    'Name': 'Name',
    'Name UA': 'Name (UA)',
    'Service Category': 'Service Category',
    'Service Type': 'Service Type',
    'Discount Type': 'Discount Type',
    'Price': 'Price',
    'Visits Count': 'Visits Count',
    'Duration In Days': 'Duration In Days',
    'Active': 'Active',
    'Trainer Fee': 'Trainer Fee',
    'Create Service': 'Create Service',
    'Locations': 'Locations',
    'Status': 'Status',
    'Not Active': 'Not Active',
    'Visible': 'Visible',
  },
  ru: {
    'Services': 'Сервисы',
    'Name': 'Название',
    'Name UA': 'Название (UA)',
    'Service Category': 'Категория Сервиса',
    'Service Type': 'Тип Сервиса',
    'Discount Type': 'Тип Скидки',
    'Price': 'Цена',
    'Visits Count': 'Количество Посещений',
    'Duration In Days': 'Продолжительность В Днях',
    'Active': 'Активный',
    'Trainer Fee': 'Плата Инструктору',
    'Create Service': 'Создать Сервис',
    'Locations': 'Локации',
    'Status': 'Статус',
    'Not Active': 'Не активный',
    'Visible': 'Видимый',
  },
  uk: {
    'Services': 'Сервіси',
    'Name': 'Назва',
    'Name UA': 'Назва (UA)',
    'Service Category': 'Категорія Сервісу',
    'Service Type': 'Тип Сервісу',
    'Discount Type': 'Тип Знижки',
    'Price': 'Ціна',
    'Visits Count': 'Кількість Відвідувань',
    'Duration In Days': 'Тривалість В Днях',
    'Active': 'Активний',
    'Trainer Fee': 'Плата Тренеру',
    'Create Service': 'Створити Сервіс',
    'Locations': 'Локації',
    'Status': 'Статус',
    'Not Active': 'Не активний',
    'Visible': 'Видимий',
  },
};

export const ServiceForm = {
  en: {
    ...Services.en,
    'New Service': 'New Service',
    'Edit Service': 'Edit Service',
    'Update Service': 'Update Service',
    'Trainer Required': 'Trainer Required',
    'Discounts': 'Discounts',
    'Club card': 'Club card',
  },
  ru: {
    ...Services.ru,
    'New Service': 'Создание Сервиса',
    'Edit Service': 'Редактирование Сервиса',
    'Update Service': 'Обновить Сервис',
    'Trainer Required': 'Необходим Выбор Тренера',
    'Discounts': 'Скидки',
    'Club card': 'Клубная карта',
  },
  uk: {
    ...Services.uk,
    'New Service': 'Створення Сервісу',
    'Edit Service': 'Редагування Сервісу',
    'Update Service': 'Оновити Сервіс',
    'Trainer Required': 'Потрібен Вибір Тренера',
    'Discounts': 'Знижки',
    'Club card': 'Клубна картка'
  },
}
