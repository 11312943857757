import { makeStyles, Box, Typography, Chip, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'Components';
import { AJAX } from 'Helpers';
import { HOURS } from 'Constants';
import confirmationStore from 'Helpers/confirmation';
import { InfoDialog } from './InfoDialog';


const useStyles = makeStyles(() => ({
  head: {
    display: 'flex',
    paddingLeft: '50px',
    marginBottom: '20px'
  },
  columnItem: {
    width: '100%',
    padding: '0 12px',
  },
  weekDayName: {
    textAlign: 'center',
  },
  scheduleContainer: {
    display: 'flex',
    width: '100%',
    position: 'relative'
  },
  hours: {
    width: '50px',
    flexShrink: '0'
  },
  hour: {
    display: 'block',
    height: '24px',
    fontSize: '0.8125rem',
    marginBottom: '3px',
    padding: '5px 0',
    boxSizing: 'border-box'
  },
  scheduleColumn: {
    width: '100%',
    padding: '0 12px',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box'
  },
  scheduleHourWrapper: {
    display: 'flex',
    marginBottom: '3px',
    width: '50%',
    padding: '0 2px',
    boxSizing: 'border-box',

    '& > *': {
      width: '100%',
      margin: '0'
    },
  },
  scheduleOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 50,
  },
  deleteBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  tooltip: {
    marginLeft: '15px'
  }
}));


const WeeklySchedule = ({ schedule, workDays, api_url, changeScheduleItem, fetchShedule }) => {
  const classes = useStyles();
  const [pending, setPending] = React.useState(false);

  const infoDialog = React.useRef(null);
  const { t } = useTranslation();

  function handleClickTime(weekdayId, id, active) {
    return async () => {
      setPending(true)
      const res = await AJAX.put(`${api_url}/${id}`, {
        body: {
          booking_provider_work_time: { active: !active }
        }
      });
      setPending(false)
      if (res.flash_type == 'success') {
        const { booking_provider_work_time } = res;
        changeScheduleItem(weekdayId, booking_provider_work_time.id, booking_provider_work_time.active)
      }
    }
  }

  function handleClearWorkDay(index) {
    return () => {
      confirmationStore.open(async function () {
        setPending(true)
        const res = await AJAX.put(`${api_url}/disable_work_day`, {
          body: {
            work_day: index
          }
        });
        setPending(false)
        if (res.flash_type == 'success') {
          fetchShedule()
        }
      })
    }
  }

  function handleRefreshSchedule() {
    confirmationStore.open(async function () {
      setPending(true)
      const res = await AJAX.patch(`${api_url}/refresh_daily_schedule`);
      setPending(false)
      infoDialog.current.open(res.flash_message)
    })
  }

  return (
    <Box>
      <Box>
        <Button color="primary" onClick={handleRefreshSchedule}>
          {t('Refresh Current Schedule')}
        </Button>
        <Tooltip className={classes.tooltip} placement="right-end"
          title={
            <Typography color="inherit">
              {t('info')}
            </Typography>
          }>
          <IconButton size="medium" >
            <i className="icon icon-info"></i>
          </IconButton>
        </Tooltip>
      </Box>
      <Box className={classes.head}>
        {workDays.map(({ id, name }, index) => (
          <Box key={id} className={classes.columnItem}>
            <div className={classes.deleteBtnWrapper}>
              <IconButton className={classes.deleteBtn} onClick={handleClearWorkDay(index)} size="small">
                <i className="icon-trash-empty"></i>
              </IconButton>
            </div>
            <Typography className={classes.weekDayName}>
              {name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box className={classes.scheduleContainer}>
        <Box className={classes.hours}>
          {HOURS.map((hour) => (
            <span className={classes.hour} key={hour}>{hour}</span>
          ))}
        </Box>
        <Box className={classes.scheduleContainer}>
          {workDays.map((day) => (
            <Box key={day.id} className={classes.scheduleColumn}>
              {schedule[day.id].map(({ id, active, time_range_start }) => (
                <Box key={id} className={classes.scheduleHourWrapper}>
                  <Chip label={time_range_start} color={active ? "primary" : 'default'} size="small"
                    onClick={handleClickTime(day.id, id, active)} />
                </Box>
              ))}
            </Box>
          ))}
          {pending && (
            <Box className={classes.scheduleOverlay}></Box>
          )}
        </Box>
      </Box>
      <InfoDialog ref={infoDialog} onClose={fetchShedule} />
    </Box>
  )
}

export default WeeklySchedule
