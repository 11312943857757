import { IconButton, Icon} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import store from 'Helpers/notifications';
import { NOTIFICATION_TYPES } from 'Constants';

const useStyles = makeStyles(() => ({
  closeButton: {
    padding: '5px'
  },
  icon: {
    color: 'white',
    width: '36px',
    height: '36px'
  },
  snack: {
    maxWidth: '385px',
    width: '100%',

    "& .MuiSnackbarContent-root": {
      flexDirection: "row",
      flexWrap: 'nowrap'
    }
  }
}));

export const Notifications = ({ children, notifications = [] }) => {
  const provider = React.useRef(null);
  const classes = useStyles();

  function hideNotification(key) {
    return function () {
      provider.current.closeSnackbar(key);
    }
  }

  function addNotification({ type, text }) {
  
    provider.current.enqueueSnackbar(text, {
      variant: NOTIFICATION_TYPES[type],
      action: key => (
        <IconButton className={classes.closeButton} onClick={hideNotification(key)}>
          <Icon className={classes.icon}>✖</Icon>
        </IconButton>
      )
    });
  }

  store.register('add', addNotification);
  React.useEffect(function () {
    notifications.forEach(addNotification);
  }, []);

  return (
    <SnackbarProvider ref={provider} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
      className={classes.snack}
      disableWindowBlurListener >
      {children}
    </SnackbarProvider>
  )
}
