import { makeStyles, Grid } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { DatePicker, Button, Select } from 'Components';

const useStyles = makeStyles(() => ({
  formActions: {
    marginBottom: '8px'
  },
}));

const DEFAULT_FILTER = {
  location_id: '',
  date_from: '',
  date_till: ''
}

export const FilterForm = ({ locations, fetchData }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [filter, setFilter] = React.useState(DEFAULT_FILTER)

  function handleChangeLocationId({ target }) {
    setFilter({
      ...filter,
      location_id: target.value
    })
  }

  function handleChangeDateFrom({ target }) {
    setFilter({
      ...filter,
      date_from: target.value
    })
  }

  function handleChangeDateTill({ target }) {
    setFilter({
      ...filter,
      date_till: target.value
    })
  }

  function onSubmit() {
    fetchData(filter)
  }

  return (
    <>
      <Grid container spacing={2} className={classes.formActions}>
        <Grid item xs={12} sm={4}>
          <Select label={t('Location')} options={locations} value={filter.location_id}
            onChange={handleChangeLocationId} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker label={t('From')} name="date" value={filter.date_from}
            onChange={handleChangeDateFrom} required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker label={t('Till')} name="date" value={filter.date_till}
            onChange={handleChangeDateTill} required />
        </Grid>
      </Grid>
      <Button variant="contained" color="primary" onClick={onSubmit}
        className={classes.formActions}>
        {t('Filter')}
      </Button>
    </>
  );
}
