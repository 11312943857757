import { useTranslation } from 'react-i18next';
import { ListWrapper, Table, ListActions, Button } from 'Components';
import { useList } from 'Hooks';
import { CellComponentItemType, PaymentCardsProps } from './types';

const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'asc',
};

export const PaymentCards = ({
  url,
  new_item_url,
  fetch_url,
}: PaymentCardsProps) => {
  const { t } = useTranslation();
  const { pending, data, page, items_per_page, total, setPage, setPageSize } =
    useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  const tableConfig = [
    {
      title: t('Name'),
      accessor: 'name',
    },
    {
      title: t('Number'),
      accessor: 'number',
    },
    {
      title: t('Active'),
      accessor: 'active',
      cellComponent: ({ active }: CellComponentItemType) =>
        active ? '+' : '-',
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }: CellComponentItemType) => (
        <>
          <ListActions editUrl={`${url}/${id}/edit`} />
        </>
      ),
    },
  ];

  return (
    <ListWrapper
      title={`${t('Payment cards')}`}
      headerAddon={
        <div>
          <Button
            href={new_item_url}
            variant="contained"
            color="primary"
          >
            {t('Create Card')}
          </Button>
        </div>
      }
    >
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        page={page}
        items_per_page={items_per_page}
        total={total}
        setPage={setPage}
        setPageSize={setPageSize}
        hidePagination
      />
    </ListWrapper>
  );
};
