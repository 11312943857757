import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, makeStyles, Button, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useList } from 'Hooks';
import { Table, Radio, } from 'Components';
import confirmationStore from 'Helpers/confirmation';
import notificationsStore from 'Helpers/notifications';
import { deleteItem, } from 'Helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    width: '52px',
    height: '52px',
  },
}));

const DEFAULT_SORT_PARAMS = {
  sort_column: 'name',
  sort_direction: 'desc'
}

const DEFAULT_SELECTED_FILTER = { id: '' };

const FiltersListDialog = React.forwardRef(({ fetchUrl, onSelect }, ref) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [isOpen, changeIsOpen] = React.useState(false);

  const {
    pending, data, records_total, paginationParams, handleChangePagination, updateList
  } = useList({ fetchUrl: fetchUrl, sorting: DEFAULT_SORT_PARAMS });


  const [selectedFilter, changeSelectedFilter] = React.useState(DEFAULT_SELECTED_FILTER)

  function handleChange(item) {
    changeSelectedFilter(item)
  }

  function onDeleteItem(res) {
    if (res.status === 'error') {
      return notificationsStore.add({ type: 'error', text: res.errors });
    }

    changeSelectedFilter(DEFAULT_SELECTED_FILTER)
    updateList()
  }

  function handleDelete({ id }) {
    confirmationStore.open(deleteItem(fetchUrl, { id }, onDeleteItem));
  }


  const tableConfig = React.useMemo(() => {
    return [
      {
        title: t('Name'),
        accessor: 'name',
        sortable: true,
        cellComponent: (item) => {
          return (
            <RadioGroup value={selectedFilter.id} name="selectedFilter" onChange={() => handleChange(item)}>
              <Radio value={item.id} label={item.name} />
            </RadioGroup>
          )
        }
      },
      {
        title: t('Actions'),
        cellComponent: (item) => {
          return (
            <>
              <IconButton size="small" onClick={() => handleDelete(item)}>
                <i className="icon-trash-empty"></i>
              </IconButton>
            </>
          );
        },
      },
    ];
  }, [i18n.language, selectedFilter]);

  const open = () => {
    changeIsOpen(true);
    updateList();
    changeSelectedFilter(DEFAULT_SELECTED_FILTER)
  }

  function close() {
    changeIsOpen(false);
  }

  React.useImperativeHandle(ref, () => ({
    open,
  }));

  function handleSelect() {
    onSelect(selectedFilter.data)
    close()
  }

  return (
    <Dialog open={isOpen} onClose={close} fullWidth>
      <DialogTitle className={classes.root}>
        {t('Filter Settings')}
        <IconButton size="medium" className={classes.closeButton} onClick={close}>
          <i className="icon-cancel"></i>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table
          config={tableConfig}
          pending={pending}
          data={data}
          pagination={{ records_total, paginationParams, handleChangePagination }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" disabled={!selectedFilter.id} onClick={handleSelect}>
          {t('Apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});


export default FiltersListDialog;
