import { useTranslation } from 'react-i18next';
import {
  FormWrapper,
  Form,
  FormInput,
} from 'Components';
import { checkRequired } from 'Helpers/validation';
import { useForm } from 'Hooks';

const validators = {
  name: checkRequired('name'),
  login: checkRequired('login'),
  password: checkRequired('password'),
  cash_register_license_key: checkRequired('cash_register_license_key'),
};

export const CashierForm = ({
  url,
  cashier,
  api_url,
}) => {
  const { t } = useTranslation();
  const {
    item,
    changeValue,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    setServerErrors
  } = useForm(cashier, validators, api_url);

  const handleChangeName = ({ target }) => {
    changeValue('name', target.value);
  }

  const handleChangeLogin = ({ target }) => {
    changeValue('login', target.value);
  }

  const handleChangePassword = ({ target }) => {
    changeValue('password', target.value);
  }

  const handleChangeCashRegisterLicenseKey = ({ target }) => {
    changeValue('cash_register_license_key', target.value);
  }

  return (
    <Form
      name="cashier"
      action={action}
      method={method}
      validators={validators}
      disabled={isItemInvalid}
      item={item}
      onSubmit={submit}
      onError={setServerErrors}
    >
      <FormWrapper title="Cashier" item={item} backUrl={url}>
        <FormInput name="cashier[name]" required
          validationMessages={validationMessagesToDisplay.name} value={item.name}
          label={t('Name')} autoFocus onChange={handleChangeName}
        />

      <FormInput name="cashier[login]" required
          validationMessages={validationMessagesToDisplay.login} value={item.login}
          label={t('Login')} onChange={handleChangeLogin} />

      <FormInput name="cashier[password]" required type='password'
          validationMessages={validationMessagesToDisplay.password} value={item.password}
          label={t('Password')} onChange={handleChangePassword} />

      <FormInput name="cashier[cash_register_license_key]" required
          validationMessages={validationMessagesToDisplay.cash_register_license_key} value={item.cash_register_license_key}
          label={t('Cash Register License Key')} onChange={handleChangeCashRegisterLicenseKey} />

      </FormWrapper>
    </Form>
  );
};

CashierForm.defaultProps = {
  location: {
    name: '',
    login: '',
    password: '',
    cash_register_license_key: '',
  }
};
