export const COMMON = {
  en: {
    authTitle: 'Sign In/Up to {{companyName}}',
    authSidebar: `<p>
    Using your account you are able:<br /><br />
    1. See the history of visits and services.<br />
    2. Buy club cards and passes.<br />
    3. Receive news and special offers on time.
    </p>`,
    authAgreement: 'Public offer',
    next: 'Next',
    password: 'Password',
    signIn: 'Sign In',
    forgotPassword: 'Forgot Password?',
  },
  uk: {
    authTitle: 'Вхід або реєстрація до особистого кабінету {{companyName}}',
    authSidebar: `<p>
    Особистий кабінет  {{companyName}} дозволяє тобі самостійно:<br /><br />
    - записуватись на тренування та класи;<br />
    - переглядати історію відвідувань;<br />
    - купувати абончики та керувати підпискою SPACE-club;
    </p>`,
    authAgreement: 'Публічна оферта',
    next: 'Далі',
    password: 'Пароль',
    signIn: 'Увiйти',
    forgotPassword: 'Забули Пароль?',

  }

};
