import { useTranslation, } from 'react-i18next';
import { TextField, InputAdornment} from '@material-ui/core';
import { FormWrapper, Form, TextEditor, FormControl } from 'Components';
import { useForm } from 'Hooks';

export const BookingSettingsForm = ({api_url, back_url, booking_setting }) => {
  const { t } = useTranslation();
  const {
    item, changeValue, action, method, submit
  } = useForm(booking_setting, {}, api_url);

  const handleChangeMinTime = ({ target }) => {
    changeValue('min_time_before_booking_event', target.value);
  }

    return (
    <>
        <Form
          name='booking_setting'
          action={action}
          method={method}
          onSubmit={submit}
          item={item}
          encType="multipart/form-data"
        >
          <FormWrapper
            backUrl={back_url}
            title="Booking Settings"
            item={item}
          >
            <FormControl
              fullWidth
              label={t('Client book time')}
            >
              <TextEditor
                value={item.client_book_time_description}
                name="booking_setting[client_book_time_description]"
                multipartFormData
              />
            </FormControl>
            <FormControl
              fullWidth
              label={t('Client book class')}
            >
              <TextEditor
                value={item.client_book_class_description}
                name="booking_setting[client_book_class_description]"
                multipartFormData
              />
            </FormControl>
            <FormControl
              fullWidth
              label={t('Client book spot event')}
            >
              <TextEditor
                value={item.client_book_spot_event_description}
                name="booking_setting[client_book_spot_event_description]"
                multipartFormData
              />
            </FormControl>
            <FormControl
              fullWidth
              label={t('Confirmation notification')}
            >
              <TextEditor
                value={item.confirmation_notification}
                name="booking_setting[confirmation_notification]"
                multipartFormData
              />
            </FormControl>
            <FormControl
              fullWidth
              label={t('Confirmation notification for spot event')}
            >
              <TextEditor
                value={item.spot_event_confirmation_notification}
                name="booking_setting[spot_event_confirmation_notification]"
                multipartFormData
              />
            </FormControl>
            <FormControl
              fullWidth
              label={t('Minimum time before booking event')}
            >
              <TextField
                type='number'
                name="booking_setting[min_time_before_booking_event]"
                value={item.min_time_before_booking_event}
                onChange={handleChangeMinTime}
                variant="outlined"
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">{t('min')}</InputAdornment>,
                }}
              />
          </FormControl>
        </FormWrapper>
      </Form>
    </>
  );
}
