export const FiscalRegistrars = {
  en: {
    'Fiscal Registrars': 'Fiscal Registrars',
    'Service In / Out': 'Service In / Out',
    Confirm: 'Confirm',
    Print: 'Print',
    'With Confirmation': 'With Confirmation',
    TypedReport: '{{type}}-Report',
    'Generate X Report': 'Generate X-Report',
    'Open Shift': 'Open Shift',
    'Close Shift': 'Close Shift(Create Z-Report)',
    'Registrar Connected To Sales': 'Registrar Connected To Sales',
    'Registrar Isn\'t Connected To Sales': 'Registrar Isn\'t Connected To Sales',
    'Cashier': 'Cashier',
    'Shift': 'Shift',
    "Doesn't exist": "Doesn't exist",
  },
  ru: {
    'Fiscal Registrars': 'Фискальные Регистраторы',
    'Service In / Out': 'Служебное Внесение / Выдача',
    Confirm: 'Подтвердить',
    Print: 'Печать',
    'With Confirmation': 'С Подтверждением',
    TypedReport: '{{type}}-Отчёт',
    'Generate X Report': 'Сформировать X-Отчёт',
    'Open Shift': 'Открыть смену',
    'Close Shift': 'Закрыть смену(Сформировать Z-Отчёт)',
    'Registrar Connected To Sales': 'Регистратор подключен к продажам',
    'Registrar Isn\'t Connected To Sales': 'Регистратор не подключен к продажам',
    'Cashier': 'Кассир',
    'Shift': 'Смена',
    "Doesn't exist": "Не существует"
  },
  uk: {
    'Fiscal Registrars': 'Фіскальні Реєстратори',
    'Service In / Out': 'Службові Внесення / Видача',
    Confirm: 'Підтвердити',
    Print: 'Друк',
    'With Confirmation': 'З Підтвердженням',
    TypedReport: '{{type}}-Звіт',
    'Generate X Report': 'Сформувати X-Звіт',
    'Open Shift': 'Відкрити зміну',
    'Close Shift': 'Закрити зміну(Сформувати Z-Звіт)',
    'Registrar Connected To Sales': 'Реєстратор підключений до продажів',
    'Registrar Isn\'t Connected To Sales': 'Реєстратор не підключений до продажів',
    'Cashier': 'Касир',
    'Shift': 'Зміна',
    "Doesn't exist": 'Не існує'
  },
};
