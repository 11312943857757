import { makeStyles, Link } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import moment from 'moment';
import {
  ListWrapper, Table, DatePicker, Button,
} from 'Components';
import { useList } from 'Hooks';

const useStyles = makeStyles(() => ({
  tableActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  datePickerWrapper: {
    width: '300px'
  }
}));

export const ActivePurchasedServices = ({ fetch_url, clients_url }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'))

  const {
    pending, data, paginationParams,
    updateList, records_total, handleChangePagination
  } = useList({ fetchUrl: fetch_url, additionalFetchParams: { date } });

  const tableConfig = [
    {
      title: t('Name'),
      accessor: 'service_name',
    },
    {
      title: t('Client'),
      accessor: 'client_full_name',
      cellComponent: ({ client_id, client_full_name }) => {
        return (
          <Link href={`${clients_url}/${client_id}`}>{client_full_name}</Link>
        )
      }
    },
    {
      title: t('Visits Count'),
      accessor: 'visits_count',
    },
    {
      title: t('Actual Visits on Date'),
      accessor: 'actual_visits_on_date',
    },
    {
      title: t('Activated At'),
      accessor: 'activated_at',
    },
    {
      title: t('Expiration'),
      accessor: 'expiration_date_time',
    }
  ];

  function fetchData() {
    updateList()
  }

  function handleChangeDate({ target }) {
    setDate(target.value)
  }

  return (
    <ListWrapper title={`${t('Active Purchased Services')} (${records_total})`}>
      <div className={classes.tableActions}>
        <div className={classes.datePickerWrapper}>
          <DatePicker label={t('Date')} name="date" value={date}
            onChange={handleChangeDate} />
        </div>
        <Button variant="contained" color="primary" onClick={fetchData}>
          {t('Filter')}
        </Button>
      </div>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
}
