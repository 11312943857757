export const AllBookings = {
    en: {
        'Location': 'Location',
        'Provider': 'Provider',
        'Booking Service': 'Booking Service',
        'Time': 'Time',
        'Client': 'Client',
        'Created At': 'Created At'
    },
    uk: {
        'Location': 'Локація',
        'Provider': 'Провайдер',
        'Booking Service': 'Букінг Сервіс',
        'Time': 'Час',
        'Client': 'Клієнт',
        'Created At': 'Створений'
  },
};
