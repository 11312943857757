import { AJAX } from 'Helpers';
export const Form = React.forwardRef(({
  name, action, method, item, children, onSubmit,
  disabled, onSuccessSubmit, getSerializedItem, onError,
  backUrl, additionalParams = {}, onRequestStart, onRequestEnd, ...props
}, ref) => {
  const formRef = React.useRef(null);

  function handleSubmit(e) {
    if (!!e && !!e.preventDefault) {
      e.preventDefault();
    }
    if (!!onSubmit) {
      onSubmit();
    }
    if (!!disabled) {
      return
    }
    const isFormDataUsing = formRef.current.getAttribute('encType') === 'multipart/form-data';

    onRequestStart?.()
    
    const request = AJAX[method](action, {
      ...(!!isFormDataUsing) && {
        'content-type': 'multipart/form-data',
      },
      body: isFormDataUsing ? new FormData(formRef.current) : {
        [name]: !!getSerializedItem ? getSerializedItem() : item,
        ...additionalParams
      },
    })
    request.then(({ errors, ...res }) => {
      onRequestEnd?.()
      if (errors && !!onError) {
        return onError(errors);
      }
      !!onSuccessSubmit && onSuccessSubmit(res);
    });
    return request;
  }

  React.useImperativeHandle(ref, function () {
    return {
      submit: handleSubmit,
    }
  });

  return (
    <form ref={formRef} onSubmit={handleSubmit} noValidate {...props}>{children}</form>
  )
});
