import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormWrapper,
  Form,
  FormInput,
  Checkbox,
  FormControl,
} from 'Components';
import { checkRequired } from 'Helpers/validation';
import { useForm } from 'Hooks';
import { OnlinePaymentKeyType, OnlinePaymentKeyFormProps } from './types';

const validators = {
  name: checkRequired('name'),
  public_key: (item: OnlinePaymentKeyType) => {
    if (!item.system_config) return null;
    return checkRequired('public_key')(item.system_config);
  },
  private_key: (item: OnlinePaymentKeyType) => {
    if (!item.system_config) return null;
    return checkRequired('private_key')(item.system_config);
  },
};

export const OnlinePaymentKeyForm = ({
  url,
  api_url,
  online_payment_key,
}: OnlinePaymentKeyFormProps) => {
  const { t } = useTranslation();
  const {
    item,
    setItem,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    setServerErrors,
  } = useForm(online_payment_key, validators, api_url);

  const onKeyNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setItem({ ...item, name: target.value });
  };

  const onPublicKeyChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setItem({
      ...item,
      system_config: { ...item.system_config, public_key: target.value },
    });
  };

  const onPrivateKeyChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setItem({
      ...item,
      system_config: { ...item.system_config, private_key: target.value },
    });
  };

  const onActiveChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setItem({
      ...item,
      active: target.checked,
    });
  };

  return (
    <Form
      name="online_payment_key"
      action={action}
      method={method}
      validators={validators}
      disabled={isItemInvalid}
      item={item}
      onSubmit={submit}
      onError={setServerErrors}
    >
      <FormWrapper
        title="Key"
        item={item}
        backUrl={url}
      >
        <FormInput
          validationMessages={validationMessagesToDisplay.name}
          value={item.name}
          label={t('Name')}
          autoFocus
          onChange={onKeyNameChange}
          required
        />
        <FormInput
          validationMessages={validationMessagesToDisplay.public_key}
          value={item.system_config.public_key}
          label={t('Public key')}
          onChange={onPublicKeyChange}
          disabled={!!item.id}
          required
        />
        <FormInput
          validationMessages={validationMessagesToDisplay.private_key}
          value={item.system_config.private_key}
          label={t('Private key')}
          onChange={onPrivateKeyChange}
          disabled={!!item.id}
          required
        />
        <FormControl
          fullWidth
          validationMessages={validationMessagesToDisplay.active}
        >
          <Checkbox
            checked={item.active || false}
            label={t('Active')}
            onChange={onActiveChange}
            disabled={item.in_use}
          />
        </FormControl>
      </FormWrapper>
    </Form>
  );
};

OnlinePaymentKeyForm.defaultProps = {
  online_payment_key: {
    active: false,
    name: '',
    system_config: {
      public_key: '',
      private_key: '',
    },
  },
};
