import i18n from "i18next";
import { initReactI18next, } from "react-i18next";
import { CLIENT_APP_LOCALES_LIST } from 'Constants';

export function initI18n(resources = {}, lng) {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng,
      resources,
      supportedLngs: CLIENT_APP_LOCALES_LIST,
      interpolation: {
        escapeValue: false
      },
    });
}


export function transformLocales(locales, availableLocales) {
  return availableLocales.reduce(function (res, lang) {
    const translations = locales.reduce(function (translation, locale = {}) {
      return { ...translation, ...locale[lang] };
    }, {});
    return { ...res, [lang]: { translation: translations } };
  }, {});
}
