import { TableCell, TableRow, makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { IncomeTableRowProps } from '../types';

const useStyles = makeStyles(() => ({
  incomeSourceName: {
    display: 'inline-block',
    maxWidth: 'min-content',
  },
  totalAmountRow: {
    color: '#3f51b5',
    fontWeight: 'bold',
  },
  totalAmountSubRow: {
    fontWeight: 'bold',
  },
}));

const INCOME_SOURCE_NAMES = {
  cash: 'Cash',
  terminal: 'Terminal',
  transfer_to_card: 'Transfer to card',
  online: 'Online',
  Total: 'Total',
  total: 'Total',
};

export const IncomeTableRow = ({
  rowData,
  subRow,
  className,
}: IncomeTableRowProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const source = subRow
    ? rowData[0].source
    : INCOME_SOURCE_NAMES[
        rowData[0].source as keyof typeof INCOME_SOURCE_NAMES
      ];

  const isTotalRow = source === INCOME_SOURCE_NAMES.Total;

  return (
    <TableRow className={className || ''}>
      <TableCell
        colSpan={subRow ? 1 : 2}
        align="center"
      >
        <span
          className={classnames({
            [classes.incomeSourceName]: true,
            [classes.totalAmountRow]: isTotalRow && !subRow,
            [classes.totalAmountSubRow]: isTotalRow && subRow,
          })}
        >
          {t(source)}
        </span>
      </TableCell>
      {rowData.map((income, index) => {
        const amount = income.amount === null ? '-' : income.amount;
        const isLastAmount = index === rowData.length - 1;

        return (
          <TableCell
            key={income.location}
            align="center"
            className={classnames({
              [classes.totalAmountRow]:
                isLastAmount || (!subRow && income.source === 'Total'),
              [classes.totalAmountSubRow]: subRow && income.source === 'Total',
            })}
          >
            {amount}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
