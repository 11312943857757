import { Grid, } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { FormWrapper, Form, FormInput, Select, MultiSelect } from 'Components';
import { checkRequired, } from 'Helpers/validation';
import { useForm, } from 'Hooks';

const validators = {
  first_name: checkRequired('first_name'),
  last_name: checkRequired('last_name'),
  email: checkRequired('email'),
  role: checkRequired('role'),
  password: function ({ password, confirm_password }) {
    if (!password) {
      return 'Can\'t be blank';
    }
    if (password.length < 6) {
      return 'Minimal Password length is 6';
    }
    if (password !== confirm_password) {
      return 'Passwords does not match';
    }
    return null;
  },

  confirm_password: function ({ password, confirm_password, }) {
    if (!confirm_password) {
      return 'Can\'t be blank';
    }
    if (password !== confirm_password) {
      return 'Passwords does not match';
    }
    return null;
  },
};

export const UserForm = ({ url, api_url, user, user_roles, locations }) => {
  const { t, } = useTranslation();
  const { item, changeValue, action, method, isItemInvalid,
    validationMessagesToDisplay, submit
  } = useForm(user, validators, api_url);
  const userRoles = React.useMemo(function () {
    return user_roles.map(role => ({ id: role, name: role }));
  }, [user_roles]);

  const serializedItem = React.useMemo(function () {
    const { confirm_password, ...serializedItem } = item;
    return serializedItem;
  }, [item]);

  function handleChangeFirstName({ target }) {
    changeValue('first_name', target.value);
  }

  function handleChangeLastName({ target }) {
    changeValue('last_name', target.value);
  }

  function handleChangeEmail({ target }) {
    changeValue('email', target.value);
  }

  function handleChangeRole({ target }) {
    changeValue('role', target.value);
  }

  function handleChangePassword({ target }) {
    changeValue('password', target.value);
  }

  function handleChangeConfirmPassword({ target }) {
    changeValue('confirm_password', target.value);
  }

  const handleChangeLocationIds = ({ target }) => {
    changeValue('location_ids', target.value);
  }

  return (
    <Form name="user" action={action} method={method} validators={validators}
      disabled={isItemInvalid} onSubmit={submit} item={serializedItem} >
      <FormWrapper title="User" item={item} backUrl={url}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput name="user[first_name]" required
              validationMessages={validationMessagesToDisplay.first_name} value={item.first_name}
              label={t('First Name')} autoFocus onChange={handleChangeFirstName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput name="user[last_name]" required
              validationMessages={validationMessagesToDisplay.last_name} value={item.last_name}
              label={t('Last Name')} onChange={handleChangeLastName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput name="user[last_name]" required
              validationMessages={validationMessagesToDisplay.email} value={item.email}
              label={t('Email')} onChange={handleChangeEmail} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select name="user[role]" required options={userRoles}
              validationMessages={validationMessagesToDisplay.role} value={item.role}
              label={t('Role')} onChange={handleChangeRole} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput type="password" name="user[password]" required
              autoComplete='new-password' label={t('Password')}
              validationMessages={validationMessagesToDisplay.password}
              onChange={handleChangePassword} value={item.password} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput type="password" name="user[password]" required
              autoComplete='new-password' label={t('Password')}
              validationMessages={validationMessagesToDisplay.confirm_password}
              onChange={handleChangeConfirmPassword} value={item.confirm_password || ''} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MultiSelect
              options={locations}
              label={t('Locations')}
              value={item.location_ids}
              onChange={handleChangeLocationIds}
              validationMessages={validationMessagesToDisplay.location_ids}
              required
            />
          </Grid>
        </Grid>
      </FormWrapper>
    </Form>
  );
}

UserForm.defaultProps = {
  user: {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    role: '',
    location_ids: []
  },
};
