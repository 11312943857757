import { toMoneyFormat, deleteItem, } from 'Helpers';
import { useTranslation, } from 'react-i18next';
import { ListWrapper, Table, ListActions, Button, Filters, Select, MultiSelect, Checkbox } from 'Components';
import { useList, } from 'Hooks';
import { DEFAULT_SORT_PARAMS } from 'Constants';

const ACTIVE_CONTROL_OPTION_IDS = {
  active: 'active',
  not_active: 'not_active'
}

const VISIBLE_CONTROL_OPTION_IDS = {
  yes: 'yes',
  no: 'no'
}

const tableContainerAttributes = {
  style: {
    maxHeight: 'calc(100vh - 200px)'
  }
}

export const Services = ({ url, new_item_url, fetch_url, service_types, locations, service_categories }) => {
  const { t } = useTranslation();

  const {
    pending, data, records_total, paginationParams, handleChangePagination, filters, setFilters
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });


  const tableConfig = [
    {
      title: t('Name'),
      sortable: true,
      accessor: 'name',
    },
    {
      title: t('Service Category'),
      accessor: 'service_category_name',
    },
    {
      title: t('Service Type'),
      accessor: 'service_type',
    },
    {
      title: t('Price'),
      accessor: 'price',
      cellComponent: item => toMoneyFormat(item.price),
    },
    {
      title: t('Visits Count'),
      accessor: 'visits_count',
    },
    {
      title: t('Duration In Days'),
      accessor: 'duration_in_days',
    },
    {
      title: t('Active'),
      accessor: 'active',
      cellComponent: item => item.active ? '+' : '-',
    },
    {
      title: t('Visible'),
      accessor: 'visible',
      cellComponent: item => item.visible ? '+' : '-',
    },
    {
      title: t('Locations'),
      accessor: 'locations',
      cellComponent: ({ locations }) => locations.map(({ name }) => name).join(', ')
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }) => (
        <ListActions editUrl={`${url}/${id}/edit`} onDelete={deleteItem(fetch_url, { id })} />
      )
    }
  ];

  const optionsActiveControl = React.useMemo(() => {
    return [
      {
        id: ACTIVE_CONTROL_OPTION_IDS.active,
        name: t('Active')
      },
      {
        id: ACTIVE_CONTROL_OPTION_IDS.not_active,
        name: t('Not Active')
      }
    ]
  }, [])

  const optionsVisibilityControl = React.useMemo(() => {
    return [
      {
        id: VISIBLE_CONTROL_OPTION_IDS.yes,
        name: t('Yes')
      },
      {
        id: VISIBLE_CONTROL_OPTION_IDS.no,
        name: t('No')
      }
    ]
  }, [])

  function onSubmitFilters({ locations, service_type, service_category, visible, active, ...filters }) {
    const currentFilter = {
      ...filters,
      ...(!!locations.length && { locations }),
      ...(!!service_type && { service_type }),
      ...(!!service_category && { service_category }),
      ...(!!visible && { visible: visible === VISIBLE_CONTROL_OPTION_IDS.yes }),
      ...(!!active && { active: active === ACTIVE_CONTROL_OPTION_IDS.active }),
    }

    localStorage.setItem('services_filter_options', JSON.stringify(currentFilter));
    setFilters(currentFilter);
  }

  const initialFilterValues = React.useMemo(() => {
    const { visible, active, locations, ...filterValues } = filters

    return {
      ...filterValues,
      locations: locations || [],
      ...(filters.hasOwnProperty('visible') && {
        visible: visible ? VISIBLE_CONTROL_OPTION_IDS.yes : VISIBLE_CONTROL_OPTION_IDS.no
      }),
      ...(filters.hasOwnProperty('active') && {
        active: active ? ACTIVE_CONTROL_OPTION_IDS.active : ACTIVE_CONTROL_OPTION_IDS.not_active
      })
    }
  }, []);



  return (
    <ListWrapper title={`${t('Services')} (${records_total})`} headerAddon={(
      <div>
        <Button href={new_item_url} variant="contained" color="primary">
          {t('Create Service')}
        </Button>
      </div>
    )}>
      <Filters onSubmit={onSubmitFilters} initialValues={initialFilterValues}>
        <Select label={t('Service Type')} options={service_types} name="service_type"
          emptyOption />
        <Select label={t('Service Category')} options={service_categories} name="service_category"
          emptyOption />
        <Select label={t('Status')} options={optionsActiveControl} name="active"
          emptyOption />
        <Select label={t('Visible')} options={optionsVisibilityControl} name="visible"
          emptyOption />
        <MultiSelect
          options={locations}
          label={t('Locations')}
          name="locations"
        />
      </Filters>
      <hr></hr>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        tableProps={{ stickyHeader: true }}
        containerAttr={tableContainerAttributes}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
}
