import { Box, Typography, Card, makeStyles } from '@material-ui/core';
import { Description } from './Description';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%'
  },
  cardItemContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    textDecoration: 'none',
    color: 'inherit',
  },
  locationName: {
    marginBottom: '8px',
    fontSize: '22px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      marginBottom: '0px',
    },
  },
  locationDescription: {
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  imageWrapper: {
    maxWidth: '275px',
    height: '185px',
    marginRight: '24px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '16px',
      maxWidth: '50%',
      height: 'auto'
    }
  },
  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  showMore: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 16px',
      display: 'block',
    }
  },
}));

export const LocationCard = ({
  name, photo_url, booking_description, href, openDescriptionDialog
}) => {
  const classes = useStyles();

  function showDescription() {
    openDescriptionDialog({ title: name, content: booking_description })
  }

  return (
    <Card elevation={8} className={classes.card}>
      <Box className={classes.cardItemContent} component='a' href={href}>
        <Box className={classes.imageWrapper}>
          <img src={photo_url} className={classes.image} alt="" />
        </Box>
        <div>
          <Typography component="h3" className={classes.locationName}>
            {name}
          </Typography>
          <div className={classes.locationDescription} dangerouslySetInnerHTML={{ __html: booking_description }}></div>
        </div>
      </Box>
      <Box className={classes.showMore} >
        <Description text={booking_description}
          showDescription={showDescription} />
      </Box>
    </Card>
  )
}
