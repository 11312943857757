import { Grid, Container } from '@material-ui/core';
import bookingCardsClasses from '../BookingCardsStyles';
import {TypeCardItem} from '../TypeCardItem'

export const BookingTypes = ({ client_book_class, client_book_spot_event, client_book_time }) => {
  const classes = bookingCardsClasses()

  return (
    <Container className={classes.cards}>
      <Grid container spacing={2} className={classes.cardsContainer}>
        <Grid item xs={12} sm={4} >
          <TypeCardItem url={client_book_time.url} description={client_book_time.description}>
            Забронювати тренування
          </TypeCardItem>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TypeCardItem url={client_book_class.url} description={client_book_class.description}>
            Записатись на клас
          </TypeCardItem>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TypeCardItem url={client_book_spot_event.url} description={client_book_spot_event.description}>
            Зареєструватись на подію
          </TypeCardItem>
        </Grid>
      </Grid>
    </Container>
  )
}
