export const Certificates = {
  en: {
    'Create Certificate': 'Create Certificate',
    'Code': 'Code',
    'Services': 'Services',
    'Comment': 'Comment',
    'Used': 'Used',
    'Created': 'Created',
    'out of': 'out of',
    'Receipt': 'Receipt'
  },
  uk: {
    'Create Certificate': 'Створити Сертифікат',
    'Code': 'Код',
    'Services': 'Сервіси',
    'Comment': 'Коментар',
    'Used': 'Використаний',
    'Created': 'Створений',
    'out of': 'із',
    'Receipt': 'Квитанція'
  },
}

export const CertificateForm = {
  en: {
    ...Certificates.en,
    'New Certificate': 'New Certificate',
    'Comment': 'Comment',
    'Total': 'Total',
    'Payment Types': 'Payment Types'
  },
  uk: {
    ...Certificates.uk,
    'New Certificate': 'Новий Сертифікат',
    'Comment': 'Коментар',
    'Total': 'Разом',
    'Payment Types': 'Типи Платежу',
  },
}
