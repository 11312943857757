export const ServiceUsageHistoryExports = {
  en: {
    'Service Usage History Exports': 'Service Usage History Exports',
    'Filename': 'Filename',
    'Status': 'Status',
    'Error': 'Error',
    'Download': 'Download'
  },
  ru: {
    'Service Usage History Exports': 'Экспорт истории использования',
    'Filename': 'Название файла',
    'Status': 'Статус',
    'Error': 'Ошибка',
    'Download': 'Скачать'
  },
  uk: {
    'Service Usage History Exports': 'Експорт iсторії використання',
    'Filename': 'Назва файлу',
    'Status': 'Статус',
    'Error': 'Помилка',
    'Download': 'Завантажити'
  },
};
