import { FormControl as MFormControl, FormLabel, FormHelperText, makeStyles, } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    boxSizing: 'border-box',
    border: `1px solid  ${theme.palette.text.disabled}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
  },
  containerInvalid: {
    borderColor: theme.palette.error.main,
  },
}));

export const FormControl = ({
  children, label, invalid, validationMessages, fullWidth, className, onClick, isRequired
}) => {
  const classes = useStyles();

  return (
    <MFormControl
      fullWidth={fullWidth}
      component="fieldset"
      className={classnames(className, classes.container, {
        [classes.containerInvalid]: !!invalid || !!validationMessages,
      })}
      onClick={onClick}
      error={!!validationMessages}
      required={isRequired}>
      <FormLabel component="legend">
        {label}
      </FormLabel>
      {children}
      {!!validationMessages && (
        <FormHelperText>{validationMessages}</FormHelperText>
      )}
    </MFormControl>
  );
}
