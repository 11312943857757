import {
  Dialog, DialogTitle, DialogContent, DialogActions, FormControl
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'Components';

const DEFAULT_STATE = {
  id: '', completely: false, isOpened: false
}

export const ConfirmDialog = React.forwardRef(({ onConfirm }, ref) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState(DEFAULT_STATE);

  function open(id) {
    setState({ ...state, id, isOpened: true });
  }

  function close() {
    setState(DEFAULT_STATE);
  }

  function handleConfirm() {
    const { id, completely } = state;
    onConfirm(id, completely);
    close();
  }

  const handleChangeCompletely = ({ target }) => {
    setState({
      ...state, completely: target.checked
    })
  }

  React.useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Dialog open={state.isOpened} disableBackdropClick disableEscapeKeyDown
      maxWidth="xs" aria-labelledby="confirmation-dialog-title">
      <DialogTitle id="confirmation-dialog-title">{t('Confirmation')}</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth>
          <Checkbox checked={state.completely} label={t('confirmLabel')}
            onChange={handleChangeCompletely} />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t('Close')}</Button>
        <Button color="primary" onClick={handleConfirm}>
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
