import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Grid, Chip, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, FormControl } from 'Components';
import { findInArrayBy, removeFromArray, pushToArray } from 'Helpers';
import { ServicesList } from './ServicesList';

const useStyles = makeStyles(() => ({
  dialog: {
    width: '100%'
  },
  height100: {
    height: '100%',
    maxHeight: '100%',
  },
  servicesColumn: {
    maxHeight: '100%',
  },
  fullHeight: {
    height: '100%',
    maxHeight: '100%',
  },
  chipsList: {
    width: '100%'
  },
  selectedServices: {
    height: '100%',
    maxHeight: '100%',
    width: '100%'
  },
  chip: {
    margin: 2,
    height: 'auto'
  },
  chipLabel: {
    whiteSpace: 'initial',
    margin: 0,
    padding: '6px 0'
  },
}))


export const ServicesPickerDialog = React.forwardRef(({ services }, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = React.useState({
    isOpened: false,
    selectedServices: [],
    expandedCategory: null,
    onSelect: null
  });

  function close() {
    setState({ ...state, isOpened: false, expandedCategory: null, })
  }

  function open(selectedServices = [], onSelect = () => { }) {
    setState({ ...state, selectedServices, isOpened: true, onSelect })
  }

  React.useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  function expandCategory(category) {
    const expandedCategory = state.expandedCategory === category ? null : category;
    setState({ ...state, expandedCategory });
  }

  function toggleActiveService(service) {
    const selectedServices = findInArrayBy(state.selectedServices, service.id)
      ? removeFromArray(state.selectedServices, service.id)
      : pushToArray(state.selectedServices, service);
    setState({ ...state, selectedServices });
  }

  function handleDelete(id) {
    setState({ ...state, selectedServices: removeFromArray(state.selectedServices, id) });
  }

  function handleSelect() {
    state.onSelect(state.selectedServices);
    close()
  }


  const serializedServices = React.useMemo(() => {
    return services.reduce((res, { service_category_name }) => {
      if (Object.keys(res).indexOf(service_category_name) > -1) {
        return res;
      }
      return {
        ...res,
        [service_category_name]: services.filter((service) => service.service_category_name === service_category_name),
      };
    }, {});
  }, [services]);

  return (
    <Dialog open={state.isOpened} maxWidth='lg' fullWidth
      PaperProps={{
        style: {
          height: '100%'
        },
      }}>

      <DialogContent dividers>
        <Grid container spacing={2} className={classes.fullHeight}>
          <Grid item xs={12} sm={8} className={classes.height100}>
            <FormControl
              label={t('Services')}
              className={classes.height100}
              fullWidth
            >
              <ServicesList
                services={serializedServices}
                selectedServices={state.selectedServices}
                expandedCategory={state.expandedCategory}
                onCategoryClick={expandCategory}
                onServiceClick={toggleActiveService}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl label={t('Selected services')} className={classes.selectedServices}>
              <div>
                {state.selectedServices.map(({ id, name }) => (
                  <Chip key={id} className={classes.chip} label={(<p className={classes.chipLabel}>{name}</p>)}
                    variant="outlined" onDelete={() => handleDelete(id)} />
                ))}
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t('Close')}</Button>
        <Button color="primary" onClick={handleSelect}>
          {t('Select')}
        </Button>
      </DialogActions>
    </Dialog>
  );
})


