import { Container, Card, CardContent, Typography, Grid, IconButton, makeStyles, } from "@material-ui/core";
import { useTranslation, } from 'react-i18next';
import { Button, } from 'Components';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '0!important',
    paddingTop: '0!important',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(2)
    },
  },
  title: {
    margin: `${theme.spacing(1)}px 0`,
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px'
    },
  }
}));

export const ListWrapper = ({ title, children, headerAddon, className, titleClassName }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <div className={classes.header}>
        <Typography className={`${classes.title} ${titleClassName || ''}`} component="h1" variant="h4">{title}</Typography>
        {headerAddon}
      </div>
      <Card className={className}>
        <CardContent classes={{ root: classes.root }} >
          {children}
        </CardContent>
      </Card>
    </Container>
  )
};

export const FormWrapper = ({ title, children, item, backUrl, formSubmitBtn, disabled }) => {
  const { t, } = useTranslation();
  const classes = useStyles();

  const submitBtn = formSubmitBtn ? formSubmitBtn : (
    <Button variant="contained" color="primary" type="submit" disabled={disabled}>
      {t(`${item.id ? 'Update' : 'Create'} ${title}`)}

    </Button>
  );
  const formTitle = `${item.id ? 'Edit' : 'New'} ${title}`;

  return (
    <Container maxWidth="md">
      <div className={classes.header}>
        <Typography component="h1" variant="h4">
          {!!backUrl && (
            <IconButton href={backUrl}><i className="icon-left-big"></i></IconButton>
          )}
          {t(formTitle)}
        </Typography>
        {submitBtn}
      </div>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
};
