import {makeStyles, Box} from '@material-ui/core';
import moment from 'moment';
import { SEND_DATES_FORMAT, DISPLAY_DATES_FORMAT } from 'Constants';
import { AJAX, getParamFromQueryString, toQueryString } from 'Helpers';
import { Preloader } from 'Components';
import { ClassCardItem } from '../ClassCardItem';
import DescriptionDialog from '../DescriptionDialog';
import { HorizontalDatePicker } from '../HorizontalDatePicker';

const TODAY = moment();
const QUERY_DATE_STRING = getParamFromQueryString('date') || TODAY.format(SEND_DATES_FORMAT)
const INITIAL_DATE = moment.max([moment(QUERY_DATE_STRING), TODAY]).format(SEND_DATES_FORMAT)

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 8px',
    display: 'flex',

    [theme.breakpoints.down('xs')]: {
      display: 'block',
      padding: 0
    }
  },
  content: {
    width: '100%'
  },
  dateInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  timeInfo: {
    textAlign: 'center'
  },
  currentDate: {
    marginRight: '25px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    }
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center'
  },
  classesContainer: {
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  },
}));

export const ClassesBookingPeriods = ({ url, active_dates }) => {
  const classes = useStyles();

  const [selectedDate, changeSelectedDate] = React.useState(INITIAL_DATE);
  const [periods, setPeriods] = React.useState({
    pending: true,
    data: []
  })

  const descriptionDialogRef = React.useRef(null);

  function openDescriptionDialog(descriptionData) {
      descriptionDialogRef.current.open(descriptionData)
  }

  function handleChangeDate(date) {
    changeSelectedDate(date.format(SEND_DATES_FORMAT))
  }

  function setQueryString() {
    const queryDate = getParamFromQueryString('date');
    if (!queryDate || queryDate !== selectedDate) {
      const dateQuery = toQueryString({ date: selectedDate });
      window.history.pushState('', '', `${url}?${dateQuery}`);
    }
  }

  async function fetchTimePeriods() {
    setPeriods({
      ...periods,
      pending: true,
    })
    const res = await AJAX.get(url, {
      body: {
        format: 'json',
        date: selectedDate
      }
    });

    return setPeriods({
        data: res?.data || [],
        pending: false,
      })
  }

  function setSelectedDateByQuery() {
    const queryDate = getParamFromQueryString('date');
    if (queryDate) {
      changeSelectedDate(queryDate);
    }
  }

  React.useEffect(() => {
    if (selectedDate) {
      setQueryString();
      fetchTimePeriods();
    }

      window.addEventListener('popstate', setSelectedDateByQuery);

    return () => {
      window.removeEventListener('popstate', setSelectedDateByQuery);
    }
  }, [selectedDate])

  const dateToDisplay = React.useMemo(() => {
    return moment(selectedDate).format(DISPLAY_DATES_FORMAT)
  }, [selectedDate])

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <HorizontalDatePicker
          activeDates={active_dates}
          onChangeDate={handleChangeDate}
          selectedDate={selectedDate}
          changeSelectedDate={changeSelectedDate}
        />
        <Box className={classes.dateInfo}>
          <h1 className={classes.currentDate}>{dateToDisplay}</h1>
          <h2>Оберіть час</h2>
        </Box>
        {periods.pending && (
          <Box className={classes.preloader}>
            <Preloader />
          </Box>
        )}
        {!periods.pending && periods.data.map((period) => (
          <Box
            key={period.id}
            className={classes.classesContainer}
          >
            <ClassCardItem
              {...period}
              openDescriptionDialog={openDescriptionDialog} />
          </Box>
        ))}
        {!periods.pending && !periods.data.length && (
          <p className={classes.timeInfo}>Немає вільного часу</p>
        )}
      </Box>
      <DescriptionDialog ref={descriptionDialogRef} />
    </Box>
  )
}
