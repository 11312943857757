import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ListWrapper, Table, ListActions, Button } from 'Components';
import { useList, } from 'Hooks';
import { DEFAULT_SORT_PARAMS } from 'Constants';

export const Companies = ({ url, new_item_url, fetch_url, }) => {
  const { t } = useTranslation();
  const {
    pending, data, records_total, paginationParams, handleChangePagination,
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  const tableConfig = [
    {
      title: t('Name'),
      accessor: 'name',
      cellComponent: ({ name, id }) => <Link href={`${url}/${id}/clients`}>{name}</Link>,
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }) => <ListActions editUrl={`${url}/${id}/edit`} />,
    },
  ];

  return (
    <ListWrapper
      title={`${t('Companies')} (${records_total})`}
      headerAddon={
        <div>
          <Button href={new_item_url} variant="contained" color="primary">
            {t('Create Company')}
          </Button>
        </div>
      }
    >
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
};
