import { forwardRef, useState, useMemo, useImperativeHandle } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { Table } from 'Components';
import confirmationStore from 'Helpers/confirmation';
import { AJAX } from 'Helpers';

import {
  CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY,
  CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
  PREVIOUSLY_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
} from 'queries';

import {
  FreezeListDialogProps,
  FreezeListDialogState,
  FreezeListDialogService,
} from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    width: '52px',
    height: '52px',
  },
}));

const INITIAL_STATE = {
  isOpened: false,
  services: [],
  service_id: '',
};

const FreezingListDialog = forwardRef((props: FreezeListDialogProps, ref) => {
  const { url } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();

  const [state, setState] = useState<FreezeListDialogState>(INITIAL_STATE);

  const onUnfreezeServiceSubmit = (service: FreezeListDialogService) => {
    const { id } = service;
    return () => {
      confirmationStore.open(onUnfreezeService(id));
    };
  };

  const onUnfreezeService = (periodId: number) => async () => {
    const res = await AJAX.put(
      `${url}/purchased_services/${state.service_id}/unfreeze`,
      {
        body: {
          freeze_period_id: periodId,
        },
      }
    );

    if (res.flash_type == 'success') {
      queryClient.invalidateQueries([
        CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
      ]);
      queryClient.invalidateQueries([CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY]);
      queryClient.invalidateQueries([
        PREVIOUSLY_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
      ]);
      onClose();
    }
  };

  const tableConfig = useMemo(() => {
    return [
      {
        title: t('From'),
        accessor: 'start_date',
      },
      {
        title: t('Till'),
        accessor: 'end_date',
      },
      {
        title: t('Actions'),
        cellComponent: (service: FreezeListDialogService) => (
          <>
            <IconButton
              size="small"
              onClick={onUnfreezeServiceSubmit(service)}
            >
              <i className="icon-trash-empty"></i>
            </IconButton>
          </>
        ),
      },
    ];
  }, [i18n.language, state]);

  const onOpen = (services: FreezeListDialogService[], id: string) => {
    setState({
      isOpened: true,
      services,
      service_id: id,
    });
  };

  const onClose = () => {
    setState(INITIAL_STATE);
  };

  useImperativeHandle(ref, () => ({
    onOpen,
    onClose,
  }));

  return (
    <Dialog
      open={state.isOpened}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle className={classes.root}>
        {t('History of Freezing')}
        <IconButton
          size="medium"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="icon-cancel"></i>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table
          config={tableConfig}
          data={state.services}
        />
      </DialogContent>
    </Dialog>
  );
});

export default FreezingListDialog;