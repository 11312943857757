import { useState, useRef, memo } from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FormControl } from 'Components';
import AddCardNumberForm from './AddCardNumberForm';
import confirmationStore from 'Helpers/confirmation';
import { AJAX, removeFromArray } from 'Helpers';

import { Card, CardNumbersProps } from './type';

const useStyles = makeStyles(() => ({
  formControl: {
    display: 'block',
  },
}));

const CardNumbers = memo((props: CardNumbersProps) => {
  const { card_numbers, card_number_url } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const addCardDialog = useRef(null);
  const [cards, setCards] = useState<Card[]>(card_numbers);

  const onCardAdd = (card: Card) => {
    setCards([...cards, card]);
  };

  const onOpenCardDeleteDialog = (id: number) => {
    const onDelete = async () => {
      const res = await AJAX.delete(`${card_number_url}/${id}`);
      if (res && res.flash_type === 'success') {
        setCards(removeFromArray(cards, id));
      }
    };
    confirmationStore.open(onDelete);
  };

  return (
    <FormControl
      label={t('Card Numbers')}
      className={classes.formControl}
    >
      {cards.map(({ card_number, id }) => (
        <Box key={id}>
          {card_number}
          <IconButton
            size="small"
            style={{ marginLeft: '20px' }}
            onClick={() => onOpenCardDeleteDialog(id)}
          >
            <i className="icon-trash-empty"></i>
          </IconButton>
        </Box>
      ))}
      <AddCardNumberForm
        ref={addCardDialog}
        url={card_number_url}
        onAddCardNumber={onCardAdd}
      />
    </FormControl>
  );
});

export default CardNumbers;