import {
  Grid, Typography, makeStyles, Card, Box, MenuItem, ListItemText, IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  ListWrapper, DatePicker, MultiSelect, Button, Preloader, Checkbox,
  ServicesPickerDialog, FormControl, Tooltip
} from 'Components';
import { AJAX, findInArrayBy } from 'Helpers';
import CreateFilterDialog from './CreateFilterDialog';
import FiltersListDialog from './FiltersListDialog';

const useStyles = makeStyles(() => ({
  filterTitle: {
    marginTop: '20px',
    marginBottom: '10px'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 20px',
    minHeight: '132px',
    position: 'relative'
  },
  cardTitle: {
    paddingRight: '20px'
  },
  submitRow: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  data: {
    marginBottom: '20px'
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px'
  },
  servicesFormGroup: {
    width: '100%',
    cursor: 'pointer'
  },
  button: {
    marginLeft: '10px'
  },
  tooltip: {
    position: 'absolute',
    top: '3px',
    right: '3px'
  },
  cardContainer: {
    position: 'relative'
  },
}))

const DATA_TYPES = {
  TOTAL: 'total',
  TRAINERS: 'trainers'
}

export const ReturnedClientsAnalytics = ({ filter_data, fetch_url, filter_views_url }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [state, setSetState] = React.useState({
    pending: false,
    data: null,
    dataType: ''
  });

  const [filter, setFilter] = React.useState({
    first_period: {
      start: '',
      end: '',
    },
    second_period: {
      start: '',
      end: '',
    },
    first_period_trainer_ids: [],
    first_period_services: [],
    first_period_location_ids: [],
    second_period_trainer_ids: [],
    second_period_services: [],
    second_period_location_ids: [],
    active_services_only: false
  });

  function handleChangeFirstPeriodStart({ target }) {
    setFilter({
      ...filter,
      first_period: {
        ...filter.first_period,
        start: target.value
      }
    })
  }

  function handleChangeFirstPeriodEnd({ target }) {
    setFilter({
      ...filter,
      first_period: {
        ...filter.first_period,
        end: target.value
      }
    })
  }

  function handleChangeFirstPeriodTrainerIds({ target }) {
    setFilter({
      ...filter,
      first_period_trainer_ids: target.value
    })
  }

  function handleChangeFirstPeriodServices(services) {
    setFilter({
      ...filter,
      first_period_services: services
    })
  }

  function handleChangeFirstPeriodLocationIds({ target }) {
    setFilter({
      ...filter,
      first_period_location_ids: target.value
    })
  }

  function handleChangeSecondPeriodStart({ target }) {
    setFilter({
      ...filter,
      second_period: {
        ...filter.second_period,
        start: target.value
      }
    })
  }

  function handleChangeSecondPeriodEnd({ target }) {
    setFilter({
      ...filter,
      second_period: {
        ...filter.second_period,
        end: target.value
      }
    })
  }

  function handleChangeSecondPeriodTrainerIds({ target }) {
    setFilter({
      ...filter,
      second_period_trainer_ids: target.value
    })

  }

  function handleChangeSecondPeriodServices(services) {
    setFilter({
      ...filter,
      second_period_services: services
    })
  }

  function handleChangeSecondPeriodLocationIds({ target }) {
    setFilter({
      ...filter,
      second_period_location_ids: target.value
    })
  }

  const filterServices = React.useMemo(() => {
    if (!filter.active_services_only) {
      return filter_data.services
    }

    return filter_data.services.filter(({ active }) => active)
  }, [filter.active_services_only])

  function handleChangeActiveServicesOnly({ target }) {
    if (target.checked) {
      return setFilter({
        ...filter,
        first_period_services: filter.first_period_services.filter(({ active }) => active),
        second_period_services: filter.second_period_services.filter(({ active }) => active),
        active_services_only: target.checked
      })
    }

    setFilter({
      ...filter,
      active_services_only: target.checked
    })
  }

  function setFilterFromList(data) {
    const { first_period_service_ids, second_period_service_ids, ...filterProps } = data

    const first_period_services = first_period_service_ids.map((id) => {
      const service = findInArrayBy(filterServices, id)
      return {
        ...service
      }
    })

    const second_period_services = second_period_service_ids.map((id) => {
      const service = findInArrayBy(filterServices, id)
      return {
        ...service
      }
    })

    setFilter({
      ...filterProps,
      first_period_services,
      second_period_services,
    })
  }

  async function fetchData() {
    setSetState({
      pending: true,
      data: null,
      dataType: ''
    })

    const { first_period_services, second_period_services, active_services_only, ...filterData } = filter;

    const res = await AJAX.get(fetch_url, {
      body: {
        ...filterData,
        first_period_service_ids: first_period_services.map(({ id }) => id),
        second_period_service_ids: second_period_services.map(({ id }) => id),
      },
    });

    if (res && (res[DATA_TYPES.TOTAL] || res[DATA_TYPES.TRAINERS])) {
      const dataType = res[DATA_TYPES.TOTAL] ? DATA_TYPES.TOTAL : DATA_TYPES.TRAINERS

      return setSetState({
        pending: false,
        data: res[dataType],
        dataType
      })
    }

    setSetState({
      pending: false,
      data: null,
      dataType: ''
    })
  }

  const servicesPicker = React.useRef(null);

  function showFirstPeriodServicesDialog() {
    servicesPicker.current.open(filter.first_period_services, handleChangeFirstPeriodServices)
  }

  function showSecondPeriodServicesDialog() {
    servicesPicker.current.open(filter.second_period_services, handleChangeSecondPeriodServices)
  }


  const body = React.useMemo(() => {
    if (state.dataType === DATA_TYPES.TOTAL) {
      return (
        <Box className={classes.data}>
          <Cards {...state.data} t={t} classes={classes} />
        </Box>
      )
    }


    if (state.dataType === DATA_TYPES.TRAINERS) {
      const data = Object.keys(state.data).reduce((res, trainerId) => {
        const trainer = findInArrayBy(filter_data.trainers, trainerId)
        return [
          ...res,
          {
            id: trainer.id,
            name: trainer.name,
            data: state.data[trainerId]
          }
        ]
      }, []);

      return (
        <Box className={classes.data}>
          {data.map((item) => (
            <Cards key={item.id} t={t} name={item.name} classes={classes} {...item.data} />
          ))}
        </Box>
      )
    }

    return null
  }, [state.dataType])

  const createFilterDialog = React.createRef(null);

  function showSaveFilterDialog() {
    createFilterDialog.current.open()
  }

  const filtersListDialog = React.createRef(null);

  function showFiltersListrDialog() {
    filtersListDialog.current.open()
  }

  return (
    <ListWrapper className={classes.cardContainer} title={t('Returned Clients Analytics')}>
      <Tooltip className={classes.tooltip} placement="bottom-end"
        title={
          <>
            <Typography>- {t('filterInfoRow1')}</Typography>
            <Typography>- {t('filterInfoRow2')}</Typography>
            <Typography>- {t('filterInfoRow3')}</Typography>
            <Typography>- {t('filterInfoRow4')}</Typography>
            <Typography>- {t('filterInfoRow5')}</Typography>
          </>
        }
      >
        <IconButton size="small" >
          <i className="icon icon-info"></i>
        </IconButton>
      </Tooltip>
      <Typography className={classes.filterTitle} variant="h5">{t('First Period')}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <DatePicker label={t('From')} value={filter.first_period.start} onChange={handleChangeFirstPeriodStart} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker label={t('Till')} value={filter.first_period.end} onChange={handleChangeFirstPeriodEnd} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MultiSelect
            options={filter_data.trainers}
            label={t('Trainer')}
            value={filter.first_period_trainer_ids}
            onChange={handleChangeFirstPeriodTrainerIds}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl className={classes.servicesFormGroup} onClick={showFirstPeriodServicesDialog} label={t('Services')}>
            {filter.first_period_services.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <MultiSelect
            options={filter_data.locations}
            label={t('Locations')}
            value={filter.first_period_location_ids}
            onChange={handleChangeFirstPeriodLocationIds}
          />
        </Grid>
      </Grid>
      <Typography className={classes.filterTitle} variant="h5">{t('Second Period')}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <DatePicker label={t('From')} value={filter.second_period.start} onChange={handleChangeSecondPeriodStart} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker label={t('Till')} value={filter.second_period.end} onChange={handleChangeSecondPeriodEnd} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MultiSelect
            options={filter_data.trainers}
            label={t('Trainer')}
            value={filter.second_period_trainer_ids}
            onChange={handleChangeSecondPeriodTrainerIds}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl className={classes.servicesFormGroup} onClick={showSecondPeriodServicesDialog} label={t('Services')}>
            {filter.second_period_services.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <MultiSelect
            options={filter_data.locations}
            label={t('Locations')}
            value={filter.second_period_location_ids}
            onChange={handleChangeSecondPeriodLocationIds}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Checkbox checked={filter.active_services_only || false}
            onChange={handleChangeActiveServicesOnly} label={t('Active Services Only')} />
        </Grid>
        <Grid item xs={12} className={classes.submitRow}>
          <Button type="button" onClick={fetchData}>{t('Filter')}</Button>
          <Box>
            <Button className={classes.button} type="button" color="primary" onClick={showSaveFilterDialog}>{t('Save Filter')}</Button>
            <Button className={classes.button} type="button" color="primary" onClick={showFiltersListrDialog}>{t('Show Filter Settings')}</Button>
          </Box>
        </Grid>
      </Grid>

      {state.pending && (
        <Box className={classes.preloader}>
          <Preloader />
        </Box>
      )}
      {body}

      <ServicesPickerDialog ref={servicesPicker} services={filterServices} />
      <CreateFilterDialog ref={createFilterDialog} apiUrl={filter_views_url} filter={filter} />
      <FiltersListDialog ref={filtersListDialog} fetchUrl={filter_views_url} onSelect={setFilterFromList} />
    </ListWrapper>
  );
};

function Cards({ first_visits, second_visits, average_money_spent, name, classes, t }) {
  return (
    <Grid container spacing={2}>
      {!!name && (
        <Grid item xs={12} sm={12}>
          <Typography variant="h5">{name}</Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={3}>
        <Card variant="outlined" className={classes.card}>
          <Typography color="textSecondary" component="h3">
            {t('firstUsagesText')}
          </Typography>
          <Typography variant="h3" color="textPrimary" component="h3">
            {first_visits}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card variant="outlined" className={classes.card}>
          <Typography color="textSecondary" component="h3">
            {t('secondUsagesText')}
          </Typography>
          <Typography variant="h3" color="textPrimary" component="h3">
            {second_visits}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card variant="outlined" className={classes.card}>
          <Typography color="textSecondary" component="h3">
            {t('percentageText')}
          </Typography>
          <Typography variant="h3" color="textPrimary" component="h3">
            {Math.round((second_visits / first_visits) * 100) || 0} %
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card variant="outlined" className={classes.card}>
          <Typography color="textSecondary" component="h3" className={classes.cardTitle}>
            {t('averageMoneySpent')}
          </Typography>
          <Tooltip className={classes.tooltip} placement="bottom-end"
            title={
              <Typography color="inherit">
                {t('averageMoneySpentExpanded')}
              </Typography>
            }>
            <IconButton size="small" >
              <i className="icon icon-info"></i>
            </IconButton>
          </Tooltip>
          <Typography variant="h3" color="textPrimary" component="h3">
            {average_money_spent}
          </Typography>
        </Card>
      </Grid>
    </Grid>
  )
}