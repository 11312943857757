import { useQuery } from '@tanstack/react-query';

import { HttpClient } from 'network/HttpClient';

import { FetchDataParams } from 'schemas';

export const CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY = 'currentActiveDiscounts';

export const useCurrentActiveDiscountsQuery = ({
  fetchUrl,
  paginationParams,
  filters,
}: FetchDataParams) => {
  return useQuery(
    [CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY, paginationParams],
    () => HttpClient.getDiscounts({ fetchUrl, paginationParams, filters }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};
