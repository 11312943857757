import { Grid, Card, Box, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  ListWrapper,
  Filters,
  Autocomplete,
  Chart,
  DatePicker,
  MultiSelect,
} from 'Components';
import { AJAX, allowResponsiveView } from 'Helpers';
import { checkRequired } from 'Helpers/validation';

const validators = {
  from: checkRequired('from'),
  till: checkRequired('till'),
}

function transformisitsData(items, categoryKey, dataKey) {
  const emptyRes = { categories: [], data: [] };

  if (!items || !Object.keys(items).length) {
    return emptyRes;
  }

  if (!Array.isArray(items)) {
    const firstLocationData = items[Object.keys(items)[0]]

    const chart = {
      categories: firstLocationData?.map((item) => item[categoryKey]),
      series: Object.entries(items).map(([name, value]) => ({
        name,
        data: value.map((item) => item[dataKey])
      }))
    }

    return chart;
  }

  return items.reduce(function (res, item) {
    return {
      categories: [...res.categories, item[categoryKey]],
      data: [...res.data, item[dataKey]],
    };
  }, emptyRes);
}

const statisticsData = [
  {
    urlKey: 'visits_by_days_url',
    itemKey: 'visits_by_days',
    categoryKey: 'visit_date',
    dataKey: 'visits_count',
  },
  {
    urlKey: 'visits_by_days_by_locations_url',
    itemKey: 'visits_by_days_by_locations',
    categoryKey: 'visit_date',
    dataKey: 'visits_count',
  },
  {
    urlKey: 'visits_by_month_url',
    itemKey: 'visits_by_month',
    categoryKey: 'visit_month',
    dataKey: 'visits_count',
  },
  {
    urlKey: 'income_by_days_url',
    itemKey: 'income_by_days',
    categoryKey: 'payment_date',
    dataKey: 'sum_by_day',
  },
  {
    urlKey: 'income_by_days_by_locations_url',
    itemKey: 'income_by_days_by_locations',
    categoryKey: 'payment_date',
    dataKey: 'sum_by_day',
  },
  {
    urlKey: 'income_by_month_url',
    itemKey: 'income_by_month',
    categoryKey: 'payment_month',
    dataKey: 'sum_by_month',
  },
  {
    urlKey: 'income_by_days_cash_url',
    itemKey: 'income_by_days_cash',
    categoryKey: 'payment_date',
    dataKey: 'sum_by_day',
  },
  {
    urlKey: 'income_by_days_terminal_url',
    itemKey: 'income_by_days_terminal',
    categoryKey: 'payment_date',
    dataKey: 'sum_by_day',
  },
  {
    urlKey: 'income_by_days_online_url',
    itemKey: 'income_by_days_online',
    categoryKey: 'payment_date',
    dataKey: 'sum_by_day',
  },
]

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 20px',
    position: 'relative'
  },
  cardsWrapper: {
    marginBottom: '12px',
  }
}))


export const Statistics = ({ data_urls, filter }) => {
  const filters = React.useRef(null);
  const [items, setItems] = React.useState({});
  const { t } = useTranslation();
  const [todaysData, setTodaysData] = React.useState([])

  const classes = useStyles();

  React.useEffect(() => {
    allowResponsiveView()
  }, [])

  async function fetchTodaysData() {
    const { locations } = filters.current.values;
    const today = moment().format();
    const tomorrow = moment().add(1, 'day').format();
    const statisticsResponse = await AJAX.get(data_urls.income_and_visits_for_today_url, {
      body: {
        filters: {
          ...(!!locations.length && { locations }),
          created_at: {
            from: today,
            till: tomorrow,
          }
        }
      }
    })

    if (!statisticsResponse) {
      return setTodaysData(todaysData)
    }

    setTodaysData(statisticsResponse)
  }

  const renderTodaysData = (data, idx) => {
    const { location, visits_count, income } = data;
    return (
      <Grid key={idx} container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Box className={classes.card}>
            <Typography component="h3">
              {location}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card variant="outlined" className={classes.card}>
            <Typography color="textSecondary" component="h3">
              {t('Visits for today')} : {visits_count}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card variant="outlined" className={classes.card}>
            <Typography color="textSecondary" component="h3">
              {t('Income for today')} : {income}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    )
  }

  function fetchData() {
    const { from, till, locations, ...filtersValues } = filters.current.values;
    setItems({});
    fetchTodaysData()
    statisticsData.forEach(({ urlKey, itemKey, categoryKey, dataKey }) => {
      AJAX.get(data_urls[urlKey], {
        body: {
          filters: {
            ...filtersValues,
            ...(!!locations.length && { locations }),
            ...((!!from || !!till) && {
              created_at: {
                ...(!!from && { from }),
                ...(!!till && { till }),
              },
            }),
          },
        },
      }).then((res) => {
        setItems((prevState) => {
          return {
            ...prevState,
            [itemKey]: {
              ...transformisitsData(res, categoryKey, dataKey),
            }
          }
        })
      })
    })
  }

  return (
    <ListWrapper title={t('General statistics')}>
      <Filters
        ref={filters}
        onSubmit={fetchData}
        initialValues={{
          from: moment().subtract(2, 'months').startOf('month').format(),
          till: moment().add(1, 'day').format(),
          locations: []
        }}
        onInit={fetchData}
        validators={validators}
      >
        <DatePicker
          label={t('From')}
          name="from"
          required
        />
        <DatePicker
          label={t('Till')}
          name="till"
          required
        />
        <MultiSelect
          options={filter.locations}
          label={t('Locations')}
          name="locations"
        />
      </Filters>
      <Grid
        container
        spacing={2}
        className={classes.cardsWrapper}
      >
        {todaysData.map(renderTodaysData)}
      </Grid>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Chart
            label={t('Visits By Days')}
            type="spline"
            tooltipHeaderText={t('Date')}
            yText={t('Visits Count')}
            pending={!items.visits_by_days}
            {...items.visits_by_days}

          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Chart
            label={t('Visits By Days By Locations')}
            type="spline"
            tooltipHeaderText={t('Date')}
            yText={t('Visits Count')}
            pending={!items.visits_by_days_by_locations}
            {...items.visits_by_days_by_locations}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Chart
            label={t('Visits By Month')}
            pending={!items.visits_by_month}
            type="column"
            tooltipHeaderText={t('Date')}
            yText={t('Visits Count')}
            {...items.visits_by_month}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Chart
            label={t('Income By Days')}
            pending={!items.income_by_days}
            type="spline"
            tooltipHeaderText={t('Date')}
            yText={t('Amount')}
            {...items.income_by_days}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Chart
            label={t('Income By Days By Locations')}
            pending={!items.income_by_days_by_locations}
            type="spline"
            tooltipHeaderText={t('Date')}
            yText={t('Amount')}
            {...items.income_by_days_by_locations}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Chart
            label={t('Income By Month')}
            pending={!items.income_by_month}
            type="column"
            tooltipHeaderText={t('Date')}
            yText={t('Amount')}
            {...items.income_by_month}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Chart
            label={t('Income By Card')}
            pending={!items.income_by_days_terminal}
            type="spline"
            tooltipHeaderText={t('Date')}
            yText={t('Amount')}
            {...items.income_by_days_terminal}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Chart
            label={t('Income By Cash')}
            pending={!items.income_by_days_cash}
            type="spline"
            tooltipHeaderText={t('Date')}
            yText={t('Amount')}
            {...items.income_by_days_cash}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <Chart
            label={t('Income By Online')}
            pending={!items.income_by_days_online}
            type="spline"
            tooltipHeaderText={t('Date')}
            yText={t('Amount')}
            {...items.income_by_days_online}
          />
        </Grid>
      </Grid>
    </ListWrapper>
  );
};
