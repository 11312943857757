import { Box, IconButton } from '@material-ui/core';
import { findInArrayBy } from 'Helpers';
import { CALENDAR_ITEM_TYPES } from 'Constants';
import classnames from 'classnames';
import { useStyles, PERIOD_HEIGHT } from './BookingCalendarStyles';
import EventCard from './EventCard'

const DEFAULT_SELECTION = {
  id: null,
  note: null,
  end_time: null,
  start_time: null,
  booking_provider_id: null,
  start_index: null,
  start_period_index: null,
  end_period_index: null,
  type: '',
}

const DEFAULT_SELECTED_NOTES_STATE = {
  providerId: '',
  noteIds: [],
  data: [],
  topPosition: 0
}

function caledarItemsToDisplay(items) {
  if (!items) {
    return {}
  }

  return items.reduce((res, { booking_provider_id, data }) => {
    return {
      ...res,
      [booking_provider_id]: [
        ...res[booking_provider_id] || [],
        ...data.map((item) => {
          return {
            ...item,
            style: {
              top: `${item.start_period_index * PERIOD_HEIGHT}px`,
              height: `${((item.end_period_index - item.start_period_index) + 1) * PERIOD_HEIGHT}px`
            }
          }
        })
      ]
    }
  }, {})
}


const CalendarBody = React.forwardRef(({
  basicEvents, timeBlockEvents, providers, notes, company_url, showItemForm
}, ref) => {
  const classes = useStyles();

  const [selection, setSelection] = React.useState(DEFAULT_SELECTION);
  const dayDataRef = React.useRef(null);

  function onSelectStart(item, booking_provider_id, index, type) {
    if (!item.active) {
      return
    }

    return (e) => {
      e.stopPropagation();

      return setSelection({
        type,
        booking_provider_id,
        start_index: index,
        start_period_index: index,
        end_period_index: index,
        ...item,
      })
    }
  }

  function onSelecting(item, provider_id, index) {
    if (!selection.booking_provider_id || selection.booking_provider_id !== provider_id || !item.active) {
      return
    }

    return () => {
      if (index === selection.start_index) {
        return setSelection({
          ...selection,
          start_time: item.start_time,
          end_time: item.end_time,
          start_period_index: index,
          end_period_index: index,
        })
      }

      if (index < selection.start_index) {
        return setSelection({
          ...selection,
          start_time: item.start_time,
          start_period_index: index,
        })
      }

      setSelection({
        ...selection,
        end_time: item.end_time,
        end_period_index: index,
      })
    }
  }

  function onEndSelecting() {
    const providerPeriods = findInArrayBy(providers, selection.booking_provider_id).booking_periods

    showItemForm({
      id: selection.id,
      booking_provider_id: selection.booking_provider_id,
      start_period_id: providerPeriods[selection.start_period_index].id,
      end_period_id: providerPeriods[selection.end_period_index].id,
    }, selection.type)
  }


  React.useEffect(() => {
    if (selection.booking_provider_id) {
      dayDataRef.current.addEventListener("mouseup", onEndSelecting)

      return () => {
        dayDataRef.current.removeEventListener("mouseup", onEndSelecting)
      }
    }
  }, [selection]);

  const selectionBlockStyle = React.useMemo(() => {
    if (!selection.booking_provider_id) {
      return {}
    }
    
    const { start_period_index, end_period_index, color } = selection;

    return {
      top: `${start_period_index * PERIOD_HEIGHT}px`,
      height: `${(end_period_index - start_period_index + 1) * PERIOD_HEIGHT}px`,
      background: color,
    }
  }, [selection]);

  function handleSelectItem(item, type, provider_id) {
    return () => {
      showItemForm({
        id: item.id,
        booking_provider_id: provider_id,
        start_period_id: item.booking_period_start_id,
        end_period_id: item.booking_period_end_id,
      }, type)
    }
  }

  const providerBasicEventsToDisplay = React.useMemo(() => {
    return caledarItemsToDisplay(basicEvents)
  }, [basicEvents]);

  const providerTimeBlockEventsToDisplay = React.useMemo(() => {
    return caledarItemsToDisplay(timeBlockEvents)
  }, [timeBlockEvents]);

  const providerNotesToDisplay = React.useMemo(() => {
    return caledarItemsToDisplay(notes)
  }, [notes]);


  const [selectedNotes, changeSelectedNotes] = React.useState(DEFAULT_SELECTED_NOTES_STATE)

  function onNoteMouseMove(providerId) {
    return (e) => {
      //Displaying note info on hover logic

      e.persist()
      const elements = document.elementsFromPoint(e.clientX, e.clientY).filter((el) => {
        return el.getAttribute('data-target') === 'note'
      })

      const hoveredNotes = elements.map((item) => {
        return providerNotesToDisplay[providerId][item.getAttribute('data-index')]
      });

      const hoveredNotesIds = hoveredNotes.map(({ id }) => id)

      const isNoChanges = hoveredNotesIds.every(id => selectedNotes.noteIds.includes(id))
        && hoveredNotesIds.length === selectedNotes.noteIds.length

      if (!isNoChanges) {
        const wrapper = document.getElementById('calendar');
        const header = document.getElementById('calendar-head');
        const topPosition = e.clientY - wrapper.getBoundingClientRect().top - header.offsetHeight + wrapper.scrollTop;
        changeSelectedNotes((prevstate) => ({
          ...prevstate,
          providerId: providerId,
          noteIds: hoveredNotesIds,
          data: hoveredNotes,
          topPosition
        }))
      }
    }
  }

  function clearSelectedNotes() {
    changeSelectedNotes(DEFAULT_SELECTED_NOTES_STATE)
  }

  function clearSelectedItem() {
    if (selection.booking_provider_id) {
      setSelection(DEFAULT_SELECTION)
    }
  }

  React.useImperativeHandle(ref, () => ({
    clearSelectedItem,
  }));

  return (
    <Box ref={dayDataRef} className={classnames(classes.dayBodyColumns, {
      [classes.dayBodySelecting]: selection.booking_provider_id
    })}>
      {providers?.map(({ id, booking_periods }) => (
        <Box key={id} className={classes.dayBodyColumn}>
          <Box className={classes.notesWrapper}
            onMouseLeave={clearSelectedNotes}>
            {providerNotesToDisplay[id]?.map((item, index) => (
              <Box key={item.id} style={item.style}
                className={classnames(classes.bookingNote)}
                onMouseMove={onNoteMouseMove(id, item)} data-target="note"
                data-index={index}></Box>
            ))}
            {!!selectedNotes.data.length && selectedNotes.providerId === id && (
              <Box className={classes.notesDescrWrapper} style={{
                top: `${selectedNotes.topPosition}px`
              }}>
                {selectedNotes.data.map((item) => (
                  <Box className={classes.noteDescription} key={item.id}>
                    <IconButton size="small" className={classes.editEventIcon}
                      onClick={handleSelectItem(item, CALENDAR_ITEM_TYPES.NOTE, id)}>
                      <i className="icon-edit"></i>
                    </IconButton>
                    <span className={classes.noteTitle}>{item.start_time} - {item.end_time}</span>
                    <span className={classes.noteContent}>{item.message}</span>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          {providerBasicEventsToDisplay[id]?.map((item) => (
            <EventCard key={item.id} item={item} type={CALENDAR_ITEM_TYPES.BASIC_EVENT}
              providerId={id} isSelecting={!!selection.booking_provider_id}
              isHidden={item.id === selection.id}
              url={`${company_url}/booking_settings/booking_event_basics/${item.id}`}
              onResize={onSelectStart} onSelect={handleSelectItem}
            />
          ))}
          {providerTimeBlockEventsToDisplay[id]?.map((item) => (
            <EventCard key={item.id} item={item} type={CALENDAR_ITEM_TYPES.TIME_BLOCK_EVENT}
              providerId={id} isSelecting={!!selection.booking_provider_id}
              isHidden={item.id === selection.id}
              onResize={onSelectStart} onSelect={handleSelectItem}
            />
          ))}
          {booking_periods.map(({ id: uniqId, ...item }, index) => (
            <Box key={uniqId} className={classnames(classes.period, {
              [classes.disabledPeriod]: !item.active
            })} onMouseDown={onSelectStart(item, id, index)}
              onMouseEnter={onSelecting(item, id, index)} ></Box>
          ))}
          {selection.booking_provider_id === id && (
            <Box className={classnames(classes.selection, {
              [classes.eventSelection]: selection.id && selection.type === CALENDAR_ITEM_TYPES.BASIC_EVENT,
              [classes.onlyBlockedTimeEventSelection]: selection.id && selection.type === CALENDAR_ITEM_TYPES.TIME_BLOCK_EVENT
            })} style={selectionBlockStyle}>
              <p className={classes.eventTime}>
                {selection.start_time} - {selection.end_time}<br /> {selection.client_full_name}
              </p>
              <span className={classnames(classes.eventContent, 'event-note')}>{selection.notes}</span>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  )
});

export default CalendarBody