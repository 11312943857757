import {makeStyles, Box, IconButton, Button, Grid} from '@material-ui/core';
import moment from 'moment';
import {SEND_DATES_FORMAT, DAYS_COUNT_IN_WEEK} from 'Constants';

const INITIAL_START_WEEK = moment().startOf('week');
const INITIAL_DATE = moment().format(SEND_DATES_FORMAT)

const useStyles = makeStyles((theme) => ({
  weekHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 50px',

    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      padding: 0
    }
  },
  dayHeaderWrapper: {
    justifyContent: 'space-around'
  },
  dateBtn: {
    position: 'relative',
    textAlign: 'center',
    textTransform: 'capitalize',
    width: '100%',
    fontSize: '18px',
    fontWeight: 700,
    padding: '6px 12px',
    color: 'rgba(0, 0, 0, 0.33)',
    borderRadius: '4px',

    [theme.breakpoints.down('xs')]: {
      width: '70px',
      fontSize: '14px',
    }
  },
  activeDateBtn: {
    color: '#19456B',
  },
  selectedDateBtn: {

    '&::after': {
      position: 'absolute',
      bottom: '-1px',
      display: 'block',
      content: '""',
      width: '100%',
      height: '2px',
      backgroundColor: '#19456B',
      borderRadius: '2px'
    }
  },
  disabledBtn: {
    textDecoration: 'line-through',
    textDecorationColor: 'rgba(0, 0, 0, 0.54)'
  },
  icon: {
    width: '50px',
    height: '50px',
    flexShrink: 0,

    '&--right': {
      transformOrigin: 'center',
      transform: 'rotate(180deg)'
    }
  }
}));

export const HorizontalDatePicker = ({ activeDates, onChangeDate, changeSelectedDate, selectedDate }) => {
  const classes = useStyles();

  const currentStartWeek = moment(selectedDate).startOf('week');
  const currentEndWeek = moment(selectedDate).endOf('week');

  const isPrevBtnDisabled = currentStartWeek <= INITIAL_START_WEEK;
  const isNextBtnDisabled = currentEndWeek.format(SEND_DATES_FORMAT) > activeDates[activeDates.length - 1];

  const days = Array(DAYS_COUNT_IN_WEEK).fill(0)
    .map((el, index) => moment(currentStartWeek).add(index, 'day'));
  
  function onPrevWeekChange() {
    const prevDate = currentStartWeek.subtract(1, 'week')
    const maxDate = moment.max([prevDate, moment(INITIAL_DATE)])
    changeSelectedDate(maxDate.format(SEND_DATES_FORMAT));
  }

  function onNextWeekChange() {
    changeSelectedDate(currentStartWeek.add(1, 'week').format(SEND_DATES_FORMAT));
  }

  const renderDay = (momentDate) => {
    const dateToDisplay = momentDate.format('dd, DD.MM.YY');
    const date = momentDate.format(SEND_DATES_FORMAT);
    const isActive = activeDates.includes(date);
    const isDateSelected = date === selectedDate;
    const disabled = date < INITIAL_DATE;

    return (
      <Grid
        item
        key={dateToDisplay}
      >
        <Button
          className={classnames(classes.dateBtn, {
            [classes.activeDateBtn]: isActive,
            [classes.selectedDateBtn]: isDateSelected,
            [classes.disabledBtn]: disabled,
          })}
          onClick={() => onChangeDate(momentDate)}
          disabled={!isActive || disabled}
        >
          {dateToDisplay}
        </Button>
      </Grid>
    )
  }

  return (
      <Box className={classes.weekHeaderWrapper}>
        <IconButton
          className={classes.icon}
          onClick={onPrevWeekChange}
          disabled={isPrevBtnDisabled}
        >
          <i className="icon-left-open"></i>
        </IconButton>
        <Grid
          container
          spacing={1}
          className={classes.dayHeaderWrapper}
        >
        {days.map(renderDay)}
        </Grid>
        <IconButton
          className={`${classes.icon}--right`}
          onClick={onNextWeekChange}
          disabled={isNextBtnDisabled}
        >
          <i className="icon-left-open"></i>
        </IconButton>
      </Box>
    )
}
