import { useQuery } from '@tanstack/react-query';

import { HttpClient } from 'network/HttpClient';

export const CLIENT_QUERY_KEY = 'client';

export const useClientQuery = (fetchUrl: string) => {
  return useQuery([CLIENT_QUERY_KEY], () => HttpClient.getClient(fetchUrl), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
