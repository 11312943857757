import { useTranslation, } from 'react-i18next';
import { ListWrapper, Table, ListActions, Button, } from 'Components';
import { useList, } from 'Hooks';
import { deleteItem } from 'Helpers';
import { DEFAULT_SORT_PARAMS } from 'Constants';

export const BookingProviders = ({ url, fetch_url, new_item_url }) => {
  const { t } = useTranslation();

  const {
    pending, data, records_total, paginationParams, handleChangePagination, updateList
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  function onDelete(res) {
    if (res.flash_type == "success") {
      updateList()
    }
  }

  const tableConfig = [
    {
      title: t('Name'),
      accessor: 'name',
      cellComponent: (item) => {
        return (<div dangerouslySetInnerHTML={{ __html: item.name }}></div>)
      }
    },
    {
      title: t('Provider'),
      accessor: 'provider_type',
      cellComponent: (item) => {
        return item.provider_type == 'trainer' ? item.trainer : t(item.provider_type)
      }
    },
    {
      title: t('Actions'),
      cellComponent: ({ id }) => (
        <ListActions editUrl={`${url}/${id}/edit`} onDelete={deleteItem(fetch_url, { id }, onDelete)} />
      )
    }
  ];

  return (
    <ListWrapper title={`${t('Booking Providers')} (${data.length})`} headerAddon={(
      <div>
        <Button href={new_item_url} variant="contained" color="primary">
          {t('Create Booking Provider')}
        </Button>
      </div>
    )}>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
}
