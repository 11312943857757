import { makeStyles, Box, Grid, Button, Card } from '@material-ui/core';
import moment from 'moment';
import classnames from 'classnames';
import { SEND_DATES_FORMAT, DISPLAY_DATES_FORMAT } from 'Constants';
import { AJAX, getParamFromQueryString, toQueryString } from 'Helpers';
import { Preloader } from 'Components';
import bookingCardsClasses from '../BookingCardsStyles';
import { HorizontalDatePicker } from '../HorizontalDatePicker';

const TODAY = moment();
const QUERY_DATE_STRING = getParamFromQueryString('date') || TODAY.format(SEND_DATES_FORMAT)
const INITIAL_DATE = moment.max([moment(QUERY_DATE_STRING), TODAY]).format(SEND_DATES_FORMAT)

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 8px',

    [theme.breakpoints.down('xs')]: {
      display: 'block',
      padding: 0
    }
  },
  col: {
    display: 'flex'
  },
  content: {
    width: '100%'
  },
  dateInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  timeInfo: {
    textAlign: 'center'
  },
  currentDate: {
    marginRight: '25px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    }
  },
  btn: {
    width: '100%',
    background: theme.palette.primary.main,
    fontSize: '20px'
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center'
  },
  periodsContainer: {
    justifyContent: 'center'
  },
  providerContainer: {
    display: 'flex',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    marginBottom: '16px',
    paddingBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  },
  providerContainer__providerInfo: {
    width: '300px',
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      width: '225px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto 22px',
      width: 'auto',
      maxWidth: '300px'
    }
  },
  providerContainer__providerCard: {
    padding: '8px 16px'
  },
  providerContainer__providerName: {
    fontSize: '20px',
    fontWeight: 700,
    margin: '12px 0'
  },
  providerContainer__providerPeriods: {
    paddingLeft: '32px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  }
}));

export const ClientBookingPeriods = ({ url, is_any_provider, active_dates }) => {
  const classes = useStyles();
  const cardsClasses = bookingCardsClasses();

  const [selectedDate, changeSelectedDate] = React.useState(INITIAL_DATE);
  const [periods, setPeriods] = React.useState({
    pending: true,
    data: []
  })

  function handleChangeDate(date) {
    changeSelectedDate(date.format(SEND_DATES_FORMAT))
  }

  function setQueryString() {
    const queryDate = getParamFromQueryString('date');
    if (!queryDate || queryDate !== selectedDate) {
      const dateQuery = toQueryString({ date: selectedDate });
      window.history.pushState('', '', `${url}?${dateQuery}`);
    }
  }

  async function fetchTimePeriods() {
    setPeriods({
      ...periods,
      pending: true,
    })
    const res = await AJAX.get(url, {
      body: {
        format: 'json',
        date: selectedDate
      }
    });

    return setPeriods({
      data: res?.data || [],
      pending: false,
    })
  }

  function setSelectedDateByQuery() {
    const queryDate = getParamFromQueryString('date');
    if (queryDate) {
      changeSelectedDate(queryDate);
    }
  }

  React.useEffect(() => {
    if (selectedDate) {
      setQueryString();
      fetchTimePeriods();
    }

    window.addEventListener('popstate', setSelectedDateByQuery);

    return () => {
      window.removeEventListener('popstate', setSelectedDateByQuery);
    }
  }, [selectedDate])

  const dateToDisplay = React.useMemo(() => {
    return moment(selectedDate).format(DISPLAY_DATES_FORMAT)
  }, [selectedDate])

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <HorizontalDatePicker
          activeDates={active_dates}
          onChangeDate={handleChangeDate}
          selectedDate={selectedDate}
          changeSelectedDate={changeSelectedDate}
        />
        <Box className={classes.dateInfo}>
          <h1 className={classes.currentDate}>{dateToDisplay}</h1>
          <h2>Оберіть час</h2>
        </Box>
        {periods.pending && (
          <Box className={classes.preloader}>
            <Preloader />
          </Box>
        )}
        {!is_any_provider && !periods.pending && !!periods.data.length && (
          <Grid
            container
            spacing={2}
            className={classes.periodsContainer}
          >
            {periods.data.map(({ start_time, url }) => (
              <Grid
                key={start_time}
                item
                xs={6}
                sm={6}
                md={2}
                className={classes.col}
              >
                <Button
                  className={classes.btn}
                  href={url}
                  variant="contained"
                  color="primary"
                  component="a"
                >
                  {start_time}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
        {!!is_any_provider && !periods.pending && !!periods.data.length && (
          <>
            {periods.data.map(({
              available_times, booking_provider_id, booking_provider_name, booking_provider_photo_url
            }) => (
              <div
                key={booking_provider_id}
                className={classes.providerContainer}
              >
                  <div className={classes.providerContainer__providerInfo}>
                  <Card
                    elevation={8}
                    className={classes.providerContainer__providerCard}
                  >
                    <Box
                      className={classes.providerContainer__providerName}
                      dangerouslySetInnerHTML={{ __html: booking_provider_name }}
                    >
                    </Box>
                    <Box className={classnames(cardsClasses.scalableImageWrapper, `${cardsClasses.scalableImageWrapper}--squared`)}>
                      <img
                        src={booking_provider_photo_url}
                        className={cardsClasses.scalableImage}
                        alt=""
                      />
                    </Box>
                    </Card>
                  </div>
                  <div className={classes.providerContainer__providerPeriods}>
                  <Grid
                    container
                    spacing={2}
                  >
                    {available_times.map(({ start_time, url }) => (
                      <Grid
                        key={start_time}
                        item
                        xs={6}
                        sm={6}
                        md={3}
                        lg={2}
                        className={classes.col}
                      >
                        <Button
                          className={classes.btn}
                          href={url}
                          variant="contained"
                          color="primary"
                          component="a"
                        >
                          {start_time}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            ))}
          </>
        )}

        {!periods.pending && !periods.data.length && (
          <p className={classes.timeInfo}>Немає вільного часу</p>
        )}
      </Box>
    </Box>
  )
}
