export const Companies = {
  en: {
    Name: 'Name',
    'Create Company': 'Create Company',
    'Required client signature photo': 'Required client signature photo',
    'Client insurance signature included': 'Client insurance signature included',
    'Force location selection': 'Force location selection'
  },
  ru: {
    Name: 'Название',
    'Create Company': 'Создать Компанию',
    'Required client signature photo': 'Требуется фото подписи клиента',
    'Client insurance signature included': 'Подпись страхования у клиента'
  },
  uk: {
    Name: 'Назва',
    'Create Company': 'Створити Компанію',
    'Required client signature photo': "Обов'язкове фото підпису клієнта",
    'Client insurance signature included': 'Підпис страхування в клієнта',
    'Force location selection': 'Примусовий вибір локації'
  },
};

export const CompanyForm = {
  en: {
    ...Companies.en,
    'New Company': 'New Company',
    'Edit Company': 'Edit Company',
    'Update Company': 'Update Company',
  },
  ru: {
    ...Companies.ru,
    'New Company': 'Создание Компании',
    'Edit Company': 'Редактирование Компании',
    'Update Company': 'Обновить Компанию',
  },
  uk: {
    ...Companies.uk,
    'New Company': 'Створення Компанії',
    'Edit Company': 'Редагування Компанії',
    'Update Company': 'Оновити Компанію',
  },
};
