export const BookingEmailTemplateForm = {
  en: {
    Subject: 'Subject',
    Body: 'Body',
    'Body parametrs': 'Body parametrs',
    'Subject parametrs': 'Subject parametrs',
    'Edit Booking Email Template': 'Edit Booking Email Template',
    'Update Booking Email Template': 'Update Booking Email Template',
    'Booking service name': 'Booking service name',
    'Booking date': 'Booking date',
    'Booking provider': 'Booking provider',
    'Booking service description': 'Booking service description',
    'Client first name': 'Client first name',
    'Successfully copied': 'Successfully copied',
    'Booking location': 'Booking location'
  },
  uk: {
    Subject: 'Тема',
    Body: 'Основний текст',
    'Body parametrs': 'Параметри основного тексту',
    'Subject parametrs': 'Параметри теми',
    'Edit Booking Email Template': 'Редагування Емейл Шаблону',
    'Update Booking Email Template': 'Оновити поштовий шаблон',
    'Booking service name': 'Назва букінг сервісу',
    'Booking date': 'Дата початку букінга',
    'Booking provider': 'Букінг провайдер',
    'Booking service description': 'Опис букінг сервіса',
    'Client first name': 'Імʼя клієнта',
    'Successfully copied': 'Успішно скопійовано',
    'Booking location': 'Локація'
  },
};

export const BookingEmailTemplates = {
  en: {
    'Create Booking Email Templates': 'Create Booking Email Templates',
    'Template for training': 'Template for training',
    'Template for class': 'Template for class',
    'Template for spot': 'Template for spot',
    Name: 'Name',
    'Template type': 'Template type',
    'Booking services': 'Booking services',
    'Test email form': 'Test email form',
    'Email': 'Email',
    'Send test email': 'Send test email',
    'First name': 'First name'
  },
  uk: {
    'Create Booking Email Templates': 'Створити емейл шаблони',
    'Template for training': 'Шаблон для тренування',
    'Template for class': 'Шаблон для класу',
    'Template for spot': 'Шаблон для події',
    Name: 'Назва',
    'Template type': 'Тип шаблону',
    'Booking services': 'Букінг сервіси',
    'Test email form': 'Форма тестового емейлу',
    'Email': 'Емейл',
    'Send email': 'Відправити лист',
    'First name': 'Імʼя'
  },
};

export const TestEmailForm = {
    ...BookingEmailTemplates,
};
