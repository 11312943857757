import { Typography, makeStyles, Box, Grid } from '@material-ui/core';
import { CardItem } from '../CardItem';
import { toMoneyFormat } from 'Helpers';
import bookingCardsClasses from '../BookingCardsStyles';
import DescriptionDialog from '../DescriptionDialog';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '16px'
  },
  serviceName: {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: 'auto'
  },
  price: {
    fontSize: '32px',
  },
  oldPrice: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through',
    fontSize: '16px',
  },
}));

export const ClientBookingServices = ({ booking_services, url }) => {
  const classes = useStyles();
  const cardsClasses = bookingCardsClasses();

  const descriptionDialogRef = React.useRef(null);

  function openDescriptionDialog(descriptionData) {
    descriptionDialogRef.current.open(descriptionData)
  }

  const renderBookingService = ({ id, name, description, price, price_with_discount, photo_url }) => {
    const isPriceWithDiscount = price_with_discount != price;
    return (
      <Grid
        key={id}
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
      >
      <CardItem
        elevation={8}
        className={classes.card}
        href={`${url}/${id}/booking_providers`}
        description={description}
        name={name}
        openDescriptionDialog={openDescriptionDialog}
      >
        <span
          className={classes.serviceName}
          dangerouslySetInnerHTML={{ __html: name }}
        >
        </span>
        <Box className={cardsClasses.scalableImageWrapper}>
          <img
            src={photo_url}
            className={cardsClasses.scalableImage}
            alt=""
          />
        </Box>
        {isPriceWithDiscount && (
          <Typography
            component="h3"
            className={classes.oldPrice}
          >
            {toMoneyFormat(price)}
          </Typography>
        )}
        <Typography
          component="h3"
          className={classes.price}
        >
          {toMoneyFormat(isPriceWithDiscount ? price_with_discount : price)}
        </Typography>
      </CardItem>
    </Grid>
    )
  }

  return (
    <Box className={cardsClasses.cards}>
      <h1>Оберіть послугу</h1>
      <Grid
        container
        spacing={2}
      >
        {booking_services.map(renderBookingService)}
      </Grid>
      <DescriptionDialog ref={descriptionDialogRef} />
    </Box>
  )
}
