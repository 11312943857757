import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button } from 'Components';

const DEFAULT_STATE = {
  text: '',
  isOpened: false
}

export const InfoDialog = React.forwardRef(({ onClose }, ref) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState(DEFAULT_STATE);

  function open(text) {
    setState({ text, isOpened: true });
  }

  function close() {
    setState(DEFAULT_STATE);
    onClose()
  }


  React.useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Dialog open={state.isOpened} maxWidth="xs"
      aria-labelledby="confirmation-dialog-title">
      <DialogContent dividers>
        {state.text}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  );
});
