import { useTranslation } from 'react-i18next';

import { useFilters, usePagination } from 'Hooks';

import DiscountTable from '../DiscountTable';
import { useClientContext } from '../providers/ClientShowProvider';
import { DEFAULT_SORT_PARAMS, PAGINATION_PARAMS } from '../ClientShow';

import { useCurrentActiveDiscountsQuery } from 'queries';

const initialFilters = { current_active_or_frozen_only_discounts: true };

const CurrentActiveDiscounts = () => {
  const { t } = useTranslation();

  const { purchased_services_url } = useClientContext();

  const paginationData = usePagination(PAGINATION_PARAMS, DEFAULT_SORT_PARAMS);
  const { paginationParams, handleChangePagination } = paginationData;
  const { filters } = useFilters(initialFilters);

  const { data: currentActiveDiscounts, isLoading } =
    useCurrentActiveDiscountsQuery({
      fetchUrl: purchased_services_url,
      paginationParams,
      filters,
    });

  const { data: services, records_total } = currentActiveDiscounts || {};

  return (
    <DiscountTable
      title={t('Current Active Discounts')}
      recordsTotal={records_total}
      services={services}
      onChangePaginationParams={handleChangePagination}
      paginationParams={paginationParams}
      pending={isLoading}
    />
  );
};

export default CurrentActiveDiscounts;