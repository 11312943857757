import { Select } from 'Components';

export const SelectField = ({
  custom_field,
  onChange,
  value,
  validationMessages,
}) => {
  const { id, required, label } = custom_field;
  const options = () => {
    const optionsArray = custom_field.option_values.split('\n');
    const options = optionsArray.map((option, index) => {
      return {
        id: index,
        name: option,
      };
    });
    return options;
  };

  return (
    <Select
      key={id}
      required={required}
      label={label}
      onChange={(e) => {
        onChange(e, custom_field);
      }}
      options={options()}
      value={value}
      validationMessages={validationMessages}
    />
  );
};
